import React, { useState } from "react";
import { BsSearch, BsXCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom/cjs/react-router-dom";

const MarketOverviewTableNav = ({ setNavActive }) => {
    const [navActive, setSelectedNavData] = useState("All Crypto");
    const [searchQuery, setSearchQuery] = useState("");

    const handleNavClick = (tabName) => {
        setSelectedNavData(tabName);
        setNavActive(tabName);
        if (tabName !== "Search") {
            setSearchQuery("");
        }
    };

    const handleSearchFilter = (e) => {
        setSearchQuery(e.target.value);
        setSelectedNavData("Search");
        setNavActive("Search");
    };

    return (
        <div className="market-data-navigation container">
            <div className="market-data-navigation-wrapper">
                <div className="market-data-navigation-container bg-gray-100 container flex items-center justify-between flex-wrap ">
                    <nav className="market-data-navigation">
                        <ul className="market-data-navigation__ul flex items-center gap-2 ">
                            {navActive === "Search" ? (
                                <li
                                    className={`market-data-navigation__ul__li px-4 pt-2 py-3 border-b-1 w-max flex gap-3 items-center ${
                                        navActive === "Search"
                                            ? "border-[#EFB81C] bg-[white]"
                                            : "border-transparent"
                                    }`}
                                >
                                    <Link
                                        to="#!"
                                        title=""
                                        className=""
                                        onClick={() => handleNavClick("Search")}
                                    >
                                        <span
                                            className={`text-[18px] font-[600] w-max ${
                                                navActive === "Search"
                                                    ? "opacity-100"
                                                    : "opacity-50"
                                            }`}
                                        >
                                            Search
                                        </span>
                                    </Link>

                                    <button
                                        type="reset"
                                        className="mt-0.5"
                                        onClick={() =>
                                            handleNavClick("All Crypto")
                                        }
                                    >
                                        <BsXCircleFill
                                            size={12}
                                            className="text-[#151415] hover:text-[#FF0000]"
                                        />
                                    </button>
                                </li>
                            ) : (
                                <>
                                    <li
                                        className={`market-data-navigation__ul__li pr-4 pt-2 py-3 border-b-1 w-max ${
                                            navActive === "Favorites"
                                                ? "border-[#EFB81C] "
                                                : "border-transparent"
                                        }`}
                                    >
                                        <Link
                                            to="#!"
                                            title=""
                                            className=""
                                            onClick={() =>
                                                handleNavClick("Favorites")
                                            }
                                        >
                                            <span
                                                className={`text-[16px] font-[600] w-max ${
                                                    navActive === "Favorites"
                                                        ? "opacity-100"
                                                        : "opacity-50"
                                                }`}
                                            >
                                                Favorites
                                            </span>
                                        </Link>
                                    </li>

                                    <li
                                        className={`market-data-navigation__ul__li px-4  pt-2 py-3 border-b-1 w-max ${
                                            navActive === "All Crypto"
                                                ? "border-[#EFB81C] "
                                                : "border-transparent"
                                        }`}
                                    >
                                        <Link
                                            to="#!"
                                            title=""
                                            className=""
                                            onClick={() =>
                                                handleNavClick("All Crypto")
                                            }
                                        >
                                            <span
                                                className={`text-[16px] font-[600] w-max ${
                                                    navActive === "All Crypto"
                                                        ? "opacity-100"
                                                        : "opacity-50"
                                                }`}
                                            >
                                                All Crypto
                                            </span>
                                        </Link>
                                    </li>

                                    <li
                                        className={`market-data-navigation__ul__li px-4 pt-2 py-3 border-b-1 w-max ${
                                            navActive === "Spot/Margin"
                                                ? "border-[#EFB81C] "
                                                : "border-transparent"
                                        }`}
                                    >
                                        <Link
                                            to="#!"
                                            title=""
                                            className=""
                                            onClick={() =>
                                                handleNavClick("Spot/Margin")
                                            }
                                        >
                                            <span
                                                className={`text-[16px] font-[600] w-max ${
                                                    navActive === "Spot/Margin"
                                                        ? "opacity-100"
                                                        : "opacity-50"
                                                }`}
                                            >
                                                Spot/Margin
                                            </span>
                                        </Link>
                                    </li>

                                    <li
                                        className={`market-data-navigation__ul__li px-4 pt-2 py-3 border-b-1 w-max ${
                                            navActive === "Futures"
                                                ? "border-[#EFB81C] "
                                                : "border-transparent"
                                        }`}
                                    >
                                        <Link
                                            to="#!"
                                            title=""
                                            className=""
                                            onClick={() =>
                                                handleNavClick("Futures")
                                            }
                                        >
                                            <span
                                                className={`text-[16px] font-[600] w-max ${
                                                    navActive === "Futures"
                                                        ? "opacity-100"
                                                        : "opacity-50"
                                                }`}
                                            >
                                                Futures
                                            </span>
                                        </Link>
                                    </li>

                                    <li
                                        className={`market-data-navigation__ul__li px-4 pt-2 py-3 border-b-1 w-max ${
                                            navActive === "Pre-Listed"
                                                ? "border-[#EFB81C] "
                                                : "border-transparent"
                                        }`}
                                    >
                                        <Link
                                            to="#!"
                                            title=""
                                            className=""
                                            onClick={() =>
                                                handleNavClick("Pre-Listed")
                                            }
                                        >
                                            <span
                                                className={`text-[16px] font-[600] w-max ${
                                                    navActive === "Pre-Listed"
                                                        ? "opacity-100"
                                                        : "opacity-50"
                                                }`}
                                            >
                                                Pre-Listed
                                            </span>
                                        </Link>
                                    </li>
                                    <li
                                        className={`market-data-navigation__ul__li px-4 pt-2 py-3 border-b-1 w-max ${
                                            navActive === "New Listed"
                                                ? "border-[#EFB81C] "
                                                : "border-transparent"
                                        }`}
                                    >
                                        <Link
                                            to="#!"
                                            title=""
                                            className=""
                                            onClick={() =>
                                                handleNavClick("New Listed")
                                            }
                                        >
                                            <span
                                                className={`text-[16px] font-[600] w-max ${
                                                    navActive === "New Listed"
                                                        ? "opacity-100 "
                                                        : "opacity-50"
                                                }`}
                                            >
                                                New Listed
                                            </span>
                                        </Link>
                                    </li>
                                </>
                            )}
                        </ul>
                    </nav>

                    <div className="search-container">
                        <div className="search-wrapper flex items-center gap-2 rounded-md pl-3 bg-gray-100 border-2">
                            <BsSearch size={15} fill="#151415" />
                            <input
                                type="text"
                                id="market-data-search"
                                name="market-data-search"
                                className="px-3 py-1 bg-gray-100 border-non outline-none focus:outline-none focus:border-none text-[14px] placeholder:14px"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={handleSearchFilter}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketOverviewTableNav;
