import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { verifyEmail, resendEmailOtp } from "store/actions/authAction";
import toast from "react-hot-toast";
import EmailVerificationHelper from "./emailVerificationHelp";
import { X } from "lucide-react";
import logo from "assets/img/logo/buycex-yellow.png";

function EmailVerification({ onSuccess = null, onClose }) {
    const { sid } = useSelector((state) => state.authUser);

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState(Array(6).fill(""));
    const [showModal, setShowModal] = useState(false);

    const [timer, setTimer] = useState({ minutes: 0, seconds: 59 });
    const [isCountdownComplete, setIsCountdownComplete] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();
    const inputRefs = useRef([]);

    useEffect(() => {
        const storedEmail = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedUsername = localStorage.getItem("username");
        if (storedUsername) setUsername(storedUsername);
        if (storedEmail) setEmail(storedEmail);
        if (storedPassword) setPassword(storedPassword);
    }, []);

    const resendCode = async () => {
        const payload = { sid };

        if (!sid) {
            toast.error("Email is required to resend OTP.");
            return;
        }

        try {
            await dispatch(resendEmailOtp(payload));
            setTimer({ minutes: 0, seconds: 59 });
            setIsCountdownComplete(false);
        } catch (error) {
            toast.error("Failed to resend OTP");
            console.error("Error resending email OTP:", error);
        }
    };

    useEffect(() => {
        const countdown = setInterval(() => {
            if (timer.seconds === 0) {
                if (timer.minutes === 0) {
                    setIsCountdownComplete(true);
                    clearInterval(countdown);
                } else {
                    setTimer({ minutes: timer.minutes - 1, seconds: 59 });
                }
            } else {
                setTimer((prev) => ({ ...prev, seconds: prev.seconds - 1 }));
            }
        }, 1000);

        return () => clearInterval(countdown);
    }, [timer]);

    const handleResendCode = () => {
        if (isCountdownComplete) {
            resendCode();
        } else {
            toast.error(
                "Please wait until the timer finishes before resending the code."
            );
        }
    };

    const handleOtpChange = (e, index) => {
        const { value } = e.target;
        const newOtp = [...otp];
        newOtp[index] = value.slice(-1);
        setOtp(newOtp);

        if (e.key === "Backspace") {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        } else if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handlePaste = (e) => {
        const paste = e.clipboardData.getData("text");
        if (paste.length === 6 && /^\d+$/.test(paste)) {
            const newOtp = paste.split("");
            setOtp(newOtp);
            inputRefs.current.forEach((input, i) => {
                input.value = newOtp[i];
            });
        }
    };

    const verifyOtp = async (e) => {
        e.preventDefault();
        const otpString = otp.join("");
        const payload = { sid, code: parseInt(otpString) };

        try {
            await dispatch(
                verifyEmail(payload, () => {
                    if (onSuccess) {
                        onSuccess();
                    } else {
                        history.push("/login");
                    }
                })
            );
            toast.success("Email verified successfully, Please login");
        } catch (error) {
            toast.error("Failed to verify email");
            console.error("Error verifying email:", error);
        }
    };

    return (
        <div className="fixed inset-0 bg-[#151415] bg-opacity-70 backdrop-blur-sm">
            <div className="bg-white absolute inset-0 flex-col z-[5] flex gap-2 items-start justify-start w-max h-max m-auto text-[#000] p-8 rounded-xl">
                <button onClick={onClose} className="absolute right-3 top-3">
                    <X className="w-[20px] h-[20px] opacity-50" />
                </button>
                <img src={logo} className="w-[200px] mb-5 self-center" />
                <div className="mr-auto mb-2">
                    <h1 className="text-2xl font-bold">Email Verification</h1>
                    <p className="opacity-60 font-medium pt-1 text-xs">
                        Enter the 6 Digit Email Verification code
                    </p>
                </div>
                <form onSubmit={verifyOtp}>
                    <p className="text-sm pb-1">Verification Code</p>
                    <div>
                        <div></div>
                        <div className="flex gap-2" onPaste={handlePaste}>
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    ref={(el) =>
                                        (inputRefs.current[index] = el)
                                    }
                                    className="border border-slate-400 w-[50px] h-[50px] text-center rounded-lg bg-transparent"
                                    type="text"
                                    maxLength="1"
                                    value={digit}
                                    onChange={(e) => handleOtpChange(e, index)}
                                    required
                                />
                            ))}
                        </div>
                        <div
                            className="flex gap-2 text-[#F4F4F4] items-center pt-1 justify-end"
                            onClick={handleResendCode}
                        >
                            <span className="text-[#F1BA19] cursor-pointer">
                                Send code again
                            </span>
                            <span className="text-[#918f8f]">
                                {isCountdownComplete
                                    ? ""
                                    : ` ${timer.minutes
                                          .toString()
                                          .padStart(2, "0")}:${timer.seconds
                                          .toString()
                                          .padStart(2, "0")}`}
                            </span>
                        </div>
                        <button
                            type="submit"
                            className="bg-[#EFB81C] rounded-lg py-2 text-center w-full mt-3 font-medium"
                        >
                            Verify
                        </button>
                    </div>
                </form>
                <div
                    onClick={() => setShowModal(true)}
                    className="text-[#EFB81C] text-sm font-medium self-end"
                >
                    Didn't receive code?
                </div>
                <EmailVerificationHelper
                    email={email}
                    visible={showModal}
                    onClose={() => setShowModal(false)}
                />
            </div>
        </div>
    );
}

export default EmailVerification;
