import React, { useState, useEffect, useRef } from 'react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentOrders } from 'store/actions/LaunchGuardAction';

function CurrentOrders() {
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('All');

    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);

    const currentOrders = useSelector((state) => state.lgData?.currentOrder?.data || []);

    useEffect(() => {
        if (userID && userToken) {
            dispatch(fetchCurrentOrders(userID, userToken));
        }
    }, [dispatch, userID, userToken]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
   

 

    const formatDate = (timestamp) => {
        return new Date(timestamp * 1000).toLocaleDateString();
    };

    // 🔹 Filter only orders where status is "0"
    const filteredOrders = currentOrders.filter(order => order.status === "0");
    const options = ["All", ...new Set(filteredOrders.map((item) => item.coinname))];

    return (
        <>
            {/* Dropdown */}
            <div className="relative w-64 mt-5" ref={dropdownRef}>
                <p className="mb-1">Coin</p>
                <div
                    className="flex items-center justify-between border px-4 py-2 rounded-md cursor-pointer"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                    <p className="font-semibold text-gray-700">{selectedOption}</p>
                    {isDropdownOpen ? <IoChevronUp size={16} /> : <IoChevronDown size={16} />}
                </div>

                {isDropdownOpen && (
                    <div className="absolute top-full mt-2 w-full bg-white border rounded-md shadow-lg z-10">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-gray-700"
                                onClick={() => {
                                    setSelectedOption(option);
                                    setIsDropdownOpen(false);
                                }}
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Orders Table */}
            <div className="w-full mt-5">
                {/* Table Header */}
                <div className="flex justify-between bg-gray-100 p-3">
                    <p className="flex-1 text-sm text-gray-500 text-start">Coin</p>
                    <p className="flex-1 text-sm text-gray-500 text-start">Project name</p>
                    <p className="flex-1 text-sm text-gray-500 text-start">Token Price</p>
                    <p className="flex-1 text-sm text-gray-500 text-start">Amount</p>
                    <p className="flex-1 text-sm text-gray-500 text-start">Total Cost</p>
                    <p className="flex-1 text-sm text-gray-500 text-start">Convert Price</p>
                    <p className="flex-1 text-sm text-gray-500 text-start">Start Date</p>
                    <p className="flex-1 text-sm text-gray-500 text-start">End Date</p>
                </div>

                {/* Table Body */}
                <div className="h-96 overflow-y-auto">
                    {filteredOrders.length > 0 ? (
                        filteredOrders.map((order, index) => (
                            <div key={index} className="flex justify-between w-full border-b p-3">
                                <p className="flex-1 text-sm text-gray-700 text-start">{order.coinname}</p>
                                <p className="flex-1 text-sm text-gray-700 text-start">{order.name}</p>
                                <p className="flex-1 text-sm text-gray-700 text-start">{order.price}</p>
                                <p className="flex-1 text-sm text-gray-700 text-start">{order.num}</p>
                                <p className="flex-1 text-sm text-gray-700 text-start">{order.mum}</p>
                                <p className="flex-1 text-sm text-gray-700 text-start">{order.convert_price}</p>
                                <p className="flex-1 text-sm text-gray-700 text-start">{formatDate(order.addtime)}</p>
                                <p className="flex-1 text-sm text-gray-700 text-start">{formatDate(order.endtime)}</p>
                            </div>
                        ))
                    ) : (
                        <div className="w-full flex justify-center items-center h-96">
                            <p className="text-gray-500 text-sm font-medium">No orders available</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default CurrentOrders;
