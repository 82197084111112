import React, { useEffect, useState } from "react";
import CoinCard from "./CoinCard";
import DPSTable from "./DPSTable";
import { useDispatch, useSelector } from "react-redux";
import { accountsummary, fetchEarnStats } from "store/actions/EarnStaking";
const heroImages = [HeroImageOne, HeroImageTwo, HeroImageThird];
import HeroImageOne from "assets/img/Aboutsection4.png";
import HeroImageTwo from "assets/img/algo-orders.png";
import HeroImageThird from "assets/img/Aboutsection3.png";

const CryptoDPS = () => {
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const [earnStats, setEarnStats] = useState({
        totalUsersJoined: 0,
        totalDeposits: "0",
        growthPercentage: 0,
        projects: 0,
        thisMonthJoined: "0",
        lastMonthJoined: "0",
    });
    const [depositData, setDepositData] = useState({
        deposit_completed: "0",
        deposit_missed: "0",
        auto_deduct: "On",
        total_installments: "0",
        monthly_deposit: "0",
        weekly_deposit: "0",
        total_to_deposit: "0",
        due: "0",
        coin: "cvm",
    });
    const [combinedMonths, setCombinedMonths] = useState(0);

    const calculateCombinedMonths = () => {
        const totalMonths = parseFloat(depositData.monthly_deposit || 0);
        const totalWeeks = parseFloat(depositData.weekly_deposit || 0);
        const weeksAsMonths = totalWeeks / 4;
        const combined = totalMonths + weeksAsMonths;
        setCombinedMonths(combined.toFixed(2)); // Keeping two decimal places
    };

    useEffect(() => {
        calculateCombinedMonths();
    }, [depositData.monthly_deposit, depositData.weekly_deposit]);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(
                    fetchEarnStats(userID, userToken)
                );
                const data = response.data.data;

                setEarnStats({
                    totalUsersJoined: data.total_users_joined,
                    totalDeposits: data.total_deposits,
                    growthPercentage: data.growth_percentage,
                    projects: data.projects,
                    thisMonthJoined: data.this_month_joined,
                    lastMonthJoined: data.last_month_joined,
                });
            } catch (error) {
                console.error("Error fetching staking data:", error.message);
            }
        };

        if (userID && userToken) {
            fetchData();
        }
    }, [dispatch, userID, userToken]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(
                    accountsummary(userID, userToken)
                );
                const data = response.data.data;

                if (Array.isArray(data) && data.length > 0) {
                    const totals = data.reduce(
                        (acc, item) => {
                            acc.total_to_deposit += parseFloat(
                                item.total_to_deposit || 0
                            );
                            acc.deposit_completed += parseFloat(
                                item.deposit_completed || 0
                            );
                            acc.deposit_missed += parseFloat(
                                item.deposit_missed || 0
                            );
                            acc.total_installments += parseFloat(
                                item.total_installments || 0
                            );
                            acc.monthly_deposit += parseFloat(
                                item.monthly_deposit || 0
                            );
                            acc.weekly_deposit += parseFloat(
                                item.weekly_deposit || 0
                            );
                            acc.due += parseFloat(item.due || 0);
                            acc.coin = item.coin;
                            acc.auto_deduct;
                            return acc;
                        },
                        {
                            total_to_deposit: 0,
                            deposit_completed: 0,
                            deposit_missed: 0,
                            total_installments: 0,
                            monthly_deposit: 0,
                            weekly_deposit: 0,
                            due: 0,
                            coin: "cvm",
                            auto_deduct: "On",
                        }
                    );

                    setDepositData(totals);
                } else {
                    console.warn("Data is not in expected format or is empty.");
                }
            } catch (error) {
                console.error("Error fetching staking data:", error.message);
            }
        };

        if (userID && userToken) {
            fetchData();
        }
    }, [dispatch, userID, userToken]);
    const flippingWords = [
        "Deposit USDT To Lock Token Before its Officially Listed in Exchange.",
        "Unlock USDT With Guarded Refund Any Time Without Assets Loss.",
    ];
    const [currentWord, setCurrentWord] = useState("Exchange Crypto");
    const [flipState, setFlipState] = useState("flip-enter");
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageTransitionState, setImageTransitionState] = useState("fade-in");

    useEffect(() => {
        const wordInterval = setInterval(() => {
            setFlipState("flip-exit");

            setTimeout(() => {
                setCurrentWord((prev) => {
                    const nextIndex =
                        (flippingWords.indexOf(prev) + 1) %
                        flippingWords.length;
                    return flippingWords[nextIndex];
                });
                setFlipState("flip-enter");
            }, 600);
        }, 3000);

        return () => clearInterval(wordInterval);
    }, []);

    useEffect(() => {
        const imageInterval = setInterval(() => {
            setImageTransitionState("fade-out");

            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => {
                    return (prevIndex + 1) % heroImages.length;
                });
                setImageTransitionState("fade-in");
            }, 600);
        }, 3000);

        return () => clearInterval(imageInterval);
    }, []);

    return (
        <div className="bg-[#f5f7fa] pb-10">
            <div className="bg-[#f5f7fa] ">
                <div classname="earn-overview">
                    <section className="hero-section-black flex relative">
                        <div className="hero-section__wrapper-black container flex items-center justify-between gap-8 w-full m-auto max-md:flex-col">
                            <div className="hero-title-wrapper-black flex flex-col gap-2 w-[60%]">
                                <h1 className="hero-title-black flex gap-2">
                                    <span className="text-[32px] font-[900] text-[#EFB81C]">
                                        CRYPTO
                                    </span>
                                    <span className="text-white text-[32px]">
                                        DPS
                                    </span>
                                </h1>
                                <div className="flex w-[70%] justify-between items-center">
                                    <h1 className="hero-title-black font-bold uppercase text-white">
                                        <p>Deposit Monthly Get Back Double Amount!</p>
                                    </h1>
                                </div>
                                <div className="flex gap-6 text-[#EFB81C] items-center flex-wrap">
                                    <div>
                                        <p className="text-[#adb1b8] text-[14px] font-semibold">
                                            Committed Amount
                                        </p>
                                        <p className="text-[18px] font-semibold">
                                            ${earnStats.totalDeposits}
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-6">
                                        <div className="w-[2px] bg-[#adb1b8] h-8"></div>
                                        <div>
                                            <p className="text-[#adb1b8] text-[14px] font-semibold">
                                                AVG. Growth
                                            </p>
                                            <p className="text-[18px] font-semibold">
                                                {parseFloat(
                                                    earnStats.growthPercentage
                                                ).toFixed(2)}
                                                %
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-6">
                                        <div className="w-[2px] bg-[#adb1b8] h-8"></div>
                                        <div>
                                            <p className="text-[#adb1b8] text-[14px] font-semibold">
                                                Participants
                                            </p>
                                            <p className="text-[18px] font-semibold">
                                                {earnStats.totalUsersJoined}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-6">
                                        <div className="w-[2px] bg-[#adb1b8] h-8"></div>
                                        <div>
                                            <p className="text-[#adb1b8] text-[14px] font-semibold">
                                                Projects
                                            </p>
                                            <p className="text-[18px] font-semibold">
                                                {earnStats.projects}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <p className="hero-description-black mt- font-semibold">
                                    <span
                                        className={`flip-text-black ${flipState}`}
                                    >
                                        {currentWord}
                                    </span>
                                    {"  "}
                                    <span className="text-white"></span>
                                </p>
                            </div>
                            {/* Right Section: Hero Image */}
                            <div className="flex flex-col items-center w-[40%]">
                                <div className="hero-image-wrapper-black relative w-full h-[10rem] rounded-xl overflow-hidden">
                                    {heroImages.map((image, index) => (
                                        <img
                                            key={index}
                                            src={image}
                                            alt={`hero-img-${index}`}
                                            className={`hero-image-black absolute top-0 left-0 w-full rounded-xl object-contain transition-opacity duration-700 ${imageTransitionState} ${
                                                index === currentImageIndex
                                                    ? "active"
                                                    : "inactive"
                                            }`}
                                        />
                                    ))}
                                </div>
                                <button className="font-semibold text-sm border-2 rounded-full border-[#EFB81C] text-[#EFB81C] px-4 py-1">
                                    Become a Member Now!
                                </button>
                            </div>
                        </div>
                    </section>
                    {/* cards  */}
                    {/* <div className="container !px-10 ">
                        <h2 className="text-[#121214] text-[24px] mt-8 font-semibold">
                            Expiring Soon
                        </h2>
                        <p className="mt-1 text-[#81858c] text-[16px]">
                            T+0 or T+1-day expiry options. Affordable and highly
                            liquid options for traders.
                        </p>
                        <div className="mt-4 flex flex-wrap gap-6">
                            <CoinCard />
                            <CoinCard />
                            <CoinCard />
                        </div>
                    </div> */}
                    {/* otrher cards  */}
                    {/* <div className="container !px-10">
                        <h2 className="text-[#121214] text-[24px] mt-8 font-semibold">
                            Top Volume in Last 24H
                        </h2>
                        <p className="mt-1 text-[#81858c] text-[16px]">
                            Recently executed individual trades with the highest
                            trading volume.
                        </p>
                        <div className="mt-4 flex flex-wrap gap-6">
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                        </div>
                    </div> */}

                    <div className="mt-6 flex justify-between gap-4 container !px-10">
                        <div className="flex-1">
                            <DPSTable />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CryptoDPS;
