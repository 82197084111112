import React from 'react';

import { RiArrowDropDownLine } from "react-icons/ri";
import { AiFillDollarCircle } from "react-icons/ai";
import { FaBitcoin, FaEthereum, FaDog } from 'react-icons/fa';
import { Input } from 'antd';
const CurrencyInput = ({ label, value, onChange, selectedCurrency, onSelect,balance }) => (
    <div className='p-5 bg-gray-100 rounded-sm'>
        <div className='flex justify-between'>
            <p className='text-xs'>{label}</p>
            {label === "From" && balance && <p className="text-xs">Balance: {balance}</p>}


        </div>
        <div className='flex gap-2 mt-5'>
            <Input value={value}
                onChange={onChange} placeholder='0.00'
                className='w-full p-0 text-2xl font-semibold bg-transparent' variant="borderless" />
            <div className='flex items-center justify-center w-1/3 gap-2 py-1 bg-white rounded-full' onClick={onSelect}>
            <a href={selectedCurrency?.icon} target="_blank" rel="noopener noreferrer">
  <img 
    src={selectedCurrency?.icon} 
    alt="Coin Icon" 
    className="w-8 h-8 rounded-full" 
  />
</a>


                <div className='flex items-center'>
                    <p className='font-medium text-md'>{selectedCurrency?.ticker}</p>
                    <RiArrowDropDownLine />
                </div>
            </div>
        </div>
    </div>
);

export default CurrencyInput;


