import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import { createGlobalStyle, styled } from "styled-components";
import AssetDropdown from './DropDownUI';
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import NoData from "assets/img/user/No-data.svg";
import { useDispatch, useSelector } from 'react-redux';
import { FinanceOrdersHistory } from 'store/actions/AssetsUserAction';

const { RangePicker } = DatePicker;

function OrderHistory() {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
  const [orderListData, setOrderListData] = useState([]);
  const [marketListData, setMarketListData] = useState([]);
  const [filteredOrderListData, setFilteredOrderListData] = useState([]);
    const [options1, setOptions1] = useState(["All"]);
    const [dateRange, setDateRange] = useState([null, null]); 

    useEffect(() => {
      let isMounted = true;  
    
      const payload = {
        user_id: userID,
      };
    
      dispatch(FinanceOrdersHistory(userID, userToken, payload))
        .then((response) => {
          if (isMounted && response) {  
            const orderList = response.data?.data?.list || [];
            const marketList = response.data?.data?.market_list || [];
            setOrderListData(orderList);
            setMarketListData(marketList);
            setFilteredOrderListData(orderList);
    
         // Extract unique market names for options
         const uniqueMarkets = [
          "All",
          ...new Set(
            Object.values(marketList).map(market => {
              const [base, quote] = market.name.split('_'); // Split the market name by '_'
              return `${quote.toUpperCase()}/${base.toUpperCase()}`; // Format as QUOTE/BASE
            })
          )
        ];
        
        setOptions1(uniqueMarkets);
          } else {
            console.error(response);
          }
        });
    

      return () => {
        isMounted = false;
      };
    }, [dispatch, userID, userToken]);
    
  const handleDateChange = (dates) => {
    setDateRange(dates);
  };
  useEffect(() => {
    // Ensure dateRange is a valid array with two elements
    if (Array.isArray(dateRange) && dateRange[0] && dateRange[1]) {
      const filteredOrders = orderListData.filter((order) => {
        const orderDate = new Date(parseInt(order.addtime) * 1000); 
        return orderDate >= dateRange[0] && orderDate <= dateRange[1];
      });
      setFilteredOrderListData(filteredOrders);
    } else {
      setFilteredOrderListData(orderListData); 
    }
  }, [dateRange, orderListData]); 
  
  const getMarketName = (market) => {
    const marketName = Object.values(marketListData).find((item) => item.name === market);
    
    if (!marketName || !marketName.xnb || !marketName.rmb) {

        return null; 
    }

    const marketNameXNB = marketName.xnb.toUpperCase();
    const marketNameRMB = marketName.rmb.toUpperCase();
    
   
    return marketNameRMB + "/" + marketNameXNB;
};
  const handleSelectType = (selected) => {
  
    if(selected === "All") {
        setFilteredOrderListData(orderListData);
    } else if (selected === "Buy") {
        const filteredOrders = orderListData.filter(order => order.type == "1");
        setFilteredOrderListData(filteredOrders);
    } else if (selected === "Sell") {
        const filteredOrders = orderListData.filter(order => order.type == "2");
        setFilteredOrderListData(filteredOrders);
    }
};


 
  const options2 = ["All","Limit",  "Market", "Stop Limit", "Stop Market", "Trailing Stop","Limit Maker"];
  const options3 = ["All", "Buy", "Sell"];


  const handleSelect1 = (selected) => {
   
    
    if (selected === "All") {
        setFilteredOrderListData(orderListData);
    } else {
        // Filter based on the selected tradetype
        const filteredOrders = orderListData.filter(order => order.tradetype === selected);
        setFilteredOrderListData(filteredOrders);
    }
};
  const handleSelectPair = (selected) => {

       if (selected === "All") {

           setFilteredOrderListData(orderListData);
       } else {
           const explodeMarketNameValue = explodeMarketName(selected);
         
           const filteredOrders = orderListData.filter(order => order.market == explodeMarketNameValue);
           setFilteredOrderListData(filteredOrders);
       }
   };
  const renderCell = (data) => {
    return data !== undefined ? data : '-';
  };
  
  const explodeMarketName = (market) => {
    var splitMarket = market.split("/");
    return splitMarket[1].toLowerCase() + "_" + splitMarket[0].toLowerCase();
};

  return (
    <>
      <div className='w-full flex justify-between items-center'>
        <div className='flex items-center w-[83%] gap-3'>
          <GlobalStyles />
          <StyledRangePicker
  className="px-2 w-[600px] h-[40px] bg-white sm:w-1/3 py-[0.55rem] border-l"
  onChange={handleDateChange} // Handle date change
/>

          <AssetDropdown
            label="Pair"
            options={options1}
            initialSelected="All"
            onSelect={handleSelectPair}
            isSearchable={true}
          />
          -
          <AssetDropdown
            label="Order Type"
            options={options2}
            initialSelected="All"
            onSelect={handleSelect1}
            isSearchable={true}
          />
          <AssetDropdown
            label="Side"
            options={options3}
            initialSelected="All"
            onSelect={handleSelectType}
            isSearchable={false}
            width="!w-[200px]"
          />
         
        </div>
       
      </div>
     
      <div className="text-xs mt-8 text-[#707a8a] w-full overflow-x-auto">
  <div className="flex justify-between border-b pb-3 w-full px-6">
    <p className="w-[8%] text-gray-600 font-medium">Order Time</p>
    <p className="w-[8%] text-gray-600 font-medium">Pair</p>
    <p className="w-[8%] text-gray-600 font-medium">Type</p>
    <p className="w-[8%] text-gray-600 font-medium">Side</p>
    <p className="w-[8%] text-gray-600 font-medium">Average</p>
    <p className="w-[8%] text-gray-600 font-medium">Price</p>
    <p className="w-[8%] text-gray-600 font-medium">Executed</p>
    <p className="w-[8%] text-gray-600 font-medium">Amount</p>
    <p className="w-[8%] text-gray-600 font-medium">Amount per Iceberg Order</p>
 
    <p className="w-[8%] text-gray-600 font-medium">Total</p>
    <p className="w-[8%] text-gray-600 font-medium">Trigger Conditions</p>
    <p className="w-[8%] text-gray-600 font-medium">TP/SL</p>
    <p className="w-[8%] text-gray-600 font-medium">Status</p>
  </div>
  {filteredOrderListData.length > 0 ? (
    <div className="w-full">
      {filteredOrderListData.map((order, index) => (
        <div key={index} className="flex justify-between border-b py-3 w-full px-6">
          <p className="w-[8%] text-gray-700">{renderCell(new Date(parseInt(order.addtime) * 1000).toLocaleString())}</p>
          <p className="w-[8%] text-gray-700">{order.market.toUpperCase()}</p>
          <p className="w-[8%] text-gray-700">-</p>
          <p className="w-[8%] text-gray-700"> {order.type === "1" ? "Buy" : "Sell"}</p>
          
          <p className="w-[8%] text-gray-700">{renderCell(parseFloat(order.price).toFixed(2))}</p>
          <p className="w-[8%] text-gray-700">{renderCell(parseFloat(order.price).toFixed(2))}</p>
          <p className="w-[8%] text-gray-700">-</p>
          <p className="w-[8%] text-gray-700">{renderCell(parseFloat(order.num).toFixed(2))}</p>
          <p className="w-[8%] text-gray-700">-</p>
 
          <p className="w-[8%] text-gray-700">{parseFloat(order.num * order.price).toFixed(2)}</p>
          <p className="w-[8%] text-gray-700">-</p>
          <p className="w-[8%] text-gray-700">-</p>
          <p className="w-[8%] text-gray-700">{renderCell(order.status)}</p>
        </div>
      ))}
    </div>
  ) : (
    <div className="w-full flex justify-center items-center h-96">
      <div className="flex flex-col gap-1 h-44 justify-center items-center">
        <img src={NoData} alt="No Data" />
        <p className="text-[#707a8a] text-sm">No records</p>
      </div>
    </div>
  )}
</div>

    </>
  );
}

const GlobalStyles = createGlobalStyle`
  // Override hover state for picker
  .ant-picker:hover {
    border-color: #EFB81C !important; /* Change border color to #EFB81C on hover */
  }

  // Override focus state for picker
  .ant-picker-focused {
    border-color: #EFB81C !important; /* Change border color to #EFB81C on focus */
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important; /* Add shadow with color #EFB81C */
  }

  // Style for the active state bar in RangePicker
  .ant-picker-active-bar {
    background: #EFB81C !important; /* Change the active border bar color to #EFB81C */
  }

  // Style calendar cells with selected date
  .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #EFB81C !important; /* Change the background color of selected date */
    color: white !important; /* Keep the text color white for contrast */
  }

  // Style for "Today" cell in the calendar dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
    border: 1px solid #EFB81C !important; /* Change border for today's date */
  }
`;

const StyledRangePicker = styled(RangePicker)`
  .ant-picker-input {
    padding-left: 10px; /* Adjust left padding */
  }
`;

export default OrderHistory;