import { Modal } from "antd";
import styled from "styled-components";
import React, { useState } from "react";
import { FiBookOpen } from "react-icons/fi";
import { RiBitCoinFill } from "react-icons/ri";
import { CgArrowsExchange } from "react-icons/cg";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaCheckSquare, FaRegSquare } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { tr } from "date-fns/locale";
import { useDispatch ,useSelector} from "react-redux";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import {
    placeBuyOrder
} from "store/actions/LaunchGuardAction";
function TradeNowPopup({ isPopupActive, onOk, onCancel, tradeValue }) {
    const history = useHistory()
    
const dispatch = useDispatch();
 const userId = useSelector((state) => state.authUser?.user?.ID);
  const token = useSelector((state) => state.authUser?.user?.TOKEN);
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = () => {
      setIsChecked(!isChecked);
  };
  const [amount, setAmount] = useState("");
    const [payPassword, setPayPassword] = useState("");
    const handleTrade = async () => {
        if (!amount || !payPassword) {
            alert("Please enter both amount and pay password.");
            return;
        }

        const payload = {
            id:tradeValue.id ,
            num: amount,
            paypassword: payPassword,
            selected_coin: "usdt",
        };

        try {
            const response = await dispatch(placeBuyOrder(userId, token, payload));
            console.log("Data response ", response)
            if (response?.status === 1) {
                toast.success("Trade successful! Redirecting...");
                setTimeout(() => {
                    history.push("/trade/launch-pad/my-order"); 
                }, 1500); 
            } else {
                toast.error(response?.data || "Transaction failed!");
               
            }
        } catch (error) {
            toast.error( "An error occurred. Please try again.");
       
        }
    };

    const toogleCheckbox = () => {
        setIsChecked(!isChecked);
    };
    return (
        <div>
            <CustomModal
                open={isPopupActive}
                onOk={onOk}
                onCancel={onCancel}
                footer={null}
                width={1000}
            >
                <>
                    <div className="flex flex-col md:flex-row w-full gap-6 justify-between mt-4">
                        <div className="flex gap-3 items-end">
                            <div className="flex gap-1 items-center">
                            <img
              src={`${tradeValue.iconlink}`}
              alt={tradeValue.name}
              className="w-10 h-10 rounded-full object-cover"
            />
                                <p className="text-2xl dark:text-white font-semibold">
                                    Pre-list
                                </p>
                            </div>
                            <p className="text-lg dark:text-white font-semibold">
                                {tradeValue?.title}
                                <span className="text-[16px] dark:text-white font-medium">
                                    ( {tradeValue?.subtitle} )
                                </span>
                            </p>
                        </div>
                        <div
                            className="border hover:text-[#EFB81C] self-start rounded-full flex gap-1 items-center px-3 py-0"
                        >
                            <FiBookOpen />
                            <p className="text-xs dark:text-white">About CVM Guard</p>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-10 w-full">
                        <div className="w-full md:w-1/2">
                        <div className="border border-[#EFB81C] mb-4 rounded-md mt-4 w-full p-4">
    <div className="flex justify-between">
        <p className="text-gray-400">Mining Amount</p>
        <p className="text-[#EFB81C] cursor-pointer">Max</p>
    </div>
    <div className="flex mt-3">
        <div className="flex p-2 bg-[#f9f9fb] rounded-md items-center">
            {/* <RiBitCoinFill size={18} /> */}
            {tradeValue?.icon}
            <p className="text-ellipsis whitespace-nowrap">
                {tradeValue.title}({tradeValue.subtitle})
            </p>
        </div>
        <input
                    type="number"
                    className="bg-transparent placeholder:text-xl w-full outline-none px-3"
                    placeholder="Please enter the amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                />
    </div>
    {/* Pay Password Input Field */}
    <div className="mt-4">
        <p className="text-gray-400">Pay Password</p>
        <input
                    type="password"
                    className="bg-transparent placeholder:text-xl w-full outline-none px-3 border border-gray-300 rounded-md p-2 mt-2"
                    placeholder="Enter your pay password"
                    value={payPassword}
                    onChange={(e) => setPayPassword(e.target.value)}
                />
    </div>
</div>

                            <div className="flex justify-between w-full my-3">
                                <p className="text-gray-400 font-medium">
                                    Price
                                </p>
                                <p className="opacity-90 dark:text-white font-medium">{tradeValue?.stats.price} USD</p>
                            </div>
                            <div className="flex justify-between w-full my-3">
                                <p className="text-gray-400 font-medium">
                                   limit
                                </p>
                                <p className="opacity-90 font-medium dark:text-white">
                                {tradeValue?.stats?.limit}
                                </p>
                            </div>
                            <div className="flex justify-between w-full my-3">
                                <p className="text-gray-400 font-medium">
                                    Volume
                                </p>
                                <p className="opacity-90 dark:text-white font-medium flex gap-1 items-center">
                                    {tradeValue?.stats?.volume} USDT
                                    <CgArrowsExchange size={22} />
                                </p>
                            </div>
                            <div className="flex justify-between w-full my-3">
                                <p className="text-gray-400 font-medium">
                                    Turnover
                                </p>
                                <p className="opacity-90 dark:text-white font-medium">{tradeValue?.stats?.turnover} USDT</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2">
                            <p className="text-[16px] dark:text-white my-3 opacity-90 font-medium">
                                Info
                            </p>
                            <div className="border-b pb-2">
                                <div className="flex justify-between w-full my-3">
                                    <p className="text-gray-400 font-medium">
                                        Trading Start Time
                                    </p>
                                    <p className="opacity-90 dark:text-white font-medium">
                                    {tradeValue?.stats?.beginTime}
                                    </p>
                                </div>
                                <div className="flex justify-between w-full my-3">
                                    <p className="text-gray-400 font-medium">
                                        Trading End Time
                                    </p>
                                    <p className="opacity-90 dark:text-white font-medium">
                                    {tradeValue?.stats?.endTime}
                                    </p>
                                </div>
                                <div className="flex justify-between w-full my-3">
                                    <p className="text-gray-400 font-medium">
                                        Exchang Listing Date
                                    </p>
                                    <p className="opacity-90 dark:text-white font-medium">
                                    {tradeValue?.stats?.exchange_listing_date}
                                    </p>
                                </div>
                                <div className="flex justify-between w-full my-3">
                                    <p className="text-gray-400 font-medium">
                                        avaialbe
                                    </p>
                                    <p className="opacity-90 dark:text-white font-medium">
                                    {tradeValue?.stats?.available}
                                    </p>
                                </div>
                            </div>

                            <p className="text-[16px] my-3 opacity-90 font-medium">
                                Overview
                            </p>
                            <div className="pb-4">
                                <div className="flex justify-between w-full my-3">
                                    <p className="text-gray-400 font-medium">
                                        Total Holded
                                    </p>
                                    <p className="opacity-90 dark:text-white font-medium">
                                        {tradeValue?.stats?.change} USDT
                                    </p>
                                </div>
                                <div className="flex justify-between w-full my-3">
                                    <p className="text-gray-400 font-medium">
                                        Total (PreTokens)
                                    </p>
                                    <p className="font-medium text-gray-400">
                                        {tradeValue?.stats?.volume} CVM
                                    </p>
                                </div>
                                <div className="flex justify-between w-full my-3">
                                    <p className="text-gray-400 font-medium flex gap-2">
                                        Price{" "}
                                        <p className="opacity-90 text-sm text-black dark:text-white font-medium">
                                            {tradeValue?.stats?.price}
                                        </p>
                                    </p>
                                    <p className="opacity-90 font-medium dark:text-white flex items-center text-[13px]">
                                        Trade Now
                                        <MdKeyboardArrowRight size={14} />
                                    </p>
                                </div>
                                <div className="flex items-center mt-7 mb-3 gap-1">
            <div className="cursor-pointer" onClick={toggleCheckbox}>
                {isChecked ? (
                    <FaCheckSquare className="text-[#EFB81C] text-xl" />
                ) : (
                    <FaRegSquare className="text-gray-400 text-xl" />
                )}
            </div>
            <p className="flex gap-1 dark:text-white">
                I have read and agreed to{" "}
                <span className="text-[#EFB81C] underline underline-offset-2">
                    Service Agreement
                </span>
            </p>
        </div>
        </div>
                           
                            <button
            onClick={handleTrade}
            disabled={!isChecked} // Disable when unchecked
            className={`w-full rounded-full py-2 font-medium ${
                isChecked ? "bg-[#EFB81C]" : "bg-gray-400 cursor-not-allowed"
            }`}
        >
            Trade & Hold
        </button>
                         
                        </div>
                    </div>
                </>
            </CustomModal>
        </div>
    );
}

const CustomModal = styled(Modal)`
    .ant-modal-content {
        padding: 25px 38px !important; /* Override padding */
    }
`;

export default TradeNowPopup;
