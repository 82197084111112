import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { FiX } from "react-icons/fi";
import { Link, NavLink } from "react-router-dom/cjs/react-router-dom";

const Sidebar = ({ isOpenSidebar, setIsOpenSidebar, megaMenu, dropdownMenu, userLoggedIn }) => {
    const [activeMegaMenu, setActiveMegaMenu] = useState(null);
    const [activeDropdown, setActiveDropdown] = useState(null);

    const toggleMegaMenu = (id) => {
        setActiveMegaMenu(activeMegaMenu === id ? null : id);
    };

    const toggleDropdown = (id) => {
        setActiveDropdown(activeDropdown === id ? null : id);
    };

    return (
        <>
            {isOpenSidebar && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 transition-opacity lg:hidden"
                    onClick={() => setIsOpenSidebar(false)}
                />
            )}

            <div
                className={`fixed top-0 right-0 w-80 h-full bg-[#17181e] text-white shadow-lg transform ${
                    isOpenSidebar ? "translate-x-0" : "translate-x-full"
                } transition-transform duration-300 ease-in-out lg:hidden overflow-y-auto`}
            >
                <button
                    className="absolute top-3 right-5 text-2xl"
                    onClick={() => setIsOpenSidebar(false)}
                >
                    <FiX />
                </button>

                {/* Sidebar Menu */}
                <div className="space-y-4 p-5">
                {userLoggedIn ? (
                    <></>
                ):(
                    <div className="flex flex-col gap-2 mt-16">
                        <Link to='/login' className="hover:bg-[#25262b] text-sm text-center py-2 hover:text-[#EFB81c] font-semibold rounded-md w-full">
                            Log In
                        </Link>
                        <Link to='/register' className="w-full bg-[#EFB81c] text-center text-sm text-black py-2 font-semibold rounded-md">
                            Sign Up
                        </Link>
                    </div>
                )}


                    <div className="mt-16 space-y-4">
                        {/* Mega Menu (Opens on Click) */}
                        {megaMenu.map((menu) => (
                            <div key={`mega-${menu.id}`}>
                                <button
                                    onClick={() => toggleMegaMenu(menu.id)}
                                    className="flex items-center w-full justify-between p-2 hover:bg-[#25262b] rounded"
                                >
                                    {menu.name}
                                    {activeMegaMenu === menu.id ? <BsChevronUp /> : <BsChevronDown />}
                                </button>
                                {activeMegaMenu === menu.id && (
                                    <div className="ml-4 mt-2">
                                        {menu.menuItems.map((item) => (
                                            <button key={`mega-item-${item.itemId}`} className="flex text-[16px] items-center p-2 hover:bg-[#25262b] rounded">
                                                {item.itemIcon}
                                                <Link to={item.itemPath} className="ml-2 !text-[16px]">{item.itemName}</Link>
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}

                        {/* Dropdown Menu (Opens on Click) */}
                        {dropdownMenu.map((menu) => (
                            <div key={`dropdown-${menu.id}`} className="mb-2">
                                <button
                                    onClick={() => toggleDropdown(menu.id)}
                                    className="flex items-center w-full justify-between p-2 hover:bg-[#25262b] rounded"
                                >
                                    {menu.name}
                                    {menu.hasDropdown && (activeDropdown === menu.id ? <BsChevronUp /> : <BsChevronDown />)}
                                </button>
                                {menu.hasDropdown && activeDropdown === menu.id && (
                                    <div className="ml-4 mt-2">
                                        {menu.menuItem.map((item) => (
                                            <button key={`dropdown-item-${item.menuItemId}`} className="flex items-center p-2 hover:bg-[#25262b] rounded">
                                                {item.menuItemIcon}
                                                <Link to={item.menuItemPath} className="ml-2 !text-[16px]">{item.menuItemName}</Link>
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
