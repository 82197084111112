import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { X } from "lucide-react";
import { confirm2faCodelogin } from "store/actions/authAction";
import { Link } from "react-router-dom";
import logo from "assets/img/logo/buycex-yellow.png";
import { toast } from "react-hot-toast";
import { AiOutlineLoading } from "react-icons/ai"; 

const Phone2faVerification = ({ onClose, onSuccess, username, password }) => {
    const inputRefs = useRef([]);
    const dispatch = useDispatch();
    const [g2aCode, setG2aCode] = useState(Array(6).fill(""));
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e) => {
            const activeIndex = inputRefs.current.findIndex(
                (input) => input === document.activeElement
            );
            handleBackspace(e, activeIndex);
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [g2aCode]);

    const handleG2aCodeChange = (e, index) => {
        const { value } = e.target;
        if (!/^\d*$/.test(value)) return;

        const newG2aCode = [...g2aCode];
        newG2aCode[index] = value.slice(-1);
        setG2aCode(newG2aCode);

        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        } else if (!value && index > 0) {
            inputRefs.current[index - 1].focus();
        }

        if (newG2aCode.every((digit) => digit !== "")) {
            triggerLoadingAndVerify(newG2aCode);
        }
    };

    const handlePaste = (e) => {
        const paste = e.clipboardData.getData("text");
        if (paste.length === 6 && /^\d+$/.test(paste)) {
            const newG2aCode = paste.split("");
            setG2aCode(newG2aCode);
            inputRefs.current.forEach((input, i) => {
                input.value = newG2aCode[i];
            });
            triggerLoadingAndVerify(newG2aCode);
        }
    };

    const triggerLoadingAndVerify = async (codeArray) => {
        setLoading(true);
        try {
            await verifyG2faCodeHandler(codeArray);
        } finally {
            setLoading(false);
        }
    };

    const verifyG2faCodeHandler = async (codeArray = g2aCode) => {
        const user_id = localStorage.getItem("user_id_2fa");
        const payload = {
            user_id: user_id,
            code: parseInt(codeArray.join("")),
        };
        await dispatch(confirm2faCodelogin(payload, onSuccess));
    };

    const handleSubmit = async () => {
        if (g2aCode.every((digit) => digit !== "")) {
            setLoading(true);
            try {
                await verifyG2faCodeHandler();
            } finally {
                setLoading(false);
            }
        } else {
            toast.error("Please complete the 6-digit verification code.");
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === "Backspace" && index > 0 && g2aCode[index] === "") {
            inputRefs.current[index - 1].focus();
            const newG2aCode = [...g2aCode];
            newG2aCode[index - 1] = "";
            setG2aCode(newG2aCode);
        }
    };

    return (
        <div className="fixed inset-0 bg-[#151415] bg-opacity-70 backdrop-blur-sm">
            <div className="bg-white absolute inset-0 flex-col z-[5] flex gap-2 items-center justify-center w-max h-max m-auto text-[#000] p-8 rounded-xl">
                <button onClick={onClose} className="absolute right-3 top-3">
                    <X className="w-[20px] h-[20px] opacity-50" />
                </button>
                <img src={logo} className="w-[200px] mb-5 self-center" />
                <div className="mr-auto mb-2">
                    <h1 className="text-2xl font-bold">Phone Verification</h1>
                    <p className="opacity-60 font-medium pt-1 text-xs">
                        Enter the 6 Digit Phone Verification code
                    </p>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                    <p className="text-sm pb-1">Verification Code</p>
                    <div className="flex gap-2" onPaste={handlePaste}>
                        {g2aCode.map((digit, index) => (
                            <input
                                key={index}
                                ref={(el) => (inputRefs.current[index] = el)}
                                className={`border border-slate-400 w-[50px] h-[50px] text-center rounded-lg bg-transparent focus:bg-transparent active:bg-transparent ${
                                    digit ? "bg-slate-100" : ""
                                }`}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleG2aCodeChange(e, index)}
                                onKeyDown={(e) => handleBackspace(e, index)}
                            />
                        ))}
                    </div>
                    <button
                        onClick={handleSubmit}
                        className={`bg-[#EFB81C] rounded-lg py-2 text-center w-full mt-3 font-medium flex items-center justify-center ${
                            loading ? "cursor-not-allowed" : ""
                        }`}
                        disabled={loading}
                    >
                        {loading ? (
                            <AiOutlineLoading className="animate-spin mr-2" />
                        ) : null}
                        {loading ? "Submitting..." : "Submit"}
                    </button>
                </form>
                <div className="w-full text-right">
                    <Link
                        to="/reset-security-settings"
                        className="text-[#EFB81C] text-sm font-medium"
                    >
                        Switch to Another Verification Method
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Phone2faVerification;
