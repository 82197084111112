import React from 'react';
import Slider from 'react-slick';
import { GoArrowRight } from 'react-icons/go';
import buycexCard from 'assets/img/buycex-card.png';
import buycexEarn from 'assets/img/buycex-earn.png';
import candleStick from 'assets/img/candleStick.png';
import tradingGpt from 'assets/img/tradingGpt.png';
import tradingBot from 'assets/img/tradingBot.png';
import BuycexWeb3 from 'assets/img/BuycexWeb3.png';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const cards = [
  { img: buycexCard, title: 'Buycex Card', desc: 'Up to 10% Cashback, 8% APR, VIP Privileges & More', link: '#' },
  { img: buycexEarn, title: 'Buycex Earn', desc: 'Grow and manage your assets with reliable and flexible offerings', link: '/earn/home' },
  { img: candleStick, title: 'Copy Trading', desc: 'Boost your earnings by copying top traders or leading trades', link: '#' },
  { img: tradingBot, title: 'Trading Bot', desc: 'Automate your trades with Grid Bot, Futures Combo Bot, DCA', link: '/trade-ai' },
  { img: tradingGpt, title: 'TradeGPT', desc: 'Trade smarter with AI-driven data analysis and market insights', link: '#' },
  { img: BuycexWeb3, title: 'Buycex Web3', desc: 'Navigate the Web3 landscape with industry-leading reliability', link: '#' }
];

function DiscoverMore() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  return (
    <div className="dark:bg-black">
      <div className="container pt-4 pb-12 lg:pb-32">
        <h3 className="capitalize text-xl sm:text-3xl mb-5 sm:mb-10 font-extrabold">Discover more products</h3>

        {/* Carousel for sm screens */}
        <div className="sm:block lg:hidden">
          <Slider {...settings}>
            {cards.map((card, index) => (
              <div key={index}>
                <div className="bg-[#F6F6F6] dark:bg-[#17181E]/40 rounded-[20px] flex flex-col-reverse items-start justify-between gap-5 sm:flex-row px-5 sm:px-10 py-6 sm:py-12 h-auto sm:h-[245px] w-full">
                  <div className="w-full sm:w-1/3">
                    <h3 className="text-2xl font-extrabold mb-1">{card.title}</h3>
                    <p className="text-sm text-[#737373]">{card.desc}</p>
                    <Link to={card.link}>
                      <div className="flex gap-1 items-center mt-4">
                        <button className="text-[14px] text-[#EFB81C] font-bold mb-1">Details</button>
                        <GoArrowRight className="mb-0.5" fill="#EFB81C" />
                      </div>
                    </Link>
                  </div>
                  <img src={card.img} className="max-w-[100px] sm:max-w-[142px] max-h-[100px] sm:max-h-[142px]" />
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* Grid layout for lg screens */}
        <div className="hidden lg:flex flex-col gap-5">
          <div className="flex gap-5">
            {cards.slice(0, 2).map((card, index) => (
              <div key={index} className="border dark:border-[#454545]/30 border-[#f5f5f5] bg-transparent hover:bg-[#f7f7f7] transition-all duration-300 ease-in-out dark:bg-[#17181E]/40 rounded-[20px] flex flex-col-reverse gap-5 sm:flex-row justify-between px-5 sm:px-10 py-6 sm:py-12 w-full h-[245px]">
                <div className="w-full sm:w-1/3">
                  <h3 className="text-2xl font-extrabold mb-1">{card.title}</h3>
                  <p className="text-sm text-[#737373]">{card.desc}</p>
                  <Link to={card.link}>
                    <div className="flex gap-1 items-center mt-4">
                      <button className="text-[14px] text-[#EFB81C] font-bold mb-1">Details</button>
                      <GoArrowRight className="mb-0.5" fill="#EFB81C" />
                    </div>
                  </Link>
                </div>
                <img src={card.img} className="max-w-[142px] max-h-[142px]" />
              </div>
            ))}
          </div>

          <div className="flex gap-5">
            {cards.slice(2).map((card, index) => (
              <div key={index} className="border dark:border-[#454545]/30 border-[#f5f5f5] bg-transparent hover:bg-[#f7f7f7] transition-all duration-300 ease-in-out dark:bg-[#17181E]/40 rounded-[20px] flex flex-col-reverse gap-5 justify-between px-10 py-12 w-full lg:w-1/3 h-[340px]">
                <div>
                  <h3 className="text-2xl font-extrabold mb-1">{card.title}</h3>
                  <p className="text-sm text-[#737373]">{card.desc}</p>
                  <Link to={card.link}>
                    <div className="flex gap-1 items-center mt-4">
                      <button className="text-[14px] text-[#EFB81C] font-bold mb-1">Details</button>
                      <GoArrowRight className="mb-0.5" fill="#EFB81C" />
                    </div>
                  </Link>
                </div>
                <img src={card.img} className="max-w-[80px] max-h-[80px]" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscoverMore;
