import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import homeSlide1 from "assets/img/home-page/hero-section/homeSlide1.png";
import homeSlide2 from "assets/img/home-page/hero-section/homeSlide2.png";
import homeSlide3 from "assets/img/home-page/hero-section/homeSlide3.png";
import homeSlide4 from "assets/img/home-page/hero-section/homeSlide4.png";
import homeSlide5 from "assets/img/home-page/hero-section/homeSlide5.png";
import homeSlide6 from "assets/img/home-page/hero-section/homeSlide6.png";
import homeSlide7 from "assets/img/home-page/hero-section/homeSlide7.png";
import homeSlide8 from "assets/img/home-page/hero-section/homeSlide8.png";
import homeSlide9 from "assets/img/home-page/hero-section/homeSlide9.png";
import homeSlide10 from "assets/img/home-page/hero-section/homeSlide10.png";

const ImageCarousel = () => {
  const images = [
    homeSlide1,
    homeSlide2,
    homeSlide3,
    homeSlide4,
    homeSlide5,
    homeSlide6,
    homeSlide7,
    homeSlide8,
    homeSlide9,
    homeSlide10
  ];

  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4, slidesToSlide: 1 },
    tablet: { breakpoint: { max: 1024, min: 768 }, items: 2, slidesToSlide: 1 },
    mobile: { breakpoint: { max: 767, min: 0 }, items: 1, slidesToSlide: 1 },
  };

  return (
    <div className="px-3 pt-8 dark:bg-black">
    <Carousel
      responsive={responsive}
      infinite
      autoPlay
      autoPlaySpeed={3000}
      keyBoardControl
      showDots={false}
      containerClass="carousel-container"
      itemClass="carousel-item"
    >
      {images.map((img, index) => (
        <div key={index} className="p-2">
          <img src={img} alt={`Slide ${index + 1}`} className="w-full h-auto rounded-lg" />
        </div>
      ))}
    </Carousel>
    </div>
  );
};

export default ImageCarousel;
