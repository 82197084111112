// AssetsUserAction
import { RepositoryFactory } from "repository/RepositoryFactory";
const assetsUser = RepositoryFactory.get("assetsData");


export const fetchAssetsData = (userid, token, type) => async (dispatch) => {
    dispatch({ type: "FETCH_ASSETS_DATA_REQUEST" });
    try {
        const response = await assetsUser.getAssetsData(userid, token, type);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_ASSETS_DATA_SUCCESS",
                payload: response.data.data,
            });
        } else {
            dispatch({
                type: "FETCH_ASSETS_DATA_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_ASSETS_DATA_FAILURE", payload: error.message });
    }
};
export const fetchspotAssetData = (userId, token) => async (dispatch) => {
    dispatch({ type: "FETCH_ASSETS_DATA_REQUEST" });

    try {
        const response = await assetsUser.getspotAssetsData(userId, token);
        console.log("response", response)
        if (response.data.status === 1) {
            const assetsArray = response.data.data.assets || []; // Ensure `assets` is an array

            const filteredData = assetsArray.filter(asset => 
                parseFloat(asset.balance) > 0 || parseFloat(asset.borrowed_amount) > 0
            );

            dispatch({
                type: "FETCH_ASSETS_DATA_SUCCESS",
                payload: filteredData,
            });
        } else {
            dispatch({
                type: "FETCH_ASSETS_DATA_FAILURE",
                payload: response.data.message,
            });
            console.error("Spot Assets Data Failure:", response.data.message);
        }
    } catch (error) {
        console.error("Spot Assets Data Error:", error.response?.data || error.message);
        dispatch({ type: "FETCH_ASSETS_DATA_FAILURE", payload: error.message });
    }
};




export const fetchAssetsContext = (userid, token, type, context) => async (dispatch) => {
    dispatch({ type: "FETCH_ASSETS_CONTEXT_REQUEST" });

    try {
        // Call the getAssetsContext function
        const response = await assetsUser.getAssetsContext(userid, token, type, context);
        console.log("Call the getAssetsContext",response)
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_ASSETS_CONTEXT_SUCCESS",
                payload: response.data.data,
            });
        } else {
            dispatch({
                type: "FETCH_ASSETS_CONTEXT_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Context Failure: ", response.data.message);
        }
    } catch (error) {
        console.log("Assets Context Error: ", error.message);
        dispatch({
            type: "FETCH_ASSETS_CONTEXT_FAILURE",
            payload: error.message,
        });
    }
};
export const FinanceUserSpot = (userID, userToken) => async (dispatch) => {
    dispatch({ type: "FETCH_FINANCE_USER_SPOT_LOADING" });
    try {
        const response = await assetsUser.FinanceUserSpot(userID, userToken);
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_FINANCE_USER_SPOT_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: response.data };
        } else {
            dispatch({
                type: "FETCH_FINANCE_USER_SPOT_FAILURE",
                payload: response.data.message,
            });
            console.log("FinanceUserSpot Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("FinanceUserSpot Data Error: ", error.message);
        dispatch({ type: "FETCH_FINANCE_USER_SPOT_FAILURE", payload: error.message });
    }
};

export const LaunchPool = (userID, userToken) => async (dispatch) => {
    dispatch({ type: "FETCH_FINANCE_USER_SPOT_LOADING" });
    try {
        const response = await assetsUser.getLauchPool(userID, userToken);
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_FINANCE_USER_SPOT_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: response.data };
        } else {
            dispatch({
                type: "FETCH_FINANCE_USER_SPOT_FAILURE",
                payload: response.data.message,
            });
            console.log("LaunchPool Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("LaunchPool Data Error: ", error.message);
        dispatch({ type: "FETCH_FINANCE_USER_SPOT_FAILURE", payload: error.message });
    }
};

export const NewEvent = (userID, userToken) => async (dispatch) => {
    dispatch({ type: "FETCH_FINANCE_USER_SPOT_LOADING" });
    try {
        const response = await assetsUser.getNewEvent(userID, userToken);
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_FINANCE_USER_SPOT_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: response.data };
        } else {
            dispatch({
                type: "FETCH_FINANCE_USER_SPOT_FAILURE",
                payload: response.data.message,
            });
            console.log("Event Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("Event Data Error: ", error.message);
        dispatch({ type: "FETCH_FINANCE_USER_SPOT_FAILURE", payload: error.message });
    }
};

export const PollRewards = (userID, userToken) => async (dispatch) => {
    dispatch({ type: "FETCH_FINANCE_USER_SPOT_LOADING" });
    try {
        const response = await assetsUser.getPollRewards(userID, userToken);
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_FINANCE_USER_SPOT_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: response.data };
        } else {
            dispatch({
                type: "FETCH_FINANCE_USER_SPOT_FAILURE",
                payload: response.data.message,
            });
            console.log("PollRewards Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("PollRewards Data Error: ", error.message);
        dispatch({ type: "FETCH_FINANCE_USER_SPOT_FAILURE", payload: error.message });
    }
};
export const YeildRewards = (userID, userToken) => async (dispatch) => {
    dispatch({ type: "FETCH_FINANCE_USER_SPOT_LOADING" });
    try {
        const response = await assetsUser.getYieldRewards(userID, userToken);
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_FINANCE_USER_SPOT_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: response.data };
        } else {
            dispatch({
                type: "FETCH_FINANCE_USER_SPOT_FAILURE",
                payload: response.data.message,
            });
            console.log("PollRewards Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("PollRewards Data Error: ", error.message);
        dispatch({ type: "FETCH_FINANCE_USER_SPOT_FAILURE", payload: error.message });
    }
};

export const FinanceUserFunding = (userID, userToken) => async (dispatch) => {
    console.log('Action triggered');
    dispatch({ type: "FETCH_FINANCE_USER_FUNDING_LOADING", payload: true });
    
    try {
      const response = await assetsUser.FinanceUserFunding(userID, userToken);
      console.log('API Response:', response.data.data);
      
      if (response.data.status === 1) {
        console.log('Dispatching success');
        dispatch({
          type: "FETCH_FINANCE_USER_FUNDING_SUCCESS",
          payload: response.data.data,  // Directly passing nested `data` as payload
        });
        return { success: true, data: response.data };
      } else {
        console.log('Dispatching failure');
        dispatch({
          type: "FETCH_FINANCE_USER_FUNDING_FAILURE",
          payload: response.data.message,
        });
      }
    } catch (error) {
      console.log('Error:', error.message);
      dispatch({ type: "FETCH_FINANCE_USER_FUNDING_FAILURE", payload: error.message });
    } finally {
      dispatch({ type: "FETCH_FINANCE_USER_FUNDING_LOADING", payload: false });
    }
  };
  
// New action for FinanceUserEarn
export const FinanceUserEarn = (userID, userToken) => async (dispatch) => {
    dispatch({ type: "FETCH_FINANCE_USER_EARN_LOADING" });
    try {
        const response = await assetsUser.FinanceUserEarn(userID, userToken);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_FINANCE_USER_EARN_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: response.data };
        } else {
            dispatch({
                type: "FETCH_FINANCE_USER_EARN_FAILURE",
                payload: response.data.message,
            });
            console.log("FinanceUserEarn Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("FinanceUserEarn Data Error: ", error.message);
        dispatch({ type: "FETCH_FINANCE_USER_EARN_FAILURE", payload: error.message });
    }
};

export const getlaunchpool = (userID, userToken) => async (dispatch) => {
    dispatch({ type: "FETCH_FINANCE_USER_EARN_LOADING" });
    try {
        const response = await assetsUser.getlaunchpool(userID, userToken);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_FINANCE_USER_EARN_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: response.data };
        } else {
            dispatch({
                type: "FETCH_FINANCE_USER_EARN_FAILURE",
                payload: response.data.message,
            });
            console.log("FinanceUserEarn Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("FinanceUserEarn Data Error: ", error.message);
        dispatch({ type: "FETCH_FINANCE_USER_EARN_FAILURE", payload: error.message });
    }
};
export const userbalance = (userID, userToken) => async (dispatch) => {
    dispatch({ type: "FETCH_FINANCE_USER_EARN_LOADING" });
    try {
        const response = await assetsUser.userbalance(userID, userToken);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_FINANCE_USER_EARN_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: response.data };
        } else {
            dispatch({
                type: "FETCH_FINANCE_USER_EARN_FAILURE",
                payload: response.data.message,
            });
            console.log("FinanceUserEarn Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("FinanceUserEarn Data Error: ", error.message);
        dispatch({ type: "FETCH_FINANCE_USER_EARN_FAILURE", payload: error.message });
    }
};

export const FinanceOrders = (userID, userToken, payload) => async (dispatch) => {
    dispatch({ type: "FETCH_ASSETS_DATA_REQUEST" });
    try {
        const response = await assetsUser.FinanceOrders(userID, userToken, payload);
        console.log(response);
        

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_ASSETS_DATA_SUCCESS",
                payload: response.data.data,
            });
            // toast.success(response.data)
            return { success: true , data: response.data}
        } else {
            dispatch({
                type: "FETCH_ASSETS_DATA_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_ASSETS_DATA_FAILURE", payload: error.message });
    }
};
export const createstake = (userID, userToken, formData) => async (dispatch) => {
    dispatch({ type: "FETCH_ASSETS_DATA_REQUEST" });
    try {
        const response = await assetsUser.createstake(userID, userToken, formData);
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_ASSETS_DATA_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: response };  // Ensure success response is returned
        } else {
            dispatch({
                type: "FETCH_ASSETS_DATA_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Data Failure: ", response.data.status);
            return { success: false, error: response };  // Return failure response
        }
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_ASSETS_DATA_FAILURE", payload: error.message });
        return { success: false, error: error.message };  // Return error response
    }
};
export const cancelSub = (userID, userToken, payload) => async (dispatch) => {
    dispatch({ type: "FETCH_ASSETS_DATA_REQUEST" });
    try {
        const response = await assetsUser.cancelSub(userID, userToken, payload);
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_ASSETS_DATA_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: response };  // Ensure success response is returned
        } else {
            dispatch({
                type: "FETCH_ASSETS_DATA_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Data Failure: ", response.data.status);
            return { success: false, error: response };  // Return failure response
        }
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_ASSETS_DATA_FAILURE", payload: error.message });
        return { success: false, error: error.message };  // Return error response
    }
};

export const getProjectDetails = (userID, userToken, ids) => async (dispatch) => {
    dispatch({ type: "FETCH_PROJECT_DETAILS_REQUEST" });

    try {
        const response = await assetsUser.getProjectDetails(userID, userToken, ids);
        console.log("Response data:", response);

        if (response?.data) {
            dispatch({
                type: "FETCH_PROJECT_DETAILS_SUCCESS",
                payload: response.data,
            });
            return response;
        } else {
            dispatch({
                type: "FETCH_PROJECT_DETAILS_FAILURE",
                payload: response,
            });
            return response;
        }
    } catch (error) {
        console.error("Fetching project details failed:", error);
        toast.error("Failed to fetch project details. Please try again later.");
        dispatch({
            type: "FETCH_PROJECT_DETAILS_FAILURE",
            error,
        });
            return { success: false, data: null };
        throw error;
    }
};
export const claimreward = (userID, userToken, ids) => async (dispatch) => {
    dispatch({ type: "FETCH_PROJECT_DETAILS_REQUEST" });

    try {
        const response = await assetsUser.claimreward(userID, userToken, ids);
        console.log("Response data:", response);

        if (response?.data) {
            dispatch({
                type: "FETCH_PROJECT_DETAILS_SUCCESS",
                payload: response.data,
            });
            return response;
        } else {
            dispatch({
                type: "FETCH_PROJECT_DETAILS_FAILURE",
                payload: response,
            });
            return response;
        }
    } catch (error) {
        console.error("Fetching project details failed:", error);
        toast.error("Failed to fetch project details. Please try again later.");
        dispatch({
            type: "FETCH_PROJECT_DETAILS_FAILURE",
            error,
        });
            return { success: false, data: null };
        throw error;
    }
};

export const subscribepool = (userID, userToken, payload) => async (dispatch) => {
    dispatch({ type: "FETCH_ASSETS_DATA_REQUEST" });
    try {
        const response = await assetsUser.subscribepool(userID, userToken, payload);
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_ASSETS_DATA_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: response };  // Ensure success response is returned
        } else {
            dispatch({
                type: "FETCH_ASSETS_DATA_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Data Failure: ", response.data.status);
            return { success: false, error: response };  // Return failure response
        }
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_ASSETS_DATA_FAILURE", payload: error.message });
        return { success: false, error: error.message };  // Return error response
    }
};

export const FinanceOpenOrders = (userID, userToken) => async (dispatch) => {
    dispatch({ type: "FETCH_FINANCE_OPEN_ORDERS_LOADING" });
    try {
        const response = await assetsUser.FinanceOpenOrders(userID, userToken);
        

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_FINANCE_OPEN_ORDERS_SUCCESS",
                payload: response.data.data,
            });
            // toast.success(response.data)
            return { success: true , data: response.data}
        } else {
            dispatch({
                type: "FETCH_FINANCE_OPEN_ORDERS_FAILURE",
                payload: response.data.message,
            });
            console.log("FETCH_FINANCE_OPEN_ORDERS_FAILURE: ", response.data.status);
        }
    } catch (error) {
        console.log("FETCH_FINANCE_OPEN_ORDERS_FAILURE: ", error.message);
        dispatch({ type: "FETCH_FINANCE_OPEN_ORDERS_FAILURE", payload: error.message });
    }
};

export const FinanceOrdersHistory = (userID, userToken) => async (dispatch) => {
    dispatch({ type: "FETCH_FINANCE_ORDERS_HISTORY_LOADING" });
    try {
        const response = await assetsUser.FinanceOrdersHistory(userID, userToken);
        

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_FINANCE_ORDERS_HISTORY_SUCCESS",
                payload: response.data.data,
            });
            // toast.success(response.data)
            return { success: true , data: response.data}
        } else {
            dispatch({
                type: "FETCH_FINANCE_ORDERS_HISTORY_FAILURE",
                payload: response.data.message,
            });
            console.log("FETCH_FINANCE_ORDERS_HISTORY_FAILURE: ", response.data.status);
        }
    } catch (error) {
        console.log("FETCH_FINANCE_ORDERS_HISTORY_FAILURE: ", error.message);
        dispatch({ type: "FETCH_FINANCE_ORDERS_HISTORY_FAILURE", payload: error.message });
    }
};

export const FinanceOrdersTradeHistory = (userID, userToken) => async (dispatch) => {
    dispatch({ type: "FETCH_FINANCE_ORDERS_TRADE_HISTORY_LOADING" });
    try {
        const response = await assetsUser.FinanceOrdersTradeHistory(userID, userToken);
        

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_FINANCE_ORDERS_TRADE_HISTORY_SUCCESS",
                payload: response.data.data,
            });
            // toast.success(response.data)
            return { success: true , data: response.data}
        } else {
            dispatch({
                type: "FETCH_FINANCE_ORDERS_TRADE_HISTORY_FAILURE",
                payload: response.data.message,
            });
            console.log("FETCH_FINANCE_ORDERS_TRADE_HISTORY_FAILURE: ", response.data.status);
        }
    } catch (error) {
        console.log("FETCH_FINANCE_ORDERS_TRADE_HISTORY_FAILURE: ", error.message);
        dispatch({ type: "FETCH_FINANCE_ORDERS_TRADE_HISTORY_FAILURE", payload: error.message });
    }
};
export const fetchFiatBalance = (userID, userToken) => async (dispatch) => {
    dispatch({ type: "FETCH_FIAT_BALANCE_LOADING" });
    try {
        const response = await assetsUser.getFiatBalance(userID, userToken);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_FIAT_BALANCE_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: response.data };
        } else {
            dispatch({
                type: "FETCH_FIAT_BALANCE_FAILURE",
                payload: response.data.message,
            });
            console.log("FiatBalance Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("FiatBalance Data Error: ", error.message);
        dispatch({ type: "FETCH_FIAT_BALANCE_FAILURE", payload: error.message });
    }
};

export default fetchAssetsData;
