import React, { useState, useEffect, useRef } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentOrders } from 'store/actions/LaunchGuardAction';

function OrderHistory() {
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("All");

    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const orderHistory =useSelector((state) => state.lgData?.currentOrder?.data || []);

    useEffect(() => {
        if (userID && userToken) {
            dispatch(fetchCurrentOrders(userID, userToken));
        }
    }, [dispatch, userID, userToken]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    console.log("orderHistory",orderHistory)
    // Filter data to only show status 1 and 3
    const filteredByStatus = orderHistory.filter(item => item.status === "1" || item.status === "2");
    console.log("filteredByStatus",filteredByStatus)
    // Extract unique coin names from filtered data
    const options = ["All", ...new Set(filteredByStatus.map((item) => item.coinname))];

    // Apply coin filter
    const filteredOrders = selectedOption === "All" 
        ? filteredByStatus 
        : filteredByStatus.filter((item) => item.coinname === selectedOption);

    return (
        <>
            {/* Dropdown */}
            <div className="relative w-64 mt-5" ref={dropdownRef}>
                <p className="mb-1">Coin</p>
                <div
                    className="flex items-center justify-between border px-4 py-2 rounded-md cursor-pointer"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                    <p className="font-semibold text-gray-700">{selectedOption}</p>
                    {isDropdownOpen ? <IoChevronUp size={16} /> : <IoChevronDown size={16} />}
                </div>

                {isDropdownOpen && (
                    <div className="absolute top-full mt-2 w-full bg-white border rounded-md shadow-lg z-10">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-gray-700"
                                onClick={() => {
                                    setSelectedOption(option);
                                    setIsDropdownOpen(false);
                                }}
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Orders Table */}
            <div className="w-full mt-5 border rounded-lg shadow-sm">
                {/* Table Header */}
                <div className="flex justify-between bg-gray-100 p-3 font-semibold text-gray-600">
                    <p className="flex-1 text-start">Coin Name</p>
                    <p className="flex-1 text-start">Project Name</p>
                    <p className="flex-1 text-start">Buy Coin</p>
                    <p className="flex-1 text-start">Price</p>
                    <p className="flex-1 text-start">Total Amount</p>
                    <p className="flex-1 text-start">Convert Price</p>
                    <p className="flex-1 text-start">Convert Currency</p>
                    <p className="flex-1 text-start">status</p>
                </div>

                {/* Table Body */}
                <div className="h-96 overflow-y-auto">
                    {filteredOrders.length > 0 ? (
                        filteredOrders.map((item, index) => (
                            <div key={index} className="flex justify-between w-full border-b p-3">
                                <p className="flex-1 text-gray-700 text-start">{item.coinname}</p>
                                <p className="flex-1 text-gray-700 text-start">{item.name}</p>
                                <p className="flex-1 text-gray-700 text-start">{item.buycoin}</p>
                                <p className="flex-1 text-gray-700 text-start">{parseFloat(item.price).toFixed(6)}</p>
                                <p className="flex-1 text-gray-700 text-start">{parseFloat(item.num).toFixed(6)}</p>
                                <p className="flex-1 text-gray-700 text-start">{parseFloat(item.convert_price).toFixed(6)}</p>
                                <p className="flex-1 text-gray-700 text-start">{item.convertcurrency}</p>
                                <p className="flex-1 text-gray-700 text-start">
    {item.status === "1" ? "Unlock" : item.status === "2" ? "Cancel" : item.status}
</p>

                            </div>
                        ))
                    ) : (
                        <div className="w-full flex justify-center items-center h-40">
                            <p className="text-gray-500 text-sm font-medium">No data found</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default OrderHistory;
