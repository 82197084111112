import React from "react";

const CryptoTransactionModal = ({ isOpen, onClose,currencyConversionRates,getConversionRateText }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-96 p-6">
        {/* Header */}
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Successful</h2>
          <button className="text-gray-500 hover:text-gray-700" onClick={onClose}>
            ✕
          </button>
        </div>

        {/* Content */}
        <div className="mt-4 text-center">
          <p className="text-2xl font-bold">{currencyConversionRates?.converted_amount}{' '}
          <span className="uppercase">{currencyConversionRates?.to_coin}</span></p>
          <p className="text-gray-500 mt-2">You received</p>
        </div>

        {/* Exchange Rate */}
        <div className="p-4 bg-gray-100 rounded-md text-gray-700 text-sm">
  <p className="flex justify-between">
    <span>Rate:</span> 
    <span className="font-semibold text-center text-gray-500">{getConversionRateText()}</span>
  </p>
  <p className="flex justify-between">
    <span>Payment Method:</span> 
    <span className="font-semibold">Spot wallet + Funding wallet</span>
  </p>
  <p className="flex justify-between">
    <span>Transaction Fees:</span> 
    <span className="font-semibold">0 {currencyConversionRates.from_coin.toUpperCase()}</span>
  </p>
</div>

        {/* Auto-Invest Section */}
        <div className="mt-4 flex items-center justify-between bg-yellow-100 p-3 rounded-lg">
          <p className="text-sm text-yellow-700">Auto-Invest {currencyConversionRates.from_coin.toUpperCase() }<br /><span className="text-red-500">3-month ROI is -0.00695316</span></p>
          <button className="bg-yellow-500 text-white px-3 py-1 rounded-md">Create a Plan</button>
        </div>

        {/* Footer Buttons */}
        <div className="mt-6 flex justify-between">
          <button className="bg-gray-200 px-4 py-2 rounded-md" onClick={onClose}>Go to Wallet</button>
          <button className="bg-yellow-500 text-white px-4 py-2 rounded-md" onClick={onClose}>OK</button>
        </div>
      </div>
    </div>
  );
};

export default CryptoTransactionModal;
