import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FinanceOrdersTradeHistory } from 'store/actions/AssetsUserAction';
import NoData from 'assets/img/user/No-data.svg';

const TradeHistoryTable = () => {
    const dispatch = useDispatch();
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const [orderListData, setOrderListData] = useState([]);

    const marketPairs = {
        ada_usdt: { id: '23', name: 'ada_usdt' },
        bcx_usdt: { id: '25', name: 'bcx_usdt' },
        btc_usdt: { id: '18', name: 'btc_usdt' },
        cvm_usdt: { id: '24', name: 'cvm_usdt' },
        doge_btc: { id: '16', name: 'doge_btc' },
        eth_usdt: { id: '19', name: 'eth_usdt' },
        ltc_btc: { id: '15', name: 'ltc_btc' },
        usdt_rub: { id: '21', name: 'usdt_rub' },
    };

    useEffect(() => {
        dispatch(FinanceOrdersTradeHistory(userID, userToken))
            .then((response) => {
                if (response) {
                    const orderList = response.data?.data?.list || [];
               

                    setOrderListData(orderList.slice(0, 15));
                } else {
                    console.error(response);
                }
            });
    }, [dispatch, userID, userToken]);

    return (
        <div className="text-xs mt-8 w-full overflow-x-auto">
            <div className="flex justify-between border-b pb-3 w-[95%] trade-table">
                <p style={{ width: '10%' }}>Order No</p>
                <p style={{ width: '10%' }}>Date</p>
                <p style={{ width: '10%' }}>Pair</p>
                <p style={{ width: '10%' }}>Side</p>
                <p style={{ width: '10%' }}>Price</p>
                <p style={{ width: '10%' }}>Fees</p>
                <p style={{ width: '10%' }}>Filled</p>
                <p style={{ width: '10%' }}>Role</p>
                <p style={{ width: '10%' }}>Total</p>
                <p style={{ width: '10%' }}>Total in USDT</p>
            </div>

            {orderListData && orderListData.length > 0 ? (
                orderListData.map((order, index) => (
                    <div key={index} className="flex justify-between py-2 border-b w-[95%] trade-table">
                        <p style={{ width: '10%' }}>{order.id || '-'}</p>
                        <p style={{ width: '10%' }}>{new Date(parseInt(order.addtime) * 1000).toLocaleString() || '-'}</p>
                        <p style={{ width: '10%' }}>{order.market?.toUpperCase() || '-'}</p>
                        <p className="w-[8%] text-gray-700">{order.type === "1" ? "Buy" : "Sell"}</p>
                        <p style={{ width: '10%' }}>{parseFloat(order.price).toFixed(2) || '-'}</p>
                        <p style={{ width: '10%' }}>
                            {order.type === "1" ? parseFloat(order.fee_buy).toFixed(2) || '-' : parseFloat(order.fee_sell).toFixed(2) || '-'}
                        </p>
                        <p style={{ width: '10%' }}>{order.fill || '-'}</p>
                        <p style={{ width: '10%' }}>{order.type === "1" ? "Taker" : "Maker"}</p>
                        <p style={{ width: '10%' }}>{parseFloat(order.price * order.num).toFixed(2) || '-'}</p>
                        <p style={{ width: '10%' }}>{parseFloat(order.price * order.num).toFixed(2) || '-'}</p>
                    </div>
                ))
            ) : (
                <div className="w-full flex justify-center items-center h-96">
                    <div className="flex flex-col gap-1 h-44 justify-center items-center">
                        <img src={NoData} alt="No Data" />
                        <p className="text-[#707a8a] text-sm">No records</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TradeHistoryTable;
