import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MdArrowDropDown } from 'react-icons/md';
import { fetchFiatList } from 'store/actions/fiatListAction';

const SkeletonRow = () => (
  <div className="flex py-2 px-4 w-full bg-gray-200 dark:bg-gray-700 animate-pulse">
    <div className="w-full h-4 bg-gray-300 dark:bg-gray-600 rounded"></div>
  </div>
);

const CurrencyDropdown = ({ selectedCurrency, setSelectedCurrency }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const { loading, fiatList, error } = useSelector((state) => state.fiatList);
  const user = useSelector((state) => state.authUser?.user || {});
  const userId = user?.ID;
  const token = user?.TOKEN;
  const history = useHistory();

  useEffect(() => {
    if (!userId || !token) {
      history.push('/login');
    } else {
      dispatch(fetchFiatList(userId, token));
      
    }
  }, [dispatch, userId, token, history, selectedCurrency]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectCurrency = (currency) => {
    setSelectedCurrency(currency);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredList = fiatList.filter((currency) =>
    currency.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="flex gap-2.5 justify-center py-2 my-auto text-center whitespace-nowrap rounded-md leading-[189%] text-neutral-900 dark:text-white cursor-pointer" onClick={toggleDropdown}>
        <div>{selectedCurrency}</div>
        <div>
          <MdArrowDropDown className="shrink-0 mt-2 aspect-[1.67] w-[25px]" />
        </div>
      </div>
      {isOpen && (
        <div className="absolute md:-ml-28 lg:-ml-44 top-full mt-2 w-40 sm:w-full md:w-52 lg:w-64 bg-[#F4F4F4] text-[#151415] dark:bg-[#151415] dark:text-[#F4F4F4] border border-gray-300 dark:border-gray-700 rounded-md shadow-lg z-10 dark:bg-custom-dark transition-transform transform origin-top scale-y-100">
          <input
            type="text"
            placeholder="Search..."
            className="w-full px-4 py-2 bg-[#F4F4F4] text-[#151415] dark:bg-[#151415] dark:text-[#F4F4F4] border-b border-gray-300 dark:border-[#454545]"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="max-h-60 overflow-y-auto">
            {loading && (
              <>
                <SkeletonRow />
                <SkeletonRow />
                <SkeletonRow />
              </>
            )}
            {error && <div className="py-2 px-4">Error: {error}</div>}
            {!loading && !error && filteredList.length === 0 && (
              <div className="py-2 px-4">No currencies found</div>
            )}
            {!loading && !error && filteredList.map((currency) => (
              <div
                key={currency.name}
                className="flex items-center py-2 px-4 bg-[#F4F4F4] text-[#151415] dark:bg-[#151415] dark:text-[#F4F4F4] hover:bg-amber-400 dark:hover:bg-amber-400 cursor-pointer"
                onClick={() => selectCurrency(currency.name)}
              >
                <img src={`https://api.buycex.com/Upload/coin/${currency.img}`} alt={currency.name} className="inline-block w-6 h-6 mr-2" />
                {currency.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrencyDropdown;
