import React from "react";
import { Modal, Input, List } from "antd";
import { IoSearchOutline } from "react-icons/io5";

const CurrencySelectModal = ({
  visible,
  onCancel,
  searchQuery,
  setSearchQuery,
  filteredCurrencies,
  onCurrencySelect,
}) => {
  const query = searchQuery.toLowerCase().trim();
  const filteredResults = filteredCurrencies.filter(
    (currency) =>
      (currency.ticker?.toLowerCase() || "").includes(query) ||
      (currency.label?.toLowerCase() || "").includes(query)
  );
  const uniqueResults = Array.from(
    new Map(
      filteredResults.map((currency) => [
        currency.ticker || currency.label,
        currency,
      ])
    ).values()
  );

  const handleSelect = (currency) => {
    onCurrencySelect(currency); // Trigger currency selection
    setSearchQuery(""); // Reset search bar
  };

  return (
    <Modal open={visible} footer={null} onCancel={onCancel} title="Select Currency">
      <Input
        prefix={<IoSearchOutline />}
        placeholder="Search currency..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: 12 }}
      />
      <div style={{ maxHeight: 300, overflowY: "auto" }}> {/* Limit height and enable scrolling */}
        <List
          dataSource={uniqueResults}
          renderItem={(currency) => (
            <List.Item
              onClick={() => handleSelect(currency)}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "8px 12px",
              }}
            >
              <img
                src={currency.icon}
                alt={currency.label}
                style={{ width: 24, height: 24, marginRight: 10 }}
              />
              <span style={{ flexGrow: 1, fontWeight: 500 }}>{currency.label}</span>
              <span style={{ color: "#888" }}>({currency.ticker})</span>
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};

export default CurrencySelectModal;
