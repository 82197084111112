import React, { useState } from 'react';
import { BsBank2, BsCalendarDateFill } from 'react-icons/bs';

function HowItWork() {
    const [selectedValue, setSelectedValue] = useState('');

    const handlePresetValueClick = (value) => {
        setSelectedValue(value);
    };

    return (
        <div className='container md:mt-0 mt-16 max-w-5xl mx-auto'>
            <div className='flex justify-center items-center'>
                <span className='text-2xl sm:text-3xl font-semibold text-black dark:text-white py-1'>
                    How it's <span className='text-[#EFB81C] text-2xl sm:text-3xl font-semibold'>works.</span>
                </span>
            </div>
            <div className='flex flex-col lg:flex-row w-full my-14 gap-8'>
                <div className='w-full lg:w-1/2 flex flex-col gap-6'>
                    <div className='px-4 py-8 rounded-xl border dark:border-[#454545] flex flex-col gap-4 items-center'>
                        <BsCalendarDateFill size={52} />
                        <h3>Setup Daily Savings</h3>
                        <div className='border-2 dark:border-[#454545] rounded-xl w-full flex items-center py-1'>
                            <div className='border-r dark:border-[#454545] px-6 py-2 font-semibold text-lg'>$</div>
                            <input
                                className='py-2 bg-transparent w-full focus:outline-none px-3 font-semibold text-lg'
                                type="number"
                                value={selectedValue}
                                onChange={(e) => setSelectedValue(e.target.value)}
                            />
                        </div>
                        <div className='flex flex-wrap gap-2 mt-2 w-full'>
                            {[5, 10, 15, 20, 50, 100].map(value => (
                                <button
                                    key={value}
                                    onClick={() => handlePresetValueClick(value)}
                                    className='px-2 sm:px-4 text-ellipsis whitespace-nowrap py-0.5 border dark:border-[#454545] dark:bg-[#151415] rounded-lg text-black dark:text-white text-sm font-medium w-1/6'>
                                    $ {value}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className='flex flex-col items-center gap-1'>
                        <h4 className='text-[#EFB81C] font-semibold text-lg'>Step <span className='text-[#EFB81C] text-lg font-semibold'>1</span></h4>
                        <p className='font-medium'>Set desired amount</p>
                        <p className='text-sm opacity-75 w-72 text-center'>Bit Saver recommends at least 1usdt/day.</p>
                    </div>
                </div>
                <div className='w-full lg:w-1/2 flex flex-col gap-6'>
                    <div className='rounded-xl border dark:border-[#454545]'>
                        <div className='h-16 w-full bg-gray-50 dark:bg-[#0b0c0f] border-b dark:border-[#454545] text-center flex justify-center items-center rounded-tr-xl rounded-tl-xl'>
                            <h3 className='font-bold text-lg md:text-2xl'>Start with <span className='font-semibold text-lg md:text-2xl'>1</span>USDT Daily</h3>
                        </div>
                        <div className='p-4'>
                            <p className='text-start text-sm pb-3 font-medium'>Debit From</p>
                            <div className='flex flex-col gap-[2.7rem] justify-between min-h-full'>
                                <div className='flex gap-2 items-center'>
                                    <div className='p-3 bg-white rounded-full flex items-center'><BsBank2 size={44} /></div>
                                    <div className='flex flex-col w-full'>
                                        <div className='flex justify-between w-full'><p className='text-lg font-medium'>Bank Name</p><button className='text-[#EFB81C] font-medium'>Change</button></div>
                                        <p className='text-sm'>UPI</p>
                                    </div>
                                </div>
                                <button className='bg-[#EFB81c] rounded-lg py-2 w-full font-medium'>Pay & Setup Autopay</button>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col items-center gap-1'>
                        <h4 className='text-[#EFB81C] font-semibold text-lg'>Step <span className='text-[#EFB81C] text-lg font-semibold'>2</span></h4>
                        <p className='font-medium'>Setup UPI mandate</p>
                        <p className='text-sm opacity-75 w-72 text-center'>Set up mandate on your UPI App to automate your savings.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HowItWork;
