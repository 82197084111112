const initialState = {
  loading: false,
  assetsContext: [],
  error: null
};

const assetsContextReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ASSETS_CONTEXT_REQUEST":
      return {
        ...state,
        loading: true,
        error: null
      };
    case "FETCH_ASSETS_CONTEXT_SUCCESS":
      return {
        ...state,
        loading: false,
        assetsContext: action.payload
      };
    case "FETCH_ASSETS_CONTEXT_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default assetsContextReducer;
