import React, { useState, useRef, useEffect } from 'react';
import { TbCaretUpDownFilled } from "react-icons/tb";
import NoData from "assets/img/user/No-data.svg";
import { FaBitcoin, FaEthereum } from "react-icons/fa";
import TransferModal from 'views/trade/PostAds/TransferModal';
import CryptoDepositModal from 'components/cryptoDeposit/CryptoDepositPopup/CryptoDepositModal';
import ConvertModal from 'views/trade/PostAds/ConvertModal';
import { IoMdMore } from "react-icons/io";

function FutureTableValues({ isVisible, searchQuery, showGreaterThanOne }) {
    const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
    const handleTransferClick = () => {
        setIsTransferModalVisible(true);
    };

    const handleTransferCancel = () => {
        setIsTransferModalVisible(false);
    };

    const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useState(false);
    const showWithdrawModal = () => {
        setIsWithdrawModalVisible(true);
    };

    const closeWithdrawModal = () => {
        setIsWithdrawModalVisible(false);
    };

    const [cryptoDepositModalVisible, setCryptoDepositModalVisible] = useState(false);
    const showCryptoDepositModal = () => {
        setCryptoDepositModalVisible(true);
    };

    const handleCloseCryptoDepositModal = () => {
        setCryptoDepositModalVisible(false);
    };

    const [isConvertModalVisible, setIsConvertModalVisible] = useState(false);
    const handleConvertClick = () => {
        setIsConvertModalVisible(true);
    };

    const handleConvertCancel = () => {
        setIsConvertModalVisible(false);
    };

    const [openDropdown, setOpenDropdown] = useState({});

    const handleDropdownToggle = (id) => {
        setOpenDropdown((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenDropdown((prevState) => {
                    const updatedState = { ...prevState };
                    Object.keys(updatedState).forEach((key) => {
                        updatedState[key] = false;
                    });
                    return updatedState;
                });
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const popularCoins = [
        {
            id: 1,
            name: 'BTC',
            icon: <FaBitcoin className='text-2xl text-[#F7931A]' />,
            color: 'red',
            amount: '0.000000',
            price: '0.000000',
            descriptions: {
                coin: 'Bitcoin',
                amount: '$0.000000',
                subprice: '$0.000000',
            }
        },
        {
            id: 2,
            name: 'ETH',
            icon: <FaEthereum className='text-2xl text-[#3C3C3D]' />,
            color: 'green',
            amount: '0.000000', // Updated amount to test the filtering
            price: '0.000000',
            descriptions: {
                coin: 'Ethereum',
                amount: '$0.000000',
                subprice: '$0.000000',
            }
        },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; 

    const filteredCoins = popularCoins.filter(coin => {
        const matchesSearch = coin.name.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesAmount = showGreaterThanOne ? parseFloat(coin.amount) > 1 : true;
        return matchesSearch && matchesAmount;
    });

    // Calculate pagination details
    const totalPages = Math.ceil(filteredCoins.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentCoins = filteredCoins.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <div className='w-full'>
            {/* Header row */}
            <div className="w-full overflow-x-auto">
            <div className='flex bg-gray-100 min-w-[700px] py-4 px-2 mb-8'>
                <div className='flex items-center gap-1 w-[20%]'>
                    <p className='text-xs text-[#929AA5]'>Coin</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-[20%]'>
                    <p className='text-xs text-[#929AA5]'>Net Equity</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-[25%]'>
                    <p className='text-xs text-[#929AA5] text-ellipsis whitespace-nowrap flex'>Wallet Balance <span className='hidden lg:block'>(Bonus Inclusive)</span></p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-[20%] text-ellipsis whitespace-nowrap'>
                    <p className='text-xs text-[#929AA5]'>Unrealized P&L</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-end gap-1 w-1/5 md:w-[35%]'>
                    <p className='text-xs text-[#929AA5]'>Action</p>
                </div>
            </div>

            {/* Check if there are any filtered coins and display the appropriate message */}
            {currentCoins.length === 0 ? (
                <div className='text-center text-md'>
                    <div className='flex flex-col gap-1 h-44 justify-center items-center'>
                        <img src={NoData} alt="No Data" />
                        <p className='text-[#707a8a] text-sm'>No records</p>
                    </div>
                </div>
            ) : (
                currentCoins.map((coin, index) => (
                    <div key={index} className='flex min-w-[700px] items-center mb-4'>
                        <div className='flex gap-2 w-[20%]'>
                            <div className='flex items-center gap-2'>
                                <div className='text-lg' style={{ color: coin.color }}>
                                    {coin.icon}
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <p className='text-sm md:text-[16px] font-medium'>{coin.name}</p>
                                <p className='text-[12px] md:text-sm text-[#929AA5]'>{coin.descriptions.coin}</p>
                            </div>
                        </div>
                        <div className='flex flex-col gap-1 w-[20%]'>
                            <p className='text-md text-end font-normal'>{isVisible ? coin.amount : '****'}</p>
                            <p className='text-sm text-end text-[#707a8a]'>{isVisible ? coin.descriptions.amount : '****'}</p>
                        </div>
                        <div className='flex flex-col gap-1 w-[25%]'>
                            <p className='text-md text-end font-normal'>{isVisible ? coin.price : '****'}</p>
                            <p className='text-sm text-end text-[#707a8a]'>{isVisible ? coin.descriptions.amount : '****'}</p>
                        </div>

                        <div className='flex flex-col gap-1 w-[20%]'>
                            <p className='text-end font-normal'>{isVisible ? <span className='text-[#2ebd85] !text-[16px]'>{coin.price}</span> : '****'}</p>
                            <p className='text-sm text-end text-[#707a8a]'>{isVisible ? coin.descriptions.amount : '****'}</p>
                        </div>
                        <div className='flex justify-end w-1/5 md:w-[35%]'>
                            <div className='hidden lg:flex justify-end gap-2 w-2/5'>
                                <button className='text-sm flex items-center px-2 py-1 border rounded-md' onClick={showCryptoDepositModal}>Deposit</button>
                                <CryptoDepositModal visible={cryptoDepositModalVisible} onClose={handleCloseCryptoDepositModal} />
                                <button className='text-sm flex items-center px-2 py-1 border rounded-md'>Loan</button>
                                <button className='text-sm flex items-center px-2 py-1 border rounded-md' onClick={handleConvertClick}>Convert</button>
                                <ConvertModal visible={isConvertModalVisible} onCancel={handleConvertCancel} />
                                <button className='text-sm flex items-center px-2 py-1 border rounded-md' onClick={handleTransferClick}>Transfer</button>
                                <TransferModal visible={isTransferModalVisible} onCancel={handleTransferCancel} />
                                <button className='text-sm flex items-center px-2 py-1 border rounded-md'>Trade</button>
                            </div>


                            <div className="lg:hidden">
                                <button className="text-sm flex items-center px-2 py-1 border rounded-md" onClick={() => handleDropdownToggle(coin.id)}>
                                    <IoMdMore className="text-lg" />
                                </button>

                                {openDropdown[coin.id] && (
                                    <div ref={dropdownRef} className="absolute mt-2 right-0 w-40 bg-white shadow-lg rounded-md border border-gray-200">
                                        <button className='text-sm w-full flex items-center px-2 py-1 hover:bg-gray-50' onClick={showCryptoDepositModal}>Deposit</button>
                                        <button className='text-sm w-full flex items-center px-2 py-1 hover:bg-gray-50'>Loan</button>
                                        <button className='text-sm w-full flex items-center px-2 py-1 hover:bg-gray-50' onClick={handleConvertClick}>Convert</button>
                                        <button className='text-sm w-full flex items-center px-2 py-1 hover:bg-gray-50' onClick={handleTransferClick}>Transfer</button>
                                        <button className='text-sm w-full flex items-center px-2 py-1 hover:bg-gray-50'>Trade</button>
                                    </div>
                                )}
                                <TransferModal visible={isTransferModalVisible} onCancel={handleTransferCancel} />
                                <ConvertModal visible={isConvertModalVisible} onCancel={handleConvertCancel} />
                                <CryptoDepositModal visible={cryptoDepositModalVisible} onClose={handleCloseCryptoDepositModal} />
                            </div>

                        </div>
                    </div>
                ))
            )}
            </div>
             {/* Pagination Controls */}
             {filteredCoins.length > itemsPerPage && (
                    <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={`px-3 py-1 mx-1 text-xs font-semibold border rounded ${currentPage === index + 1 ? 'bg-yellow-500 text-black' : 'bg-gray-100 text-gray-700'}`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
                )}
        </div>
    );
}

export default FutureTableValues;
