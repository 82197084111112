import React, { useState, useEffect } from "react";
import { GoDotFill } from "react-icons/go";
import { NavLink } from "react-router-dom";
import { FaCheck, FaArrowRight } from "react-icons/fa";
import { components } from "react-select";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CoinSelect from "./CoinSelect";
import NetworkSelect from "./NetworkSelect";
import DepositAddress from "./DepositAddress";
import trace from "assets/img/image-21@2x.png";
import RoutingNavbar from "components/routingNavbar/RoutingNavbar";
import { ImNotification } from "react-icons/im";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { fetchAssetsContext } from "store/actions/AssetsUserAction";
import { CgSoftwareDownload } from "react-icons/cg";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { fetchCryptoDeposit } from "store/actions/cryptoDepositAction";
import { BiLinkAlt, BiSolidCopy } from "react-icons/bi";

const CustomOption = ({ data, ...props }) => (
    <components.Option {...props}>
        <div className="flex items-center">
            <img
                src={data.icon}
                alt={`${data.name}-coin`}
                className="w-6 h-6 mr-2"
            />
            <div className="flex-grow">{data.label}</div>
        </div>
    </components.Option>
);
const CryptoDeposit = () => {
    const [coins, setCoins] = useState([]);
    const [selectedCrypto, setSelectedCrypto] = useState(null);
    const [networks, setNetworks] = useState([]); 
    const [networkContract, setNetworkContract] = useState([]); // Initialize as an empty array
    const [selectedNetwork, setSelectedNetwork] = useState([]);
    const [withdrawalAddress, setWithdrawalAddress] = useState("");
    const [deposits, setDeposits] = useState([]);
    const history = useHistory();

   

    const handleRedirect = () => {
        history.push("/user/history/crypto-deposit");
    };
    const dispatch = useDispatch();
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};

    const { loading, data, error } = useSelector((state) => state.cryptoDeposit);
    const { assetsContext, } = useSelector(
        (state) => state.assetsContext || {}
    );



useEffect(() => {
    if (selectedCrypto && TOKEN && ID) {
      dispatch(fetchCryptoDeposit(selectedCrypto, TOKEN, ID));
    }
  }, [dispatch, selectedCrypto, TOKEN, ID]);
    const handleNetworkChange = (selectedOption) => {
       
        setSelectedNetwork(selectedOption);
        
    };

   
  
    useEffect(() => {
        if (!TOKEN || !ID) {
            console.log("Redirecting to sign-in");
            history.push("/login");
            return;
        }
     
        if (assetsContext?.cryptoList) {
            const coinsData = [];

            
            Object.keys(assetsContext.cryptoList).forEach((coinKey) => {
                const coin = assetsContext.cryptoList[coinKey];
            
                coinsData.push({
                    value: coinKey.toUpperCase(),
                    label: coinKey.toUpperCase(),
                    icon: `${coin.img}`,
                });
            });

          
            setCoins(coinsData);
        }
    }, [assetsContext]);
    useEffect(() => {
        if (!ID || !TOKEN) {
            history.push("/deposit");
            return;
        }
        dispatch(fetchAssetsContext(ID, TOKEN, "spot","deposit"));
    }, [dispatch, ID, TOKEN, history]);


    useEffect(() => {
        if (!TOKEN || !ID) {
            console.log("Redirecting to sign-in");
            history.push("/login");
            return;
        }
    
        const processCryptoDeposit = async () => {
            try {
                // Dispatch Redux action to fetch deposit data
                const response = await dispatch(fetchCryptoDeposit(selectedCrypto, TOKEN, ID));
    
                if (response && response.data) {
                    console.log("Fetched crypto deposit data:", response.data);
    
                    // Extract networks from the response
                    const networks = Object.keys(response.data.network).map((key) => {
                        const coin = response.data.network[key];
                        return { value: coin.name, label: coin.title };
                    });
    
                    // Set the networks state
                    setNetworks(networks);
                 
                    // Check and set the withdrawal address for the selected network
                    const networkKey = selectedNetwork?.value;
                    if (networkKey && response.data.wallet[networkKey]) {
                        setWithdrawalAddress(response.data.wallet[networkKey]);
                       setNetworkContract(response.data.network[networkKey].contract);
               
                        console.log("networkContract",withdrawalAddress)
                      
                    } else {
                        console.error(
                            `Error: Wallet data for network "${networkKey}" not found.`
                        );
                    }
                } else {
                    console.log("No data available from the crypto deposit response.");
                }
            } catch (error) {
                console.error("Error processing crypto deposit:", error);
            }
        };
    
        if (selectedCrypto) {
            processCryptoDeposit();
        }
       
        
    }, [selectedCrypto, selectedNetwork, TOKEN, ID, history, dispatch]);
    
    useEffect(() => {
        if (!TOKEN || !ID) {
            console.log("Redirecting to sign-in");
            history.push("/login");
            return;
        }
    
        const fetchCryptoData = async () => {
            try {
                if (!selectedCrypto || !selectedCrypto.value) {
                    console.error("Error: selectedCrypto is not set.");
                    return;
                }
        
                // Reset the deposit data before fetching new data
                setDeposits([]); // Clear existing data
        
                const depositHistoryResponse = await fetch(
                    `https://api.buycex.com/Crypto/MyDeposits/coin/?coin=${selectedCrypto.value.toLowerCase()}`,
                    {
                        headers: {
                            TOKEN: TOKEN,
                            ID: ID,
                        },
                    }
                );
        
                if (!depositHistoryResponse.ok) {
                    throw new Error(
                        `Error fetching deposit history: ${depositHistoryResponse.status} - ${depositHistoryResponse.statusText}`
                    );
                }
        
                const depositHistoryData = await depositHistoryResponse.json();
        
                if (depositHistoryData && depositHistoryData.data) {
                    setDeposits(depositHistoryData.data);
                    console.log("Fetched deposits data:", depositHistoryData.data);
                } else {
                    console.log("No deposits data available.");
                }
            } catch (error) {
                console.error("Error fetching deposit data:", error);
            }
        };
        
    
        if (selectedCrypto) {
            fetchCryptoData();
        }
    }, [selectedCrypto, TOKEN, ID, history]);
    
   

   

    const handleImageClick = (coin) => {
        setSelectedNetwork(null);
        setSelectedCrypto(coin);
    };

    const faqs = [
        "How to Make a Deposit",
        "Unsupported Deposit Recovery Procedure Rules",
        "FAQ — Crypto Deposit",
        "How to Recover a Deposit with Wrong or Missing Tag/Memo",
        "Deposits yet to be credited? Self-Service",
        "Deposit/Withdrawal Status of All Coins Find Out",
    ];
    const SkeletonRow = () => (
        <tr>
            <td className="px-4 py-3 text-center">
                <div className="w-4 h-4 m-auto bg-gray-300 rounded-full animate-pulse"></div>
            </td>
            <td className="px-4 py-3 text-center">
                <div className="w-24 h-4 m-auto bg-gray-300 rounded animate-pulse"></div>
            </td>
            <td className="px-4 py-3 text-center">
                <div className="w-16 h-4 m-auto bg-gray-300 rounded animate-pulse"></div>
            </td>
            <td className="px-4 py-3 text-center">
                <div className="w-32 h-4 m-auto bg-gray-300 rounded animate-pulse"></div>
            </td>
            <td className="px-4 py-3 text-center">
                <div className="w-40 h-4 m-auto bg-gray-300 rounded animate-pulse"></div>
            </td>
        </tr>
    );

    const handleCoinSelect = (crypto) => {
        setSelectedCrypto(crypto);
        setSelectedNetwork(null);
    };

    return (
        <main className="container py-2 w-full h-full pb-5">
            <div className="flex flex-col items-center justify-between w-full h-full my-2 gap-8">
                {/* section1 */}
                <section className="flex flex-col items-center justify-between w-full gap-3 section1">
                    {/* nav (upper) */}
                    {/* <RoutingNavbar /> */}

                    <div className=" w-full max-w-100 max-md:max-w-full m-auto min-w-[400px]">
                        <div className="mb- flex justify-between w-full mt-[10 px] md:mt-5">
                            <p className="font-semibold text-[22px] md:text-3xl">
                                Deposit
                            </p>
                            <Link
                                to="/crypto/withdraw"
                                className="flex justify-end"
                            >
                                <button className="self-end rounded-md font-semibold mb-4 bg-[#EFB81C] text-white py-2 flex items-center gap-1 px-4">
                                    Withdraw
                                    <FaArrowRight />
                                </button>
                            </Link>
                        </div>
                        <div className="flex flex-col gap-10 lg:flex-row md:gap-10 lg:w-full md:space-x-0">
                            <section className="w-full lg:w-1/2 lg:min-w-[53%]">
                                <div className="border dark:border-[#454545] rounded-[20px] pt-5 pb-5 dark:bg-[#090a0c]">
                                    <div className="flex w-full">
                                        <div className="flex flex-col w-[10%] mt-1 items-center gap-8">
                                            <CgSoftwareDownload size={28} />
                                            <div>
                                                <div className="flex items-center justify-end">
                                                    <div className="flex flex-col items-center">
                                                        {/* First Icon with Kite Shape Background */}
                                                        <div
                                                            className={`relative flex items-center justify-center w-6 h-6 ${selectedCrypto
                                                                ? "bg-black"
                                                                : "bg-black"
                                                                } text-white`}
                                                            style={{
                                                                clipPath:
                                                                    "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
                                                            }}
                                                        >
                                                            <span className="absolute transform text-md font-semibold">
                                                                {selectedCrypto ? (
                                                                    <FaCheck />
                                                                ) : (
                                                                    "1"
                                                                )}
                                                            </span>
                                                        </div>

                                                        {/* Vertical Line */}
                                                        <div
                                                            className={`w-0.5 h-[10rem] ${selectedCrypto
                                                                ? "bg-black"
                                                                : "bg-gray-100"
                                                                }`}
                                                        ></div>

                                                        {/* Second Icon with Kite Shape Background */}
                                                        <div className="flex items-center justify-center w-6 h-6">
                                                            <div
                                                                className={`relative flex items-center justify-center w-6 h-6 ${selectedCrypto
                                                                    ? "bg-black"
                                                                    : "bg-gray-100"
                                                                    } text-white`}
                                                                style={{
                                                                    clipPath:
                                                                        "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
                                                                }}
                                                            >
                                                                <span className="absolute transform text-md font-semibold">
                                                                    {selectedNetwork ? (
                                                                        <FaCheck />
                                                                    ) : (
                                                                        <span
                                                                            className={`${selectedCrypto
                                                                                ? "text-white"
                                                                                : "text-gray-400"
                                                                                }`}
                                                                        >
                                                                            2
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        {/* Vertical Line */}
                                                        <div
                                                            className={`w-0.5 ${selectedNetwork
                                                                ? "bg-black h-[5rem]"
                                                                : "bg-gray-100 h-[55px]"
                                                                }`}
                                                        ></div>

                                                        {/* Third Icon with Kite Shape Background */}
                                                        <div className="flex items-center justify-center w-6 h-6">
                                                            <div
                                                                className={`relative flex items-center justify-center w-6 h-6 rounded-md ${selectedNetwork
                                                                    ? "bg-black"
                                                                    : "bg-gray-100"
                                                                    } text-white`}
                                                                style={{
                                                                    clipPath:
                                                                        "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
                                                                }}
                                                            >
                                                                <span className="absolute transform text-white font-semibold">
                                                                    {selectedNetwork ? (
                                                                        <FaCheck />
                                                                    ) : (
                                                                        <span
                                                                            className={`${selectedNetwork
                                                                                ? "text-white"
                                                                                : "text-gray-400"
                                                                                }`}
                                                                        >
                                                                            3
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-5/6">
                                            <form className="px-3 mx-auto">
                                                <CoinSelect
                                                    coins={coins}
                                                    selectedCrypto={
                                                        selectedCrypto
                                                    }
                                                    setSelectedCrypto={
                                                        handleCoinSelect
                                                    }
                                                    handleImageClick={
                                                        handleImageClick
                                                    }
                                                />
                                                <NetworkSelect
            networks={networks}
            selectedCrypto={selectedCrypto}
            selectedNetwork={selectedNetwork}
            setSelectedNetwork={handleNetworkChange}
            networkContract={networkContract}
        />
                                                <label className="block mb-4 text-[20px] font-medium text-gray-900 dark:text-white mt-[2.5rem]">
                                                    <label
                                                        className={`${selectedNetwork
                                                            ? "text-gray-900 dark:text-gray-400"
                                                            : "text-gray-400"
                                                            }`}
                                                    >
                                                        Deposit Address
                                                    </label>
                                                </label>
                                                <DepositAddress
                                                    selectedNetwork={
                                                        selectedNetwork
                                                    }
                                                    selectedCrypto={
                                                        selectedCrypto
                                                    }
                                                    withdrawalAddress={
                                                        withdrawalAddress
                                                    }
                                                />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="w-full md:-1/2">
                                <section>
                                    <div className="font-semibold text-[22px] mt-5 md:text-xl mb-6">
                                        Recent Deposits
                                    </div>
                                    {loading ? (
    Array.from({ length: 5 }).map((_, index) => (
        <SkeletonRow key={index} />
    ))
) : deposits.length === 0 ? (
    <tr>
        <td colSpan="5" className="py-4 text-center">
            <div className="flex items-center justify-center gap-2">
                <ImNotification className="text-black dark:text-white" />
                <span className="text-black dark:text-white">
                    No Recent Deposits
                </span>
            </div>
        </td>
    </tr>
) : (
    deposits.map((deposit, index) => (
        <div className="py-3" key={index}>
            <div className="flex items-center gap-2 py-2">
                <img
                    src={selectedCrypto.icon}
                    alt="Coin"
                    className="w-5 h-5 mt-1"
                />
                <p className="text-md font-medium">{deposit.num}</p>
                <span
                    className={`text-xs font-medium px-2 py-1 rounded-md ${
                        deposit.status === "1"
                            ? "bg-[#22c55e] bg-opacity-10 text-[#22c55e]"
                            : "bg-[#Ef4444] bg-opacity-10 text-[#Ef4444]"
                    }`}
                >
                    {deposit.status === "1" ? "Completed" : "Failed"}
                </span>
            </div>
            <div className="flex justify-between w-full">
                                                    <div className="flex gap-2">
                                                        <p className="text-sm text-[#81858c]">Date</p>
                                                        <p className="text-sm">{new Date(
                                                            parseInt(
                                                                deposit.addtime
                                                            ) * 1000
                                                        )
                                                            .toISOString()
                                                            .slice(0, 19)
                                                            .replace("T", " ")}</p>
                                                    </div>
                                                    <div className="flex gap-2">
                                                        <p className="text-sm text-[#81858c]">Network</p>
                                                        <p className="text-sm">{deposit.coinname}</p>
                                                    </div>
                                                    <div className="flex gap-2">
                                                        <p className="text-sm text-[#81858c]">TxID</p>
                                                        <div className="flex items-center gap-2">
                                                            <p className="text-sm">
                                                                {deposit.txid.length > 8
                                                                    ? deposit.txid.slice(0, 4) + "..." + deposit.txid.slice(-4)
                                                                    : deposit.txid}
                                                            </p>
                                                            <BiLinkAlt className="cursor-pointer" fill="#81858c" />
                                                            <BiSolidCopy
                                                                fill="#81858c"
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(deposit.txid);
                                                                    toast.success("TxID copied to clipboard!");
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>

        </div>
    ))
)}

                                </section>

                                <div className="flex justify-center w-full">
                                    <div className="w-[26rem] p-2 mt-4 rounded-lg bg-whitesmoke box-border overflow-hidden flex flex-row items-center justify-between max-w-full gap-[1.25rem] border-[1px] border-solid border-dark-2 mq450:flex-wrap">
                                        <div className="flex flex-row items-center justify-start gap-[0.937rem] w-100">
                                            <img
                                                className="h-[3.438rem] w-[3.188rem] relative object-cover"
                                                alt=""
                                                src={trace}
                                            />
                                            <Link to='/crypto/trace-deposit' className="flex justify-around gap-16 align-center w-100">
                                                <div className="flex flex-col items-start justify-start gap-[0.062rem]">
                                                    <div className="relative text-[11px] sm:text-[14px] font-semibold">
                                                        Trace your Deposit Now
                                                    </div>
                                                    <div className="relative text-[11px]">
                                                        Get your trace Deposit
                                                    </div>
                                                </div>
                                                <button
            type="button"
            onClick={handleRedirect}
            className="focus:outline-none text-white bg-[#EFB81C] hover:bg-[#EFB81C] focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 dark:focus:ring-yellow-900"
        >
            <FaArrowRight />
        </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
};

export default CryptoDeposit;
