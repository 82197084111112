import React from 'react'
import HeroSection from './heroSection'
import MarketToday from './MarketToday'
import BuySell from './Buy&Sell'
import GetStarted from './GetStarted'
import DiscoverMore from './DiscoverMore'
import TrustedExchange from './TrustedExchange'
import KeepTrading from './KeepTrading'
import StartJourney from './StartJourney'
import Faqs from './Faqs'

function HomePageNew() {
  return (
    <>
    <HeroSection/>
    <MarketToday/>
    <BuySell/>
    <GetStarted/>
    <DiscoverMore/>
    <TrustedExchange/>
    <KeepTrading/>
    <Faqs/>
    <StartJourney/>
    </>
  )
}

export default HomePageNew