import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdArrowForward } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import CurrencyDropdown from "components/currencyDropdown";
import { getPaymentMethods } from "store/actions/fiatDepositAction";
import { doFiatWithdrawal } from "store/actions/fiatWithdrawAction";
import WithdrawalRoutingNavbar from "components/withdrawalRoutingNavbar";
import FaQs from "components/FAQs";
import FiatImg from "assets/img/FiatImg.png";
import FiatDepositPromotion from "components/FiatDepositPromotion";
import withdrawKey from "assets/img/Withdraw-key.png";
import { FaArrowRight } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

const SkeletonRow = () => (
    <div className="flex flex-col gap-5 justify-between px-4 py-3 w-full rounded-xl border border-solid border-zinc-300 dark:border-gray-700 animate-pulse">
        <div className="flex items-center">
            <div className="h-[30px] w-[30px] rounded-full bg-gray-300 dark:bg-gray-700"></div>
            <div className="ml-4 h-5 w-1/2 bg-gray-300 dark:bg-gray-700 rounded"></div>
        </div>
    </div>
);

const FiatWithdraw = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState("USD");
    const [selectedGateway, setSelectedGateway] = useState(null);
    const [paymentMethodName, setPaymentMethodName] = useState("");
    const [fundPassword, setFundPassword] = useState("");
    const [amount, setAmount] = useState("");
    const dispatch = useDispatch();
    const { loading, gateways, error } = useSelector(
        (state) => state.fiatDeposit
    );
    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;
    const history = useHistory();

    useEffect(() => {
        if (!userId || !token) {
            history.push("/login");
        } else {
            dispatch(getPaymentMethods(userId, token, selectedCurrency)).then(
                (response) => {
                    const paymentMethods = response.data.data.gateways;
                    paymentMethods.forEach((method) => {
                        setPaymentMethodName(method.id);
                    });
                }
            );
        }
    }, [dispatch, userId, token, history, selectedCurrency]);

    const handleGatewaySelect = (gatewayId) => {
        setSelectedGateway(gatewayId);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handleButtonClick = () => {
        setIsVisible(!isVisible);
    };

    const handleContinue = () => {
        const withdrawalInputs = {
            num: amount,
            paypassword: fundPassword,
            type: paymentMethodName,
            coinname: selectedCurrency.toLocaleLowerCase(),
        };
        dispatch(doFiatWithdrawal(userId, token, withdrawalInputs)).then((response) => {
          if (response && response.data && response.data.status === 1) {
              setAmount("");
              setFundPassword("");
              setPaymentMethodName('');
              setSelectedCurrency('USD')   
          }
      });;
    };

    return (
        <div className="flex flex-col items-center min-w-[330px] pb-10 text-[#151415] dark:bg-[#151415] dark:text-[#F4F4F4]">
            {/* Header section with navigation */}
            <header className="flex flex-col w-full text-base font-medium text-center max-md:mt-10 max-md:max-w-full">
                <div className="mt-6">
                    <WithdrawalRoutingNavbar />
                </div>
                <div className="flex container !mt-[2.25rem] justify-end items-center">
                    <Link to="/fiat/deposit">
                        <button
                            type="button"
                            className="focus:outline-none mt-3 text-white bg-[#EFB81C] hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-3 py-2 me-2 mb-2 dark:focus:ring-yellow-900"
                        >
                            <div className="flex items-center">
                                <span className="font-medium text-sm">
                                    Fiat Deposit
                                </span>
                                <FaArrowRight className="w-6 pl-1" />
                            </div>
                        </button>
                    </Link>
                </div>
            </header>

            {/* Main content section */}
            <main className="flex flex-col container mt-5 w-full max-md:gap-0 max-md:max-w-full">
                <section className="max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        {/* Left side content */}
                        <div className="flex flex-col w-[57%] max-md:ml-0 max-md:w-full">
                            <article className="flex flex-col mt-2 max-md:mt-10 max-md:max-w-full">
                                <h1 className="text-3xl font-semibold leading-9 text-neutral-900 dark:text-white max-md:max-w-full max-md:text-md">
                                    Fiat Withdraw
                                </h1>
                                <div className="mt-12 max-md:mt-10 max-md:max-w-full hidden md:flex">
                                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                                            <img
                                                alt="Coin"
                                                src={FiatImg}
                                                className="ml-16 mt-5 grow w-full aspect-[1.25] max-w-[538px] max-md:mt-5 max-md:ml-0"
                                                style={{
                                                    transform: "scale(1.5)",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>

                        {/* Right side content */}
                        <div className="flex flex-col w-[40%] max-md:ml-0 max-md:w-full">
                            <section className="flex flex-col grow px-8 py-9 w-full rounded-3xl border border-gray-200 border-solid shadow-sm max-md:px-5 max-md:mt-10 max-md:max-w-full">
                                <label
                                    htmlFor="amount"
                                    className="text-base max-md:text-sm max-md:max-w-full"
                                >
                                    Amount
                                </label>
                                <div className="flex gap-5 justify-between px-4 py-3 mt-3.5 w-full text-lg rounded-xl shadow-[0px_0px_16px_2px_rgba(0,_0,_0,_0.07)] border border-solid border-zinc-300 max-md:flex-wrap max-md:max-w-full">
                                    <input
                                        id="amount"
                                        type="number"
                                        className="flex gap-5 justify-between px-1 py-2 w-full text-lg rounded-xl border-bottom border-solid border-amber-400 max-md:flex-wrap max-md:max-w-full dark:bg-gray-800 dark:text-white"
                                        value={amount}
                                        onChange={handleAmountChange}
                                        placeholder="Limit: 10 - 5000"
                                    />
                                    <CurrencyDropdown
                                        selectedCurrency={selectedCurrency}
                                        setSelectedCurrency={
                                            setSelectedCurrency
                                        }
                                    />
                                </div>
                                <label
                                    htmlFor="payment-method"
                                    className="mt-11 text-base text-neutral-900 dark:text-white max-md:mt-10 max-md:max-w-full max-md:text-sm"
                                >
                                    Payment Method
                                </label>
                                <div
                                    id="payment-method"
                                    className="flex flex-col gap-5 justify-between mt-3 w-full rounded-xl shadow-[0px_0px_16px_2px_rgba(0,_0,_0,_0.07)] text-neutral-900 dark:text-white max-md:flex-wrap max-md:max-w-full"
                                >
                                    {loading && (
                                        <>
                                            <SkeletonRow />
                                        </>
                                    )}
                                    {error && (
                                        <div>
                                            Error loading payment methods{" "}
                                            {error.message}
                                        </div>
                                    )}
                                    {!loading && !error && (
                                        <div className="flex flex-col gap-5">
                                            {gateways && gateways.length > 0 ? (
                                                gateways.map((gateway) => (
                                                    <div
                                                        key={gateway.id}
                                                        className={`flex flex-col gap-5 justify-between px-4 py-3 w-full rounded-xl border border-solid cursor-pointer ${
                                                            selectedGateway ===
                                                            gateway.id
                                                                ? "activeMethod"
                                                                : "border-zinc-300"
                                                        }`}
                                                        onClick={() =>
                                                            handleGatewaySelect(
                                                                gateway.id
                                                            )
                                                        }
                                                    >
                                                        <div className="flex items-center">
                                                            <img
                                                                src={
                                                                    gateway.img
                                                                }
                                                                alt={
                                                                    gateway.title
                                                                }
                                                                className="h-[30px] w-[30px] rounded-full"
                                                            />{" "}
                                                            &nbsp;
                                                            <div className="my-auto text-lg leading-9 text-center max-md:text-sm">
                                                                {gateway.title}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="flex flex-col gap-5 justify-between px-4 py-3 w-full rounded-xl border border-solid border-zinc-300">
                                                    <div className="flex items-center">
                                                        <div className="h-[30px] w-[30px] rounded-full bg-gray-300 animate-pulse"></div>
                                                        &nbsp;
                                                        <div className=" text-lg text-center ml-2 rounded">
                                                            No Payment Method
                                                            Avaliable
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <button
                                    className="justify-center items-center px-5 py-3 mt-10 text-xl font-medium text-center text-white bg-amber-400 rounded-xl shadow-[0px_0px_16px_2px_rgba(0,_0,_0,_0.07)] max-md:px-5 max-md:mt-10 max-md:max-w-full cursor-pointer max-md:text-sm"
                                    onClick={handleButtonClick}
                                >
                                    Continue
                                </button>
                                {isVisible && (
                                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50 ">
                                        <div
                                            className="bg-white p-6 rounded-lg shadow-lg dark:!bg-zinc-900"
                                            style={{
                                                width: "30%",
                                                minWidth: "350px",
                                            }}
                                        >
                                            <div className="flex flex-col items-center justify-center">
                                                <div
                                                    className="flex self-end cursor-pointer"
                                                    onClick={
                                                        handleButtonClick
                                                    }
                                                >
                                                    <AiOutlineClose />
                                                </div>
                                                <img
                                                    src={withdrawKey}
                                                    alt="withdrawKey"
                                                    className="w-40"
                                                />
                                                <div className="w-100">
                                                    <label className="font-[600] dark:text-white">
                                                        Fund Password
                                                    </label>
                                                    <input
                                                        type="password"
                                                        className="bg-gray-50 h-14 rounded-[10px] border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="1234567"
                                                        value={fundPassword}
                                                        onChange={(e) =>
                                                            setFundPassword(
                                                                e.target.value
                                                            )
                                                        }
                                                        required
                                                    />
                                                </div>
                                                <button
                                                    type="button"
                                                    className="mt-4 pl-4 w-100 pr-4 px-4 py-2 rounded-lg bg-[#EFB81C] text-white"
                                                    onClick={handleContinue}
                                                >
                                                    Withdraw
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </section>
                        </div>
                    </div>
                </section>

                {/* Promotional section */}
                <aside>
                    <FiatDepositPromotion />
                    <FaQs />
                </aside>
            </main>
        </div>
    );
};

export default FiatWithdraw;
