import React, { useState, useEffect, useRef } from "react";
import { CiCirclePlus } from "react-icons/ci";
import { MdKeyboardArrowRight, MdOutlinePersonOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom";
import TransferModal from "views/trade/PostAds/TransferModal";
import LockModal from "../LockModal";
import { getProjectDetails, claimreward } from "store/actions/AssetsUserAction";
import { IoIosCloseCircleOutline } from "react-icons/io";
import toast from "react-hot-toast";

const PoolInfo = ({ title, value, align = "start" }) => (
    <div className="flex flex-row md:flex-col justify-between md:justify-normal py-1 md:py-0">
        <p className="text-sm md:text-[13px] opacity-60">{title}</p>
        <p
            className={`text-sm md:text-md pb-0 md:pb-8 font-medium md:font-semibold text-${align}`}
        >
            {value}
        </p>
    </div>
);

const Description = ({ description, descriptionRef }) => (
    <div ref={descriptionRef}>
        <p className="text-sm text-[#1D1D1D99] dark:text-white py-2">
            {description}
        </p>
    </div>
);

const PoolDetails = ({ details, pool }) => {
    return (
        <div className="hidden md:block border-b dark:border-[#454545]/60">
            <div className="flex flex-wrap justify-between w-[80%]">
                <div className="mt-10">
                    <PoolInfo
                        title={`Total ${pool?.pool_name} Rewards`}
                        value={pool?.reward}
                    />
                    <PoolInfo
                        title={`Total Locked (${pool?.pool_name})`}
                        value={pool?.total_tokens_staked || 0}
                        align="start"
                    />
                </div>
                <div className="mt-10">
                    <PoolInfo
                        title={`Locked (${pool?.pool_name})`}
                        value={
                            details?.user_stakes
                                ? details?.user_stakes?.reduce(
                                      (total, stake) =>
                                          total + Number(stake?.tokens_staked),
                                      0
                                  )
                                : 0
                        }
                    />
                    <PoolInfo
                        title={`Claimed Rewards (${pool?.pool_name})`}
                        value={pool?.reward}
                        align="start"
                    />
                </div>
                <div className="mt-10">
                    {/* <PoolInfo title="Start Time" value={pool.starttime} /> */}
                    <PoolInfo
                        title="Participants"
                        value={pool?.unique_users_staked}
                    />
                </div>
                <div className="mt-10">
                    <PoolInfo
                        title="End Time"
                        value={(() => {
                            const stakingPeriodEnd =
                                details?.project?.[0]?.project
                                    ?.staking_period_end;
                            if (!stakingPeriodEnd) return "Loading...";

                            const targetDate = new Date(
                                stakingPeriodEnd
                            ).getTime();
                            const now = new Date().getTime();
                            const difference = targetDate - now;

                            if (difference <= 0) return "Ended";

                            const days = Math.floor(
                                difference / (1000 * 60 * 60 * 24)
                            );
                            const hours = String(
                                Math.floor(
                                    (difference % (1000 * 60 * 60 * 24)) /
                                        (1000 * 60 * 60)
                                )
                            ).padStart(2, "0");
                            const minutes = String(
                                Math.floor(
                                    (difference % (1000 * 60 * 60)) /
                                        (1000 * 60)
                                )
                            ).padStart(2, "0");
                            const seconds = String(
                                Math.floor((difference % (1000 * 60)) / 1000)
                            ).padStart(2, "0");

                            return `${days}d ${hours}h ${minutes}m ${seconds}s`;
                        })()}
                        align="start"
                    />
                </div>
            </div>
        </div>
    );
};

const PoolDetailsPopup = ({ pool }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            {/* Button to open popup - Visible only on mobile */}
            <button
                className="md:hidden text-[#EFB81C] text-xs flex items-center gap-0.5 justify-self-center px-4 py-2 rounded mt-4"
                onClick={() => setIsOpen(true)}
            >
                <span>More Details </span>
                <MdKeyboardArrowRight />
            </button>

            {isOpen && (
                <>
                    <div
                        className="fixed inset-0 bg-black bg-opacity-50 z-30"
                        onClick={() => setIsOpen(false)}
                    ></div>

                    <div className="fixed bottom-0 left-0 w-full bg-white dark:bg-[#090a0c] opacity-100 z-40 px-5 rounded-t-lg shadow-lg transition-transform duration-300">
                        <div className="bg-white dark:bg-[#090a0c]  px-5 py-3 rounded-lg w-full">
                            <div className="flex justify-between w-full border-b dark:border-[#454545]/60 items-center pb-3">
                                <h2 className="text-xl font-bold">
                                    {pool.title}
                                </h2>
                                <IoIosCloseCircleOutline
                                    onClick={() => setIsOpen(false)}
                                    className="opacity-60 cursor-pointer"
                                    size={26}
                                />
                            </div>
                            <div className="flex flex-col mt-3">
                                <div className="flex justify-between py-1">
                                    <p className="text-sm md:text-[13px] opacity-60">
                                        Status
                                    </p>
                                    <p className="text-sm md:text-md pb-0 md:pb-8 font-medium md:font-semibold text-[#EFB81c]">
                                        Coming Up
                                    </p>
                                </div>
                                <PoolInfo
                                    title="Total BCX Rewards"
                                    value={pool.rewards}
                                />
                                <PoolInfo
                                    title={`Total Locked ${pool.title}`}
                                    value={pool.locked}
                                />
                                <div className="flex justify-between py-1">
                                    <p className="text-sm md:text-[13px] opacity-60">
                                        Locked Coin
                                    </p>
                                    <div className="flex items-center gap-1">
                                        <img
                                            src="https://global.buycex.com/Upload/coin/677da3cd24a43.png"
                                            className="w-5 lg:w-14 h-5 lg:h-14"
                                            alt="buycex"
                                        />
                                        <p className="text-sm md:text-md pb-0 md:pb-8 font-medium md:font-semibold">
                                            BUYCEX
                                        </p>
                                    </div>
                                </div>
                                <PoolInfo
                                    title={`Locked ${pool.title}`}
                                    value={pool.myLocked}
                                />
                                <PoolInfo
                                    title={`Claimed Rewards ${pool.title}`}
                                    value={pool.claimed}
                                />
                                <PoolInfo
                                    title="Start Time"
                                    value={pool.starttime}
                                />
                                <PoolInfo
                                    title="Participants"
                                    value={pool.participants}
                                />
                                <PoolInfo
                                    title="End Time"
                                    value={pool.endtime}
                                />
                            </div>
                            {/* Close Button */}
                            <button
                                className="mt-4 bg-[#f7f7f7] dark:bg-[#17181e] text-black dark:text-white/60 font-semibold px-4 py-2 rounded-md w-full"
                                onClick={() => setIsOpen(false)}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

const coinData = {
    buycex: {
        id: "buycex",
        name: "BUYCEX",
        status: "Coming Soon",
        image: "https://global.buycex.com/Upload/coin/677da3cd24a43.png",
        description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing.",
        totalPoolAmount: "12,345,678 BCX",
        eventPeriod: "15 day(s)",
        endTime: "03/05 16:00 (UTC)",
    },
};

const pools = [
    {
        title: "BUYCEX Pool",
        stand: "BCX",
        img: "https://global.buycex.com/Upload/coin/677da3cd24a43.png",
        subtitle: "Lock BCX for BCX",
        participants: 500,
        rewards: "3,000,000",
        starttime: "02/21 13:00:00 (UTC)",
        endtime: "02/27 13:00:00 (UTC)",
        participants: "43",
        locked: "2,100,000",
        myLocked: "500",
        claimed: "250",
    },
    {
        title: "USDT Pool",
        stand: "USDT",
        img: "https://global.buycex.com/Upload/coin/6780483188651.png",
        subtitle: "Stake BCX for Rewards",
        participants: 650,
        rewards: "1,500,000",
        starttime: "02/21 13:00:00 (UTC)",
        endtime: "02/27 13:00:00 (UTC)",
        participants: "43",
        locked: "1,200,000",
        myLocked: "300",
        claimed: "100",
    },
    {
        title: "BUYCEX Pool C",
        stand: "BCX",
        img: "https://global.buycex.com/Upload/coin/6780483188651.png",
        subtitle: "Earn BCX with Staking",
        participants: 800,
        rewards: "2,800,000",
        starttime: "02/21 13:00:00 (UTC)",
        endtime: "02/27 13:00:00 (UTC)",
        participants: "43",
        locked: "1,500,000",
        myLocked: "700",
        claimed: "400",
    },
];

function LaunchPoolComing() {
    const poolRefs = useRef([]);
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(0);
    const [details, setDetails] = useState("");
    const user = useSelector((state) => state.authUser.user);
    const descriptionRef = useRef(null);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const pathParts = window.location.pathname.split("/");
    const ids = pathParts[pathParts.length - 1];
    console.log(ids);

    useEffect(() => {
        dispatch(getProjectDetails(userID, userToken, ids))
            .then((response) => {
                setDetails(response?.data?.data);
                console.log(response.data.data);
            })
            .catch((error) => console.error("Error:", error));
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveIndex(poolRefs.current.indexOf(entry.target));
                    }
                });
            },
            { threshold: 0.6 }
        );

        poolRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => {
            poolRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    const scrollToPool = (index) => {
        poolRefs.current[index]?.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
        setActiveIndex(index);
    };

    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const dropdownRefs = useRef([]);

    const toggleDropdown = (index) => {
        setOpenDropdownIndex((prevIndex) =>
            prevIndex === index ? null : index
        );
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                openDropdownIndex !== null &&
                dropdownRefs.current[openDropdownIndex] &&
                !dropdownRefs.current[openDropdownIndex].contains(event.target)
            ) {
                setOpenDropdownIndex(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [openDropdownIndex]);

    const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);

    const handleButtonClick = () => {
        setIsTransferModalVisible(true);
    };

    const [isLockModalVisible, setIsLockModalVisible] = useState(false);
    const [selectedCoin, setSelectedCoin] = useState({ name: "", image: "" });

    const selectcoin = {
        name: "BUYCEX",
        image: "https://global.buycex.com/Upload/coin/677da3cd24a43.png",
    };
    const [selectedPool, setSelectedPool] = useState(null);

    const showLockModal = (pool) => {
        console.log("Opening modal for pool:", pool.pool_name);
        setSelectedPool(pool); // Set the clicked pool
        setIsLockModalVisible(true);
    };

    const handleCancel = () => {
        setIsLockModalVisible(false);
    };

    const { id } = useParams();
    const [newEvent, setNewEvent] = useState(null);
    const coin = coinData["buycex"];
    console.log(details?.project);

    //    useEffect(() => {
    //         const fetchData = async () => {
    //             try {
    //                 const response = await dispatch(NewEvent(id));
    //                 console.log("API Response:", response.data);
    //                 setNewEvent(response?.data?.data);
    //             } catch (error) {
    //                 console.error("Error fetching LaunchPool:", error);
    //             }
    //         };

    //         fetchData();
    //     }, [id]);

    //     if (!newEvent) {
    //         return <p>Loading...</p>;
    //     }

    const handleScrollToDescription = () => {
        if (descriptionRef.current) {
            const yOffset = -140;
            const y =
                descriptionRef.current.getBoundingClientRect().top +
                window.scrollY +
                yOffset;

            window.scrollTo({ top: y, behavior: "smooth" });
        }
    };

    const claimrewardAction = () => {
        dispatch(claimreward(userID, userToken, ids))
            .then((response) => {
                toast.error(response.data.data);
            })
            .catch((error) => console.error("Error:", error));
    };

    return (
        <div className="bg-white dark:bg-black">
            {/* {newEvent.running && newEvent.running.length > 0 ? (
                newEvent.running.map((completedEvent, eventIndex) =>
                    completedEvent?.project ? (
                        <div
                            key={eventIndex}
                            className="bg-white dark:bg-black"
                        >
                            <h3 className="font-bold text-xl">
                                {completedEvent.project.name}
                            </h3>
                        </div>
                    ) : (
                        <p key={eventIndex}>Invalid Event Data</p>
                    )
                )
            ) : (
                <p>No events found.</p>
            )} */}
            <div className="mb-0 bg-[#f7f7f7] dark:bg-[#090a0c] lg:mb-10">
                <div className="bg-[#f7f7f7] dark:bg-[#090a0c] py-3 lg:py-6">
                    <div className="container">
                        <div className="flex gap-2 items-center text-sm">
                            <Link to="/launchpool" className="opacity-60">
                                <h3>Launchpool</h3>
                            </Link>
                            /
                            <Link to="#">
                                <h3>{details?.project?.[0]?.project?.name}</h3>
                            </Link>
                        </div>
                        <div className="flex flex-col lg:flex-row justify-between items-center">
                            <div className="flex items-center gap-2 lg:gap-4 py-4 lg:py-8 w-full lg:w-2/3">
                                <div className="w-auto lg:w-1/5 dark:border-[#454545]/60 border-0 lg:border rounded-md flex items-center justify-center px-0 lg:px-4 py-0 lg:py-8">
                                    <img
                                        src={`https://global.buycex.com/Upload/issue/${details?.project?.[0]?.project?.image}`}
                                        className="w-7 lg:w-14 h-7 lg:h-14"
                                        alt={coin.name}
                                    />
                                </div>
                                <div className="w-full">
                                    <div className="flex items-center justify-between lg:justify-normal gap-2">
                                        <h3 className="font-bold text-lg lg:text-xl">
                                            {
                                                details?.project?.[0]?.project
                                                    ?.name
                                            }
                                            <span
                                                className="flex-inline ml-2 relative -top-0.5 lg:hidden font-normal border dark:border-[#454545]/60 rounded-full px-2"
                                                onClick={
                                                    handleScrollToDescription
                                                }
                                            >
                                                Details
                                            </span>
                                        </h3>
                                        <span className="px-2 text-[#EFB81c] font-semibold rounded-md bg-opacity-10 bg-[#EFB81c]">
                                            {(() => {
                                                const stakingPeriodEnd =
                                                    details?.project?.[0]
                                                        ?.project
                                                        ?.staking_period_end;
                                                if (!stakingPeriodEnd)
                                                    return (
                                                        <span>Loading...</span>
                                                    );

                                                const targetDate = new Date(
                                                    stakingPeriodEnd
                                                ).getTime();
                                                const now =
                                                    new Date().getTime();
                                                const difference =
                                                    targetDate - now;

                                                if (difference <= 0) {
                                                    return (
                                                        <span className="text-red-500 bg-opacity-10 font-medium bg-red-500 px-2 mb-0 lg:mb-2 rounded-tr-md rounded-bl-md">
                                                            Completed
                                                        </span>
                                                    );
                                                } else if (
                                                    difference <
                                                    1000 * 60 * 60 * 24 * 3
                                                ) {
                                                    // Less than 3 days
                                                    return (
                                                        <span className="text-blue-500 bg-opacity-10 font-medium bg-blue-500 px-2 mb-0 lg:mb-2 rounded-tr-md rounded-bl-md">
                                                            In Review
                                                        </span>
                                                    );
                                                } else {
                                                    return (
                                                        <span className="text-[#EFB81c] bg-opacity-10 font-medium bg-[#EFB81c] px-2 mb-0 lg:mb-2 rounded-tr-md rounded-bl-md">
                                                            Coming Soon
                                                        </span>
                                                    );
                                                }
                                            })()}
                                        </span>
                                    </div>
                                    <div className="hidden lg:block">
                                        <Description
                                            description={
                                                details?.project?.[0]?.project
                                                    ?.description
                                            }
                                        />
                                        <div className="hidden lg:flex items-center gap-3">
                                            <p className="border dark:border-[#454545]/60 rounded-full p-1 text-xs opacity-55">
                                                Webiste
                                            </p>
                                            <p className="border dark:border-[#454545]/60 rounded-full p-1 text-xs opacity-55">
                                                X (Twitter)
                                            </p>
                                            <p className="border dark:border-[#454545]/60 rounded-full p-1 text-xs opacity-55">
                                                Whitepaper
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-full lg:w-[20%]">
                                <div className="border-0 dark:border-[#454545]/60 lg:border rounded-[0.375rem] flex flex-row lg:flex-col pb-2 lg:p-3 justify-between lg:justify-center items-center">
                                    <span className="text-[13px] lg:text-sm opacity-70">
                                        Ends in
                                    </span>
                                    <span className="text-sm lg:text-xl font-bold">
                                        {(() => {
                                            const stakingPeriodEnd =
                                                details?.project?.[0]?.project
                                                    ?.staking_period_end;
                                            if (!stakingPeriodEnd)
                                                return "Loading...";

                                            const targetDate = new Date(
                                                stakingPeriodEnd
                                            ).getTime();
                                            const now = new Date().getTime();
                                            const difference = targetDate - now;

                                            if (difference <= 0) return "Ended";

                                            const days = Math.floor(
                                                difference /
                                                    (1000 * 60 * 60 * 24)
                                            );
                                            const hours = String(
                                                Math.floor(
                                                    (difference %
                                                        (1000 * 60 * 60 * 24)) /
                                                        (1000 * 60 * 60)
                                                )
                                            ).padStart(2, "0");
                                            const minutes = String(
                                                Math.floor(
                                                    (difference %
                                                        (1000 * 60 * 60)) /
                                                        (1000 * 60)
                                                )
                                            ).padStart(2, "0");
                                            const seconds = String(
                                                Math.floor(
                                                    (difference % (1000 * 60)) /
                                                        1000
                                                )
                                            ).padStart(2, "0");

                                            return `${days}d ${hours}h ${minutes}m ${seconds}s`;
                                        })()}
                                    </span>
                                </div>

                                <div className="hidden lg:flex justify-between">
                                    <span className="text-sm block lg:hidden">
                                        Payout Time
                                    </span>
                                    <p className="text-center text-sm opacity-70">
                                        Rewards will be distributed daily at
                                        14:00:00 (UTC).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white dark:bg-black">
                <h3 className="font-bold text-lg container block lg:hidden pt-8">
                    Lock and Earn
                </h3>
            </div>
            {/* Navbar for pools */}
            <div className="bg-white dark:bg-black">
                <div className="sticky top-14 container bg-white dark:bg-black border-b dark:border-[#454545]/60 h-12 flex gap-2 sm:gap-4 z-10">
                    {details?.project?.[0]?.pools?.map((pool, index) => (
                        <button
                            key={index}
                            className={`text-sm sm:text-lg font-semibold h-12 mr-2 transition-colors duration-200 
                            ${
                                activeIndex === index
                                    ? "border-b-2 border-[#EFB81c] text-black dark:text-white"
                                    : "text-gray-400"
                            }`}
                            onClick={() => scrollToPool(index)}
                        >
                            {pool?.pool_name}
                        </button>
                    ))}
                </div>
            </div>
            <div className="bg-white dark:bg-black">
                <div className="container flex flex-col gap-3 w-full py-4 lg:py-8">
                    {details?.project?.[0]?.pools?.map((pool, index) => (
                        <div
                            key={index}
                            ref={(el) => (poolRefs.current[index] = el)}
                            className="flex-1 bg-white dark:bg-black dark:border-[#454545]/60 border hover:bg-[#f7f7f7] transition-all duration-300 ease-in-out cursor-pointer min-h-20 rounded-lg"
                        >
                            <div className="relative left-0 top-0 flex justify-end">
                                <span className="text-[#EFB81c] bg-opacity-10 font-medium bg-[#EFB81c] px-2 mb-0 lg:mb-2 rounded-tr-md rounded-bl-md">
                                    {(() => {
                                        const stakingPeriodEnd =
                                            details?.project?.[0]?.project
                                                ?.staking_period_end;
                                        if (!stakingPeriodEnd)
                                            return <span>Loading...</span>;

                                        const targetDate = new Date(
                                            stakingPeriodEnd
                                        ).getTime();
                                        const now = new Date().getTime();
                                        const difference = targetDate - now;

                                        if (difference <= 0) {
                                            return (
                                                <span className="text-red-500 bg-opacity-10 font-medium bg-red-500 px-2 mb-0 lg:mb-2 rounded-tr-md rounded-bl-md">
                                                    Completed
                                                </span>
                                            );
                                        } else if (
                                            difference <
                                            1000 * 60 * 60 * 24 * 3
                                        ) {
                                            // Less than 3 days
                                            return (
                                                <span className="text-blue-500 bg-opacity-10 font-medium bg-blue-500 px-2 mb-0 lg:mb-2 rounded-tr-md rounded-bl-md">
                                                    In Review
                                                </span>
                                            );
                                        } else {
                                            return (
                                                <span className="text-[#EFB81c] bg-opacity-10 font-medium bg-[#EFB81c] px-2 mb-0 lg:mb-2 rounded-tr-md rounded-bl-md">
                                                    Coming Soon
                                                </span>
                                            );
                                        }
                                    })()}
                                </span>
                            </div>
                            <div className="px-4 py-0 lg:py-4">
                                <div className="flex justify-between items-center w-full">
                                    <div className="flex flex-col">
                                        <div className="flex items-center gap-2 sm:gap-4">
                                            <img
                                                src={`https://global.buycex.com/Upload/issue/${pool?.pool_coin_image}`}
                                                className="w-6 h-6"
                                                alt="BUYCEX"
                                            />
                                            <h3 className="font-bold text-xl">
                                                {pool?.pool_name}
                                            </h3>
                                        </div>
                                        <p className="hidden md:block text-sm text-[#1D1D1D99] dark:text-white/60 font-medium py-1">
                                            Lock {pool?.pool_name} for{" "}
                                            {pool?.pool_name}
                                        </p>
                                        <div className="hidden md:flex items-center mt-4 gap-1">
                                            <p className="flex items-center">
                                                <MdOutlinePersonOutline className="text-[#1D1D1D99] dark:text-white/60" />{" "}
                                                <span className="text-[#1D1D1D99] dark:text-white/60 text-sm">
                                                    {pool?.total_tokens_staked}
                                                </span>
                                            </p>
                                            <span className="bg-[#EFB81c] bg-opacity-10 self-start flex justify-self-start font-medium text-[#EFB81c] px-2 rounded">
                                                {(() => {
                                                    const stakingPeriodEnd =
                                                        details?.project?.[0]
                                                            ?.project
                                                            ?.staking_period_end;
                                                    if (!stakingPeriodEnd)
                                                        return (
                                                            <span>
                                                                Loading...
                                                            </span>
                                                        );

                                                    const targetDate = new Date(
                                                        stakingPeriodEnd
                                                    ).getTime();
                                                    const now =
                                                        new Date().getTime();
                                                    const difference =
                                                        targetDate - now;

                                                    if (difference <= 0) {
                                                        return (
                                                            <span className="text-red-500 bg-opacity-10 font-medium bg-red-500 px-2 mb-0 lg:mb-2 rounded-tr-md rounded-bl-md">
                                                                Completed
                                                            </span>
                                                        );
                                                    } else if (
                                                        difference <
                                                        1000 * 60 * 60 * 24 * 3
                                                    ) {
                                                        // Less than 3 days
                                                        return (
                                                            <span className="text-blue-500 bg-opacity-10 font-medium bg-blue-500 px-2 mb-0 lg:mb-2 rounded-tr-md rounded-bl-md">
                                                                In Review
                                                            </span>
                                                        );
                                                    } else {
                                                        return (
                                                            <span className="text-[#EFB81c] bg-opacity-10 font-medium bg-[#EFB81c] px-2 mb-0 lg:mb-2 rounded-tr-md rounded-bl-md">
                                                                Coming Soon
                                                            </span>
                                                        );
                                                    }
                                                })()}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <PoolDetails details={details} pool={pool} />
                                <div className="flex flex-col lg:flex-row w-full justify-between">
                                    <div className="py-2 md:py-8 w-full lg:w-1/2">
                                        <div className="hidden md:flex gap-3 items-center">
                                            <img
                                                src={`https://global.buycex.com/Upload/issue/${pool?.pool_coin_image}`}
                                                className="w-6 h-6"
                                                alt="BUYCEX"
                                            />
                                            <h3 className="text-md font-bold">
                                                My Assets
                                            </h3>
                                        </div>
                                        <div className="py-2 md:py-6">
                                            <span className="text-[13px] md:text-sm opacity-60">
                                                My Locked ({pool?.pool_name})
                                            </span>
                                            <p className="text-[13px] md:text-sm font-bold opacity-60">
                                                {details?.user_stakes
                                                    ? details.user_stakes.reduce(
                                                          (total, stake) =>
                                                              total +
                                                              Number(
                                                                  stake.tokens_staked
                                                              ),
                                                          0
                                                      )
                                                    : 0}
                                            </p>
                                        </div>
                                        <div
                                            className="relative"
                                            ref={(el) =>
                                                (dropdownRefs.current[index] =
                                                    el)
                                            }
                                        >
                                            {user && (
                                                <div className="flex flex-col md:flex-row gap-2 justify-between w-full lg:w-1/2">
                                                    <p className="flex items-center text-sm gap-2">
                                                        <span className="text-sm opacity-60">
                                                            Available:{" "}
                                                        </span>
                                                        <span className="flex items-center gap-0.5 cursor-pointer">
                                                            <span className="text-sm font-medium">
                                                                {details?.user_stakes
                                                                    ? details.user_stakes.reduce(
                                                                          (
                                                                              total,
                                                                              stake
                                                                          ) =>
                                                                              total +
                                                                              Number(
                                                                                  stake.tokens_staked
                                                                              ),
                                                                          0
                                                                      )
                                                                    : 0}{" "}
                                                                {
                                                                    pool?.pool_name
                                                                }
                                                            </span>
                                                        </span>
                                                        <TransferModal
                                                            visible={
                                                                isTransferModalVisible
                                                            }
                                                            onCancel={() =>
                                                                setIsTransferModalVisible(
                                                                    false
                                                                )
                                                            }
                                                        />
                                                    </p>
                                                    <span className="text-[#EFb81c] font-medium">
                                                        <span
                                                            onClick={
                                                                handleButtonClick
                                                            }
                                                            className="border-r dark:border-[#454545]/60 pr-2 md:px-2 cursor-pointer !text-sm"
                                                        >
                                                            Transfer
                                                        </span>{" "}
                                                        <span className="pl-1 !text-sm">
                                                            Buy {pool.title}
                                                        </span>
                                                    </span>
                                                </div>
                                            )}

                                            {openDropdownIndex === index && (
                                                <div className="absolute left-0 mt-2 w-32 bg-white dark:border-[#454545]/60 border rounded-lg shadow-md">
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            handleButtonClick();
                                                        }}
                                                        className="w-full text-left px-4 py-2 hover:bg-gray-100"
                                                    >
                                                        Transfer
                                                    </button>
                                                    <Link to="/trade/spot">
                                                        <button className="w-full text-left px-4 py-2 hover:bg-gray-100">
                                                            Buy BCX
                                                        </button>
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                        {user ? (
                                            <>
                                                <div className="flex w-full md:w-2/3 gap-4 mt-4">
                                                    <button
                                                        onClick={() =>
                                                            showLockModal(pool)
                                                        }
                                                        className="rounded-md w-1/2 bg-[#EFB81c] font-semibold py-2"
                                                    >
                                                        Lock {pool?.pool_name}
                                                    </button>
                                                    <LockModal
                                                        isVisible={
                                                            isLockModalVisible
                                                        }
                                                        handleClose={
                                                            handleCancel
                                                        }
                                                        data={selectedPool}
                                                        coinImage={`https://global.buycex.com/Upload/issue/${pool?.pool_coin_image}`}
                                                        coinName={
                                                            pool?.pool_name
                                                        }
                                                        handleButtonClick={
                                                            handleButtonClick
                                                        }
                                                        id={pool?.pool_id}
                                                    />
                                                    <button className="rounded-md w-1/2 bg-gray-200 dark:bg-[#17181e] text-gray-400 font-semibold py-2">
                                                        Redeem
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <Link to="/login">
                                                <button className="rounded-md w-full lg:w-1/2 mt-8 bg-[#EFB81c] font-semibold py-2">
                                                    Login
                                                </button>
                                            </Link>
                                        )}
                                    </div>
                                    <div className="py-2 mt-3 md:mt-0 md:py-8 w-full lg:w-1/2 border-t-[1px] dark:border-[#454545]/60 md:border-t-0">
                                        <div className="hidden md:flex gap-3 items-center">
                                            <img
                                                src={`https://global.buycex.com/Upload/issue/${pool?.pool_coin_image}`}
                                                className="w-6 h-6"
                                                alt="BUYCEX"
                                            />
                                            <h3 className="text-md font-bold">
                                                My Rewards
                                            </h3>
                                        </div>
                                        <div className="flex flex-col md:flex-row gap-0 md:gap-8 w-full">
                                            <div className="pt-3 sm:py-6">
                                                <span className="text-[13px] md:text-sm opacity-60">
                                                    Unclaimed Rewards (
                                                    {pool?.pool_name})
                                                </span>
                                                <p className="text-[13px] md:text-sm font-bold opacity-60">
                                                    {details?.unclaimed_rewards
                                                        ? details.unclaimed_rewards
                                                              .filter(
                                                                  (reward) =>
                                                                      reward.launch_pool_id ===
                                                                      pool?.pool_id
                                                              ) // Filter rewards matching pool_id
                                                              .reduce(
                                                                  (
                                                                      total,
                                                                      reward
                                                                  ) =>
                                                                      total +
                                                                      Number(
                                                                          reward.total_prize ||
                                                                              0
                                                                      ),
                                                                  0
                                                              )
                                                        : 0}
                                                </p>
                                            </div>

                                            <div className="py-0 sm:py-6">
                                                <span className="text-[13px] md:text-sm opacity-60">
                                                    Claimed Rewards (
                                                    {pool?.pool_name})
                                                </span>
                                                <p className="text-[13px] md:text-sm font-bold opacity-60">
                                                    <p className="text-[13px] md:text-sm font-bold opacity-60">
                                                        {details?.claimed_rewards
                                                            ? details.claimed_rewards.reduce(
                                                                  (
                                                                      total,
                                                                      reward
                                                                  ) =>
                                                                      total +
                                                                      Number(
                                                                          reward.total_prize ||
                                                                              0
                                                                      ),
                                                                  0
                                                              )
                                                            : 0}
                                                    </p>
                                                </p>
                                            </div>
                                        </div>
                                        <p className="flex invisible items-center text-sm gap-2">
                                            <span className="text-sm opacity-60">
                                                Available:{" "}
                                            </span>
                                            <span className="flex items-center gap-0.5">
                                                <span className="text-sm font-medium">
                                                    0 BCX
                                                </span>
                                                <CiCirclePlus
                                                    size={18}
                                                    fill="#EFB81c"
                                                />
                                            </span>
                                        </p>
                                        {user ? (
                                            <>
                                                <div className="flex w-full md:w-2/3 gap-4 mt-0 md:mt-4">
                                                    <button
                                                        className={`rounded-md w-1/2 font-semibold py-2 ${
                                                            details?.unclaimed_rewards &&
                                                            details.unclaimed_rewards
                                                                .filter(
                                                                    (reward) =>
                                                                        reward.launch_pool_id ===
                                                                        pool?.pool_id
                                                                )
                                                                .reduce(
                                                                    (
                                                                        total,
                                                                        reward
                                                                    ) =>
                                                                        total +
                                                                        Number(
                                                                            reward.total_prize ||
                                                                                0
                                                                        ),
                                                                    0
                                                                ) > 0
                                                                ? "bg-[#EFB81c]"
                                                                : "bg-[#EFB81c] opacity-40 cursor-not-allowed"
                                                        }`}
                                                        disabled={
                                                            !details?.unclaimed_rewards ||
                                                            details.unclaimed_rewards
                                                                .filter(
                                                                    (reward) =>
                                                                        reward.launch_pool_id ===
                                                                        pool?.pool_id
                                                                )
                                                                .reduce(
                                                                    (
                                                                        total,
                                                                        reward
                                                                    ) =>
                                                                        total +
                                                                        Number(
                                                                            reward.total_prize ||
                                                                                0
                                                                        ),
                                                                    0
                                                                ) <= 0
                                                        }
                                                        onClick={() => {
                                                            const totalUnclaimed =
                                                                details?.unclaimed_rewards
                                                                    ?.filter(
                                                                        (
                                                                            reward
                                                                        ) =>
                                                                            reward.launch_pool_id ===
                                                                            pool?.pool_id
                                                                    )
                                                                    .reduce(
                                                                        (
                                                                            total,
                                                                            reward
                                                                        ) =>
                                                                            total +
                                                                            Number(
                                                                                reward.total_prize ||
                                                                                    0
                                                                            ),
                                                                        0
                                                                    );

                                                            if (
                                                                totalUnclaimed >
                                                                0
                                                            ) {
                                                                claimrewardAction();
                                                            }
                                                        }}
                                                    >
                                                        Claim
                                                    </button>
                                                    <button className="rounded-md w-1/2 bg-gray-200 dark:bg-[#17181e] font-semibold py-2">
                                                        Trade
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <Link to="/login">
                                                <button className="rounded-md w-full lg:w-1/2 mt-4 bg-[#EFB81c] font-semibold py-2">
                                                    Login
                                                </button>
                                            </Link>
                                        )}
                                        <PoolDetailsPopup pool={pool} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* Description at the Bottom for Mobile */}
            <div className="bg-white dark:bg-black">
                <div className="lg:hidden container py-6 px-4">
                    <div className="flex items-center gap-2">
                        <img
                            src={`https://global.buycex.com/Upload/issue/${details?.project?.[0]?.project?.image}`}
                            className="w-7 lg:w-14 h-7 lg:h-14"
                            alt={details?.project?.[0]?.project?.name}
                        />
                        <h3 className="font-bold text-lg">
                            {" "}
                            {details?.project?.[0]?.project?.name}
                        </h3>
                    </div>
                    <div className="flex lg:hidden items-center gap-3 my-3">
                        <p className="border rounded-full p-1 text-xs opacity-55">
                            Webiste
                        </p>
                        <p className="border rounded-full p-1 text-xs opacity-55">
                            X (Twitter)
                        </p>
                        <p className="border rounded-full p-1 text-xs opacity-55">
                            Whitepaper
                        </p>
                    </div>
                    <Description
                        description={
                            details?.project?.[0]?.project?.description
                        }
                        descriptionRef={descriptionRef}
                    />
                </div>
            </div>
        </div>
    );
}

export default LaunchPoolComing;
