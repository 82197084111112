import React, { useState } from 'react'
import { DatePicker, Modal, Input } from 'antd';
import { createGlobalStyle, styled } from "styled-components";
import dayjs from 'dayjs';
import HistoryDropdown from 'views/profile/History/SpotHistory/DropDownUI';
import ClaimHistoryTable from './ClaimHistoryTable';


const { RangePicker } = DatePicker;

function ClaimHistory() {
    const option1 = ["Past 7 days", "Past 30 days", "Past 90 days", "Customized"];
    const option3 = [ "COSVM", "Dodge", "BuyCex"];

    const [selectedOption, setSelectedOption] = useState("Past 7 days");
    const [selectedDates, setSelectedDates] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleSelect1 = (selected) => {
        console.log("Dropdown 1 selected:", selected);
    };

    const handleSelect2 = (selected) => {
        if (selected === "Customized") {
            setIsModalVisible(true);
        } else {
            setSelectedOption(selected);
        }
    };

    const handleOk = () => {
        if (selectedDates && selectedDates.length === 2) {
            const formattedStartDate = dayjs(selectedDates[0]).format('YYYY-MM-DD');
            const formattedEndDate = dayjs(selectedDates[1]).format('YYYY-MM-DD');
            setSelectedOption(`${formattedStartDate} - ${formattedEndDate}`);
        }
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
       <div>
         <div className='flex gap-4 items-center'>
            <HistoryDropdown
                label="Time"
                options={option1}
                initialSelected={selectedOption}
                onSelect={handleSelect2}
                isSearchable={false}
                width="120px"
            />
            <Modal
                title="Select Date Range"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <div className="flex flex-col gap-4">
                    <p className='mt-10'>Select your time range within 12 months.</p>
                    <GlobalStyles />
                    <StyledRangePicker
                        onChange={(dates) => setSelectedDates(dates)}
                    />
                    <button key="ok" type="primary" onClick={handleOk} className='px-4 py-2 my-7 font-semibold bg-[#EFB81C] rounded-lg'>Continue</button>
                </div>
            </Modal>
            <HistoryDropdown
                label="Coin"
                options={option3}
                initialSelected={""}
                onSelect={handleSelect1}
                isSearchable={true}
                width="80px"
            />
            <button className='text-[#EFB81C] font-semibold ml-2'>Reset</button>
        </div>
        <ClaimHistoryTable/>
       </div>
    )
}

const GlobalStyles = createGlobalStyle`
  .ant-picker:hover {
    border-color: #EFB81C !important;
  }

  .ant-picker-focused {
    border-color: #EFB81C !important;
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important;
  }

  .ant-picker-active-bar {
    background: #EFB81C !important;
  }

  .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #EFB81C !important;
    color: white !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1;
    border: 1px solid #EFB81C !important;
    border-radius: 4px;
    content: "";
  }

  .ant-picker-dropdown .ant-picker-header-view > button:hover {
    color: #EFB81C !important;
  }
`;

const StyledRangePicker = styled(RangePicker)`
  .ant-picker-input > input:focus {
    box-shadow: none !important;
    border-color: #EFB81C !important;
  }
`;

export default ClaimHistory