import React, { useState, useCallback, useEffect } from "react";
import { IoSettings } from "react-icons/io5";
import { FaUserShield } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Modal, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FiatListRepository from "../../../repository/FiatListRepository";
import { fetchUserProfile } from "../../../store/actions/userProfileAction";
import useFiatDropdown from "../../../hooks/useFiatDropdown";
import LoginHistory from "components/user/account/overview/LoginHistory";
import {
    updateUsername,
    updateUserNickName,
    updaterCurrency,
    updaterLanguage,
} from "../../../store/actions/userUpdateAction";
import {
    BsPatchCheckFill,
    BsPatchExclamationFill,
    BsPatchQuestionFill,
} from "react-icons/bs";
import toast from "react-hot-toast";
import * as types from "../../../store/types";
import {
    fetchUserDashboard,
    fetchUserP2PName,
} from "store/actions/userProfileAction";

const settingsData = [
    {
        title: "Nick Name",
        description:
            "Set up an avatar and nickname, it is suggested not to use your real name or the name of your social account as a nickname.",
        label: "Crypto-Holder",
        icon: <FaUserShield size={20} fill="#EFB81C" />,
        button: "Edit",
        modalData: {
            content:
                "This is the modal for editing your Nick Name. Make sure your nickname is unique and does not disclose personal information.",
            footerText: "Please save your changes after editing.",
            inputFields: [
                {
                    placeholder: "Enter new nickname",
                    value: "",
                    label: "New Nickname",
                },
                {
                    placeholder: "Confirm new nickname",
                    value: "",
                    label: "Confirm Nickname",
                },
            ],
        },
    },
    {
        title: "P2P Nick Name",
        description:
            "Edit your C2C nickname, manage your payment methods and the list of users you follow.",
        label: "Dailypay",
        icon: <FaUserShield size={20} fill="#EFB81C" />,
        button: "Edit",
        modalData: {
            content:
                "This modal allows you to edit your P2P nickname. Ensure your nickname is recognizable and suitable for C2C interactions.",
            footerText:
                "This nickname will be shown to other users in C2C transactions.",
            inputFields: [
                {
                    placeholder: "Enter P2P nickname",
                    value: "",
                    label: "P2P Nickname",
                },
                {
                    placeholder: "Confirm P2P nickname",
                    value: "",
                    label: "Confirm P2P Nickname",
                },
            ],
        },
    },
    {
        title: "Update Documents",
        description:
            "Update Users Documents like National ID, Passport, Driving License, Proof of Address or any KYC Document.",
        label: <p className="text-[#008000]">Approved</p>,
        icon: <IoIosCheckmarkCircle size={20} fill="#008000" />,
        button: "Update",
    },
];

const otherSettingsData = [
    {
        title: "Language",
        description: "Change the default language for your account.",
        label: "English",
        button: "Edit",
    },
    {
        title: "Currency",
        description:
            "Select the currency you want to use as default currency in your account.",
        label: "",
        button: "Manage",
    },
    {
        title: "Vip Level",
        description:
            "Your VIP level is determined by the highest qualified level from all VIP programs.",
        label: <p className="text-[#008000]">Premium</p>,
        icon: <IoIosCheckmarkCircle size={20} fill="#008000" />,
        button: "Upgrade",
    },
    {
        title: "Email Subscription",
        description:
            "Enable to receive email notification in the following categories.",
        label: <p className="text-[#EFB81C]">Subscribed</p>,
        button: "Disable",
    },
];

const supportedLanguages = [
    { label: "English", value: "en" },
    { label: "Spanish", value: "es" },
    { label: "French", value: "fr" },
    { label: "German", value: "de" },
    { label: "Chinese", value: "zh" },
];

function Settings() {
    const { userDashboard } = useSelector((state) => state.userDashboard);
    const userNickName = useSelector((state) => state.userUpdate.username);

    const userP2pNickName = useSelector(
        (state) => state.userUpdate.p2pNickname
    );
    const selectedCurrency = useSelector(
        (state) => state.userUpdate.selectedCurrency
    );

    const selectedLanguage = useSelector(
        (state) => state.userUpdate.selectedLanguage
    );

    const vipLevel = userDashboard?.user?.vip_level || "Regular User";

    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [modalFooterText, setModalFooterText] = useState("");
    const [modalInputs, setModalInputs] = useState([]);
    const user = useSelector((state) => state.authUser?.user || {});
    const userDetails = useSelector(
        (state) => state.authCheck?.authStatus || {}
    );
    const userId = user?.ID;
    const token = user?.TOKEN;
    const history = useHistory();
    const dropdownOptionsValue = useFiatDropdown(userId, token, history);
    const verifyStatus = userDashboard?.user?.verify_status;
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [dropdownOptionsLanguage, setDropdownOptionsLanguage] = useState([]);
    // const [selectedLanguage, setSelectedLanguage] = useState("ENGLISH");

    useEffect(() => {
        if (
            userDetails &&
            Object.keys(userDetails).length > 0 &&
            userNickName === ""
        ) {
            dispatch({
                type: types.UPDATE_USERNAME_SUCCESS,
                payload: userDetails.username,
            });
        }
    }, [userDetails]);

    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);

    // Fetch user profile and dashboard on component mount
    useEffect(() => {
        dispatch(fetchUserProfile(userID, userToken));
        dispatch(fetchUserDashboard(userID, userToken));
        dispatch(fetchUserP2PName(userID, userToken));
    }, [dispatch, userID, userToken]);

    const statusMap = {
        "Not Submitted": {
            icon: <BsPatchExclamationFill size={10} fill="red" />,
            text: "Not Submitted",
            textColor: "text-[red]",
        },
        Approved: {
            icon: <BsPatchCheckFill size={10} fill="green" />,
            text: "Approved",
            textColor: "text-[green]",
        },
        "In Review": {
            icon: <BsPatchQuestionFill size={10} fill="orange" />,
            text: "In Review",
            textColor: "text-[orange]",
        },
        Rejected: {
            icon: <BsPatchExclamationFill size={10} fill="red" />,
            text: "Rejected",
            textColor: "text-[red]",
        },
    };

    const defaultStatus = statusMap[verifyStatus] || statusMap["In Review"];

    const showModal = (
        title,
        content,
        footerText,
        inputFields,
        dropdown,
        langOption
    ) => {
        setModalTitle(title);
        setModalContent(content);
        setModalFooterText(footerText);
        setModalInputs(inputFields);
        setIsModalVisible(true);
        setDropdownOptions(dropdown || []);
        setDropdownOptionsLanguage(langOption || []);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
    useEffect(() => {
        if (!userId || !token) {
            history.push("/login");
        } else {
            if (ID && TOKEN) {
                dispatch(fetchUserProfile(ID, TOKEN));
            }
        }
    }, [dispatch, ID, TOKEN]);

    const [newUsername, setNewUsername] = useState("");

    const [nickname, setNickName] = useState("");
    const [conNickname, setConNickname] = useState("");

    const [isNameModalVisible, setIsNameModalVisible] = useState(false);
    const [loadingname, setLoadingname] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const showNameModal = () => setIsNameModalVisible(true);
    const handleNameCancel = () => setIsNameModalVisible(false);
    const [localCurrency, setLocalCurrency] = useState(selectedCurrency);

    const loading = useSelector((state) => state.user?.loading);
    const error = useSelector((state) => state.user?.error);

    useEffect(() => {
        setLocalCurrency(selectedCurrency);
    }, [selectedCurrency]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newUsername.length < 5) {
            toast.error("Minimum 5 characters should be added!");
        } else {
            setLoadingname(true);
            // Add your submit logic here
            setTimeout(() => setLoadingname(false), 2000); // Simulate API call
        }
        const token = TOKEN;
        const id = ID;
        setIsModalVisible(false);
        // dispatch(updateUsername(nickname, token, id));
    };

    const handleChange = (e) => {
        const value = e.target.value;
        if (/^[A-Za-z]*$/.test(value) && value.length <= 10) {
            setNewUsername(value);
            setIsButtonDisabled(value.length < 5);
        }
    };

    const handleUpdateNicKName = () => {
        if (nickname !== conNickname) {
            toast.error("Nicknames do not match. Please try again!");
            setNickName("");
            setConNickname("");
            setIsModalVisible(true);
        } else {
            dispatch(updateUsername(nickname, token, ID));
            // dispatch(updateUserNickName(newUsername, token, ID));
            setNickName("");
            setConNickname("");
            toast.success("Nickname updated successfully!");
            setIsModalVisible(false);
        }
    };

    const handleUpdateP2PNickName = (item) => {
        if (nickname !== conNickname) {
            toast.error("Nicknames do not match. Please try again!");
            setNickName("");
            setConNickname("");
            setIsModalVisible(false);
        } else {
            item === "P2P Nick Name"
                ? dispatch(updateUserNickName(nickname, token, ID))
                : dispatch(updateUsername(nickname, token, ID));
            setNickName("");
            setConNickname("");
            toast.success("Nickname updated successfully!");
            setIsModalVisible(false);
        }
    };

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    const handleCurrencyChange = (value) => {
        // Call the API to update the selected currency
        FiatListRepository.setCurrencySelected(userId, token, value)
            .then((response) => {
                handleUpdateCurrency(value);
            })
            .catch((error) => {
                console.error("Error updating currency:", error);
            });
    };

    const handleManage = useCallback(
        (item) => {
            if (item === "Manage") {
                showModal(
                    "Manage Currency",
                    "Select your preferred currency for transactions.",
                    "Make sure to save changes after selecting a currency.",
                    [], // No input fields
                    dropdownOptionsValue.map((option) => ({
                        label: option.name, // The currency name
                        value: option.name, // The value to identify the option
                        icon: `https://api.buycex.com/Upload/coin/${option.img}`, // Full image URL
                    })),
                    []
                );
            } else if (item === "Edit") {
                showModal(
                    "Change Language",
                    "Select your preferred language for the application.",
                    "Ensure you save your changes after selecting a language.",
                    [], // No input fields
                    [],
                    supportedLanguages
                );
            }
        },
        [showModal, dropdownOptionsValue] // Dependencies
    );

    const handleLanguageChange = (value) => {
        dispatch(updaterLanguage(value));
        toast.success(`Language updated to ${value}`);
        setIsModalVisible(false);
    };

    const handleUpdateCurrency = (currency) => {
        // updaterCurrency
        dispatch(updaterCurrency(currency));
    };

    const getLanguageLabel = (value) => {
        const language = supportedLanguages.find(
            (lang) => lang.value === selectedLanguage
        );
        return language ? language.label : "";
    };

    return (
        <div className="mt-0 lg:mt-10 px-0 lg:px-3">
            <div className="flex items-center gap-2 mb-[35px]">
                <span className="text-[22px] font-semibold">Profile</span>
            </div>
            {settingsData.map((item, index) => (
                <div
                    key={index}
                    className={`flex flex-col mt-2 gap-5 p-0 sm:p-4 bg-white rounded-md border-0 sm:border border-[#F4F4F4]`}
                >
                    <div className="flex flex-col border-b sm:border-0 py-4 sm:py-0 sm:flex-row w-full sm:gap-0 gap-3 justify-between">
                        <div className="flex flex-col gap-1 w-full sm:w-1/2">
                            <p className="text-md font-semibold">
                                {item.title}
                            </p>
                            <p className="text-[12px] text-[#15141590] font-medium">
                                {item.description}
                            </p>
                        </div>
                        <div className="flex gap-6 sm:justify-normal justify-between items-center">
                            <div className="flex gap-1 items-center">
                                {item.title === "Update Documents" ? (
                                    <span>{defaultStatus.icon}</span>
                                ) : (
                                    <p>{item.icon}</p>
                                )}
                                <p className="font-normal text-sm">
                                    {item.title === "Nick Name"
                                        ? userNickName
                                        : item.title === "P2P Nick Name"
                                        ? userP2pNickName
                                        : item.title === "Update Documents"
                                        ? defaultStatus.text
                                        : item.label}
                                </p>
                            </div>
                            {item.title === "Nick Name" ||
                            item.title === "P2P Nick Name" ? (
                                <button
                                    onClick={() =>
                                        showModal(
                                            item.title,
                                            item.modalData.content,
                                            item.modalData.footerText,
                                            item.modalData.inputFields,
                                            [],
                                            []
                                        )
                                    }
                                    className="px-8 py-2 rounded-md font-semibold text-sm text-black bg-[#F5F5F5]"
                                >
                                    {item.button}
                                </button>
                            ) : (
                                item.button && (
                                    <button
                                        disabled={
                                            item.title === "Update Documents" &&
                                            defaultStatus.text === "In Review"
                                                ? true
                                                : false
                                        }
                                        onClick={() => {
                                            if (
                                                item.title ===
                                                "Update Documents"
                                            ) {
                                                history.push(
                                                    "/identity-verification"
                                                );
                                            } else {
                                                showModal(
                                                    item.title,
                                                    item.modalData?.content,
                                                    item.modalData?.footerText,
                                                    item.modalData?.inputFields,
                                                    [],
                                                    []
                                                );
                                            }
                                        }}
                                        className="px-8 py-2 rounded-md font-semibold text-sm text-black bg-[#F5F5F5]"
                                    >
                                        {defaultStatus.text === "Not Submitted"
                                            ? "Verify Now"
                                            : item.button}
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                    <Modal
                        title={modalTitle}
                        visible={isModalVisible}
                        onOk={() => {
                            modalTitle === "Nick Name"
                                ? handleUpdateNicKName(modalTitle)
                                : modalTitle === "P2P Nick Name"
                                // ? handleUpdateP2PNickName(modalTitle)
                                // : handleOk();
                        }}
                        onCancel={handleCancel}
                    >
                        <p>{modalContent}</p>
                        <div className="mt-4">
                            {modalInputs.map((input, index) => (
                                <div key={index} className="mb-3">
                                    <label className="block text-sm font-medium mb-1">
                                        {input.label}
                                    </label>

                                    <Input
                                        placeholder={input.placeholder}
                                        value={
                                            input.label ===
                                                "Confirm Nickname" ||
                                            input.label ===
                                                "Confirm P2P Nickname"
                                                ? conNickname
                                                : nickname
                                        }
                                        onChange={(e) =>
                                            input.label ===
                                                "Confirm Nickname" ||
                                            input.label ===
                                                "Confirm P2P Nickname"
                                                ? setConNickname(e.target.value)
                                                : setNickName(e.target.value)
                                        }
                                    />
                                </div>
                            ))}

                            {dropdownOptionsLanguage.length > 0 && (
                                <Select
                                    className="w-full"
                                    placeholder="Select a language"
                                    onChange={(value) => {
                                        handleLanguageChange(value);
                                    }}
                                >
                                    {dropdownOptionsLanguage.map(
                                        (option, index) => (
                                            <Option
                                                key={index}
                                                value={option.value}
                                            >
                                                <div className="flex items-center">
                                                    {/* You can include a language flag or icon if available, 
                        or simply display the language label */}
                                                    {option.label}
                                                </div>
                                            </Option>
                                        )
                                    )}
                                </Select>
                            )}

                            {dropdownOptions.length > 0 && (
                                <Select
                                    className="w-full"
                                    placeholder="Select a currency"
                                    onChange={(value) => {
                                        handleCurrencyChange(value);
                                    }}
                                >
                                    {dropdownOptions.map((option, index) => (
                                        <Option
                                            key={index}
                                            value={option.value}
                                        >
                                            <div className="flex items-center">
                                                <img
                                                    src={option.icon}
                                                    alt={option.label}
                                                    className="w-6 h-6 mr-2"
                                                />
                                                {option.label}{" "}
                                            </div>
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </div>
                        {modalFooterText && (
                            <p className="mt-4 text-sm text-gray-500">
                                {modalFooterText}
                            </p>
                        )}
                    </Modal>
                    <Modal
                        title="Update Username"
                        visible={isNameModalVisible}
                        onCancel={handleNameCancel}
                        footer={null}
                    >
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col">
                                <div className="mt-4">
                                    <p className="font-medium mb-1">Username</p>
                                    <input
                                        type="text"
                                        value={newUsername}
                                        className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2"
                                        onChange={handleChange}
                                        placeholder="Enter new username"
                                        required
                                    />
                                    <span className="text-red-500 ml-2">
                                        5-10 characters. Special characters not
                                        allowed.
                                    </span>
                                </div>
                                <div className="flex gap-5 mt-8">
                                    <button
                                        type="submit"
                                        className={`bg-[#EFB81C] mt-5 py-2 text-center rounded-md w-1/2 font-semibold ${
                                            isButtonDisabled
                                                ? "opacity-50 cursor-not-allowed"
                                                : ""
                                        }`}
                                        disabled={isButtonDisabled}
                                    >
                                        {loadingname
                                            ? "Updating..."
                                            : "Update Username"}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleNameCancel}
                                        className="border-2 border-[#81858c]  mt-5 py-2 text-center rounded-md w-1/2 font-semibold"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                        {error && (
                            <p style={{ color: "red", marginTop: "10px" }}>
                                {error}
                            </p>
                        )}
                    </Modal>
                </div>
            ))}

            <div className="flex items-center gap-2">
                <span className="text-[22px] my-3 font-semibold">
                    Other Settings
                </span>
            </div>
            <div className="flex flex-col px-0 sm:px-4 bg-white rounded-md border-0 sm:border border-[#F4F4F4]">
                {otherSettingsData.map((item, index) => (
                    <div
                        key={index}
                        className={`flex flex-col sm:flex-row w-full py-4 sm:gap-0 gap-3 justify-between ${
                            index < otherSettingsData.length - 1
                                ? "border-b"
                                : ""
                        }`}
                    >
                        <div className="flex flex-col gap-1 w-full sm:w-1/2">
                            <p className="text-md font-semibold">
                                {item.title}
                            </p>
                            <p className="text-[12px] text-[#15141590] font-medium">
                                {item.description}
                            </p>
                        </div>
                        <div className="flex gap-6 sm:justify-normal justify-between items-center">
                            <div className="flex gap-1 items-center">
                                {item.icon}
                                <p className="font-normal text-sm">
                                    {item.button === "Manage"
                                        ? localCurrency
                                        : item.title === "Vip Level"
                                        ? vipLevel
                                        : item.title === "Language"
                                        ? getLanguageLabel()
                                        : item.label}
                                </p>
                            </div>
                            <button
                                onClick={() => handleManage(item.button)}
                                className="px-8 py-2 rounded-md font-semibold text-sm text-black bg-[#F5F5F5]"
                            >
                                {item.button}
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mt-6">
                <LoginHistory />
            </div>
        </div>
    );
}

export default Settings;
