import React from 'react'
import journeyLineBottom from 'assets/img/bg-lines-journey.png'
import journeyLineTop from 'assets/img/bg-lines-journey-top.png'
import journeyLineMobile from 'assets/img/journeyLineMobile.png'

function JoinNow() {
  return (
    <div className='bg-[#191A20] h-[211px] sm:h-80 flex justify-center sm:justify-between relative'>
      {/* Large screen images */}
      <img src={journeyLineBottom} className='hidden sm:block w-1/4' alt='journeyLine' />
      <div className='flex flex-col gap-8 items-center justify-center text-center'>
        <h4 className='text-white text-[24px] flex justify-self-center sm:text-4xl font-extrabold'>Become part of Buycex’s future — Invest in Infinity Bonds today.</h4>
          <button className="rounded-[0.375rem] bg-[#EFB81C] font-extrabold text-black px-8 py-2">
            Join Now
          </button>
      </div>
      <img src={journeyLineTop} className='hidden sm:block w-1/4' alt='journeyLine' />

      {/* Mobile image */}
      <img src={journeyLineMobile} className='sm:hidden absolute top-0 left-1/2 transform -translate-x-1/2 w-full h-[211px]' alt='journeyLineMobile' />
    </div>
  )
}

export default JoinNow
