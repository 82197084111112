import React from 'react'
import CreateAccount from 'assets/img/create-account.png'
import fundAccount from 'assets/img/fundAccount.png'
import candleStick from 'assets/img/candleStick.png'
import { GoArrowRight } from 'react-icons/go'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

function GetStarted() {
    return (
        <div className='py-0 md:py-32 dark:bg-black mx-auto'>
            <div className='bg-gradient-to-t dark:from-[#826000] from-[#fff8e3] dark:to-[#000000] to-[#FFFFFF]'>
                <div className='py-[50px]'>
                    <h3 className='text-3xl font-extrabold text-center mb-[30px]'>How To Get Started</h3>
                    <div className='container mx-auto w-full flex flex-col lg:flex-row gap-4'>
                        <div className='border dark:border-[#454545] shadow-sm bg-white dark:bg-black rounded-3xl flex-1 py-8 px-6'>
                            <div className='flex items-center justify-between mb-12'>
                                <span className="text-7xl font-extrabold bg-gradient-to-t from-white dark:from-black to-black dark:to-white bg-clip-text text-transparent">
                                    1
                                </span>
                                <img src={CreateAccount} alt='CreateAccount' className='w-16 h-16 sm:h-24 sm:w-24'/>
                            </div>
                            <p className='font-bold text-xl'>Create Account</p>
                            <span className='text-[#737373] capitalize text-md'>Sign up and complete beginner tasks, win-up to $6666.</span>
                            <Link to='/register'>
                            <div className="flex gap-1 items-center mt-2">
                                <button
                                    className="text-[14px] text-[#EFB81C] font-bold mb-1"
                                >
                                    Sign up now
                                </button>
                                <GoArrowRight className="mb-0.5" fill="#EFB81C" />
                            </div>
                            </Link>
                        </div>
                        <div className='border dark:border-[#454545] shadow-sm bg-white dark:bg-black rounded-3xl flex-1 py-8 px-6'>
                            <div className='flex items-center justify-between mb-12'>
                                <span className="text-7xl font-extrabold bg-gradient-to-t from-white dark:from-black to-black dark:to-white bg-clip-text text-transparent">
                                    2
                                </span>
                                <img src={fundAccount} alt='CreateAccount' className='w-16 h-16 sm:h-24 sm:w-24'/>
                            </div>
                            <p className='font-bold text-xl'>Fund Your Account</p>
                            <span className='text-[#737373] capitalize text-md'>Add funds to your account to start your crypto investment journey.</span>
                            <Link to='/crypto/deposit'>
                            <div className="flex gap-1 items-center mt-2">
                                <button
                                    className="text-[14px] text-[#EFB81C] font-bold mb-1"
                                >
                                    Deposit/Buy up now
                                </button>
                                <GoArrowRight className="mb-0.5" fill="#EFB81C" />
                            </div>
                            </Link>
                        </div>
                        <div className='border dark:border-[#454545] shadow-sm bg-white dark:bg-black rounded-3xl flex-1 py-8 px-6'>
                            <div className='flex items-center justify-between mb-12'>
                                <span className="text-7xl font-extrabold bg-gradient-to-t from-white dark:from-black to-black dark:to-white bg-clip-text text-transparent">
                                    3
                                </span>
                                <img src={candleStick} alt='CreateAccount' className='sm:w-[101px] sm:h-[101px] w-16 h-16'/>
                            </div>
                            <p className='font-bold text-xl'>Start Trading</p>
                            <span className='text-[#737373] capitalize text-md'>Sell, buy or copy trade crypto and explore more.</span>
                            <Link to="/trade/spot">
                            <div className="flex gap-1 items-center mt-2">
                                <button
                                    className="text-[14px] text-[#EFB81C] font-bold mb-1"
                                >
                                    Trade now
                                </button>
                                <GoArrowRight className="mb-0.5" fill="#EFB81C" />
                            </div>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default GetStarted