import React, { useState, useRef, useEffect } from "react";
import ThirdPartyNav from '../../components/thirdPartyComponents/thirdPartyNav';
import { FaBitcoin, FaEthereum, FaDollarSign } from 'react-icons/fa';
import { SiLitecoin, SiRipple, SiCardano, SiDogecoin } from 'react-icons/si';
import { RiArrowDropDownLine, RiSearchLine } from "react-icons/ri";
import { FaArrowRightLong } from "react-icons/fa6";
import { CiFilter } from "react-icons/ci";
import { Radio } from "antd";
import mastercard from 'assets/img/mastercard.png'
import moonpay from 'assets/img/moonpay.png'
import banxa from 'assets/img/banxa.png'
import visa from 'assets/img/visa.png'
import styled from "styled-components";
import Faq from "../../components/thirdPartyComponents/faq";
import PaymentDetails from "../../components/thirdPartyComponents/paymentDetails";
import { FaRupeeSign } from 'react-icons/fa'; // Rupee sign icon
import { FaPoundSign } from 'react-icons/fa'; // Pound sign icon
import { minWidth } from "@mui/system";
import { fetchWalletData } from "store/actions/walletActions";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Faqs from "components/FAQs";

// Coin options including icons
const coins = [
  { name: 'BTC', icon: <FaBitcoin />, color: '#F7931A' },
  { name: 'ETH', icon: <FaEthereum />, color: '#3C3C3D' },
  { name: 'LTC', icon: <SiLitecoin />, color: '#A6A9AA' },
  { name: 'XRP', icon: <SiRipple />, color: '#006097' },
  { name: 'ADA', icon: <SiCardano />, color: '#0033AD' },
];

const fiatCoins = [
  { name: 'USD', icon: <FaDollarSign />, color: 'red', currency: '$' }, // USD with red background
  { name: 'GBP ', icon: <FaPoundSign />, color: 'green', currency: '₹' }, // INR with green background
  { name: 'INR', icon: <FaRupeeSign />, color: 'green', currency: 'INR' }, // PKR with green background
];

function ThirdPartyPayments() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalFor, setModalFor] = useState(null);
  const [selectedCurrency2, setSelectedCurrency2] = useState(coins[0]);
  const [isAmountDropdownOpen, setIsAmountDropdownOpen] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(fiatCoins[0]); // Default to BTC
  const dropdownRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isPaymentMethodOpen, setIsPaymentMethodOpen] = useState(false);
  const [selectedMethods, setSelectedMethods] = useState([]); // State for selected payment methods
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('MoonPay');
  const [inputValue, setInputValue] = useState(null);
//   const history = useHistory();
//   const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};

//   useEffect(() => {
//     if (!TOKEN || !ID) {
//         history.push("/login");
//     }
// }, [TOKEN, ID, history]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handle the selection of MoonPay or Banxa
  const handlePaymentMethodSelect = (method) => {
    setSelectedPaymentMethod(method);  // Update state with selected payment method
  };


  const paymentMethods = ["Credit Card", "PayPal", "Bank Transfer", "Crypto", "Mobile Payment"];

  // Toggle payment method dropdown
  const togglePaymentMethod = () => {
    setIsPaymentMethodOpen(!isPaymentMethodOpen);
  };

  // Function to handle method selection
  const handleMethodSelect = (method) => {
    // If the method is already selected, remove it; otherwise, add it
    setSelectedMethods((prevSelected) =>
      prevSelected.includes(method)
        ? prevSelected.filter((item) => item !== method)
        : [...prevSelected, method]
    );
  };

  const filteredCoins = fiatCoins.filter((coin) =>
    coin.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle coin selection
  const handleCoinSelect = (coin) => {
    setSelectedCoin(coin); // Set selected coin (with icon and name)
    setIsAmountDropdownOpen(false); // Close the dropdown after selecting
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsAmountDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to show the modal for the correct currency select
  const showModal = (currencySelect) => {
    setModalFor(currencySelect);
    setIsModalVisible(true);
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  // Handle currency selection
  const handleSelectCurrency = (currency) => {
    setSelectedCurrency2(currency);
    setIsDropdownVisible(false); // Close dropdown on selection
  };

  const faqsData = [
    {
      question: "Will there be any transaction fees if I purchase crypto via Buycex's fiat service providers?",
      answer: "Most service providers charge transaction fees for crypto purchases. Please check the official website of the respective service provider for the actual fee.",
    },
    {
      question: "Will Buycex charge any transaction fee?",
      answer: "No, Buycex will not charge users any transaction fee.",
    },
    {
      question: "Why is the final price quote from the service provider different from the quote I saw on Buycex?",
      answer: "The prices quoted on Buycex are derived from prices provided by third-party service providers, and are for references only. It may differ from the final quote due to market movement or rounding error. Please refer to the respective service providers' official website for accurate quotes.",
    },
    {
      question: "Why is my final exchange rate different from the one I saw on the Buycex platform?",
      answer: "The figures stated on Buycex only serves to be indicative and are quoted based on the trader's last inquiry. It does not dynamically change based on the price movement of the cryptocurrency. For the final exchange rates and figures, please refer to our third party providers’ website.",
    },
    {
      question: "When will I receive the cryptocurrency I purchased?",
      answer: "The cryptocurrency is usually deposited into your Buycex account in 2 to 30 minutes after purchase. It may take longer, however, depending on blockchain network condition and service level of the respective service provider. For new users, it may take up to one day.",
    },
  ];

  return (
    <div>
      <div className='container'>
        {/* <ThirdPartyNav /> */}
        <div className='flex w-full justify-between items-center'>
          <p className="text-[24px] font-bold py-6">Third Party</p>
          <button className='bg-[#EAECEF] dark:bg-[#090a0c] flex gap-2 text-sm items-center px-2 font-medium h-7 py-1.5 rounded-[4px]'>My Plans<FaArrowRightLong /></button>
        </div>
        <div className='flex gap-32 justify-center w-full mt-5'>
          {/* Create Recurring Plan */}
          <div className='md:w-[486px] lg:w-[50%] mb-12'>
            <div className="flex gap-2 items-center"><span className="bg-[#EAECEF] dark:bg-[#EFB81c] flex justify-center items-center font-semibold p-2 h-7 w-7 rounded-full">1</span><p className='text-[22px] font-medium'>Choose purchase amount</p></div>

            <div className='flex flex-col sm:flex-row md:flex-row lg:flex-row justify-between items-center'>
              <div className="flex flex-col w-full">
                <p className='text-md mt-14 pb-2'>I want to spend</p>
                {/* First Currency Select */}
                <div
                  tabIndex="0"
                  ref={dropdownRef}
                  className="relative flex items-center border dark:border-[#454545] hover:border-[#EFB81C] rounded-[4px] font-normal text-[12px] focus:ring-[1px] focus:ring-[#EFB81C]"
                  onFocus={(e) =>
                    (e.currentTarget.style.boxShadow = "0 0 0 1px rgba(239, 184, 28, 1)")
                  }
                  onBlur={(e) => (e.currentTarget.style.boxShadow = "none")}
                >
                  {/* Input Field for Amount */}
                  <input
                    type="text"
                    placeholder="70 - 11,00"
                    value={inputValue}
                    onChange={handleInputChange}
                    className="flex-grow px-3 py-[8px] w-full bg-transparent border-r border-transparent dark:border-[#454545] rounded-l-[4px] focus:outline-none"
                  />

                  {/* Dropdown Button for Coin Selection */}
                  <button
                    type="button"
                    className="relative flex items-center justify-center w-1/3 px-4 py-3 text-sm font-semibold text-gray-900 border-none rounded-[4px] hover:bg-gray-100 dark:hover:bg-[#090a0c]"
                    id="filter-button"
                    aria-haspopup="true"
                    onClick={() => setIsAmountDropdownOpen(!isAmountDropdownOpen)}
                  >
                    <span className="absolute left-0 top-1/2 transform -translate-y-1/2 h-[20px] w-0 border-l border-slate-300"></span>

                    <div className="flex items-center gap-2">
                      {/* Show selected coin's icon and name on the button */}
                      <span style={{ color: selectedCoin.color }}>{selectedCoin.icon}</span>
                      <span className="text-md text-black dark:text-white font-medium">{selectedCoin.name}</span>
                      <RiArrowDropDownLine className="text-black dark:text-white"/>
                    </div>
                  </button>

                  {/* Dropdown for Coins */}
                  {isAmountDropdownOpen && (
                    <div className="absolute top-full left-0 w-full bg-white dark:bg-[#090a0c] shadow-lg rounded-md z-10 mt-1">
                      {/* Search input inside dropdown */}
                      <div className="relative p-2">
                        {/* Search icon */}
                        <div className="absolute inset-y-0 left-0 flex items-center pl-[1.5rem]">
                          <RiSearchLine className="text-gray-400" />
                        </div>
                        <input
                          type="text"
                          placeholder="Search coins..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          className="w-full p-2 pl-10 border border-gray-300 dark:bg-black dark:border-[#454545] rounded"
                        />
                      </div>

                      {/* Filtered list of coins based on search query */}
                      <ul className="max-h-48 overflow-y-auto">
                        {filteredCoins.length > 0 ? (
                          filteredCoins.map((coin) => (
                            <li
                              key={coin.name}
                              className="flex items-center gap-2 px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleCoinSelect(coin)}
                            >
                              {/* Show coin's icon and name in dropdown */}
                              <span style={{ color: coin.color }}>{coin.icon}</span>
                              <span>{coin.name}</span>
                            </li>
                          ))
                        ) : (
                          <li className="px-4 py-2 text-gray-500 text-center">No coins found</li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              {/* "To" Text */}
              <p className='w-[10%] text-center pt-5 sm:pt-[85px] lg:pt-[85px] md:pt-[85px]'>To</p>

              {/* Second Currency Select */}
              <div className='w-full sm:w-[20%] md:w-[20%] lg:w-[20%]'>
                <p className='text-md mt-5 sm:mt-14 md:mt-14 lg:mt-14 pb-2 w-max'>I will receive</p>
                <div
                  className="flex items-center justify-between border dark:border-[#454545] hover:border-[#EFB81C] cursor-pointer h-[45px] rounded-[4px] py-3 px-3"
                  onClick={toggleDropdown} // Toggle dropdown on click
                >
                  <div className="flex items-center">
                    <span style={{ color: selectedCurrency2.color }}>{selectedCurrency2.icon}</span>
                    <p className="text-md font-medium ml-2">{selectedCurrency2.name}</p>
                  </div>
                  <div>
                    <RiArrowDropDownLine />
                  </div>
                </div>

                {/* Dropdown List */}
                {isDropdownVisible && (
                  <div className="absolute bg-white dark:bg-[#090a0c] border dark:border-[#454545] border-gray-200 rounded-md mt-2 w-full md:w-32 lg:w-32 z-10 shadow-lg">
                    {coins.map((currency) => (
                      <div
                        key={currency.name}
                        className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 p-2 rounded-md"
                        onClick={() => handleSelectCurrency(currency)}
                      >
                        <span style={{ color: currency.color }}>{currency.icon}</span>
                        <div>
                          <p className="font-medium">{currency.name}</p>
                          <p className="text-gray-500">{currency.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {/* Dropdown for Coins */}
                {isAmountDropdownOpen && (
                  <div className="absolute top-full left-0 w-full bg-white shadow-lg rounded-md z-10 mt-1">
                    {/* Search input inside dropdown */}
                    <div className="relative p-2">
                      {/* Search icon */}
                      <div className="absolute inset-y-0 left-0 flex items-center pl-[1.5rem]">
                        <RiSearchLine className="text-gray-400" />
                      </div>
                      <input
                        type="text"
                        placeholder="Search coins..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-full p-2 pl-10 border border-gray-300 rounded"
                      />
                    </div>

                    {/* Filtered list of coins based on search query */}
                    <ul className="max-h-48 overflow-y-auto">
                      {filteredCoins.length > 0 ? (
                        filteredCoins.map((coin) => (
                          <li
                            key={coin.name}
                            className="flex items-center gap-2 px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleCoinSelect(coin)}
                          >
                            {/* Show coin's icon and name in dropdown */}
                            <span style={{ color: coin.color }}>{coin.icon}</span>
                            <span>{coin.name}</span>
                          </li>
                        ))
                      ) : (
                        <li className="px-4 py-2 text-gray-500 text-center">No coins found</li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-16 w-full">
              <div className='flex flex-col'>
                <div className="flex items-center justify-between mb-7 w-full">
                  <div className="flex gap-2 items-center"><span className="bg-[#EAECEF] dark:bg-[#EFB81c] justify-center flex items-center font-semibold p-2 h-7 w-7 rounded-full">2</span><p className='text-[22px] font-medium'>Choose payment channel</p></div>
                  <button className="flex items-center gap-1 bg-[#EAECEF] dark:bg-[#EFB81c] text-sm px-2 font-medium h-7 py-1.5 rounded-[4px]" onClick={togglePaymentMethod}><span className="hidden sm:block md:block lg:block">Payment method</span> {selectedMethods.length > 0 ? selectedMethods.length : ''}<CiFilter /></button>
                </div>
                {/* Payment Method Dropdown */}
                {isPaymentMethodOpen && (
                  <div className="mt-2 bg-white shadow-md p-4 rounded-md">
                    <p className="mb-2">Select a payment method:</p>
                    <ul className="list-none flex flex-wrap gap-2">
                      {paymentMethods.map((method, index) => (
                        <li
                          key={index}
                          onClick={() => handleMethodSelect(method)}
                          className={`cursor-pointer text-sm p-2 rounded-md ${selectedMethods.includes(method) ? 'border text-[#EFB81C] border-[#EFB81C]' : 'text-black border rounded-md'
                            }`}
                        >
                          {method}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {/* Payment Method Buttons */}
                <button
                  value="MoonPay"
                  onClick={() => handlePaymentMethodSelect('MoonPay')}
                  className={`mt-2 bg-white dark:bg-[#090a0c] shadow-md p-4 rounded-md ${selectedPaymentMethod === 'MoonPay' ? 'border border-[#EFB81C]' : ''}`}
                >
                  <div className={`flex justify-between items-center w-full cursor-pointer`}>
                    <div className="flex items-center gap-4">
                      <SelectRadio checked={selectedPaymentMethod === 'MoonPay'} />
                      <img src={moonpay} alt="moonpay" className="w-[22px] h-[22px]" />
                      <p className="text-[20px] font-semibold">MoonPay</p>
                    </div>
                    <div className="flex gap-1 items-center">
                      <img src={mastercard} alt="mastercard" className="w-[38.17px] h-[22px]" />
                      <img src={visa} alt="visa" className="w-[38.17px] h-[22px]" />
                    </div>
                  </div>
                </button>

                <button
                  value="Banxa"
                  onClick={() => handlePaymentMethodSelect('Banxa')}
                  className={`mt-2 bg-white dark:bg-[#090a0c] shadow-md p-4 rounded-md ${selectedPaymentMethod === 'Banxa' ? 'border border-[#EFB81C]' : ''}`}
                >
                  <div className={`flex justify-between items-center w-full cursor-pointer`}>
                    <div className="flex items-center gap-4">
                      <SelectRadio checked={selectedPaymentMethod === 'Banxa'} />
                      <img src={banxa} alt="banxa" className="w-[22px] h-[22px]" />
                      <p className="text-[20px] font-semibold">Banxa</p>
                    </div>
                    <div className="flex items-center gap-1">
                      <img src={mastercard} alt="mastercard" className="w-[38.17px] h-[22px]" />
                      <img src={visa} alt="visa" className="w-[38.17px] h-[22px]" />
                    </div>
                  </div>
                </button>

              </div>
            </div>
            <div className='mt-16 w-full block md:block lg:hidden'>
            <PaymentDetails inputValue={inputValue} selectedPayment={selectedPaymentMethod} />
            </div>
            <div className='w-full'>
              {/* Background div for when button is fixed */}
              <div className="fixed bottom-0 z-40 left-0 w-full h-24 bg-white dark:bg-[#090a0c] sm:hidden"></div>

              {/* The button */}
              <button
                type="button"
                className="fixed bottom-0 z-50 left-0 w-full bg-[#EFB81C] hover:bg-[#d8a926] font-medium rounded-[10px] text-md px-5 py-3 mb-2 sm:static sm:mt-12 sm:bg-[#EFB81C] sm:hover:bg-[#d8a926]"
              >
                Continue
              </button>
            </div>


          </div>

          {/* Top Cryptos */}
          <div className='w-1/2 hidden md:hidden lg:block'>
          <PaymentDetails inputValue={inputValue} selectedPayment={selectedPaymentMethod} />
          </div>
        </div>

       
        {/* <DCAwork /> */}
        <Faqs faqsData={faqsData} />
      </div>
      {/* <DCAbenefits /> */}
      {/* <TopCurrency /> */}
    </div>
  );
}

const SelectRadio = styled(Radio)`
  .ant-radio-inner {
    border-color: #efb81c; /* Change the border color of the radio */
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #efb81c; /* Change border color when checked */
    background-color: #efb81c; /* Change the background color when checked */
  }

  .ant-radio-checked .ant-radio-inner::after {
    background-color: #fff; /* Inner dot color when selected */
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2); /* Focus state outline color */
  }

  /* Change label color */
  .ant-radio-wrapper {
    color: #1f2937; /* Text color for the radio label */
  }
`;

export default ThirdPartyPayments;
