import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { RxChevronRight, RxChevronLeft } from "react-icons/rx";
import "swiper/css";
import "swiper/css/navigation";

function MarketCoinInfo() {
    const swiperRef = useRef(null);

    const coinsData = [
        { img: "https://global.buycex.com/Upload/coin/BTC.png", symbol: "BTCUSD", name: "Bitcoin", price: "65,430", change: "+2.5%", currency: 'USD' },
        { img: "https://global.buycex.com/Upload/coin/ETH.png", symbol: "ETHUSD", name: "Ethereum", price: "3,250", change: "-1.2%", currency: 'USD' },
        { img: "https://global.buycex.com/Upload/coin/XRP.png", symbol: "XRPUSD", name: "Ripple", price: "0.75", change: "+0.8%", currency: 'USD' },
        { img: "https://global.buycex.com/Upload/coin/677da3cd24a43.png", symbol: "BCXUSD", name: "Buycex Coin", price: "1.23", change: "-0.5%", currency: 'USD' },
        { img: "https://global.buycex.com/Upload/coin/BTC.png", symbol: "BTCUSD", name: "Bitcoin", price: "65,430", change: "+2.5%", currency: 'USD' },
        { img: "https://global.buycex.com/Upload/coin/ETH.png", symbol: "ETHUSD", name: "Ethereum", price: "3,250", change: "-1.2%", currency: 'USD' },
        { img: "https://global.buycex.com/Upload/coin/XRP.png", symbol: "XRPUSD", name: "Ripple", price: "0.75", change: "+0.8%", currency: 'USD' },
        { img: "https://global.buycex.com/Upload/coin/677da3cd24a43.png", symbol: "BCXUSD", name: "Buycex Coin", price: "1.23", change: "-0.5%", currency: 'USD' }
    ];

    return (
        <div className="relative container w-full mt-8 md:mt-0">
            <RxChevronLeft
                size={40}
                className="absolute cursor-pointer z-10 top-1/2 left-[10px] transform -translate-y-1/2 bg-white p-1 rounded-full shadow-md"
                onClick={() => swiperRef.current?.swiper.slidePrev()}
            />

            <Swiper
                ref={swiperRef}
                spaceBetween={15}
                breakpoints={{
                    0: { slidesPerView: 1 }, 
                    640: { slidesPerView: 3 },
                    1024: { slidesPerView: 4 } 
                }}
            >
                {coinsData.map((coin, index) => (
                    <SwiperSlide key={index}>
                        <div className="bg-white rounded-2xl border p-2">
                            <div className="card-header flex gap-2 items-center p-2">
                                <img src={coin.img} alt="coin" className="w-8 h-8"/>
                                <h3 className="flex flex-col gap-1 text-xs">
                                    <span className="text-[16px] font-medium">{coin.symbol}</span>
                                    <span className="text-[15px] opacity-60">{coin.name}</span>
                                </h3>
                            </div>
                            <div className="coin-info p-2">
                                <div className="text-[16px] font-medium">${coin.price} <span className="!text-xs">{coin.currency}</span></div>
                                <div className={`text-sm font-medium py-1 ${coin.change.includes("+") ? "text-green-500" : "text-red-500"}`}>
                                    {coin.change}
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <RxChevronRight
                size={40}
                className="absolute cursor-pointer top-1/2 right-[10px] z-10 transform -translate-y-1/2 bg-white p-1 rounded-full shadow-md"
                onClick={() => swiperRef.current?.swiper.slideNext()}
            />
        </div>
    );
}

export default MarketCoinInfo;
