import React from 'react'
import register from 'assets/img/register-express.svg'
import verify from 'assets/img/verify-express.svg'
import buy from 'assets/img/buy-express.svg'

function HowBuy() {
    return (
        <div className='bg-white dark:bg-black'>
            <div className='py-28'>
            <h3 className='text-xl sm:text-4xl font-bold text-center'>How to buy cryptocurrency</h3>
            <div className='relative flex justify-between items-center mx-auto max-w-5xl py-8'>

                {/* Register */}
                <div className='flex flex-col gap-3 items-center justify-center relative'>
                    <img src={register} alt='register' className="bg-white dark:bg-black min-w-[80px] relative z-10 px-4" />
                    <h3 className='text-sm sm:text-xl font-bold text-center'>Register Now</h3>
                </div>

                <div className="w-10 sm:w-1/6 border-t opacity-40 border-gray-800 dark:border-gray-400 border-dashed"></div>

                {/* Verify */}
                <div className='flex flex-col gap-3 items-center justify-center relative'>
                    <img src={verify} alt='verify' className="bg-white dark:bg-black  min-w-[60px] relative z-10 px-4" />
                    <h3 className='text-sm sm:text-xl font-bold text-center'>Verify your identity</h3>
                </div>

                <div className="w-10 sm:w-1/6 border-t opacity-40 border-gray-800 dark:border-gray-400 border-dashed"></div>

                {/* Buy */}
                <div className='flex flex-col gap-3 items-center justify-center relative'>
                    <img src={buy} alt='buy' className="bg-white dark:bg-black  min-w-[60px] relative z-10 px-4" />
                    <h3 className='text-sm sm:text-xl font-bold text-center'>Buy cryptocurrency</h3>
                </div>

            </div>
        </div>
        </div>
    )
}

export default HowBuy
