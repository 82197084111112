import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { RiBitCoinFill } from "react-icons/ri";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { IoChevronDown, IoChevronUp, IoHomeSharp } from "react-icons/io5";
import { FaBook, FaCircleNotch,FaTelegramPlane } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { MdOutlineCheck } from "react-icons/md";
import TradeNowPopup from "../TradeNowPopup";
import { useLocation } from "react-router-dom";
import { useDispatch ,useSelector} from "react-redux";

import {
    cancelOrder,
    unlockOrder,
    fetchCurrentOrders
} from "store/actions/LaunchGuardAction";

const LaunchguardDetail = () => {
    const dispatch = useDispatch();
 const userId = useSelector((state) => state.authUser?.user?.ID);
  const token = useSelector((state) => state.authUser?.user?.TOKEN);
 
    const location = useLocation();
    const section = location.state?.section || JSON.parse(localStorage.getItem("selectedSection"));

     const currentOrders = useSelector((state) => state.lgData?.currentOrder?.data || []);
    
        useEffect(() => {
            if (userId && token) {
                dispatch(fetchCurrentOrders(userId, token));
            }
        }, [dispatch, userId, token]);
    
        console.log(" currentOrders data filtered ",currentOrders);
    const filteredOrders = currentOrders.filter(order => order.status === "0");
    console.log("data filtered ",filteredOrders);
    const orderId = filteredOrders.length > 0 ? filteredOrders[0].id : null;
    console.log(orderId);
    const convert_price= filteredOrders.length > 0 ? filteredOrders[0].convert_price : null;

    console.log("section code data ",section)
    const [isPopupActive, setIsPopupActive] = useState(false);
        const [tradeValue, setTradeValue] = useState(null);
       

        const handleCancelOrder = () => {
            console.log("order ID", orderId);
            if (!userId || !token || !orderId) {
                console.error("Missing required parameters for cancelOrder");
                return;
            }
            
            dispatch(cancelOrder(userId, token, orderId)).then(() => {
                window.location.reload();  // Refresh the page after API call
            });
        };
        
        const handleUnlockOrder = () => {
            console.log("order ID", orderId);
            if (!userId || !token || !orderId) {
                console.error("Missing required parameters for unlockOrder");
                return;
            }
        
            dispatch(unlockOrder(userId, token, orderId)).then(() => {
                window.location.reload();  // Refresh the page after API call
            });
        };
        
        // Function to handle Claim Token (Unlock Order)
       
    
    const handleCancel = () => {
            setIsPopupActive(false);
        };
    
        const handleOk = () => {
            setIsPopupActive(false);
        };

        const showModal = (item) => {
            setIsPopupActive(true);
            setTradeValue({ ...item, icon: <RiBitCoinFill size={18} /> });
        };
    const { title } = useParams();
   

   
    const steps = ["Position Calculation", "Start to Invest", "Distribution"];
    const dates = [section.stats.beginTime, section.stats.exchange_listing_date, section.stats.endTime];
        console.log("dates for data", dates)
    const currentStep = 5;

    return (
        <div className="bg-white dark:bg-black">
            <div className="bg-[#f7f7f7] dark:bg-[#090a0c] pb-4">
                <div className="container p-6 rounded-lg">
                    <div className="flex gap-2 items-center text-sm"><Link to="/trade/launch-guard" className="opacity-60"><h3>Launch Guard</h3></Link>/<Link to="#"><h3>{section.title}</h3></Link></div>
                    <div className="flex items-center mt-4">
                    <img
              src={`${section.iconlink}`}
              alt={section.name}
              className="w-10 h-10 rounded-full object-cover"
            />
                        <div className="ml-2">
    <h1 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-white">
        {section?.title || "No Title"}
    </h1>
    <h2 className="text-lg sm:text-xl font-semibold text-gray-600 dark:text-gray-400">
        {section?.subtitle || "No Subtitle"}
    </h2>
    
</div>

                    </div>
                    <div className="flex justify-between items-center py-5">
  
    <div className="flex gap-8">
    <a href="/" title="Home">
                <IoHomeSharp className="opacity-80 hover:opacity-100 cursor-pointer transition-all duration-300 ease-in-out text-2xl" />
            </a>
            <a href={section.facebook} target="_blank" rel="noopener noreferrer" title="Whitepaper">
                <FaBook className="opacity-80 hover:opacity-100 cursor-pointer transition-all duration-300 ease-in-out text-2xl" />
            </a>
            <a href={section.twitter} target="_blank" rel="noopener noreferrer" title="Twitter">
                <BsTwitterX className="opacity-80 hover:opacity-100 cursor-pointer transition-all duration-300 ease-in-out text-2xl" />
            </a>
            <a href={section.telegram} target="_blank" rel="noopener noreferrer" title="Telegram">
                <FaTelegramPlane className="opacity-80 hover:opacity-100 cursor-pointer transition-all duration-300 ease-in-out text-2xl text-blue-500" />
            </a>    </div>

   
   
</div>

                    <div className="border-t dark:border-[#454545]/60 pt-6">
                        <div className="flex flex-col sm:flex-row gap-5 sm:gap-20 w-full">
                            <div className="flex justify-between w-full lg:w-1/3">
                            <div className="flex flex-col gap-1">
                                <label className="opacity-70 text-sm">Price</label>
                                <span className="text-[16px] font-medium">{section.stats.price} USDT</span>
                            </div>
                            <div className="flex flex-col gap-1">
                                <label className="opacity-70 text-sm">Total Supply</label>
                                <span className="text-[16px] font-medium">{section.stats.volume} </span>
                            </div>
                            </div>
                           
                                <>
                                <div className="flex gap-8">
                                <button
                                            style={{
                                                animation: "scaleUpDown 1s infinite ease-in-out z-30"
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault(); 
                                                showModal(section);
                                            }}
                                            className="bg-[#EFB81C] w-full md:w-40 px-10 py-2 rounded-md text-md text-black font-medium animate-pulse"
                                        >
                                            Buy Now
                                        </button>

                                        
                                        
                                </div>
                                 {/* Right side: Buttons */}
        
            <div className="flex gap-8">
            {orderId && (
            <button
                className="bg-yellow-500 font-medium text-black flex items-center gap-2 px-8 py-1 rounded-md mt-4"
               
                onClick={() => handleUnlockOrder(orderId)}
            >
                Claim Token
            </button>
        )}
            {orderId && (
    <button
        className="bg-gray-300 font-medium text-black flex items-center gap-2 px-8 py-1 rounded-md mt-4"
        onClick={() => handleCancelOrder(orderId)}
    >
        Request Fund
    </button>
  )}

            </div>
       
                                </>
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
    <div className="flex items-center justify-between w-full mt-10">
        {/* Left side: Steps */}
        <div className="flex items-center">
            {steps.map((step, index) => (
                <div key={index} className="flex items-start">
                    <div className="flex flex-col h-32">
                        <div className="flex items-center">
                            <div
                                className={`flex items-center justify-center rounded-full ${
                                    index < currentStep
                                        ? "border-green-500 border"
                                        : "border-gray-300 border w-[24px] h-[24px]"
                                }`}
                            >
                                {index < currentStep ? (
                                    <MdOutlineCheck className="text-green-500" size={20} />
                                ) : (
                                    <p className="text-center">{index + 1}</p>
                                )}
                            </div>
                            {index < steps.length - 1 && (
                                <div
                                    className={`w-12 sm:w-32 h-[1px] ${
                                        index < currentStep - 1 ? "bg-green-500" : "bg-gray-300"
                                    }`}
                                ></div>
                            )}
                        </div>
                        <p className="text-xs sm:text-sm font-medium mt-2 text-gray-700 dark:text-white w-12">{step}</p>
                        <p className="text-[10px] sm:text-xs text-gray-500 dark:text-white/70">{dates[index]}</p>
                    </div>
                </div>
            ))}
        </div>

       
    </div>
</div>

            <div className="container flex gap-10 justify-between w-full">
                <section className="py-8 w-full lg:w-[60%]">
                    <div className="inline-block">
                        <label className="text-2xl font-medium">Details</label>
                        <div className="w-1/2 border-b-2 border-[#EFB81C] mt-1"></div>
                    </div>
                    <div className="flex justify-between w-full pt-4 gap-6 md:gap-0 flex-wrap">
                        <div className="flex flex-col gap-1 w-1/3 min-w-[200px]">
                            <label className="opacity-70 text-sm">Price</label>

                            <span className="text-[16px] font-medium">{section.stats.price} USDT</span>
                        </div>
                        <div className="flex flex-col gap-1 w-1/3 min-w-[200px]">
                            <label className="opacity-70 text-sm">Subscibed</label>
                            <span className="text-[16px] font-medium">{section.stats.subscribed}</span>
                        </div>
                        <div className="flex flex-col gap-1 w-1/3 min-w-[200px]">
                            <label className="opacity-70 text-sm">Buy Limit</label>
                            <span className="text-[16px] font-medium">{section.stats.limit}</span>
                        </div>
                    </div>
                    <div className="flex justify-between w-full pt-4 gap-6 md:gap-0 flex-wrap">
                        <div className="flex flex-col w-1/3 gap-1 min-w-[200px]">
                            <label className="opacity-70 text-sm">Remaining Time</label>
                            <span className="text-[16px] font-medium">{section.stats.endTime}</span>
                        </div>
                        <div className="flex flex-col w-1/3 gap-1 min-w-[200px]">
                            <label className="opacity-70 text-sm">Listing Date</label>
                            <span className="text-[16px] font-medium">{section.stats.exchange_listing_date}</span>
                        </div>
                        <div className="hidden md:block invisible flex-col w-1/3 min-w-[200px] gap-1 items-start xl:items-end">
                            <label className="opacity-70 text-sm">Subscibed</label>
                            <span className="text-[16px] font-medium">20,000</span>
                        </div>
                    </div>

                    <div>
                        <div className="inline-block pt-16">
                            <label className="text-lg sm:text-2xl font-medium">Distribution Plan</label>
                            <div className="w-1/2 border-b-2 border-[#EFB81C] mt-1"></div>
                        </div><br />
                        <span className="text-sm pt-2 opacity-70">Distributions will follow the outlined schedule</span>
                    </div>
                    <div className="w-full lg:w-1/2 flex flex-col sm:flex-row justify-between">
                        <div className="flex items-center gap-2 py-3 sm:py-6"><FaCircleNotch size={12} /><span className="text-sm font-medium">{section.stats.endTime}</span></div>
                        <div className="flex items-center gap-2 py-3 sm:py-6"><span className="text-xs bg-gray-300 dark:bg-gray-700 rounded-md px-2 py-0.5">Distribted</span><span className="text-sm font-medium">100%</span></div>
                    </div>

                    <div>
                        <div className="inline-block pt-16">
                            <label className="text-lg sm:text-2xl font-medium">Participation Requirements</label>
                            <div className="w-1/2 border-b-2 border-[#EFB81C] mt-1"></div>
                        </div><br />
                        <span className="text-sm pt-2 opacity-70">Meet any of the following conditions during the period from {section.stats.beginTime} to {section.stats.endTime}</span>
                    </div>
                    <div className="w-full lg:w-1/2 flex flex-col sm:flex-row justify-between">
                        <div className="flex items-center gap-2 py-3 sm:py-6"><FaCircleNotch size={12} /><span className="text-sm font-medium">Spot Trades ≥ 1</span></div>
                        <div className="flex items-center gap-2 py-3 sm:py-6"><FaCircleNotch size={12} /><span className="text-sm font-medium">Futures Trades ≥ 1</span></div>                </div>

                    <div>
                        <div className="inline-block pt-16">
                            <label className="text-lg sm:text-2xl font-medium">Allocation Rules</label>
                            <div className="w-1/2 border-b-2 border-[#EFB81C] mt-1"></div>
                        </div><br />
                        <span className="text-sm pt-2 opacity-70">Meet any of the following conditions during the period from {section.stats.beginTime} to {section.stats.endTime}</span>
                    </div>
                    <div className="flex justify-between w-full pt-4 gap-6 flex-wrap">
                        <div className="flex flex-col gap-1 flex-1 min-w-[200px]">
                            <label className="opacity-70 text-sm">Allocation Source</label>
                            <span className="text-[16px] font-medium">Holding or principal snapshot</span>
                        </div>
                        <div className="flex flex-col gap-1 flex-1 min-w-[200px]">
                            <label className="opacity-70 text-sm">Snapshot Start</label>
                            <span className="text-[16px] font-medium">{section.stats.beginTime}</span>
                        </div>
                        <div className="flex flex-col gap-1 flex-1 min-w-[200px]">
                            <label className="opacity-70 text-sm">Snapshot End</label>
                            <span className="text-[16px] font-medium">{section.stats.endTime}</span>
                        </div>
                    </div>
                    <div>
                        <div className="inline-block pt-16">
                            <label className="text-lg sm:text-2xl font-medium">Project Introduction</label>
                            <div className="w-1/2 border-b-2 border-[#EFB81C] mt-1"></div>
                        </div><br />
                        <span className="text-sm pt-2 opacity-70">{section.description}</span>
                    </div>
                </section>
                <StickyBox section={section} convert_price={convert_price} />

                {tradeValue !== null && (
                                <TradeNowPopup
                                    isPopupActive={isPopupActive}
                                    onOk={handleCancel}
                                    onCancel={handleOk}
                                    tradeValue={tradeValue}
                                />
                            )}
            </div>
        </div>
    );
};



const StickyBox = ({ section ,convert_price}) => {
    const [isExpanded, setIsExpanded] = useState(true);

    return (
        <div className="lg:sticky lg:top-14 fixed bottom-0 left-0 w-full lg:w-1/3 bg-white dark:bg-black border dark:border-[#454545]/60 p-4 rounded-md mt-8 self-start flex flex-col gap-2 sm:gap-4 z-10">
            <div className="flex justify-between items-center border-b dark:border-[#454545]/60 py-4">
                <h3>{section.stats.available}</h3>
                <button onClick={() => setIsExpanded(!isExpanded)} className="text-xl block lg:hidden">
                    {isExpanded ? <IoChevronUp /> : <IoChevronDown />}
                </button>
            </div>

            {isExpanded && (
    <div className="py-4">
        <span className="border-r text-[16px] pr-2">Tokens</span>
        <span className="text-[16px] pl-2">
            Received {convert_price} {section.title}
        </span>

        {/* Show message only if convert_price is null or 0 */}
        {(convert_price === null || convert_price === "0" || convert_price === 0) && (
            <h3 className="opacity-80 text-sm py-2">Not participating in this project</h3>
        )}
    </div>
)}

        </div>
    );
};

export default LaunchguardDetail;
