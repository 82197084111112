import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import toast from "react-hot-toast"; // Already in use
import { fetchWalletData } from 'store/actions/walletActions';
import { doTransfer } from 'store/actions/TransferAction'; // Import the transfer action
import { FaAngleDoubleRight } from "react-icons/fa";
import WithdrawalRoutingNavbar from 'components/withdrawalRoutingNavbar';
import { TbFileTime, TbCirclesRelation } from "react-icons/tb";
import { IoMdSwap } from "react-icons/io";
import coin_1 from 'assets/img/any-pair-convert.svg';
import coin_2 from 'assets/img/gurranted.svg';
import coin_3 from 'assets/img/instant-price.svg';
import FaQs from 'components/FAQs';
import ConvertModal from './ConvertModal';
import { FaCaretDown, FaCaretUp } from "react-icons/fa6";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CryptoTransferForm  from './cryptoTransfer/cryptoTransferForm';
const CryptoTransfer = () => {
 

  return (
    <>
      <div className='mt-6'>
        <WithdrawalRoutingNavbar />
      </div>
      <section className="relative sm:px-8 px-4 items-center justify-center w-full mt-3">
        
        <div className="relative w-full z-10 text-center text-[#f4f4f4] dark:text-[#151415]">
        
          <div className='sm:flex justify-between hidden  mt-[2.25rem] w-full'>
          <p className="text-4xl font-semibold uppercase text-[#EFB81C] invisible">Transfer</p>
          <p className="sm:text-5xl font-semibold uppercase text-[#EFB81C] text-2xl ml-[-35px] text-ellipsis whitespace-nowrap">Transfer</p>
          <div className=" text-[#f4f4f4] gap-8 font-medium invisible sm:visible">
            <Link to="/user/history/transfer-history">
          <button className='bg-[#EFB81C] px-3 py-0 sm:py-2 rounded-lg inline-flex items-center text-sm'>
            <TbFileTime className='mr-1 hidden sm:block' /> Transfer history
          </button>
          </Link>
        </div>
          </div>
          <p className="sm:text-4xl block sm:hidden font-semibold uppercase text-[#EFB81C] text-2xl text-ellipsis whitespace-nowrap">Transfer</p>
          <p className="text-xs text-gray-500 sm:text-[14px] font-medium mt-1 mb-8">
            Transfer Crypto Between BuyCex Wallet
          </p>
        </div>
        <CryptoTransferForm  />


        <div className=''>
        <div className='flex justify-center w-full sm:w-[400px] mx-auto'>
        <div className='flex mt-[40px] w-full justify-between items-center'>
          <div className='flex flex-col gap-2 items-center'>
            <img src={coin_3} alt='small_coin_1' className='w-[48px] h-[48px]' />
            <p className='text-xs sm:text-sm text-ellipsis whitespace-nowrap'>Instant Price</p>
          </div>
          <div className='flex flex-col gap-2 items-center'>
            <img src={coin_2} alt='small_coin_2' className='w-[48px] h-[48px]' />
            <p className='text-xs sm:text-sm text-ellipsis whitespace-nowrap'>Guaranteed Price</p>
          </div>
          <div className='flex flex-col gap-2 items-center'>
            <img src={coin_1} alt='small_coin_2' className='w-[48px] h-[48px]' />
            <p className='text-xs sm:text-sm text-ellipsis whitespace-nowrap'>Any Pair</p>
          </div>
        </div>
        </div>
        </div>
      </section>
      <section className='container'>
        <FaQs />
      </section>
    </>
  );
};

export default CryptoTransfer;
