import ListingCard from 'components/ListingOfVoice/ListingCard';
import ListingProject from 'components/ListingOfVoice/ListingProject';
import ListingFaq from 'components/ListingOfVoice/ListingFaq';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompetition } from 'store/actions/competitionActions';

import "../../components/home-page-sections/hero-section/./HeroSection.scss";

const heroImages = [HeroImageOne, HeroImageTwo, HeroImageThird];
import HeroImageOne from "assets/img/Aboutsection4.png";
import HeroImageTwo from "assets/img/algo-orders.png";
import HeroImageThird from "assets/img/Aboutsection3.png";
import { FaCopy } from 'react-icons/fa';


function ListingOfVoice() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
    const competition = useSelector((state) => state.competition);
    const dispatch = useDispatch();
    const IMAGE_BASE_URL = "https://api.buycex.com";
    useEffect(() => {
        dispatch(fetchCompetition());
    }, [dispatch]);

    useEffect(() => {
        if (TOKEN && ID) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [TOKEN, ID]);
    // Check if competition.data and competition.data.list are defined
    const competitionList = competition?.data?.list;
    console.log("competitionList", competitionList);
    // Map competition data to fit the structure required for ListingCard and ListingProject components
    const mappedData = Object.values(competitionList || {}).map((item) => ({
        logo: `${IMAGE_BASE_URL}${item.img}`,
        name: item.title || item.name,
        coinId: item.id,
        completePercent: Math.round((item.zongji / item.zhichi) * 100),
        remainingPercent: 100 - Math.round((item.zongji / item.zhichi) * 100),
        votecoin: item.votecoin,
        assumnum: item.assumnum
    }));

    const flippingWords = [
        "Empower Your Voice",
        "Cast Your Vote!",
        "Make It Count",
        "Take Action"
    ];

    const [currentWord, setCurrentWord] = useState("Cast Your Vote!");
    const [flipState, setFlipState] = useState("flip-enter");
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageTransitionState, setImageTransitionState] = useState("fade-in");

    useEffect(() => {
        const wordInterval = setInterval(() => {
            setFlipState("flip-exit");

            setTimeout(() => {
                setCurrentWord((prev) => {
                    const nextIndex =
                        (flippingWords.indexOf(prev) + 1) %
                        flippingWords.length;
                    return flippingWords[nextIndex];
                });
                setFlipState("flip-enter");
            }, 600);
        }, 3000);

        return () => clearInterval(wordInterval);
    }, []);

    useEffect(() => {
        const imageInterval = setInterval(() => {
            setImageTransitionState("fade-out");

            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => {
                    return (prevIndex + 1) % heroImages.length;
                });
                setImageTransitionState("fade-in");
            }, 600);
        }, 3000);

        return () => clearInterval(imageInterval);
    }, []);

    return (
        <>


            <section className="hero-section-black bg-black flex relative items-center">
                <div className="hero-section__wrapper-black container flex items-center justify-between gap-8 w-full m-auto max-md:flex-col">
                    {/* Left Section */}
                    <div className="hero-title-wrapper-black flex flex-col gap-6 w-[60%]">
                        <h1 className="hero-title-black flex flex-col gap-3 leading-tight">
                            <span className="font-[900] text-[#EFB81C]">Make Your Voice Heard</span>
                            <p className="justify-center text-gray-400">$118,961,785</p>
                        </h1>
                        <p className="hero-description-black -mt-2">
                            <span className={`flip-text-black ${flipState}`}>{currentWord}</span>{"  "}
                            <span className='text-white'> in shaping the future of digital finance</span>
                        </p>

                        <div className="hero-btn-wrap flex items-center gap-4 mt-2">
                            <button className="bg-[#EFB81C] font-medium text-sm text-white py-1 px-4 rounded-md">
                                Click To Vote
                            </button>
                            <button className="border text-sm font-medium border-[#EFB81C] text-[#EFB81C] hover:bg-[#EFB81C] py-1 px-4 rounded-md">
                                Vote History
                            </button>
                        </div>
                    </div>

                    {/* Right Section: Hero Image */}
                    <div className="flex flex-col items-center w-[40%]">
                        <div className="hero-image-wrapper-black relative w-full h-[10rem] rounded-xl overflow-hidden">
                            {heroImages.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`hero-img-${index}`}
                                    className={`hero-image-black absolute top-0 left-0 w-full rounded-xl object-contain transition-opacity duration-700 ${imageTransitionState} ${index === currentImageIndex ? "active" : "inactive"
                                        }`}
                                />
                            ))}
                        </div>
                        <button className='font-semibold text-sm border-2 rounded-full border-[#EFB81C] text-[#EFB81C] px-4 py-1'>
                            Become a Member Now!
                        </button>
                    </div>
                </div>
            </section>

            <div className="flex flex-col min-h-screen p-10" style={{ backgroundColor: '#fef9ea' }}>
                <div className="flex flex-wrap justify-center mb-8">
                    {/* Section 1: Prompt to Register or Log In */}

                    <div className="w-full text-center mb-8">
                        <h1 className="p-2 text-4xl font-bold">
                            Stand Up and Be Counted – Vote Now!
                        </h1>
                        <p className="text-[#707A8A] text-[20px]">
                            Be Part of the Decision – Vote Now!
                        </p>
                        {!isAuthenticated && (
                            <div className="flex justify-center gap-3 py-4">
                                <button className="bg-[#EFB81C] rounded-lg px-8 py-1 text-[16px] font-semibold">
                                    Register
                                </button>
                                <button className="bg-[#EAECEF] rounded-lg px-8 py-1 text-[16px] font-semibold">
                                    Log In
                                </button>
                            </div>
                        )}
                    </div>

                </div>

                {/* Section 2: Render the competition data as listing cards */}
                <div className="flex flex-wrap justify-center gap-6 ">
                    {mappedData.map((item, index) => (
                        <ListingCard
                            key={index}
                            logo={item.logo}
                            coinId={item.coinId}
                            name={item.name}
                            completePercent={item.completePercent}
                            remainingPercent={item.remainingPercent}
                            votecoin={item.votecoin}
                            assumnum={item.assumnum}
                        />
                    ))}
                </div>
            </div>


            <div className="min-h-screen text-black flex flex-col items-center py-10">
                <h1 className="text-3xl font-bold mb-6">Successful Projects</h1>
                <div className="w-full max-w-3xl">
                    {mappedData.map((project, index) => (
                        <ListingProject
                            key={index}
                            logo={project.logo}
                            coinId={project.coinId}
                            name={project.name}
                            completePercent={project.completePercent}
                            remainingPercent={project.remainingPercent}
                            votecoin={project.votecoin}
                            assumnum={project.assumnum}
                        />
                    ))}
                </div>
                <ListingFaq />
            </div>
        </>
    );
}

export default ListingOfVoice;
