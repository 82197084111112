import TradeNowPopup from "components/trade/LaunchGuard/TradeNowPopup";
import React, { useState, useEffect } from "react";
import { RiBitCoinFill } from "react-icons/ri";

const StakingPool = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [tradeValue, setTradeValue] = useState(null);
  const [isPopupActive, setIsPopupActive] = useState(false);

  const showModal = (item) => {
    setIsPopupActive(true);
    setTradeValue({ ...item, icon: <RiBitCoinFill size={18} /> });
  };

  const handleCancel = () => {
    setIsPopupActive(false);
  };

  const handleOk = () => {
    setIsPopupActive(false);
  };

  const pools = [
    
    {
      id: 1,
      name: "CVM (CosVM)",
      pool: "CVM Guard",
      status: "in-progress",
      totalRewards: "3,500,000 CVM",
      stakingPeriod: "30 Days",
      endTime: "2024-11-21 15:00:00",
      apr: "386.55%",
      participants: "10,771",
      individualCap: "3,000,000,000 CVM",
      totalStaked: "11,044,524 CVM",
      remainingTime: "27D : 00H : 30M : 56S",
    },
    {
      id: 2,
      name: "CYRUS",
      pool: "CYRUS pool",
      status: "in-progress",
      totalRewards: "3,500,000 CYRUS",
      stakingPeriod: "30 Days",
      endTime: "2024-11-21 15:00:00",
      apr: "386.55%",
      participants: "10,771",
      individualCap: "3,000,000,000 CYRUS",
      totalStaked: "11,044,524 CYRUS",
      remainingTime: "27D : 00H : 30M : 56S",
    },
    {
      id: 3,
      name: "SOLAR",
      pool: "SOLAR pool",
      status: "upcoming",
      totalRewards: "1,500,000 SOLAR",
      stakingPeriod: "60 Days",
      endTime: "2024-12-15 10:00:00",
      apr: "450.25%",
      participants: "8,332",
      individualCap: "2,000,000,000 SOLAR",
      totalStaked: "5,023,123 SOLAR",
      remainingTime: "N/A",
    },
    {
      id: 4,
      name: "TERRA",
      pool: "TERRA pool",
      status: "ended",
      totalRewards: "2,000,000 TERRA",
      stakingPeriod: "15 Days",
      endTime: "2023-09-10 12:00:00",
      apr: "200.00%",
      participants: "15,000",
      individualCap: "1,500,000,000 TERRA",
      totalStaked: "12,000,000 TERRA",
      remainingTime: "Ended",
    },
  ];

  const filteredPools = pools.filter((pool) => {
    if (activeTab === "all") return true;
    return pool.status === activeTab;
  });

  return (
    <div className="staking-pool-container max-w-screen-lg mx-auto p-2 bg-gray-50 rounded-lg py-10 shadow-xl">
      {/* Navigation Tabs */}
      <div className="tabs flex justify-between items-center mb-4">
        <div className="flex space-x-6 text-lg font-semibold text-gray-700">
          {["all", "in-progress", "upcoming", "ended"].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`cursor-pointer pb-2 ${
                activeTab === tab ? "border-b-2 border-yellow-500 text-black" : "text-gray-500"
              }`}
            >
              {tab.replace("-", " ").toUpperCase()}
            </button>
          ))}
        </div>
        <div className="ml-auto">
          <button className="border px-4 py-2 rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200">
            My Order
          </button>
        </div>
      </div>

      {/* Pool Content */}
      <div className="grid grid-cols-1 gap-8">
        {filteredPools.length > 0 ? (
          filteredPools.map((pool) => (
            <div
              key={pool.id}
              className="pool-card flex flex-col md:flex-row items-start md:items-center bg-white border rounded-lg p-6 shadow-md transition-transform transform hover:scale-105"
            >
              {/* Left Side (Staking Pool Information) */}
              <div className="left-content w-full md:w-1/2 bg-white border rounded-lg p-4">
                <div className="flex items-center justify-between mb-4">
                  <span className="text-green-500 font-semibold text-lg capitalize">
                    {pool.status.replace("-", " ")}
                  </span>
                  <a href="#" className="text-gray-400 hover:underline">
                    View details
                  </a>
                </div>
                <h2 className="text-2xl font-bold">{pool.name}</h2>
                <p className="text-gray-500 mt-2">
                  This project focuses on renewable energy and reducing carbon emissions.
                </p>

                <div className="pool-stats mt-4 space-y-2">
                  <p>
                    <strong>Total Sales Cap:</strong> {pool.totalRewards}
                  </p>
                  <p>
                    <strong>Selling Period:</strong> {pool.stakingPeriod}
                  </p>
                  <p>
                    <strong>End Time:</strong> {pool.endTime}
                  </p>
                </div>

                <div className="mining-ends mt-6 text-center text-yellow-500 font-bold">
                  Mining Ends In
                  <p className="text-xl">{pool.remainingTime}</p>
                </div>
              </div>

              {/* Right Side (Staking Pool Statistics and CTA) */}
              <div className="right-content w-full md:w-1/2 bg-white border rounded-lg p-4 mt-6 md:mt-0 md:ml-8">
                <h2 className="text-xl font-bold">{pool.pool}</h2>
                <p className="text-gray-500 mt-2">
                  Stake {pool.name} and earn {pool.name}
                </p>

                <div className="staking-info mt-6 space-y-2">
                  <div className="flex justify-between">
                    <span>APR</span>
                    <span>{pool.apr}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Participants</span>
                    <span>{pool.participants}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Individual Staking Cap</span>
                    <span>{pool.individualCap}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Total Staked</span>
                    <span>{pool.totalStaked}</span>
                  </div>
                </div>

                <button
                  onClick={() => showModal(pool)}
                  className="w-full bg-yellow-500 text-white py-3 mt-6 rounded-md hover:bg-yellow-400"
                >
                  Lock
                </button>
                {tradeValue !== null && (
                  <TradeNowPopup
                    isPopupActive={isPopupActive}
                    onOk={handleCancel}
                    onCancel={handleOk}
                    tradeValue={tradeValue}
                  />
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500">No pools available in this category.</div>
        )}
      </div>
    </div>
  );
};

export default StakingPool;