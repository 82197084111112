import React, { useState } from 'react';
import { GiTwoCoins } from "react-icons/gi";
import History from './History';
import { HiChevronDown } from 'react-icons/hi';

function BitSavings() {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className='dark:bg-black'>
            <div className='pt-8 container'>
                <div className='border-b dark:border-[#454545]'>
                    <button className='py-3 ml-12 px-4 border-b-2 border-[#EFB81C] font-medium'>Savings</button>
                </div>

                <div className='border dark:border-[#454545] flex flex-col gap-4 md:flex-row justify-between rounded-xl w-full my-4 px-4 py-6'>
                    <div className='flex flex-col gap-8 md:flex-row w-full'>
                        <div className='flex justify-between'>
                            <div className='flex gap-4 items-center pr-6 dark:border-[#454545] border-0 md:border-r'>
                                <GiTwoCoins size={34} />
                                <div className='flex flex-col gap-3'>
                                    <h3 className='text-sm'>Savings in BTC</h3>
                                    <span className='text-sm font-medium'>0.0926 BTC</span>
                                </div>
                            </div>
                            <button 
                                className='bg-[#0b0c0f] flex justify-center self-center p-2 rounded-lg md:hidden' 
                                onClick={() => setIsExpanded(!isExpanded)}
                            >
                                <HiChevronDown size={24} className={`${isExpanded ? 'rotate-180' : ''} transition-transform`} />
                            </button>
                        </div>

                        <div className={`gap-3 px-4 sm:px-12 ${isExpanded ? 'flex' : 'hidden'} md:flex md:gap-12`}>
                            <div className='flex flex-col gap-3'>
                                <h3 className='text-sm'>Invested Value</h3>
                                <span className='text-sm font-medium'>882.11 USDT</span>
                            </div>
                            <div className='flex flex-col gap-3'>
                                <h3 className='text-sm'>Current Value</h3>
                                <span className='text-sm font-medium'>855.99 USDT</span>
                            </div>
                        </div>
                    </div>
                    <button className='bg-[#EFB81C] rounded-xl flex w-full text-center justify-center md:w-auto md:self-center py-3 px-4 font-medium text-sm'>
                        Buy More Coins
                    </button>
                </div>
                <History />
            </div>
        </div>
    );
}

export default BitSavings;
