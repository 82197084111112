import React, { useEffect, useRef, useState } from "react";
import { MdOutlinePersonOutline } from "react-icons/md";
import { SiLitecoin } from "react-icons/si";
import { Link } from "react-router-dom";
import LockModal from "./LockModal";
import { cancelSub, getlaunchpool, NewEvent } from "store/actions/AssetsUserAction";
import { useSelector, useDispatch } from "react-redux";
import { TfiAnnouncement } from "react-icons/tfi";
import { subscribepool } from "store/actions/AssetsUserAction";
import toast from "react-hot-toast";

function NewEvents({ login }) {
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const dispatch = useDispatch();
    const [newEvent, setNewEvent] = useState(null);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const dropdownRefs = useRef([]);
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        dispatch(getlaunchpool(userID, userToken)).then((response) => {
            setSubscriptions(response.data.data.subscriptions);
        });
    }, [dispatch, userID, userToken]);

    const isSubscribed = subscriptions.some(
        (sub) => sub.user_id === String(userID)
    );

    const handleSubscriptionToggle = () => {
        const payload = { user_id: userID };

        if (isSubscribed) {
            // Call Unsubscribe API
            dispatch(cancelSub(userID, userToken, payload))
                .then((response) => {
                    if (response.success) {
                        toast.success("Unsubscribed successfully");
                        // Remove user from subscriptions
                        setSubscriptions(
                            subscriptions.filter(
                                (sub) => sub.user_id !== String(userID)
                            )
                        );
                    } else {
                        toast.error("Failed to unsubscribe");
                    }
                })
                .catch((error) => console.error("Error:", error));
        } else {
            // Call Subscribe API
            dispatch(subscribepool(userID, userToken, payload))
                .then((response) => {
                    if (response.success) {
                        toast.success("Subscribed successfully");
                        // Add user to subscriptions
                        setSubscriptions([
                            ...subscriptions,
                            { user_id: String(userID) },
                        ]);
                    } else {
                        toast.error("Failed to subscribe");
                    }
                })
                .catch((error) => console.error("Error:", error));
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                openDropdownIndex !== null &&
                dropdownRefs.current[openDropdownIndex] &&
                !dropdownRefs.current[openDropdownIndex].contains(event.target)
            ) {
                setOpenDropdownIndex(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [openDropdownIndex]);

    const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);

    const handleButtonClick = () => {
        setIsTransferModalVisible(true);
    };
    const [selectedPool, setSelectedPool] = useState(null);

    const [isLockModalVisible, setIsLockModalVisible] = useState(false);

    const showLockModal = (pool) => {
        setSelectedPool(pool);
        setIsLockModalVisible(true);
    };

    const handleCancel = () => {
        setSelectedPool(null);
        setIsLockModalVisible(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(NewEvent(userID, userToken));
                setNewEvent(response.data.data);
            } catch (error) {
                console.error("Error fetching LaunchPool:", error);
            }
        };

        fetchData();
    }, [dispatch, userID, userToken]);

    useEffect(() => {
        console.log("Updated EventData:", newEvent);
    }, [newEvent]);

    if (!newEvent) {
        return <div>Loading events...</div>;
    }

    return (
        <div className="bg-[#F5F7FA] dark:bg-black">
            <div className="max-w-[1400px] w-full px-0 sm:px-[32px] mx-auto">
                <p className="text-lg sm:text-[24px] dark:text-white font-semibold pt-8 pb-4 px-[16px] sm:px-0">
                    Stake and Earn Tokens for Free
                </p>

                <div className="bg-white dark:bg-[#090a0c] rounded-lg p-3 max-w-[1400px] px-0 sm:px-[32px] mx-auto flex flex-col lg:flex-row gap-4 justify-between w-full">
                    <div className="flex items-center gap-4 container">
                        <TfiAnnouncement className="text-black min-w-[24px] min-h-[24px] dark:text-white" />
                        <label className="font-medium lg:font-semibold text-xs sm:text-[16px]">
                            You‘ve subscribed to receive notifications for all
                            upcoming Launchpool events!
                        </label>
                    </div>
                    <button
                        onClick={handleSubscriptionToggle}
                        className={`${!isSubscribed ? 'bg-[#EFB81c] text-white' : 'bg-gray-500 text-white'} mx-6 font-semibold text-sm px-4 rounded-lg py-2`}
                    >
                        {isSubscribed ? "Unsubscribe" : "Subscribe"}
                    </button>
                </div>
            </div>
            <div className="max-w-[1400px] w-full px-0 sm:px-[32px] mx-auto">
                <p className="text-lg sm:text-[24px] dark:text-white font-semibold pt-8 pb-4 px-[16px] sm:px-0">
                    New Events
                </p>

                {newEvent.upcoming && newEvent.upcoming.length > 0 && (
                    <div>
                        {newEvent.upcoming.map((completedEvent, eventIndex) => (
                            <div
                                key={`completed-event-${eventIndex}`}
                                className="rounded-lg bg-white dark:bg-black border-t-[3px] border-t-[#EFB81c] dark:border-t-[#EFB81c] dark:border-r dark:border-l dark:border-b dark:border-[#454545]/60 px-6 pb-6"
                            >
                                <div className="relative left-4 mb-0 sm:mb-2 flex justify-end">
                                    <span className="bg-[#EFB81c] bg-opacity-10 font-medium py-1 text-[#EFB81c] px-2 rounded-bl-md">
                                        Coming soon
                                    </span>
                                </div>
                                <div className="px-0 sm:px-8">
                                    <Link
                                        to={`/launchpool/${completedEvent.project.id}`}
                                    >
                                        <div className="flex items-center gap-2 sm:gap-4">
                                            <img
                                                src={`https://global.buycex.com/Upload/issue/${completedEvent.project.image}`}
                                                className="w-6 sm:w-14 h-6 sm:h-14 rounded-full"
                                                alt={
                                                    completedEvent.project.name
                                                }
                                            />
                                            <div className="flex flex-col gap-1">
                                                <h3 className="font-bold text-lg sm:text-xl">
                                                    {
                                                        completedEvent.project
                                                            .name
                                                    }
                                                </h3>
                                                <p className="line-clamp-2 hidden sm:block text-sm text-[#1D1D1D99] dark:text-white/60">
                                                    {
                                                        completedEvent.project
                                                            .description
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="flex flex-col sm:flex-row gap-1.5 sm:gap-3 w-full py-2 sm:py-8">
                                        <div className="flex-1 flex flex-row-reverse sm:flex-col gap-1 items-center justify-between sm:justify-center bg-white dark:bg-black dark:border-[#454545]/60 border-0 sm:border min-h-max sm:min-h-20 rounded-lg p-0 sm:p-4">
                                            <p className="dark:text-white text-black font-bold opacity-80 sm:opacity-100 text-center text-[13px] sm:text-[16px] md:text-xl">
                                                {completedEvent.pools.reduce(
                                                    (total, pool) =>
                                                        total +
                                                        (parseFloat(
                                                            pool.reward
                                                        ) || 0),
                                                    0
                                                )}
                                            </p>
                                            <p className="text-[13px] font-normal dark:text-white text-black sm:text-[#1D1D1D99] opacity-60 text-center">
                                                Total Pool Amount (
                                                {completedEvent.project.name})
                                            </p>
                                        </div>
                                        <div className="flex-1 flex flex-row-reverse sm:flex-col gap-1 items-center justify-between sm:justify-center bg-white dark:bg-black dark:border-[#454545]/60 border-0 sm:border min-h-max sm:min-h-20 rounded-lg p-0 sm:p-4">
                                            <p className="dark:text-white text-black font-bold opacity-80 sm:opacity-100 text-center text-[13px] sm:text-[16px] md:text-xl">
                                                {Math.ceil(
                                                    (new Date(
                                                        completedEvent.project.staking_period_end
                                                    ) -
                                                        new Date(
                                                            completedEvent.project.staking_period_start
                                                        )) /
                                                        (1000 * 60 * 60 * 24)
                                                )}{" "}
                                                Days
                                            </p>
                                            <p className="text-[13px] font-normal dark:text-white text-black sm:text-[#1D1D1D99] opacity-60 text-center">
                                                Event Period
                                            </p>
                                        </div>
                                        <div className="flex-1 flex flex-row-reverse sm:flex-col gap-1 items-center justify-between sm:justify-center bg-white dark:bg-black dark:border-[#454545]/60 border-0 sm:border min-h-max sm:min-h-20 rounded-lg p-0 sm:p-4">
                                            <p className="dark:text-white text-black font-bold opacity-80 sm:opacity-100 text-center text-[13px] sm:text-[16px] md:text-xl">
                                                {
                                                    completedEvent.project
                                                        .staking_period_end
                                                }
                                            </p>
                                            <p className="text-[13px] font-normal dark:text-white text-black sm:text-[#1D1D1D99] opacity-60 text-center">
                                                End Time
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap gap-3 w-full py-8">
                                        {completedEvent.pools.map(
                                            (pool, poolIndex) => (
                                                <div
                                                    key={`completed-pool-${poolIndex}`}
                                                    className="flex-1 px-2 py-3 sm:p-4 bg-white dark:bg-[#090a0c] dark:hover:bg-[#090a0c]/80 dark:border-[#454545]/60 border hover:bg-[#f7f7f7] !min-w-max sm:min-w-[340px] transition-all duration-300 ease-in-out cursor-pointer min-h-20 rounded-lg"
                                                >
                                                    <Link
                                                        to={`/launchpool/${completedEvent.project.id}`}
                                                    >
                                                        <div className="flex justify-between items-center w-full">
                                                            <div className="flex flex-col">
                                                                <div className="flex gap-1.5 items-center">
                                                                    <img
                                                                        src={`https://global.buycex.com/Upload/issue/${completedEvent.project.image}`}
                                                                        className="w-6 sm:w-14 h-6 sm:h-14 rounded-full"
                                                                        alt={
                                                                            completedEvent
                                                                                .project
                                                                                .name
                                                                        }
                                                                    />
                                                                    <h3 className="font-bold text-lg sm:text-xl dark:text-white text-black">
                                                                        {
                                                                            pool.pool_name
                                                                        }
                                                                    </h3>
                                                                </div>
                                                                <h3 className="opacity-80 text-sm hidden sm:block">
                                                                    Lock{" "}
                                                                    {
                                                                        pool.pool_name
                                                                    }{" "}
                                                                    to{" "}
                                                                    {
                                                                        completedEvent
                                                                            .project
                                                                            .name
                                                                    }
                                                                </h3>
                                                                <div className="flex flex-col gap-2 mt-4">
                                                                    <p className="flex items-center">
                                                                        <MdOutlinePersonOutline className="text-[#1D1D1D99] dark:text-white/60" />{" "}
                                                                        <span className="text-[#1D1D1D99] dark:text-white/60 text-sm">
                                                                            {
                                                                                pool.unique_users_staked
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                    <span className="bg-[#EFB81c] bg-opacity-10 self-start flex justify-self-start font-medium text-[#EFB81c] px-2 rounded">
                                                                        Coming
                                                                        soon
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="border dark:border-[#454545]/60 opacity-60 h-16 sm:h-28 w-16 sm:w-28 flex items-center justify-center p-2 sm:p-1 mt-2 rounded-full">
                                                                <SiLitecoin
                                                                    fill="#10d173"
                                                                    size={32}
                                                                />
                                                                <SiLitecoin
                                                                    size={32}
                                                                    className="ml-[-2px]"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col sm:flex-row justify-between w-full mt-4 sm:mt-10">
                                                            <div className="flex flex-row justify-between sm:justify-normal sm:flex-col items-start w-full sm:w-1/2">
                                                                <p className="sm:text-sm text-[#1D1D1D99] dark:text-white/60 text-[13px] font-normal py-1 w-full text-start">
                                                                    Total Pool
                                                                    Rewards (
                                                                    {
                                                                        completedEvent
                                                                            .project
                                                                            .name
                                                                    }
                                                                    )
                                                                </p>
                                                                <p className="text-end sm:text-start sm:text-sm text-[13px] text-black dark:text-white font-bold py-1">
                                                                    {
                                                                        pool.reward
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="flex flex-row justify-between sm:justify-normal sm:flex-col items-end w-full sm:w-1/2">
                                                                <p className="sm:text-sm text-[#1D1D1D99] dark:text-white/60 text-[13px] font-normal py-1 w-full text-start sm:text-end">
                                                                    Total Locked
                                                                    (
                                                                    {
                                                                        pool.pool_name
                                                                    }
                                                                    )
                                                                </p>
                                                                <p className="!text-end sm:text-start font-bold py-1 sm:text-sm text-[13px] text-black dark:text-white">
                                                                    {
                                                                        pool.total_tokens_staked
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col sm:flex-row justify-between w-full mt-0 sm:mt-4">
                                                            <div className="flex flex-row justify-between sm:justify-normal sm:flex-col items-start w-full sm:w-1/2">
                                                                <p className="sm:text-sm text-[#1D1D1D99] dark:text-white/60 text-[13px] font-normal py-1 w-full text-start">
                                                                    My Locked (
                                                                    {
                                                                        pool.pool_name
                                                                    }
                                                                    )
                                                                </p>

                                                                {login ? (
                                                                    <p className="text-end sm:text-start sm:text-sm text-[13px] text-black dark:text-white font-bold py-1">
                                                                        {
                                                                            pool.unique_users_staked
                                                                        }
                                                                    </p>
                                                                ) : (
                                                                    "--"
                                                                )}
                                                            </div>
                                                            <div className="flex flex-row justify-between sm:justify-normal sm:flex-col items-end w-full sm:w-1/2">
                                                                <p className="sm:text-sm text-[#1D1D1D99] dark:text-white/60 text-[13px] font-normal py-1 w-full text-start sm:text-end">
                                                                    Claimed
                                                                    Rewards (
                                                                    {
                                                                        completedEvent
                                                                            .project
                                                                            .name
                                                                    }
                                                                    )
                                                                </p>
                                                                {login ? (
                                                                    <p className="!text-end sm:text-start font-bold py-1 sm:text-sm text-[13px] text-black dark:text-white">
                                                                        {
                                                                            pool.reward
                                                                        }
                                                                    </p>
                                                                ) : (
                                                                    "--"
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <button
                                                        onClick={() =>
                                                            showLockModal(pool)
                                                        }
                                                        className="bg-[#EFB81c] rounded-[0.375rem] w-full h-10 font-semibold my-2"
                                                    >
                                                        {login ? (
                                                            "Lock"
                                                        ) : (
                                                            <Link to="/login">
                                                                Login
                                                            </Link>
                                                        )}
                                                    </button>
                                                    <LockModal
                                                        isVisible={
                                                            isLockModalVisible
                                                        }
                                                        handleClose={
                                                            handleCancel
                                                        }
                                                        data={selectedPool}
                                                        handleButtonClick={
                                                            handleButtonClick
                                                        }
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {newEvent.running && newEvent.running.length > 0 && (
                    <div>
                        {newEvent.running.map((completedEvent, eventIndex) => (
                            <div
                                key={`completed-event-${eventIndex}`}
                                className="rounded-lg bg-white dark:bg-black border-t-[3px] border-t-[#EFB81c] dark:border-t-[#EFB81c] dark:border-r dark:border-l dark:border-b dark:border-[#454545]/60 px-6 pb-6"
                            >
                                <div className="relative left-4 mb-0 sm:mb-2 flex justify-end">
                                    <span className="bg-gray-500 bg-opacity-10 font-medium py-1 text-gray-800 px-2 rounded-bl-md">
                                        In progress
                                    </span>
                                </div>
                                <div className="px-0 sm:px-8">
                                    <Link
                                        to={`/launchpool/${completedEvent.project.id}`}
                                    >
                                        <div className="flex items-center gap-2 sm:gap-4">
                                            <img
                                                src={`https://global.buycex.com/Upload/issue/${completedEvent.project.image}`}
                                                className="w-6 sm:w-14 h-6 sm:h-14 rounded-full"
                                                alt={
                                                    completedEvent.project.name
                                                }
                                            />
                                            <div className="flex flex-col gap-1">
                                                <h3 className="font-bold text-lg sm:text-xl">
                                                    {
                                                        completedEvent.project
                                                            .name
                                                    }
                                                </h3>
                                                <p className="line-clamp-2 hidden sm:block text-sm text-[#1D1D1D99] dark:text-white/60">
                                                    {
                                                        completedEvent.project
                                                            .description
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="flex flex-col sm:flex-row gap-1.5 sm:gap-3 w-full py-2 sm:py-8">
                                        <div className="flex-1 flex flex-row-reverse sm:flex-col gap-1 items-center justify-between sm:justify-center bg-white dark:bg-black dark:border-[#454545]/60 border-0 sm:border min-h-max sm:min-h-20 rounded-lg p-0 sm:p-4">
                                            <p className="dark:text-white text-black font-bold opacity-80 sm:opacity-100 text-center text-[13px] sm:text-[16px] md:text-xl">
                                                {completedEvent.pools.reduce(
                                                    (total, pool) =>
                                                        total +
                                                        (parseFloat(
                                                            pool.reward
                                                        ) || 0),
                                                    0
                                                )}
                                            </p>
                                            <p className="text-[13px] font-normal dark:text-white text-black sm:text-[#1D1D1D99] opacity-60 text-center">
                                                Total Pool Amount (
                                                {completedEvent.project.name})
                                            </p>
                                        </div>
                                        <div className="flex-1 flex flex-row-reverse sm:flex-col gap-1 items-center justify-between sm:justify-center bg-white dark:bg-black dark:border-[#454545]/60 border-0 sm:border min-h-max sm:min-h-20 rounded-lg p-0 sm:p-4">
                                            <p className="dark:text-white text-black font-bold opacity-80 sm:opacity-100 text-center text-[13px] sm:text-[16px] md:text-xl">
                                                {Math.ceil(
                                                    (new Date(
                                                        completedEvent.project.staking_period_end
                                                    ) -
                                                        new Date(
                                                            completedEvent.project.staking_period_start
                                                        )) /
                                                        (1000 * 60 * 60 * 24)
                                                )}{" "}
                                                Days
                                            </p>
                                            <p className="text-[13px] font-normal dark:text-white text-black sm:text-[#1D1D1D99] opacity-60 text-center">
                                                Event Period
                                            </p>
                                        </div>
                                        <div className="flex-1 flex flex-row-reverse sm:flex-col gap-1 items-center justify-between sm:justify-center bg-white dark:bg-black dark:border-[#454545]/60 border-0 sm:border min-h-max sm:min-h-20 rounded-lg p-0 sm:p-4">
                                            <p className="dark:text-white text-black font-bold opacity-80 sm:opacity-100 text-center text-[13px] sm:text-[16px] md:text-xl">
                                                {
                                                    completedEvent.project
                                                        .staking_period_end
                                                }
                                            </p>
                                            <p className="text-[13px] font-normal dark:text-white text-black sm:text-[#1D1D1D99] opacity-60 text-center">
                                                End Time
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap gap-3 w-full py-8">
                                        {completedEvent.pools.map(
                                            (pool, poolIndex) => (
                                                <div
                                                    key={`completed-pool-${poolIndex}`}
                                                    className="flex-1 px-2 py-3 sm:p-4 bg-white dark:bg-[#090a0c] dark:hover:bg-[#090a0c]/80 dark:border-[#454545]/60 border hover:bg-[#f7f7f7] !min-w-max sm:min-w-[340px] transition-all duration-300 ease-in-out cursor-pointer min-h-20 rounded-lg"
                                                >
                                                    <Link
                                                        to={`/launchpool/${completedEvent.project.id}`}
                                                    >
                                                        <div className="flex justify-between items-center w-full">
                                                            <div className="flex flex-col">
                                                                <div className="flex gap-1.5 items-center">
                                                                    <img
                                                                        src={`https://global.buycex.com/Upload/issue/${completedEvent.project.image}`}
                                                                        className="w-6 sm:w-14 h-6 sm:h-14 rounded-full"
                                                                        alt={
                                                                            completedEvent
                                                                                .project
                                                                                .name
                                                                        }
                                                                    />
                                                                    <h3 className="font-bold text-lg sm:text-xl dark:text-white text-black">
                                                                        {
                                                                            pool.pool_name
                                                                        }
                                                                    </h3>
                                                                </div>
                                                                <h3 className="opacity-80 text-sm hidden sm:block">
                                                                    Lock{" "}
                                                                    {
                                                                        pool.pool_name
                                                                    }{" "}
                                                                    to{" "}
                                                                    {
                                                                        completedEvent
                                                                            .project
                                                                            .name
                                                                    }
                                                                </h3>
                                                                <div className="flex flex-col gap-2 mt-4">
                                                                    <p className="flex items-center">
                                                                        <MdOutlinePersonOutline className="text-[#1D1D1D99] dark:text-white/60" />{" "}
                                                                        <span className="text-[#1D1D1D99] dark:text-white/60 text-sm">
                                                                            {
                                                                                pool.unique_users_staked
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                    <span className="bg-gray-500 bg-opacity-10 self-start flex justify-self-start font-medium text-gray-800 px-2 rounded">
                                                                        In
                                                                        progress
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="border dark:border-[#454545]/60 opacity-60 h-16 sm:h-28 w-16 sm:w-28 flex items-center justify-center p-2 sm:p-1 mt-2 rounded-full">
                                                                <SiLitecoin
                                                                    fill="#10d173"
                                                                    size={32}
                                                                />
                                                                <SiLitecoin
                                                                    size={32}
                                                                    className="ml-[-2px]"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col sm:flex-row justify-between w-full mt-4 sm:mt-10">
                                                            <div className="flex flex-row justify-between sm:justify-normal sm:flex-col items-start w-full sm:w-1/2">
                                                                <p className="sm:text-sm text-[#1D1D1D99] dark:text-white/60 text-[13px] font-normal py-1 w-full text-start">
                                                                    Total Pool
                                                                    Rewards (
                                                                    {
                                                                        completedEvent
                                                                            .project
                                                                            .name
                                                                    }
                                                                    )
                                                                </p>
                                                                <p className="text-end sm:text-start sm:text-sm text-[13px] text-black dark:text-white font-bold py-1">
                                                                    {
                                                                        pool.reward
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="flex flex-row justify-between sm:justify-normal sm:flex-col items-end w-full sm:w-1/2">
                                                                <p className="sm:text-sm text-[#1D1D1D99] dark:text-white/60 text-[13px] font-normal py-1 w-full text-start sm:text-end">
                                                                    Total Locked
                                                                    (
                                                                    {
                                                                        pool.pool_name
                                                                    }
                                                                    )
                                                                </p>
                                                                <p className="!text-end sm:text-start font-bold py-1 sm:text-sm text-[13px] text-black dark:text-white">
                                                                    {
                                                                        pool.total_tokens_staked
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col sm:flex-row justify-between w-full mt-0 sm:mt-4">
                                                            <div className="flex flex-row justify-between sm:justify-normal sm:flex-col items-start w-full sm:w-1/2">
                                                                <p className="sm:text-sm text-[#1D1D1D99] dark:text-white/60 text-[13px] font-normal py-1 w-full text-start">
                                                                    My Locked (
                                                                    {
                                                                        pool.pool_name
                                                                    }
                                                                    )
                                                                </p>

                                                                {login ? (
                                                                    <p className="text-end sm:text-start sm:text-sm text-[13px] text-black dark:text-white font-bold py-1">
                                                                        {
                                                                            pool.unique_users_staked
                                                                        }
                                                                    </p>
                                                                ) : (
                                                                    "--"
                                                                )}
                                                            </div>
                                                            <div className="flex flex-row justify-between sm:justify-normal sm:flex-col items-end w-full sm:w-1/2">
                                                                <p className="sm:text-sm text-[#1D1D1D99] dark:text-white/60 text-[13px] font-normal py-1 w-full text-start sm:text-end">
                                                                    Claimed
                                                                    Rewards (
                                                                    {
                                                                        completedEvent
                                                                            .project
                                                                            .name
                                                                    }
                                                                    )
                                                                </p>
                                                                {login ? (
                                                                    <p className="!text-end sm:text-start font-bold py-1 sm:text-sm text-[13px] text-black dark:text-white">
                                                                        {
                                                                            pool.reward
                                                                        }
                                                                    </p>
                                                                ) : (
                                                                    "--"
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <button
                                                        onClick={() =>
                                                            showLockModal(pool)
                                                        }
                                                        className="bg-[#EFB81c] rounded-[0.375rem] w-full h-10 font-semibold my-2"
                                                    >
                                                        {login ? (
                                                            "Lock"
                                                        ) : (
                                                            <Link to="/login">
                                                                Login
                                                            </Link>
                                                        )}
                                                    </button>
                                                    <LockModal
                                                        isVisible={
                                                            isLockModalVisible
                                                        }
                                                        handleClose={
                                                            handleCancel
                                                        }
                                                        data={selectedPool}
                                                        handleButtonClick={
                                                            handleButtonClick
                                                        }
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default NewEvents;
