import React, { useEffect, useRef, useState } from "react";
import { MdOutlinePersonOutline } from "react-icons/md";
import { SiLitecoin } from "react-icons/si";
import { Link } from "react-router-dom";
import { NewEvent } from "store/actions/AssetsUserAction";
import { useSelector, useDispatch } from "react-redux";
import LockModal from "./LockModal";

function NewEvents() {
    const user = useSelector((state) => state.authUser.user);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const dispatch = useDispatch();
    const [newEvent, setNewEvent] = useState(null); // Initialize as null
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const dropdownRefs = useRef([]);
    const itemsPerPage = 2;
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                openDropdownIndex !== null &&
                dropdownRefs.current[openDropdownIndex] &&
                !dropdownRefs.current[openDropdownIndex].contains(event.target)
            ) {
                setOpenDropdownIndex(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [openDropdownIndex]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(NewEvent(userID, userToken));
                setNewEvent(response.data.data);
                console.log("API Response:", response.data.data); // Log the API response
            } catch (error) {
                console.error("Error fetching LaunchPool:", error);
            }
        };

        fetchData();
    }, [dispatch, userID, userToken]);

    useEffect(() => {
        console.log("Updated EventData:", newEvent);
    }, [newEvent]);

    if (!newEvent) {
        return <div>Loading events...</div>; // Or a better loading indicator
    }

    const totalPages = Math.ceil(newEvent.completed.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentLaunches = newEvent.completed.slice(
        indexOfFirstItem,
        indexOfLastItem
    );

    return (
        <div className="bg-[#F5F7FA] dark:bg-black pt-10">
            <div className="max-w-[1400px] w-full px-0 sm:px-[32px] mx-auto">
                <p className="text-lg sm:text-[24px] dark:text-white font-semibold my-4 px-[32px] sm:px-0">
                    Previous Launch
                </p>

                {/* Completed Events */}
                {newEvent.completed && newEvent.completed.length > 0 && (
                    <div className="mb-8">
                        {currentLaunches.map((completedEvent, eventIndex) => (
                            <div
                                key={`completed-event-${eventIndex}`}
                                className="rounded-lg bg-white dark:bg-black border-gray-400 dark:border-[#454545]/60 dark:border-r dark:border-l dark:border-b border-t-[3px] px-6 pb-6"
                            >
                                <div className="relative left-4 mb-2 flex justify-end">
                                    <span className="bg-gray-500 bg-opacity-10 font-medium text-gray-900 dark:text-white/40 px-2 rounded-bl-md">
                                        Ended
                                    </span>
                                </div>
                                <div className="px-0 sm:px-8">
                                    <Link
                                        to={`/launchpool/${completedEvent.project.id}`}
                                    >
                                        <div className="flex items-center gap-4">
                                            <img
                                                src={`https://global.buycex.com/Upload/issue/${completedEvent.project.image}`}
                                                className="w-6 sm:w-14 h-6 sm:h-14 rounded-full"
                                                alt={
                                                    completedEvent.project.name
                                                }
                                            />
                                            <div className="flex flex-col gap-1">
                                                <h3 className="font-bold dark:text-white text-xl">
                                                    {
                                                        completedEvent.project
                                                            .name
                                                    }
                                                </h3>
                                                <p className="line-clamp-2 hidden sm:block text-sm text-[#1D1D1D99] dark:text-white/60">
                                                    {
                                                        completedEvent.project
                                                            .description
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="flex flex-col sm:flex-row gap-1.5 sm:gap-3 w-full py-2 sm:py-8">
                                        <div className="flex-1 flex flex-row-reverse sm:flex-col gap-1 items-center justify-between sm:justify-center bg-white dark:bg-black dark:border-[#454545]/60 border-0 sm:border min-h-max sm:min-h-20 rounded-lg p-0 sm:p-4">
                                            <p className="text-black dark:text-white font-bold opacity-80 sm:opacity-100 text-center text-[13px] sm:text-[16px] md:text-xl">
                                                {completedEvent.pools.reduce(
                                                    (total, pool) =>
                                                        total +
                                                        (parseFloat(
                                                            pool.reward
                                                        ) || 0),
                                                    0
                                                )}
                                            </p>
                                            <p className="text-[13px] font-normal text-black dark:text-white sm:text-[#1D1D1D99] opacity-60 text-center">
                                                Total Pool Amount (
                                                {completedEvent.project.name})
                                            </p>
                                        </div>
                                        <div className="flex-1 flex flex-row-reverse sm:flex-col gap-1 items-center justify-between sm:justify-center bg-white dark:bg-black dark:border-[#454545]/60 border-0 sm:border min-h-max sm:min-h-20 rounded-lg p-0 sm:p-4">
                                            <p className="text-black dark:text-white font-bold opacity-80 sm:opacity-100 text-center text-[13px] sm:text-[16px] md:text-xl">
                                                {Math.ceil(
                                                    (new Date(
                                                        completedEvent.project.staking_period_end
                                                    ) -
                                                        new Date(
                                                            completedEvent.project.staking_period_start
                                                        )) /
                                                        (1000 * 60 * 60 * 24)
                                                )}{" "}
                                                Days
                                            </p>
                                            <p className="text-[13px] font-normal text-black dark:text-white sm:text-[#1D1D1D99] opacity-60 text-center">
                                                Event Period
                                            </p>
                                        </div>
                                        <div className="flex-1 flex flex-row-reverse sm:flex-col gap-1 items-center justify-between sm:justify-center bg-white dark:bg-black dark:border-[#454545]/60 border-0 sm:border min-h-max sm:min-h-20 rounded-lg p-0 sm:p-4">
                                            <p className="text-black dark:text-white font-bold opacity-80 sm:opacity-100 text-center text-[13px] sm:text-[16px] md:text-xl">
                                                {
                                                    completedEvent.project
                                                        .staking_period_end
                                                }
                                            </p>
                                            <p className="text-[13px] font-normal text-black dark:text-white sm:text-[#1D1D1D99] opacity-60 text-center">
                                                End Time
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap gap-3 w-full py-8">
                                        {completedEvent.pools.map(
                                            (pool, poolIndex) => (
                                                <div
                                                    key={`completed-pool-${poolIndex}`}
                                                    className="flex-1 px-2 sm:p-4 bg-white dark:bg-[#090a0c] border dark:border-[#454545]/60 dark:hover:bg-[#090a0c]/80 hover:bg-[#f7f7f7] !min-w-max sm:min-w-[340px] transition-all duration-300 ease-in-out cursor-pointer min-h-20 rounded-lg"
                                                >
                                                    <Link
                                                        to={`/launchpool/${completedEvent.project.id}`}
                                                    >
                                                        <div className="flex justify-between items-center w-full">
                                                            <div className="flex flex-col">
                                                                <div className="flex gap-1.5 items-center">
                                                                    <img
                                                                        src={`https://global.buycex.com/Upload/issue/${completedEvent.project.image}`}
                                                                        className="w-6 sm:w-14 h-6 sm:h-14 rounded-full"
                                                                        alt={
                                                                            completedEvent
                                                                                .project
                                                                                .name
                                                                        }
                                                                    />
                                                                    <h3 className="font-bold text-lg sm:text-xl">
                                                                        {
                                                                            pool.pool_name
                                                                        }
                                                                    </h3>
                                                                </div>
                                                                <div className="flex items-center gap-3 mt-4">
                                                                    <p className="flex items-center">
                                                                        <MdOutlinePersonOutline className="text-[#1D1D1D99] dark:text-white/60" />{" "}
                                                                        <span className="text-[#1D1D1D99] dark:text-white/60 text-sm">
                                                                            {
                                                                                pool.unique_users_staked
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                    <span className="bg-gray-500 dark:text-white/40 bg-opacity-10 self-start flex justify-self-start font-medium text-black px-2 rounded">
                                                                        Ended
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="border dark:border-[#454545]/60 opacity-60 h-16 sm:h-28 w-16 sm:w-28 flex items-center justify-center p-2 sm:p-1 mt-2 rounded-full">
                                                                <SiLitecoin
                                                                    fill="#10d173"
                                                                    size={32}
                                                                />
                                                                <SiLitecoin
                                                                    size={32}
                                                                    className="ml-[-2px]"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col sm:flex-row justify-between w-full mt-4 sm:mt-10">
                                                            <div className="flex flex-row justify-between sm:justify-normal sm:flex-col items-start w-full sm:w-1/2">
                                                                <p className="text-start dark:text-white/60 sm:text-sm text-[13px] opacity-80 sm:opacity-100 font-normal py-1 w-full">
                                                                    Total Pool
                                                                    Rewards (
                                                                    {
                                                                        completedEvent
                                                                            .project
                                                                            .name
                                                                    }
                                                                    )
                                                                </p>
                                                                <p className="text-end sm:text-start sm:text-sm text-[13px] text-black dark:text-white font-bold py-1">
                                                                    {
                                                                        pool.reward
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="flex flex-row justify-between sm:justify-normal sm:flex-col items-end w-full sm:w-1/2">
                                                                <p className="sm:text-sm text-[13px] dark:text-white/60 opacity-80 sm:opacity-100 font-normal py-1 w-full text-start sm:text-end">
                                                                    Total Locked
                                                                    (
                                                                    {
                                                                        pool.pool_name
                                                                    }
                                                                    )
                                                                </p>
                                                                <p className="!text-end sm:text-start font-bold py-1 sm:text-sm text-[13px] text-black dark:text-white">
                                                                    {
                                                                        pool.total_tokens_staked
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col sm:flex-row justify-between w-full mt-0 sm:mt-10"></div>
                                                    </Link>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <div className="flex justify-center dark:bg-black py-5 pb-5">
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index}
                            onClick={() => setCurrentPage(index + 1)}
                            className={`px-3 py-1 mx-1 text-xs font-semibold border dark:border-[#454545]/60 rounded ${
                                currentPage === index + 1
                                    ? "bg-yellow-500 text-black"
                                    : "bg-gray-100 text-gray-700"
                            }`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default NewEvents;
