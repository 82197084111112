import React, { useState, useEffect, useRef } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { FcGoogle } from "react-icons/fc";
import { FaRegEye, FaApple } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa6";
import signupBanner from "assets/img/auth/login.png";
import logo from "assets/img/logo/buycex-yellow.png";
import { GlobalOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";

import EmailVerification from "components/auth/validations/email/emailVerification";
import PhoneVerification from "components/auth/validations/phone/phoneVerification";

import "assets/css/auth/Register.scss";

import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
    emailSignup,
    googleRegisterNew,
    phoneSignup,
} from "store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import i18nIsoCountries from "i18n-iso-countries";
import CaptchaModal from "./captchaSlider";
import {
    getUserIP,
    getCountryCode,
} from "../../components/UserLocation/geolocationUtils";
import { useGoogleLogin } from "@react-oauth/google";
import sliderCaptchaImgCreate from "../../helpers/sliderCaptchaImgCreate";
import { Button, Flex } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import axios from "axios";
import { GoCheckCircle, GoCircle } from "react-icons/go";

i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const Signup = () => {
    const { loading } = useSelector((state) => state?.auth);
    const { isLoading, googleData } = useSelector(
        (state) => state?.googleRegister
    );
    const [email, setEmail] = useState("");
    const [emailPassword, setEmailPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [phonePassword, setPhonePassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);

    const [referralCode, setReferralCode] = useState("");
    const [showReferralInput, setShowReferralInput] = useState(false);
    const [acknowledge, setAcknowledge] = useState(true);

    const [showModal, setShowModal] = useState(false);

    const [showEmailVerification, setShowEmailVerification] = useState(false);
    const [showPhoneVerification, setShowPhoneVerification] = useState(false);
    const [showCaptchaModal, setShowCaptchaModal] = useState(false);
    const [showPhoneCaptchaModal, setShowPhoneCaptchaModal] = useState(false);
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [defaultCountry, setDefaultCountry] = useState("");
    const [nextLoading, setNextLoading] = useState(false);
    const [captchaImage, setCaptchaImage] = useState({
        bgUrl: "",
        puzzleUrl: "",
    });
    const [captchaVal, setCaptchaVal] = useState(-1);

    const dispatch = useDispatch();
    const history = useHistory();

    const formHeader = document.getElementById("register-head");

    useEffect(() => {
        const initialize = async () => {
            const ip = await getUserIP();
            if (ip) {
                const countryCode = await getCountryCode(ip);
                if (countryCode) {
                    setDefaultCountry(countryCode);
                }
            }
        };

        initialize();
    }, []);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const validateForm = () => {
        let valid = true;

        const hasUpperCase = /[A-Z]/.test(emailPassword);
        const hasNumber = /\d/.test(emailPassword);
        const hasSymbol = /[!@#$%^&*()_+]/.test(emailPassword);
        const emailUsername = email.split("@")[0];

        if (email.trim() === "") {
            toast.error("Email is required.");
            valid = false;
        } else if (emailUsername.length < 5) {
            toast.error(
                "The username part of the email must be at least 5 characters long."
            );
            valid = false;
        } else if (emailPassword.trim() === "") {
            toast.error("Password is required.");
            valid = false;
        } else if (!hasUpperCase) {
            toast.error("Password must contain at least one uppercase letter.");
            valid = false;
        } else if (!hasNumber) {
            toast.error("Password must contain at least one number.");
            valid = false;
        } else if (!hasSymbol) {
            toast.error("Password must contain at least one symbol.");
            valid = false;
        } else if (emailPassword.length < 8) {
            toast.error("Password must be at least 8 characters long.");
            valid = false;
        }

        return valid;
    };

    const validatePhoneForm = () => {
        let valid = true;

        const hasUpperCase = /[A-Z]/.test(phonePassword);
        const hasNumber = /\d/.test(phonePassword);
        const hasSymbol = /[!@#$%^&*()_+]/.test(phonePassword);

        if (phone.trim() === "") {
            toast.error("Phone Number is required.");
            valid = false;
        } else if (phonePassword.trim() === "") {
            toast.error("Password is required.");
            valid = false;
        } else if (!hasUpperCase) {
            toast.error("Password must contain at least one uppercase letter.");
            valid = false;
        } else if (!hasNumber) {
            toast.error("Password must contain at least one number.");
            valid = false;
        } else if (!hasSymbol) {
            toast.error("Password must contain at least one symbol.");
            valid = false;
        } else if (phonePassword.length < 8) {
            toast.error("Password must be at least 8 characters long.");
            valid = false;
        }

        return valid;
    };

    const toggleReferralCode = () => {
        setShowReferralInput(!showReferralInput);
    };

    const [toggler, setToggler] = useState(1);
    function updateToggle(id) {
        setToggler(id);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleEmailPasswordChange = (e) => {
        setEmailPassword(e.target.value);
    };

    const handlePhonePassChange = (e) => {
        setPhonePassword(e.target.value);
    };

    const handleEmailSignUp = (e) => {
        // e.preventDefault();

        if (!acknowledge) {
            toast.dismiss("error");
            toast.error(
                "You must agree to the Terms of Service and Privacy Policy",
                {
                    id: "error",
                }
            );
            return;
        }

        const emailUsername = email.split("@")[0].replace(/\./g, "");

        let payload = {
            username: emailUsername,
            email: email,
            password: emailPassword,
            invit: referralCode,
        };

        localStorage.setItem("email", email);
        localStorage.setItem("password", emailPassword);
        localStorage.setItem("username", emailUsername);
        localStorage.setItem("invit", referralCode);
        dispatch(
            emailSignup(payload, () => {
                setShowEmailVerification(true);
                formHeader.style.display = "none";
            })
        );
    };

    const handlePhoneSignup = (e) => {
        // e.preventDefault();

        if (!acknowledge) {
            toast.dismiss("error");
            toast.error(
                "You must agree to the Terms of Service and Privacy Policy",
                {
                    id: "error",
                }
            );
            return;
        }

        const numericPart = phone.replace(/^\+/, "");
        const phoneUsername = `user${numericPart}`;
        console.log("phoneUsername", phoneUsername);

        let payload = {
            username: phoneUsername,
            phone: phone,
            password: phonePassword,
            invit: referralCode,
        };
        localStorage.setItem("phone", phone);
        localStorage.setItem("username", phoneUsername);
        localStorage.setItem("password", phonePassword);
        localStorage.setItem("invit", referralCode);

        // console.log("Payload of Signup", payload);
        dispatch(
            phoneSignup(payload, () => {
                // toast.success("Please verify your phone number!");
                setShowPhoneVerification(true);
                formHeader.style.display = "none";
            })
        );
    };

    const handleReferralCode = (e) => {
        setReferralCode(e.target.value);
    };

    const handleAcknowledge = (e) => {
        setAcknowledge(!acknowledge);
    };

    useEffect(() => {
        // Retrieve input value from localStorage
        const storedInput = localStorage.getItem("signupInput");
        if (storedInput) {
            // Check if input is an email or phone number
            if (storedInput.includes("@")) {
                setEmail(storedInput); // Set email state
            } else {
                setPhone(storedInput); // Set phone state
            }
        }
    }, []);

    const handleCaptchaSuccess = () => {
        setCaptchaVerified(true);
        setShowCaptchaModal(false);
        handleEmailSignUp();
        setShowEmailVerification(true);
    };

    const handlePhoneCaptchaSuccess = () => {
        setCaptchaVerified(true);
        setShowPhoneCaptchaModal(false);
        handlePhoneSignup();
    };

    const handleCaptchaFailure = () => {
        setCaptchaVerified(false);
        // setShowCaptchaModal(false);
    };

    const handlePhoneCaptchaFailure = () => {
        setCaptchaVerified(false);
        // setShowCaptchaModal(false);
    };

    const showCaptchaModel = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        if (!acknowledge) {
            toast.error("You must agree to the terms and conditions.");
            return;
        }

        await refetchCaptcha();

        await setNextLoading(false);
        await setShowCaptchaModal(true);
    };

    const refetchCaptcha = async () => {
        await setShowCaptchaModal(false);
        await setNextLoading(true);
        const val = await sliderCaptchaImgCreate();
        await setCaptchaVal(val.left);

        await setCaptchaImage({
            bgUrl: val.bg,
            puzzleUrl: val.puzzle,
        });
    };

    const showPhoneCaptchaModel = async (e) => {
        e.preventDefault();
        if (!validatePhoneForm()) return;

        if (!acknowledge) {
            toast.error("You must agree to the terms and conditions.");
            return;
        }
        await setShowPhoneCaptchaModal(false);
        await refetchCaptcha();
        await setNextLoading(false);
        await setShowPhoneCaptchaModal(true);
    };
    // useEffect(() => {
    //     // dispatch(googleLogin());
    // }, [dispatch]);

    const register = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            console.log("Login successful, codeResponse:", codeResponse);
            const { access_token } = codeResponse;

            try {
                const response = await axios.get(
                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                            Accept: "application/json",
                        },
                    }
                );
                const profileData = response.data;
                const email = profileData.email;

                // Dispatch registration action with both email and username
                dispatch(googleRegisterNew(email, () => history.push("/")));
            } catch (error) {
                console.log("Profile fetch error:", error);
                dispatch({
                    type: "FETCH_GOOGLE_REGISTER_FAILURE",
                    payload: error.message,
                });
            }
        },
        onError: (error) => {
            console.log("Login Failed:", error);
            dispatch({
                type: "FETCH_GOOGLE_REGISTER_FAILURE",
                payload: error.message,
            });
        },
    });

    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [isPhoneTooltipVisible, setIsPhoneTooltipVisible] = useState(false);

    // Password validation check
    const isUpperCase = /[A-Z]/.test(emailPassword);
    const isLowerCase = /[a-z]/.test(emailPassword);
    const isSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(emailPassword);
    const isValidLength = emailPassword.length >= 8 && emailPassword.length <= 30;
    const isNumber = /[0-9]/.test(emailPassword);

    //Phone Password validation check
    const isPhoneUpperCase = /[A-Z]/.test(phonePassword);
    const isPhoneLowerCase = /[a-z]/.test(phonePassword);
    const isPhoneSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(phonePassword);
    const isPhoneValidLength = phonePassword.length >= 8 && phonePassword.length <= 30;
    const isPhoneNumber = /[0-9]/.test(phonePassword);

    const passwordInputRef = useRef(null);
    const phonepasswordInputRef = useRef(null);
    const tooltipRef = useRef(null);
    const phonetooltipRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                passwordInputRef.current &&
                !passwordInputRef.current.contains(event.target) &&
                tooltipRef.current &&
                !tooltipRef.current.contains(event.target)
            ) {
                setIsTooltipVisible(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    useEffect(() => {
        const handlePhoneClickOutside = (event) => {
            if (
                phonepasswordInputRef.current &&
                !phonepasswordInputRef.current.contains(event.target) &&
                phonetooltipRef.current &&
                !phonetooltipRef.current.contains(event.target)
            ) {
                setIsPhoneTooltipVisible(false);
            }
        };

        document.addEventListener("click", handlePhoneClickOutside);

        return () => {
            document.removeEventListener("click", handlePhoneClickOutside);
        };
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            const header = document.querySelector('.buycex-master__header');
            if (header) {
                if (window.scrollY > 0) {
                    header.classList.add('header-sticky');
                } else {
                    header.classList.remove('header-sticky');
                }
            }
        };

        // Add scroll event listener on mount
        window.addEventListener('scroll', handleScroll);

        // Cleanup scroll event listener on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])
    

    useEffect(() => {
        const storedEmail = localStorage.getItem("userEmail");
        const storedPhone = localStorage.getItem("userPhone");
    
        if (storedEmail) {
          setEmail(storedEmail);
          setToggler(1);
          localStorage.removeItem("userEmail");
        } else if (storedPhone) {
          setPhone(storedPhone);
          setToggler(2);
          localStorage.removeItem("userPhone");
        }
      }, []);

    return (
        <>
            <header className="buycex-master__header hidden">Header Content</header>
            <div className="flex flex-col items-center justify-between w-full min-h-[90vh] h-[100vh]">
                <div
                    className={`${showCaptchaModal ? "block" : "hidden"
                        } absolute h-[100vh] bg-black/60  w-full z-10`}
                ></div>
                <div className="rounded-2xl text-center flex-col items-center justify-center flex border mt-4  sm:mt-[45px]  inset-0 mx-auto dark:bg-[#000000] w-[90%] sm:w-[420px]">
                    <div className="flex lg:items-center w-full px-6 py-0 sm:py-4 rounded-3xl bg-[#FFFFFF] dark:bg-[#1A191B] mb-6">
                        {/* <div className="overflow-hidden rounded-md register__img__wrapper max-lg:hidden lg:w-1/2 md:w-1/2 sm:w-full">
                        <img
                            src={signupBanner}
                            className="object-cover scale-[1.06]"
                            alt="BUYCEX"
                            title="BUYCEX Official"
                        />
                    </div> */}
                        <div className="flex items-center justify-center w-full text-center ">
                            <div className="w-full">
                                <div id="register-head" className="flex-col">
                                    <div className="flex flex-col items-start sm:items-center justify-center w-full gap-4 my-3 text-start sm:text-center">
                                        <img
                                            src={logo}
                                            alt=""
                                            className="bg-white/90 sm:w-[250px] w-[200px]"
                                        />

                                        <p className="text-[14px] font-bold">
                                            Welcome To BuyCex Community
                                        </p>
                                        <h1 className="text-2xl font-bold">
                                            Register
                                        </h1>
                                    </div>
                                    {/* Toggle Button Section */}
                                    <div className="mt-2 mb-4 form-tabs">
                                        <div className="border-b gap-3 border-[#F4F4F4] flex items-center">
                                            <button
                                                className={`cursor-pointer font-bold pb-1.5 text-[14px] border-b-2 transition-all ${toggler === 1
                                                    ? "border-[#EFB81C]"
                                                    : "border-transparent text-[#999]"
                                                    }`}
                                                onClick={() => updateToggle(1)}
                                                role="tab"
                                                aria-selected={toggler === 1}
                                            >
                                                Email
                                            </button>
                                            <button
                                                className={`text-[14px] font-bold pb-1.5 border-b-2 transition-all ${toggler === 2
                                                    ? "border-[#EFB81C] font-bold"
                                                    : "border-transparent text-[#999]"
                                                    }`}
                                                onClick={() => updateToggle(2)}
                                                role="tab"
                                                aria-selected={toggler === 2}
                                                type="button"
                                            // disabled
                                            >
                                                Mobile
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {!showEmailVerification ? (
                                    <div
                                        className={
                                            toggler === 1
                                                ? "block form-field-wrapper"
                                                : "hidden"
                                        }
                                    >
                                        <form
                                            onSubmit={showCaptchaModel}
                                            encType="multipart/form-data"
                                        >
                                            <div className="flex flex-col items-start justify-between w-full gap-4 h-fit">
                                                <div className="flex flex-col items-start justify-start w-full">
                                                    <input
                                                        className="w-full px-3 py-2 bg-transparent placeholder:text-gray-300 placeholder:text-[14px] focus:ring-0 ring-0 hover:border-[#EFB81C] focus:outline-none border border-[#151415]/20 dark:border-[#F4F4F4] focus:border-red-400 rounded-md"
                                                        placeholder="Enter your Email"
                                                        type="email"
                                                        autoComplete="email"
                                                        id="email"
                                                        value={email}
                                                        onChange={
                                                            handleEmailChange
                                                        }
                                                    />
                                                </div>
                                                <div className="relative flex flex-col w-full">
                                                    {/* <label
                                                        htmlFor=""
                                                        className="flex items-center justify-start w-full"
                                                    >
                                                        Password
                                                    </label> */}
                                                    <input
                                                        ref={passwordInputRef}
                                                        type={
                                                            passwordVisible
                                                                ? "text"
                                                                : "password"
                                                        }
                                                        id="emailPassword"
                                                        name="emailPassword"
                                                        autoComplete="new-password webauthn"
                                                        placeholder="Enter your Password"
                                                        className="w-full px-3 py-2 bg-transparent placeholder:text-gray-300 placeholder:text-[14px] focus:ring-0 ring-0 hover:border-[#EFB81C] focus:outline-none border border-[#151415]/20 dark:border-[#F4F4F4] focus:border-red-400 rounded-md"
                                                        value={emailPassword}
                                                        onChange={
                                                            handleEmailPasswordChange
                                                        }
                                                        onFocus={() => setIsTooltipVisible(true)}
                                                        onBlur={() => setIsTooltipVisible(false)}
                                                    />
                                                    {/* Tooltip */}
                                                    {isTooltipVisible && (


                                                        <div
                                                            ref={tooltipRef}
                                                            className={`absolute top-[calc(100%+5px)] z-30 left-0 w-full p-2 bg-white shadow-md text-white text-sm rounded-md transition-all duration-300 opacity-0 ${emailPassword ? "opacity-100" : ""
                                                                }`}
                                                        >
                                                            <ul>
                                                                <li className={`flex mb-1 items-center ${isValidLength ? "text-green-400" : "text-gray-400"}`}>
                                                                    <span className="mr-2">{isValidLength ? <GoCheckCircle size={16} /> : <GoCircle size={16} />}</span> 8-30 Characters
                                                                </li>
                                                                <li className={`flex mb-1 items-center ${isNumber ? "text-green-400" : "text-gray-400"}`}>
                                                                    <span className="mr-2">{isNumber ? <GoCheckCircle size={16} /> : <GoCircle size={16} />}</span> At least one Number
                                                                </li>
                                                                <li className={`flex mb-1 items-center ${isUpperCase ? "text-green-400" : "text-gray-400"}`}>
                                                                    <span className="mr-2">{isUpperCase ? <GoCheckCircle size={16} /> : <GoCircle size={16} />}</span> At least one Uppercase letter
                                                                </li>
                                                                <li className={`flex mb-1 items-center ${isLowerCase ? "text-green-400" : "text-gray-400"}`}>
                                                                    <span className="mr-2">{isLowerCase ? <GoCheckCircle size={16} /> : <GoCircle size={16} />}</span> At least one Lowercase letter
                                                                </li>
                                                                <li className={`flex mb-1 items-center ${isSpecialChar ? "text-green-400" : "text-gray-400"}`}>
                                                                    <span className="mr-2">{isSpecialChar ? <GoCheckCircle size={16} /> : <GoCircle size={16} />}</span> At least one Special character
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )}
                                                    <button
                                                        type="button"
                                                        className="absolute bottom-1 right-1 p-2 cursor-pointer text-[#c4c4c4]"
                                                        onClick={
                                                            togglePasswordVisibility
                                                        }
                                                    >
                                                        {passwordVisible ? (
                                                            <FaRegEye />
                                                        ) : (
                                                            <FaRegEyeSlash />
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="flex flex-col mt-3.5">
                                                <div className="referral-code__wrapper">
                                                    <label
                                                        htmlFor="referral-code"
                                                        className="text-[14px] font-medium cursor-pointer flex items-center w-max"
                                                        onClick={
                                                            toggleReferralCode
                                                        }
                                                    >
                                                        Referral Code (Optional)
                                                        <IoMdArrowDropdown />
                                                    </label>
                                                    {showReferralInput && (
                                                        <input
                                                            className="w-full px-3 py-2 mb-3 bg-transparent placeholder:text-gray-300 placeholder:text-[14px] focus:ring-0 ring-0 hover:border-[#EFB81C] focus:outline-none border border-[#151415]/20 dark:border-[#F4F4F4] focus:border-red-400 rounded-md"
                                                            placeholder="Enter invitation code"
                                                            type="text"
                                                            id="invit"
                                                            name="invit"
                                                            onChange={
                                                                handleReferralCode
                                                            }
                                                            value={referralCode}
                                                        />
                                                    )}
                                                </div>
                                                <div className="relative flex items-center mt-4">
                                                    <label id="checkbox-tick">
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                acknowledge
                                                            }
                                                            onChange={
                                                                handleAcknowledge
                                                            }
                                                        />
                                                        <div className="checkbox__check-mark"></div>
                                                    </label>
                                                    <p className=" text-[12px] font-normal pt-2 !text-start">
                                                        I am agreeing to
                                                        Buycex's
                                                        <Link
                                                            to="/terms-and-condition"
                                                            className="text-[12px] mx-1 underline text-[#EFB81C]"
                                                            title="Terms of Service"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Terms of Service
                                                        </Link>{" "}
                                                        and
                                                        <Link
                                                            to="/privacy-policy"
                                                            className="text-[12px] mx-1 underline text-[#EFB81C]"
                                                            title="Privacy Policy"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Privacy Policy
                                                        </Link>{" "}
                                                        by clicking 'Register'
                                                    </p>
                                                </div>
                                                {nextLoading ? (
                                                    <Button
                                                        className="login-button bg-[#EFB81C] hover:bg-[#f1bb19db] text-[#151415] mt-3 py-[10px] w-full rounded-md font-medium"
                                                        type="submit"
                                                        icon={
                                                            <PoweroffOutlined />
                                                        }
                                                        loading
                                                        size="large"
                                                    >
                                                        Loading...
                                                    </Button>
                                                ) : (
                                                    <button
                                                        className={`register-button mt-5 py-[10px] w-full  rounded-md font-medium ${!acknowledge
                                                            ? "cursor-not-allowed bg-[#F4F4F4]"
                                                            : "bg-[#EFB81C] hover:bg-[#f1bb19db] text-[#151415]"
                                                            }`}
                                                        type="submit"
                                                        onClick={
                                                            showCaptchaModel
                                                        }
                                                        disabled={!acknowledge}
                                                    >
                                                        {/* {loading ? <Spinner size="sm" /> : "Register"} */}
                                                        {loading
                                                            ? "Loading..."
                                                            : "Next"}
                                                    </button>
                                                )}
                                            </div>
                                        </form>
                                        <div className="flex flex-col gap-6 mt-4 register-form__footer">
                                            <div className="flex items-center w-full gap-4">
                                                <a
                                                    // href={
                                                    //     isLoading ? "" : googleData
                                                    // }
                                                    className="w-full hover:text-black"
                                                >
                                                    <button
                                                        onClick={() =>
                                                            register()
                                                        }
                                                        className="footer__google-btn inline-flex items-center text-nowrap text-[14px] border border-gray-300 w-full rounded-md px-6 py-2.5 dark:text-white justify-start hover:dark:bg-[#000000] font-bold "
                                                    >
                                                        <FcGoogle className="w-[18px] h-[18px] me-2" />
                                                        <span className="ml-2 md:ml-4 lg:ml-24">
                                                            Continue With Google
                                                        </span>
                                                    </button>
                                                </a>
                                            </div>
                                            <Link
                                                className="text-[#EFB81C] text-[22px] font-bold hover:underline"
                                                to="/login"
                                            >
                                                Login Now
                                            </Link>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                    </>
                                )}

                                {!showPhoneVerification ? (
                                    <div
                                        className={
                                            toggler === 2
                                                ? "block form-field-wrapper"
                                                : "hidden"
                                        }
                                    >
                                        <form
                                            onSubmit={showPhoneCaptchaModel}
                                            encType="multipart/form-data"
                                        >
                                            <div className="flex flex-col items-start justify-between w-full gap-4 h-fit">
                                                <div className="flex flex-col items-start justify-start w-full">
                                                    <PhoneInput
                                                        country={defaultCountry}
                                                        value={phone}
                                                        enableSearch={true}
                                                        onChange={(phone) =>
                                                            setPhone(phone)
                                                        }
                                                        inputClass="w-full px-3 py-2 bg-transparent placeholder:text-gray-300 placeholder:text-[14px] focus:ring-0 ring-0 hover:border-[#EFB81C] focus:outline-none border border-[#151415] dark:border-[#F4F4F4] focus:border-red-400 rounded-md"
                                                        inputProps={{
                                                            name: "phone",
                                                            id: "phone",
                                                            autoComplete:
                                                                "phone",
                                                            autoFocus: true,
                                                        }}
                                                        containerClass="w-full flex"
                                                        placeholder="Enter your phone"
                                                    />
                                                </div>
                                                <div className="relative flex flex-col w-full">
                                                    {/* <label
                                                        htmlFor=""
                                                        className="flex items-center justify-start w-full"
                                                    >
                                                        Password
                                                    </label> */}
                                                    <input
                                                        ref={phonepasswordInputRef}
                                                        type={
                                                            passwordVisible
                                                                ? "text"
                                                                : "password"
                                                        }
                                                        id="phonePassword"
                                                        name="phonePassword"
                                                        autoComplete="new-password webauthn"
                                                        placeholder="Enter your password"
                                                        className="w-full px-3 py-2 bg-transparent placeholder:text-gray-300 placeholder:text-[14px] focus:ring-0 ring-0 hover:border-[#EFB81C] focus:outline-none border border-[#151415]/30 dark:border-[#F4F4F4] focus:border-red-400 rounded-md"
                                                        value={phonePassword}
                                                        onChange={
                                                            handlePhonePassChange
                                                        }
                                                        onFocus={() => setIsPhoneTooltipVisible(true)}
                                                        onBlur={() => setIsPhoneTooltipVisible(false)}
                                                    />
                                                    {/* Tooltip */}
                                                    {isPhoneTooltipVisible && (


                                                        <div
                                                            ref={phonetooltipRef}
                                                            className={`absolute top-[calc(100%+5px)] z-30 left-0 w-full p-2 bg-white shadow-md text-white text-sm rounded-md transition-all duration-300 opacity-100"
                                                                }`}
                                                        >
                                                            <ul>
                                                                <li className={`flex mb-1 items-center ${isPhoneValidLength ? "text-green-400" : "text-gray-400"}`}>
                                                                    <span className="mr-2">{isPhoneValidLength ? <GoCheckCircle size={16} /> : <GoCircle size={16} />}</span> 8-30 Characters
                                                                </li>
                                                                <li className={`flex mb-1 items-center ${isPhoneNumber ? "text-green-400" : "text-gray-400"}`}>
                                                                    <span className="mr-2">{isPhoneNumber ? <GoCheckCircle size={16} /> : <GoCircle size={16} />}</span> At least one number
                                                                </li>
                                                                <li className={`flex mb-1 items-center ${isPhoneUpperCase ? "text-green-400" : "text-gray-400"}`}>
                                                                    <span className="mr-2">{isPhoneUpperCase ? <GoCheckCircle size={16} /> : <GoCircle size={16} />}</span> At least one uppercase letter
                                                                </li>
                                                                <li className={`flex mb-1 items-center ${isPhoneLowerCase ? "text-green-400" : "text-gray-400"}`}>
                                                                    <span className="mr-2">{isPhoneLowerCase ? <GoCheckCircle size={16} /> : <GoCircle size={16} />}</span> At least one lowercase letter
                                                                </li>
                                                                <li className={`flex mb-1 items-center ${isPhoneSpecialChar ? "text-green-400" : "text-gray-400"}`}>
                                                                    <span className="mr-2">{isPhoneSpecialChar ? <GoCheckCircle size={16} /> : <GoCircle size={16} />}</span> At least one special character
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )}
                                                    <button
                                                        type="button"
                                                        className="absolute bottom-1 right-1 p-2 cursor-pointer text-[#c4c4c4]"
                                                        onClick={
                                                            togglePasswordVisibility
                                                        }
                                                    >
                                                        {passwordVisible ? (
                                                            <FaRegEye />
                                                        ) : (
                                                            <FaRegEyeSlash />
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="flex flex-col mt-3.5">
                                                <div className="referral-code__wrapper">
                                                    <label
                                                        htmlFor="referral-code"
                                                        className="text-[14px] font-medium cursor-pointer flex items-center w-max"
                                                        onClick={
                                                            toggleReferralCode
                                                        }
                                                    >
                                                        Referral Code (Optional)
                                                        <IoMdArrowDropdown />
                                                    </label>
                                                    {showReferralInput && (
                                                        <input
                                                            className="w-full px-3 py-2 mb-3 bg-transparent placeholder:text-gray-300 placeholder:text-[14px] focus:ring-0 ring-0 hover:border-[#EFB81C] focus:outline-none border border-[#151415]/30 dark:border-[#F4F4F4] focus:border-red-400 rounded-md"
                                                            placeholder="Enter invitation code"
                                                            type="text"
                                                            id="invit"
                                                            name="invit"
                                                            onChange={
                                                                handleReferralCode
                                                            }
                                                            value={referralCode}
                                                        />
                                                    )}
                                                </div>
                                                <div className="relative flex items-center mt-4">
                                                    <label id="checkbox-tick">
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                acknowledge
                                                            }
                                                            onChange={
                                                                handleAcknowledge
                                                            }
                                                        />
                                                        <div className="checkbox__check-mark"></div>
                                                    </label>
                                                    <p className=" text-[12px] font-normal pt-2   !text-start">
                                                        I am agreeing to
                                                        Buycex's
                                                        <Link
                                                            to="/terms-and-condition"
                                                            className="text-[12px] mx-1 underline text-[#EFB81C]"
                                                            title="Terms of Service"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Terms of Service
                                                        </Link>{" "}
                                                        and
                                                        <Link
                                                            to="/privacy-policy"
                                                            className="text-[12px] mx-1 underline text-[#EFB81C]"
                                                            title="Privacy Policy"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Privacy Policy
                                                        </Link>{" "}
                                                        by clicking 'Register'
                                                    </p>
                                                </div>
                                                {nextLoading ? (
                                                    <Button
                                                        className="login-button bg-[#EFB81C] hover:bg-[#f1bb19db] text-[#151415] mt-3 py-[10px] w-full rounded-md font-medium"
                                                        type="submit"
                                                        icon={
                                                            <PoweroffOutlined />
                                                        }
                                                        loading
                                                        size="large"
                                                    >
                                                        Loading...
                                                    </Button>
                                                ) : (
                                                    <button
                                                        className={`register-button mt-5 py-[10px] w-full rounded-md font-medium ${!acknowledge
                                                            ? "cursor-not-allowed bg-[#F4F4F4]"
                                                            : "bg-[#EFB81C] hover:bg-[#f1bb19db] text-[#151415]"
                                                            }`}
                                                        type="submit"
                                                        onClick={
                                                            showPhoneCaptchaModel
                                                        }
                                                        disabled={!acknowledge}
                                                    >
                                                        {/* {loading ? <Spinner size="sm" /> : "Register"} */}
                                                        {loading
                                                            ? "Loading..."
                                                            : "Next"}
                                                    </button>
                                                )}
                                            </div>
                                        </form>
                                        <div className="flex flex-col gap-6 mt-4">
                                            <div className="flex items-center w-full gap-4">
                                                <a
                                                    // href={
                                                    //     isLoading ? "" : googleData
                                                    // }
                                                    className="w-full hover:text-black"
                                                >
                                                    {" "}
                                                    <button
                                                        onClick={() =>
                                                            register()
                                                        }
                                                        className="footer__google-btn inline-flex items-center text-nowrap text-[14px] border border-gray-300 w-full rounded-md px-6 py-2.5 dark:text-white justify-start hover:dark:bg-[#000000] font-bold "
                                                    >
                                                        <FcGoogle className="w-[18px] h-[18px] me-2" />
                                                        <span className="ml-2 md:ml-4 lg:ml-24">
                                                            Continue With Google
                                                        </span>
                                                    </button>
                                                </a>
                                            </div>
                                            <Link
                                                className="text-[#EFB81C] text-[22px] font-bold hover:underline"
                                                to="/login"
                                            >
                                                Login Now
                                            </Link>
                                        </div>
                                    </div>
                                ) : (
                                    <PhoneVerification
                                        email={email}
                                        onClick={() =>
                                            setShowPhoneVerification(false)
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {showCaptchaModal && (
                    <CaptchaModal
                        isOpen={showCaptchaModal}
                        onClose={() => setShowCaptchaModal(false)}
                        onSuccess={handleCaptchaSuccess}
                        onFailure={handleCaptchaFailure}
                        captchaVal={captchaVal}
                        captchaImage={captchaImage}
                        getCaptchaHandler={showCaptchaModel}
                    />
                )}
                {showPhoneCaptchaModal && (
                    <CaptchaModal
                        isOpen={showPhoneCaptchaModal}
                        onClose={() => setShowPhoneCaptchaModal(false)}
                        onSuccess={handlePhoneCaptchaSuccess}
                        onFailure={handlePhoneCaptchaFailure}
                        captchaVal={captchaVal}
                        captchaImage={captchaImage}
                        getCaptchaHandler={showPhoneCaptchaModel}
                    />
                )}
                {showEmailVerification && (
                    <EmailVerification
                        email={email}
                        onClose={() =>
                            setShowEmailVerification(false)
                        }
                    />
                )}
                <div className="flex justify-center text-sm font-semibold space-x-6 text-gray-500 mb-2">
                    <a href="#" className="cursor-pointer hover:text-gray-700">
                        <GlobalOutlined /> English
                    </a>
                    <a href="#" className="cursor-pointer hover:text-gray-700">
                        Cookies
                    </a>
                    <a href="/terms-and-condition" className="cursor-pointer hover:text-gray-700">
                        Terms
                    </a>
                    <a href="/privacy-policy" className="cursor-pointer hover:text-gray-700">
                        Privacy
                    </a>
                </div>
            </div>
        </>
    );
};

export default Signup;
