import React, { useEffect, useState } from "react";
import AssetDropdown from './DropDownUI';
import NoData from "assets/img/user/No-data.svg";
import { useDispatch, useSelector } from "react-redux";
import { FinanceOpenOrders } from "store/actions/AssetsUserAction";

const OpenOrder = () => {
   const dispatch = useDispatch();
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const [orderListData, setOrderListData] = useState([]);
    const [marketListData, setMarketListData] = useState([]);
    const [filteredOrderListData, setFilteredOrderListData] = useState([]);

     useEffect(() => {
        if (!marketListData.length && userID && userToken) {
            const payload = { user_id: userID };
            dispatch(FinanceOpenOrders(userID, userToken, payload))
                .then((response) => {
                    if (response) {
                        const orderList = response.data?.data?.entrust || [];
                        const marketList = response.data?.data?.market_list || [];
                        setOrderListData(orderList);
                        setMarketListData(marketList);

                        // Sort by date and limit to the latest 7 records
                        const latestOrders = orderList
                            .sort((a, b) => new Date(b.addtime) - new Date(a.addtime))
                            .slice(0, 15);

                        setFilteredOrderListData(latestOrders);
                    }
                });
        }
    }, [dispatch, userID, userToken]);

    const getMarketName = (market) => {
        const marketName = Object.values(marketListData).find((item) => item.name === market);
        if (!marketName || !marketName.xnb || !marketName.rmb) return null;
        return `${marketName.rmb.toUpperCase()}/${marketName.xnb.toUpperCase()}`;
    };

    return (
        <div className="w-full overflow-x-auto">
            <table className="w-full border-collapse border border-gray-200 bg-white dark:bg-gray-800">
                <thead className="bg-gray-100 dark:bg-gray-700 sticky top-0">
                    <tr className="text-left">
                        <th className="px-4 py-2 text-sm font-semibold text-gray-700 dark:text-gray-300">Date</th>
                        <th className="px-4 py-2 text-sm font-semibold text-gray-700 dark:text-gray-300">Pair</th>
                        <th className="px-4 py-2 text-sm font-semibold text-gray-700 dark:text-gray-300">Type</th>
                        <th className="px-4 py-2 text-sm font-semibold text-gray-700 dark:text-gray-300">Side</th>
                        <th className="px-4 py-2 text-sm font-semibold text-gray-700 dark:text-gray-300">Price</th>
                        <th className="px-4 py-2 text-sm font-semibold text-gray-700 dark:text-gray-300">Amount</th>
                        <th className="px-4 py-2 text-sm font-semibold text-gray-700 dark:text-gray-300">Amount per Iceberg Order</th>
                        <th className="px-4 py-2 text-sm font-semibold text-gray-700 dark:text-gray-300">Dealt</th>
                        <th className="px-4 py-2 text-sm font-semibold text-gray-700 dark:text-gray-300">Total</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
                    {filteredOrderListData.length > 0 ? (
                        filteredOrderListData.map((order) => (
                            <tr
                                key={order.id}
                                className="hover:bg-gray-50 dark:hover:bg-gray-700 transition"
                            >
                                <td className="px-4 py-2 text-gray-800 dark:text-gray-200">{order.addtime}</td>
                                <td className="px-4 py-2 text-gray-800 dark:text-gray-200">
                                    {getMarketName(order.market)}
                                </td>
                                <td className="px-4 py-2">
                                    <span
                                        className={`${
                                            order.tradetype === "Buy" ? "text-red-500" : "text-green-500"
                                        } font-semibold`}
                                    >
                                        {order.tradetype}
                                    </span>
                                </td>
                                <td className="px-4 py-2">
                                    <span
                                        className={`${
                                            order.type === "1" ? "text-red-500" : "text-green-500"
                                        } font-semibold`}
                                    >
                                        {order.type === "1" ? "Buy" : "Sell"}
                                    </span>
                                </td>
                                <td className="px-4 py-2 text-gray-800 dark:text-gray-200">{order.price}</td>
                                <td className="px-4 py-2 text-gray-800 dark:text-gray-200">{order.num}</td>
                                <td className="px-4 py-2 text-gray-800 dark:text-gray-200">{order.condition}</td>
                                <td className="px-4 py-2 text-gray-800 dark:text-gray-200">{order.deal}</td>
                                <td className="px-4 py-2 text-gray-800 dark:text-gray-200">
                                    {(order.price * order.num).toFixed(2)}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="9" className="text-center py-10">
                                <div className="flex flex-col items-center">
                                    <img src={NoData} alt="No Data" className="w-24 mb-4" />
                                    <p className="text-gray-500 text-sm">No orders available</p>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default OpenOrder;
