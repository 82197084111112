import React, { useState } from "react";
import { BsX } from "react-icons/bs";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { doTrade } from "store/actions/buySellAction";
import toast from "react-hot-toast";

const BuyOrderDetails = ({ closeBuyDetailsModal, orderDetails, selectedCoin }) => {
    const dispatch = useDispatch();
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const handleConfirmTrade = async () => {
        if (!TOKEN || !ID) {
            toast.error("User authentication failed. Please log in.");
            return;
        }

        setIsLoading(true);

        const payload = {
            marketId: selectedCoin.ticker,
            price: orderDetails.selectedCoinBuyPrice,
            num: orderDetails.orderQuantity,
            type: orderDetails.orderAction.toLowerCase() === "buy" ? 1 : 2, // 1 for Buy, 2 for Sell
            tradetype: orderDetails.orderActionType.toLowerCase(), // Ensure lowercase
        };
        

        try {
            const response = await dispatch(doTrade(payload, TOKEN, ID));
            console.log("orderDetails",response)
            if (response?.status === 200 && response?.data?.status === 1) {
               
                closeBuyDetailsModal(); // Close the modal upon success
            } else {
                toast.error(response?.data?.data || "Trade failed. Please try again.");
            }
        } catch (error) {
            console.error("Trade execution error:", error);
            toast.error("Trade failed. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full backdrop-blur-[0.5px] z-[99999] bg-black/30">
            <div className="modal-content bg-[#FFFFFF] w-[250px] md:w-[300px] lg:w-[450px] rounded-md h-auto p-4 flex flex-col gap-3">
                <div className="flex items-center justify-between w-full gap-1">
                    <h3 className="text-[14px] w-full font-bold flex items-center gap-2">
                        <span className="text-[16px]">{orderDetails.orderAction}</span>
                        <span className="text-[16px]">{orderDetails.orderActionType}</span>
                        <span className="text-[16px]">{orderDetails.selectedCoinName}</span>
                    </h3>
                    <button type="button" className="h-auto w-max" onClick={closeBuyDetailsModal}>
                        <BsX size={20} />
                    </button>
                </div>

                <div className="flex flex-col w-full gap-2.5 modal-data-table">
                    <div className="flex items-center justify-between gap-2">
                        <div className="fields text-[13px] font-bold text-[#15141590]">
                            Order Price
                        </div>
                        <div className="fields-data text-[12px] font-[500]">
                            ${orderDetails.selectedCoinBuyPrice}{" "}
                            {selectedCoin.ticker.toUpperCase().replace("_", "/").split("/")[1]}
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-2">
                        <div className="fields text-[13px] font-bold text-[#15141590]">
                            Quantity
                        </div>
                        <div className="fields-data text-[12px] font-[500]">
                            {orderDetails.orderQuantity} {orderDetails.selectedCoinName}
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-2">
                        <div className="fields text-[13px] font-bold text-[#15141590]">
                            Total Value
                        </div>
                        <div className="fields-data text-[12px] font-[500]">
                            ${orderDetails.totalOrderValue}{" "}
                            {selectedCoin.ticker.toUpperCase().replace("_", "/").split("/")[1]}
                        </div>
                    </div>
                </div>

                {/* Buttons for cancel and confirm order */}
                <div className="flex justify-between gap-4 mt-2 modal-confirm-btn">
                    <button
                        type="button"
                        className="px-4 w-full rounded-md py-2 font-[500] text-[#151415] bg-[#EF454A]"
                        onClick={closeBuyDetailsModal}
                        disabled={isLoading}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className={`px-4 w-full rounded-md py-2 font-[500] text-white ${
                            isLoading ? "bg-gray-400 cursor-not-allowed" : "bg-[#20B26C]"
                        }`}
                        onClick={handleConfirmTrade}
                        disabled={isLoading}
                    >
                        {isLoading ? "Processing..." : "Confirm"}
                    </button>
                </div>
            </div>
        </div>
    );
};

BuyOrderDetails.propTypes = {
    closeBuyDetailsModal: PropTypes.func.isRequired,
    orderDetails: PropTypes.object.isRequired,
    selectedCoin: PropTypes.object.isRequired,
};

export default BuyOrderDetails;
