import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { BsArrowClockwise, BsPlusCircle } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types"; // Importing PropTypes

import { useSelectedCoin } from "../context/SelectedCoinContext";

import useMakerTakerConditions from "../hooks/useMakerTakerConditions";

import AddFundModal from "./modals/AddFundModal";
import BuyDetails from "./modals/BuyOrderDetails";
import { toast } from "react-toastify";
import BuyOrderDetails from "./modals/BuyOrderDetails";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CoverTradeModel from "./modals/CoverTradeModel";
import TradeSlider from "./TradeSlider";
import { fetchData } from "store/actions/tradeAction";

const TradeAreaSpot = ({
    orderAction,
    orderActionType,
    setShowDepositModel,
    setShowTransferModel,
}) => {
    // Get market overview data of each market coin
    const { makerTakerData, loading, error } = useMakerTakerConditions();
    const location = useLocation();
    const [perpetualTrade, setPerpetualTrade] = useState(false);
    const dispatch = useDispatch();
  const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
  
    const { data } = useSelector((state) => state.trade);

    const [availableBalance, setAvailableBalance] = useState(0);
    const [assetName, setAssetName] = useState("");

    // Function to update balance on Buy/Sell click
    useEffect(() => {
        if (orderAction === "Buy") {
          
            setAvailableBalance(data?.data?.user?.data?.user_buy?.num || 0);
            setAssetName(data?.data?.user?.data?.user_buy?.ename?.toUpperCase() || "USDT");
            setSelectedCoinBuyPrice(data?.data?.info?.buy_price || "");
        } else {
            console.log("data",data);
            setAvailableBalance(data?.data?.user?.data?.user_sell?.num || 0);
            setAssetName(data?.data?.user?.data?.user_sell?.ename?.toUpperCase() || "Crypto");
            setSelectedCoinBuyPrice(data?.data?.info?.sell_price || "");
        }
    }, [orderAction, data]); // Update when orderAction changes
    

    useEffect(() => {
        if (
            location.pathname.includes("perpetual") ||
            location.pathname.includes("future")
        ) {
            setPerpetualTrade(true);
        } else {
            setPerpetualTrade(false);
        }
    }, [location.pathname]);

    // Get selected coin data
    const { selectedCoin } = useSelectedCoin();

    console.log("SelectedCoin:", selectedCoin);

    const selectedCoinPair = selectedCoin.ticker
        .toUpperCase()
        .replace("_", "/");
    const selectedCoinName =
        selectedCoin.ticker.toUpperCase().replace("_", "/").split("/")[0] || "";

    // Dummy User Asset Balance to show
    const [userAssetBalance] = useState(5000);
    const [selectedCoinBuyPrice, setSelectedCoinBuyPrice] = useState(
        selectedCoin.buy_price
    );
    const [orderQuantity, setOrderQuantity] = useState();
    const [totalOrderValue, setTotalOrderValue] = useState();
    const [leverage, setLeverage] = useState();

    const [errorMessage, setErrorMessage] = useState("");

    const [minWithdraw, setMinWithdraw] = useState(0);
    const [maxWithdraw, setMaxWithdraw] = useState(0);
    const [estimateBuyFee, setEstimateBuyFee] = useState(0);
    const [estimateSellFee, setEstimateSellFee] = useState(0);
    const [isAddFundModalOpen, setIsAddFundModalOpen] = useState(false);
    const [isBuyDetailsOpen, setIsBuyDetailsOpen] = useState(false);
    const [showConvertModel, setShowConvertModel] = useState(false);

    // Get data of each coin in the exchange (like, min withdraw, max withdraw, maker fee, taker fee, and more)
    useEffect(() => {
        if (selectedCoin && makerTakerData) {
            //
            const baseCoin = selectedCoin.ticker.split("/")[0];
            const data = makerTakerData[baseCoin];
            console.log("data minWithdrawValue ", data)
            if (data) {
                const minWithdrawValue = parseFloat(data.min_withdraw) || 0;
                const maxWithdrawValue = parseFloat(data.max_withdraw) || 0;
                const estimateBuyFee = parseFloat(data.maker_fee) || 0;
                const estimateSellFee = parseFloat(data.taker_fee) || 0;

                setMinWithdraw(minWithdrawValue);
                setMaxWithdraw(maxWithdrawValue);
                setEstimateBuyFee(estimateBuyFee);
                setEstimateSellFee(estimateSellFee);
            } else {
                setMinWithdraw(0);
                setMaxWithdraw(0);
                setEstimateBuyFee(0);
                setEstimateSellFee(0);
            }
        }
    }, [selectedCoin, makerTakerData]);

    useEffect(() => {
        dispatch(fetchData(selectedCoin.id, TOKEN, ID));
    }, [dispatch,selectedCoin, TOKEN, ID]);

    useEffect(() => {
        // Calculate total order value based on quantity and price
        const totalEstimate = orderQuantity * selectedCoinBuyPrice;
        setTotalOrderValue(totalEstimate);
    
        // Update leverage based on total order value
        const newLeverage = (totalEstimate / availableBalance) * 100;
        setLeverage(newLeverage > 100 ? 100 : newLeverage); // Cap leverage at 100%
    
        // Validate user funds
        if (totalEstimate > userAssetBalance) {
            setErrorMessage("You do not have enough funds to buy the entered amount.");
        } else {
            setErrorMessage("");
        }
    }, [selectedCoinBuyPrice, orderQuantity, userAssetBalance, availableBalance]);
    




    const handlePriceChange = (e) => {
        let newPrice = parseFloat(e.target.value);
        if (isNaN(newPrice) || newPrice <= 0) newPrice = 0;
    
        setSelectedCoinBuyPrice(newPrice);
    
        // Recalculate total order value
        const newTotal = (orderQuantity * newPrice).toFixed(2);
        setTotalOrderValue(newTotal);
    
        // Adjust slider based on total order value
        const newLeverage = (newTotal / availableBalance) * 100;
        setLeverage(newLeverage > 100 ? 100 : newLeverage); // Cap at 100%
    };
    
    const handleSliderChange = (e) => {
        const percentage = parseFloat(e.target.value);
        setLeverage(percentage);
    
        // Calculate total order value
        const calculatedTotal = ((percentage / 100) * availableBalance).toFixed(2);
        setTotalOrderValue(calculatedTotal);
    
        // Update quantity if price is valid
        if (selectedCoinBuyPrice > 0) {
            const newQuantity = (calculatedTotal / selectedCoinBuyPrice).toFixed(6);
            setOrderQuantity(newQuantity);
        }
    };
    
    const handleOrderQuantityChange = (e) => {
        let newQuantity = parseFloat(e.target.value);
        if (isNaN(newQuantity) || newQuantity <= 0) newQuantity = 0;
    
        setOrderQuantity(newQuantity);
    
        // Recalculate total order value
        const newTotal = (newQuantity * selectedCoinBuyPrice).toFixed(2);
        setTotalOrderValue(newTotal);
    
        // Adjust slider based on total
        const newLeverage = (newTotal / availableBalance) * 100;
        setLeverage(newLeverage > 100 ? 100 : newLeverage); // Cap at 100%
    };
    
    const handleTotalChange = (e) => {
        let enteredTotal = parseFloat(e.target.value);
        if (isNaN(enteredTotal) || enteredTotal < 0) enteredTotal = 0;
        if (enteredTotal > availableBalance) enteredTotal = availableBalance; // Restrict max value
    
        setTotalOrderValue(enteredTotal);
    
        // Adjust slider
        const newLeverage = ((enteredTotal / availableBalance) * 100).toFixed(2);
        setLeverage(newLeverage);
    
        // Update quantity if price is valid
        if (selectedCoinBuyPrice > 0) {
            const newQuantity = (enteredTotal / selectedCoinBuyPrice).toFixed(6);
            setOrderQuantity(newQuantity);
        }
    };








    // Handles change in price input, updates orderQuantity and total accordingly
   

    // Handle orderQuantity change
    

    const handleLeverageChange = (event) => {
        const newLeverageValue = event.target.value;
        setLeverage(Math.round(newLeverageValue * 100) / 100);

        const newOrderQuantity =
            ((newLeverageValue / 100) * userAssetBalance) /
            selectedCoinBuyPrice;
        setOrderQuantity(newOrderQuantity);

        const newTotalOrderValue = selectedCoinBuyPrice * newOrderQuantity;
        setTotalOrderValue(newTotalOrderValue);
    };

    

    const handleAddFundModal = () => {
        setIsAddFundModalOpen(true);
    };

    const closeAddFundModal = () => {
        setIsAddFundModalOpen(false);
    };

    const handleSubmitOrder = () => {
        if (!orderQuantity) {
            toast.error("Please enter order quantity");
        } else {
            toast.success("Order submitted successfully");
        }
    };

    const handleOrderSubmit = (e) => {
        e.preventDefault();
        setIsBuyDetailsOpen(true);
    };

    const closeBuyDetailsModal = () => {
        setIsBuyDetailsOpen(false);
    };

    const orderDetails = {
        orderAction: orderAction,
        orderActionType: orderActionType,
        selectedCoinName: selectedCoinName,
        selectedCoinBuyPrice: selectedCoinBuyPrice,
        orderQuantity: orderQuantity,
        totalOrderValue: totalOrderValue,
        estimateBuyFee: estimateBuyFee,
    };
   


    

    if (loading)
        return (
            <div className="flex w-full min-h-[50vh]">
                <div role="status" className="m-auto">
                    <BsArrowClockwise size={18} className="animate-spin" />
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        );
    if (error) return <div>Error: {error}</div>;
    return (
        <div className="flex flex-col gap-0 trade-area__spot-wrapper min-h-[50vh] ">
            <div className="flex items-start justify-between gap-1 mb-2">
                <div className="flex flex-col gap-1">
                    <span className="text-[12px] text-[#15141590]  dark:text-[#71777A] font-[500]">
                        Available Balance
                    </span>
                    <span className="text-[12px] dark:text-[#71777A]">
                        ≈ {availableBalance.toFixed(4)} {assetName}
                    </span>
                </div>
                <div className="flex items-center gap-1">
                    <button
                        type="button"
                        data-tooltip-id="add-fund-tooltip"
                        data-tooltip-content="Boost up your balance through the following options."
                        data-tooltip-place="bottom"
                        className="flex items-center h-auto gap-1 text-yellow-800 w-max"
                        onClick={handleAddFundModal}
                    >
                        <span className="text-[12px]">Add</span>
                        <BsPlusCircle size={12} />
                        <Tooltip id="add-fund-tooltip" effect="solid" />
                    </button>
                </div>{" "}
                {/* for showing on add model */}
                {showConvertModel && (
                    <CoverTradeModel
                        selectedCoin={selectedCoin}
                        showConvertModel={setShowConvertModel}
                    />
                )}
            </div>
            <form
                action="#"
                method="post"
                id="order-form"
                autoComplete="off"
                onSubmit={handleSubmitOrder}
                encType="multipart/form-data"
                className="flex flex-col gap-0"
            >
               
                <div className="flex flex-col gap-1 my-3">
                    <label
                        className="w-max text-[13px] text-[#15141590]   dark:text-[#71777A]"
                        htmlFor="order-price"
                    >
                        Order Price
                    </label>
                    <div className="flex items-center justify-between gap-2 px-2 overflow-hidden border rounded-md bg-gray-100 dark:bg-[#25282C]  dark:text-[#FFFFFF] dark:border-transparent">
                    <input
            type="number"
            id="order-price"
            name="order-price"
            value={selectedCoinBuyPrice}
            placeholder={selectedCoin.buy_price}
            onChange={handlePriceChange}
            className="w-full py-2.5 text-[12px] border-none bg-transparent placeholder:text-[11px] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        />
                        <span className="text-[12px]">{assetName}</span>
                    </div>
                </div>
                {(orderActionType === "Limit" || orderActionType === "Stop") && (
                    <div className="flex flex-col gap-1 my-3">
                        <label
                            className="w-max text-[13px] text-[#15141590] dark:bg-[#101014] dark:text-[#71777A]"
                            htmlFor="quantity"
                        >
                            Quantity
                        </label>
                        <div className="flex items-center justify-between gap-2 px-2 overflow-hidden border rounded-md bg-gray-100  dark:text-[#FFFFFF] dark:bg-[#25282C] dark:border-transparent">
                        <input
            type="number"
            id="quantity"
            name="quantity"
            placeholder="0.00"
            min={minWithdraw}
            max={maxWithdraw}
            value={orderQuantity}
            onChange={handleOrderQuantityChange}
            className="w-full py-2.5 text-[12px] placeholder:text-[11px] bg-transparent border-none"
        />
                            <span className="text-[12px]">
                                {selectedCoinPair.split("/")[0]}
                            </span>
                        </div>
                    </div>
                )}
             {/*    <div className="p-2 mb-6 mt-2">
                    <TradeSlider />
                </div> */}
              <div className="flex flex-col mb-6">
              <input
            id="range-slider"
            name="range-slider"
            type="range"
            min="0"
            max="100"
            step={"0.5"}
            value={availableBalance <= 0 ? 0 : leverage || 0}
            onChange={handleSliderChange}
            className="w-full appearance-none cursor-pointer dark:bg-[#101014] dark:text-[#71777A]"
            data-tooltip-id="leverage-tooltip"
            data-tooltip-content={`${availableBalance <= 0 ? 0 : leverage || 0}%`}
            data-tooltip-place="top"
            {...(availableBalance <= 0 ? { disabled: true } : {})}
        />


                    <div className="relative w-full">
                        <span className="text-[11px] text-[#15141580]  dark:text-[#71777A] font-bold absolute left-0 -bottom-4">
                            0%
                        </span>
                        <span className="text-[11px] text-[#15141580]  dark:text-[#71777A] font-bold absolute left-1/4 transform -translate-x-1/2 -bottom-4">
                            25%
                        </span>
                        <span className="text-[11px] text-[#15141580]  dark:text-[#71777A] font-bold absolute left-1/2 transform -translate-x-1/2 -bottom-4">
                            50%
                        </span>
                        <span className="text-[11px] text-[#15141580]  dark:text-[#71777A] font-bold absolute left-3/4 transform -translate-x-1/2 -bottom-4">
                            75%
                        </span>
                        <span className="text-[11px] text-[#15141580]  dark:text-[#71777A] font-bold absolute left-full transform -translate-x-full -bottom-4">
                            100%
                        </span>
                    </div>

                    <Tooltip id="leverage-tooltip" effect="solid" />
                </div>
                <div className="flex flex-col gap-1 my-3">
                    <label
                        className="w-max text-[14px] text-[#15141580]  dark:text-[#71777A]"
                        htmlFor="total"
                    >
                        Total
                    </label>
                    <div className="flex items-center justify-between gap-2 px-2 overflow-hidden border rounded-md bg-gray-100 dark:bg-[#25282C] dark:text-[#FFFFFF] dark:border-transparent">
                    <input
            type="number"
            id="total"
            name="total"
            placeholder="0.00"
            value={totalOrderValue}
            onChange={handleTotalChange}
            className="w-full py-2.5 text-[12px] bg-transparent border-none placeholder:text-[11px] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        />

                        <span className="text-[12px]">USDT</span>
                    </div>
                </div>

                {/* Displaying minimum and maximum withdrawal limits */}
                <div className="text-[12px] text-[#15141590] dark:bg-[#101014] dark:text-[#71777A] flex flex-col gap-0.5">
                    <div className="flex items-center justify-between w-full gap-1">
                        <span className="text-[12px]">Min Amount:</span>
                        <span className="text-[12px]">
                            {minWithdraw.toFixed(4)}{" "}
                            {selectedCoinPair.split("/")[0]}
                        </span>
                    </div>
                    <div className="flex items-center justify-between w-full gap-1">
                        <span className="text-[12px]">Max Amount:</span>
                        <span className="text-[12px]">
                            {maxWithdraw.toFixed(4)}{" "}
                            {selectedCoinPair.split("/")[0]}
                        </span>
                    </div>
                    {orderActionType === "buy" ? (
                        <div className="flex items-center justify-between w-full gap-1">
                            <span className="text-[12px]">Est Fee:</span>
                            <span className="text-[12px]">
                                {estimateBuyFee.toFixed(4)}{" "}
                                {selectedCoinPair.split("/")[0]}
                            </span>
                        </div>
                    ) : (
                        <div className="flex items-center justify-between w-full gap-1">
                            <span className="text-[12px]">Est Fee:</span>
                            <span className="text-[12px]">
                                {estimateSellFee.toFixed(4)}{" "}
                                {selectedCoinPair.split("/")[1]}
                            </span>
                        </div>
                    )}
                </div>

                {/* Error message */}
                {errorMessage && (
                    <div className="mt-2 text-sm text-red-600">
                        {errorMessage}
                    </div>
                )}

                {/* Buttons to submit order based on Order action type (BUY or SELL) */}
                {perpetualTrade ? (
                    <div className="flex items-center justify-around gap-2  py-2 trade-area__spot-btn-wrap mt-4">
                        {/* Button: Buy */}
                        <button
                            type="button"
                            className={`py-2 px-4 w-full rounded-md font-semibold bg-[#20B26C] text-white 
                                `}
                        >
                            Buy/Long
                        </button>

                        {/* Button: Sell */}
                        <button
                            type="button"
                            className={`py-2 px-4 w-full rounded-md font-semibold bg-[#EF454A] text-white`}
                        >
                            Sell/Short
                        </button>
                    </div>
                ) : (
                    <div className="py-6">
                        <button
                            type="button"
                            className={`py-2 px-4 w-full rounded-md ${
                                orderAction === "Buy"
                                    ? "bg-[#20B26C]"
                                    : "bg-[#EF454A]"
                            }`}
                            onClick={handleOrderSubmit}
                        >
                            <span className="text-[14px] font-bold dark:text-[#ffffff]">
                                {orderAction === "Buy"
                                    ? `Buy ${selectedCoinName}`
                                    : `Sell ${selectedCoinName}`}
                            </span>
                        </button>
                    </div>
                )}
            </form>

            {isAddFundModalOpen ? (
                <AddFundModal
                    closeAddFundModal={closeAddFundModal}
                    setShowDepositModel={setShowDepositModel}
                    setShowTransferModel={setShowTransferModel}
                    setShowConvertModel={setShowConvertModel}
                />
            ) : null}

            {isBuyDetailsOpen ? (
                <BuyOrderDetails
                    orderDetails={orderDetails}
                    closeBuyDetailsModal={closeBuyDetailsModal}
                    selectedCoin={selectedCoin}
                />
            ) : null}
        </div>
    );
};

TradeAreaSpot.propTypes = {
    orderActionType: PropTypes.string,
    selectedCoin: PropTypes.shape({
        buy_price: PropTypes.number,
        ticker: PropTypes.string,
    }),
};

export default TradeAreaSpot;
