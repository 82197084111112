import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMarketData } from "store/actions/marketActions";
import { BsQuestionCircleFill, BsSearch } from "react-icons/bs";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import { MdOutlineChevronRight } from "react-icons/md";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './heroSection.css'
const MarketToday = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const marketData = useSelector((state) => state.market.data?.market || []);
    const loading = useSelector((state) => state.market.loading);
    const [validIcons, setValidIcons] = useState({});

    useEffect(() => {
        dispatch(fetchMarketData());
    }, [dispatch]);
    console.log("marketData", marketData)
    // Function to check if an image URL is valid
    const checkIconValidity = (url) => {
        // Treat specific placeholder URLs as invalid
        const invalidUrls = ["https://api.buycex.com/Upload/coin/"];
        if (invalidUrls.includes(url)) {
            return Promise.resolve(false);
        }

        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = url;
        });
    };

    useEffect(() => {
        const validateIcons = async () => {
            const results = {};
            for (const coin of marketData) {
                if (coin.icon) {
                    results[coin.id] = await checkIconValidity(coin.icon);
                }
            }
            setValidIcons(results);
        };

        if (marketData.length > 0) {
            validateIcons();
        }
    }, [marketData]);

    const [isSearchActive, setIsSearchActive] = useState(false);

    const handleSearchClick = () => {
        setIsSearchActive(!isSearchActive);
    };

    const formatPrice = (price) => {
        const numPrice = Number(price);
        return isNaN(numPrice) ? "N/A" : numPrice.toFixed(2);
    };

    const topGainers = marketData
        .filter((coin) => coin.change > 0)
        .sort((a, b) => b.change - a.change)
        .slice(0, 5);
    console.log("topGainers", topGainers)
    const newListed = marketData
        .map((coin) => ({
            ...coin,
            id: Number(coin.id),
        }))
        .sort((a, b) => b.id - a.id)
        .slice(0, 5) 
        .map((coin) => ({
            ...coin,
            id: coin.id.toString(), 
        }));

    const topLosers = marketData
        .filter((coin) => coin.change < 0)
        .sort((a, b) => a.change - b.change)
        .slice(0, 5);

    if (loading) {
        return (
            <div className="min-h-[30vh] w-full text-center m-auto">
                Loading...
            </div>
        );
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <section className="market-top-coins-se bg-white dark:bg-black">
            <div className="container py-5">
                <div className="flex flex-col gap-4 md:flex-row justify-between items-start md:items-center pb-3 w-full">
                    <h3 className="text-[24px] md:w-1/2 w-full sm:text-3xl text-ellipsis whitespace-normal sm:whitespace-nowrap font-extrabold">Crypto Market Overviews </h3>
                    <div className="md:w-1/5 w-full flex gap-1 items-center">
                        <div className="flex gap-3 items-center w-full">
                            <div className="flex items-center justify-normal md:justify-end w-full gap-0 md:gap-3">
                                <div
                                    className="cursor-pointer transition-all ease-in-out duration-300 md:block hidden"
                                    onClick={handleSearchClick}
                                >
                                    <BsSearch size={20} />
                                </div>

                                <div
                                    className={`border dark:border-[#454545] rounded-[0.375rem] flex items-center pl-0 md:pl-2.5 transition-all ease-in-out duration-300 
                                    ${isSearchActive || window.innerWidth < 768 ? "w-full opacity-100 scale-x-100" : "w-0 opacity-0 scale-x-0"}`}
                                    style={{ transformOrigin: "left" }}
                                >
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="text-[#151415] bg-transparent py-2 px-2 pr-3 flex items-center w-full outline-none placeholder:text-[12px] text-[13px] transition-all ease-in-out duration-300"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="market-top-coins-header flex items-center justify-end mb-2">
                </div>
                <div className="sm:hidden">
                    <Slider {...settings}>
                        {/* Top Gainers */}
                        <div className="market-top-category w-full h-auto">
                            <div className="category-wrapper min-h-[275px] flex flex-col dark:bg-[#090a0c] dark:border-[#454545]/30 border rounded-[1.5rem] p-2 ">
                                <div className="card-header flex items-center justify-between px-3 py-2">
                                    <p className="card-header__title text-xs font-semibold">
                                        Hot Coins
                                    </p>
                                    <Link
                                        to="#!"
                                        title="More details about hot coins coins"
                                        className="card-header__link flex items-center text-xs font-semibold"
                                    >
                                        More
                                        <MdOutlineChevronRight className="mt-0.5 opacity-60" />
                                    </Link>
                                </div>
                                <div className="flex items-center justify-center w-full overflow-hidden">
                                    {topGainers.length > 0 ? (
                                        <table className="market-top-coins-table w-full">

                                            <tbody>
                                                {topGainers.map((coin) => (
                                                    <tr key={coin.id}>
                                                        <td className="px-3 py-1.5 w-1/3 text-left text-[14px] font-[500]">
                                                            <div className="flex items-center gap-2">
                                                                <div className="flex py-1.5">
                                                                    {validIcons[
                                                                        coin.id
                                                                    ] ? (
                                                                        <img
                                                                            src={
                                                                                coin.icon
                                                                            }
                                                                            alt={
                                                                                coin.ticker
                                                                            }
                                                                            className="coin-icon w-5 h-5 object-contain"
                                                                        />
                                                                    ) : (
                                                                        <BsQuestionCircleFill
                                                                            className="w-5 h-5"
                                                                            fill="#15141550"
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="flex w-1/3 flex-col">
                                                                    <span className="text-[14px] uppercase font-[600]">
                                                                        {coin.coinData.name.charAt(0).toUpperCase() + coin.coinData.name.slice(1)}
                                                                    </span>
                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td className="flex py-1.5 justify-center items-end">
                                                            <div className="text-right py-1.5 text-[14px] font-[500]">
                                                                $
                                                                {formatPrice(
                                                                    coin.new_price
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td className="w-1/3">
                                                            <div
                                                                className={`text-[14px] py-1.5 px-3 font-medium text-end ${coin.change >= 0
                                                                    ? "text-[#2ECC71]"
                                                                    : "text-[#F65454]"
                                                                    }`}
                                                            >
                                                                {coin.change.toFixed(2)}
                                                                %
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <p className="m-auto">
                                            No <strong>Top Gainers</strong> data
                                            available
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* New Listed */}
                        <div className="market-top-category w-full h-auto">
                            <div className="category-wrapper flex flex-col min-h-[275px] dark:bg-[#090a0c] dark:border-[#454545]/30 border rounded-[1.5rem] p-2 ">
                                <div className="card-header flex items-center justify-between px-3 py-2">
                                    <p className="card-header__title text-xs font-semibold">
                                        New Coins
                                    </p>
                                    <Link
                                        to="#!"
                                        title="More details about new coins coins"
                                        className="card-header__link flex items-center text-xs font-semibold"
                                    >
                                        More
                                        <MdOutlineChevronRight className="mt-0.5 opacity-60" />
                                    </Link>
                                </div>
                                <div className="flex items-center justify-center w-full overflow-hidden">
                                    {newListed.length > 0 ? (
                                        <table className="market-top-coins-table w-full">

                                            <tbody>
                                                {newListed.map((coin) => (
                                                    <tr key={coin.id}>
                                                        <td className="px-3 py-1.5 text-left text-[14px] font-[500]">
                                                            <div className="flex items-center gap-2">
                                                                <div className="flex py-1.5">
                                                                    {validIcons[
                                                                        coin.id
                                                                    ] ? (
                                                                        <img
                                                                            src={
                                                                                coin.icon
                                                                            }
                                                                            alt={
                                                                                coin.ticker
                                                                            }
                                                                            className="coin-icon w-5 h-5 object-contain"
                                                                        />
                                                                    ) : (
                                                                        <BsQuestionCircleFill
                                                                            className="w-5 h-5"
                                                                            fill="#15141550"
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    <span className="text-[14px] uppercase font-[600]">
                                                                        {coin.coinData.name.charAt(0).toUpperCase() + coin.coinData.name.slice(1)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td className="flex py-1.5 justify-center items-end">
                                                            <div className="text-right py-1.5 text-[14px] font-[500]">
                                                                $
                                                                {formatPrice(
                                                                    coin.new_price
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td className="w-1/3">
                                                            <div
                                                                className={`text-[14px] py-1.5 px-3 font-medium text-end ${coin.change >= 0
                                                                    ? "text-[#2ECC71]"
                                                                    : "text-[#F65454]"
                                                                    }`}
                                                            >
                                                                {coin.change.toFixed(2)}
                                                                %
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <p className="m-auto">
                                            No <strong>New Listed</strong> coins
                                            data available
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Top Losers */}
                        <div className="market-top-category w-full h-auto">
                            <div className="category-wrapper flex flex-col dark:bg-[#090a0c] dark:border-[#454545]/30 min-h-[275px] border rounded-[1.5rem] p-2 ">
                                <div className="card-header flex items-center justify-between px-3 py-1.5">
                                    <p className="card-header__title text-xs font-semibold">
                                        Top Losers
                                    </p>
                                    <Link
                                        to="#!"
                                        title="More details about top losers coins"
                                        className="card-header__link flex items-center text-xs font-semibold"
                                    >
                                        More
                                        <MdOutlineChevronRight className="mt-0.5 opacity-60" />
                                    </Link>
                                </div>
                                <div className="flex items-center justify-center w-full overflow-hidden">
                                    {topLosers.length > 0 ? (
                                        <table className="market-top-coins-table w-full">

                                            <tbody>
                                                {topLosers.map((coin) => (
                                                    <tr key={coin.id}>
                                                        <td className="px-0 sm:px-3 py-1.5 text-left text-[14px] font-[500]">
                                                            <div className="flex items-center gap-2">
                                                                <div className="flex py-1.5">
                                                                    {validIcons[
                                                                        coin.id
                                                                    ] ? (
                                                                        <img
                                                                            src={
                                                                                coin.icon
                                                                            }
                                                                            alt={
                                                                                coin.ticker
                                                                            }
                                                                            className="coin-icon w-5 h-5 object-contain"
                                                                        />
                                                                    ) : (
                                                                        <BsQuestionCircleFill
                                                                            className="w-5 h-5"
                                                                            fill="#15141550"
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    <span className="text-sm sm:text-[14px] uppercase font-[600]">
                                                                        {coin.coinData.name.charAt(0).toUpperCase() + coin.coinData.name.slice(1)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="flex py-1.5 justify-center items-end">
                                                            <div className="text-right py-1.5 text-[14px] font-[500]">
                                                                $
                                                                {formatPrice(
                                                                    coin.new_price
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td className="w-1/3">
                                                            <div
                                                                className={`text-[14px] py-1.5 px-3 font-medium text-end ${coin.change >= 0
                                                                    ? "text-[#2ECC71]"
                                                                    : "text-[#F65454]"
                                                                    }`}
                                                            >
                                                                {coin.change.toFixed(2)}
                                                                %
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <p className="m-auto">
                                            No <strong>Top Losers</strong> data available
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
                <div className="market-top-coins-wrapper hidden sm:flex flex-col lg:flex-row items-stretch justify-between gap-8">
                    {/* Top Gainers */}
                    <div className="market-top-category w-full h-auto">
                        <div className="category-wrapper min-h-[275px] flex flex-col dark:bg-[#090a0c] dark:border-[#454545]/30 border rounded-[1.5rem] p-2 ">
                            <div className="card-header flex items-center justify-between px-3 py-2">
                                <p className="card-header__title text-xs font-semibold">
                                    Hot Coins
                                </p>
                                <Link
                                    to="#!"
                                    title="More details about hot coins coins"
                                    className="card-header__link flex items-center text-xs font-semibold"
                                >
                                    More
                                    <MdOutlineChevronRight className="mt-0.5 opacity-60" />
                                </Link>
                            </div>
                            <div className="flex items-center justify-center w-full overflow-hidden">
                                {topGainers.length > 0 ? (
                                    <table className="market-top-coins-table w-full">

                                        <tbody>
                                            {topGainers.map((coin) => (
                                                <tr key={coin.id}>
                                                    <td className="px-3 py-1.5 w-1/3 text-left text-[14px] font-[500]">
                                                        <div className="flex items-center gap-2">
                                                            <div className="flex py-1.5">
                                                                {validIcons[
                                                                    coin.id
                                                                ] ? (
                                                                    <img
                                                                        src={
                                                                            coin.icon
                                                                        }
                                                                        alt={
                                                                            coin.ticker
                                                                        }
                                                                        className="coin-icon w-5 h-5 object-contain"
                                                                    />
                                                                ) : (
                                                                    <BsQuestionCircleFill
                                                                        className="w-5 h-5"
                                                                        fill="#15141550"
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="flex w-1/3 flex-col">
                                                                <span className="text-[14px] uppercase font-[600]">
                                                                    {coin.coinData.name.charAt(0).toUpperCase() + coin.coinData.name.slice(1)}
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </td>
                                                    <td className="flex py-1.5 justify-center items-end">
                                                        <div className="text-right py-1.5 text-[14px] font-[500]">
                                                            $
                                                            {formatPrice(
                                                                coin.new_price
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="w-1/3">
                                                        <div
                                                            className={`text-[14px] py-1.5 px-3 font-medium text-end ${coin.change >= 0
                                                                ? "text-[#2ECC71]"
                                                                : "text-[#F65454]"
                                                                }`}
                                                        >
                                                            {coin.change.toFixed(2)}
                                                            %
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p className="m-auto">
                                        No <strong>Top Gainers</strong> data
                                        available
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* New Listed */}
                    <div className="market-top-category w-full h-auto">
                        <div className="category-wrapper flex flex-col min-h-[275px] dark:bg-[#090a0c] dark:border-[#454545]/30 border rounded-[1.5rem] p-2 ">
                            <div className="card-header flex items-center justify-between px-3 py-2">
                                <p className="card-header__title text-xs font-semibold">
                                    New Coins
                                </p>
                                <Link
                                    to="#!"
                                    title="More details about new coins coins"
                                    className="card-header__link flex items-center text-xs font-semibold"
                                >
                                    More
                                    <MdOutlineChevronRight className="mt-0.5 opacity-60" />
                                </Link>
                            </div>
                            <div className="flex items-center justify-center w-full overflow-hidden">
                                {newListed.length > 0 ? (
                                    <table className="market-top-coins-table w-full">

                                        <tbody>
                                            {newListed.map((coin) => (
                                                <tr key={coin.id}>
                                                    <td className="px-3 py-1.5 text-left text-[14px] font-[500]">
                                                        <div className="flex items-center gap-2">
                                                            <div className="flex py-1.5">
                                                                {validIcons[
                                                                    coin.id
                                                                ] ? (
                                                                    <img
                                                                        src={
                                                                            coin.icon
                                                                        }
                                                                        alt={
                                                                            coin.ticker
                                                                        }
                                                                        className="coin-icon w-5 h-5 object-contain"
                                                                    />
                                                                ) : (
                                                                    <BsQuestionCircleFill
                                                                        className="w-5 h-5"
                                                                        fill="#15141550"
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <span className="text-[14px] uppercase font-[600]">
                                                                    {coin.coinData.name.charAt(0).toUpperCase() + coin.coinData.name.slice(1)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td className="flex py-1.5 justify-center items-end">
                                                        <div className="text-right py-1.5 text-[14px] font-[500]">
                                                            $
                                                            {formatPrice(
                                                                coin.new_price
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="w-1/3">
                                                        <div
                                                            className={`text-[14px] py-1.5 px-3 font-medium text-end ${coin.change >= 0
                                                                ? "text-[#2ECC71]"
                                                                : "text-[#F65454]"
                                                                }`}
                                                        >
                                                            {coin.change.toFixed(2)}
                                                            %
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p className="m-auto">
                                        No <strong>New Listed</strong> coins
                                        data available
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Top Losers */}
                    <div className="market-top-category w-full h-auto">
                        <div className="category-wrapper flex flex-col dark:bg-[#090a0c] dark:border-[#454545]/30 min-h-[275px] border rounded-[1.5rem] p-2 ">
                            <div className="card-header flex items-center justify-between px-3 py-1.5">
                                <p className="card-header__title text-xs font-semibold">
                                    Top Losers
                                </p>
                                <Link
                                    to="#!"
                                    title="More details about top losers coins"
                                    className="card-header__link flex items-center text-xs font-semibold"
                                >
                                    More
                                    <MdOutlineChevronRight className="mt-0.5 opacity-60" />
                                </Link>
                            </div>
                            <div className="flex items-center justify-center w-full overflow-hidden">
                                {topLosers.length > 0 ? (
                                    <table className="market-top-coins-table w-full">

                                        <tbody>
                                            {topLosers.map((coin) => (
                                                <tr key={coin.id}>
                                                    <td className="px-0 sm:px-3 py-1.5 text-left text-[14px] font-[500]">
                                                        <div className="flex items-center gap-2">
                                                            <div className="flex py-1.5">
                                                                {validIcons[
                                                                    coin.id
                                                                ] ? (
                                                                    <img
                                                                        src={
                                                                            coin.icon
                                                                        }
                                                                        alt={
                                                                            coin.ticker
                                                                        }
                                                                        className="coin-icon w-5 h-5 object-contain"
                                                                    />
                                                                ) : (
                                                                    <BsQuestionCircleFill
                                                                        className="w-5 h-5"
                                                                        fill="#15141550"
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <span className="text-sm sm:text-[14px] uppercase font-[600]">
                                                                    {coin.coinData.name.charAt(0).toUpperCase() + coin.coinData.name.slice(1)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="flex py-1.5 justify-center items-end">
                                                        <div className="text-right py-1.5 text-[14px] font-[500]">
                                                            $
                                                            {formatPrice(
                                                                coin.new_price
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="w-1/3">
                                                        <div
                                                            className={`text-[14px] py-1.5 px-3 font-medium text-end ${coin.change >= 0
                                                                ? "text-[#2ECC71]"
                                                                : "text-[#F65454]"
                                                                }`}
                                                        >
                                                            {coin.change.toFixed(2)}
                                                            %
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p className="m-auto">
                                        No <strong>Top Losers</strong> data available
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MarketToday;
