// src/store/actions/walletActions.js
import WalletRepository from 'repository/walletRepository';
import {
  FETCH_WALLET_REQUEST,
  FETCH_WALLET_SUCCESS,
  FETCH_WALLET_FAILURE,
} from '../types';

export const fetchWalletData = (from, to, token, id) => async (dispatch) => {

  console.log(from, to,"from from selectedFrom, selectedTo");
  dispatch({ type: FETCH_WALLET_REQUEST });

  try {
    // Call repository function to get wallet data based on selected from and to wallets
    const response = await WalletRepository.getWalletData(from, to, token, id);
    console.log("wallet action response", response);

    // Check response status
    if (response.data.status === 1) {
      const coins = response.data.data.coins; // Assuming the coins are in this structure

      // Format all crypto coins where balance > 0
      const formattedCoins = Object.keys(coins)
        .filter((coinKey) => parseFloat(coins[coinKey].balance) > 0) // Filter coins with balance > 0
        .map((coinKey) => ({
          icon: coins[coinKey].img,
          name: coins[coinKey].name,
          title: coins[coinKey].title,
          balance: parseFloat(coins[coinKey].balance),
          frozen: parseFloat(coins[coinKey].freeze),
          minWithdrawal: parseFloat(coins[coinKey].zc_min),
          maxWithdrawal: parseFloat(coins[coinKey].zc_max),
          withdrawalFee: parseFloat(coins[coinKey].zc_fee),
          symbol: coins[coinKey].symbol,
        }));

      console.log("formattedCoins", formattedCoins);

      dispatch({
        type: FETCH_WALLET_SUCCESS,
        payload: {
          assets: formattedCoins,
        },
      });
    } else {
      dispatch({ type: FETCH_WALLET_FAILURE, payload: 'Failed to fetch wallet data' });
    }
  } catch (error) {
    dispatch({ type: FETCH_WALLET_FAILURE, payload: error.message });
  }
};
