import React from "react";
import { Modal } from "antd";
import { FaCheckCircle } from "react-icons/fa";
import { BiSolidCopy, BiLinkAlt } from "react-icons/bi";
import { toast } from "react-toastify";

function TransactionDetailsModal({ isVisible, onClose, withdrawResponse }) {
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!");
  };

  const truncateText = (text) => {
    return text.length > 8 ? text.slice(0, 4) + "..." + text.slice(-4) : text;
  };

  return (
    <Modal
      title="Transaction Success"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      className="custom-modal"
      width={"90%"} // Responsive width
      style={{ maxWidth: "400px" }} // Limits max width
      destroyOnClose
    >
      <div className="p-4 rounded-lg shadow-lg max-w-full mx-auto">
        {withdrawResponse === "Your request is being processed!" ? (
          <div className="text-center text-gray-500">{withdrawResponse}</div>
        ) : withdrawResponse && typeof withdrawResponse === "object" ? (
          <>
            {withdrawResponse.fees && (
              <div className="flex flex-col gap-4 justify-center items-center text-center">
                <FaCheckCircle className="text-5xl text-[#00e297] bg-[#DFF7F0] p-2 rounded-full" />
                <h2 className="text-base font-semibold text-gray-800">Recipient Amount</h2>
                <p className="text-lg font-bold text-gray-900 uppercase">
                  {withdrawResponse.amount} {withdrawResponse.network}
                </p>
                <p className="text-xs text-gray-600 mb-3 mt-1">
                  The Recipient can check their balance in the funding wallet.
                </p>

                <div className="bg-[#EFB81C] bg-opacity-20 rounded-md p-3 mt-2 w-full max-w-xs">
                  <p className="font-semibold text-center text-sm">
                    Visit Buycex Pay for more payment services
                  </p>
                  <p
                    className="text-[#EFB81C] font-semibold text-xs text-center cursor-pointer mt-1"
                    onClick={() => (window.location.href = "/user/dashboard")}
                  >
                    View Now
                  </p>
                </div>

                <div className="my-4 flex flex-col gap-3 w-full text-sm text-[#707a8a]">
                  <div className="flex justify-between font-medium">
                    <p>Total Paid</p>
                    <p className="uppercase">{withdrawResponse.amount} {withdrawResponse.network}</p>
                  </div>
                  <div className="flex justify-between font-medium">
                    <p>Fee</p>
                    <p>{withdrawResponse.fees}</p>
                  </div>
                  <div className="flex justify-between font-medium">
                    <p>Source Wallet</p>
                    <p>{withdrawResponse.source_wallet}</p>
                  </div>
                  <div className="flex justify-between font-medium">
                    <p>Network</p>
                    <p>{withdrawResponse.network}</p>
                  </div>
                </div>

                <div className="my-3 flex flex-col gap-3 w-full">
                  <div className="flex justify-between items-center text-sm font-medium">
                    <p className="font-medium">Wallet Address</p>
                    <div className="flex items-center gap-2">
                      <p className="text-xs">{truncateText(withdrawResponse.wallet_address)}</p>
                      <BiSolidCopy
                        fill="#81858c"
                        className="cursor-pointer"
                        onClick={() => handleCopy(withdrawResponse.wallet_address)}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between items-center text-sm font-medium">
                    <p className="font-medium">Transaction ID</p>
                    <div className="flex items-center gap-2">
                      <p className="text-xs">{truncateText(withdrawResponse.Trasection_ID)}</p>
                      <BiSolidCopy
                        fill="#81858c"
                        className="cursor-pointer"
                        onClick={() => handleCopy(withdrawResponse.Trasection_ID)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="text-center text-gray-500">No transaction details available.</div>
        )}

        <div className="mt-4">
          <button
            onClick={onClose}
            className="w-full py-2 bg-[#EFB81C] text-white rounded-lg font-semibold shadow-md hover:bg-[#f6a800] transition duration-300 ease-in-out"
          >
            Ok
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default TransactionDetailsModal;