import React from 'react'
import HeroSection from './HeroSection'
import GetStarted from './index2/GetStarted'
import DiscoverMore from './index2/DiscoverMore'
import TrustedExchange from './index2/TrustedExchange'
import KeepTrading from './index2/KeepTrading'
import Faqs from './index2/Faqs'
import StartJourney from './index2/StartJourney'
import BuySell from './index2/Buy&Sell'
import MarketToday from './index2/MarketToday'
import ImageCarousel from './ImageCarousel'
import { useSelector } from 'react-redux'

function Home2() {
  const user = useSelector((state) => state.authUser.user);
  
  return (
    <div>
      <HeroSection username={user}/>
      <ImageCarousel/>
      <MarketToday />
      {/* <BuySell /> */}
      {!user && <GetStarted />}
      <DiscoverMore />
      <TrustedExchange />
      <KeepTrading />
      <Faqs />
      <StartJourney username={user}/>
    </div>
  )
}

export default Home2