// src/components/LoginHistory.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginHistory } from "store/actions/AccountuserActions";
import { toast } from "react-hot-toast";

const LoginHistory = () => {
    const dispatch = useDispatch();
    const { loginHistory, loading, error } = useSelector(
        (state) => state.userReducer || {}
    );
    console.log("loginHistory",loginHistory)
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};

    useEffect(() => {
        if (ID && TOKEN) {
            dispatch(fetchLoginHistory(ID, TOKEN));
        }
    }, [dispatch, ID, TOKEN]);

    useEffect(() => {
        if (error) {
            toast.error("Error fetching login history");
        }
    }, [error]);

    return (
        <div className="bg-white rounded-md border border-[#F4F4F4] p-4 sm:p-6 lg:p-8">
            <div className={`font-semibold text-[16px] mb-6 ${loading ? "border-b-[0.5px] pb-10" : ""} `}>Login History</div>
            <div className="w-full overflow-x-auto">
            <div className="account-overview__login-status-table__wrapper flex min-w-[800px]">
                {loading ? (
                    <div className="w-full min-w-[800px] flex">
                        <p className="text-center m-auto">Loading...</p>
                    </div>
                ) : (
                    <table className="login-status-table w-full min-w-[800px]">
                        <thead className="login-status-table__header">
                            <tr>
                                <th scope="col" className="w-1/5">
                                    Time
                                </th>
                                <th scope="col" className="w-1/5">
                                    Device
                                </th>
                                <th scope="col" className="w-1/5">
                                    IP
                                </th>
                                <th scope="col" className="w-1/5">
                                    Location
                                </th>
                               
                                <th scope="col" className="w-1/5 opacity-20 cursor-not-allowed user-select-none">Operation</th>
                            </tr>
                        </thead>
                        <tbody className="login-status-table__body">
                            {loginHistory &&
                                loginHistory.slice(0, 5).map((item) => (
                                    <tr key={item.id} className="py-8">
                                        <th scope="col" className="text-start w-1/5">
                                        {item.remark}
                                        </th>
                                        <td className="w-1/5">
                                            {item.org}
                                        </td>
                                        <td className="w-1/5">
                                            {item.addip}
                                        </td>
                                        <td className="w-1/5">
    {`${item.addr}, ${item.region}, ${item.country} - ${item.postal}`}
</td>

                                        <td className="w-1/5">    {item.type}</td>
                                       
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                )}
            </div>
            </div>
        </div>
    );
};

export default LoginHistory;
