import React, { useEffect, useState } from "react";
import { BsQuestionCircleFill } from "react-icons/bs";
import { MdOutlineChevronRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchMarketData } from "store/actions/marketActions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MarketCard = ({ title, coins, loading, validIcons, formatPrice }) => {
    return (
        <div className="bg-white border rounded-2xl market-changer__card min-w-[24%] p-2 relative">
            <div className="card-header flex items-center justify-between px-3 py-2">
                <h3 className="card-header__title text-xs font-semibold">
                    {title}
                </h3>
                <Link
                    to="#!"
                    className="card-header__link flex items-center text-xs font-semibold"
                >
                    More <MdOutlineChevronRight className="mt-0.5 opacity-60" />
                </Link>
            </div>
            <div className="card-body flex flex-col">
                {loading
                    ? [...Array(3)].map((_, index) => (
                          <div
                              key={index}
                              className="flex items-center justify-between w-full p-2 animate-pulse"
                          >
                              <div className="w-3 h-3 bg-gray-300 rounded"></div>
                              <div className="h-2 bg-gray-300 rounded w-16"></div>
                              <div className="h-2 bg-gray-300 rounded w-12"></div>
                          </div>
                      ))
                    : coins.map((coin) => (
                          <div
                              key={coin.id}
                              className="flex items-center gap-2 justify-between w-full px-3 py-2.5"
                          >
                              <div className="flex items-center gap-2 min-w-[32%]">
                                  {validIcons[coin.id] ? (
                                      <img
                                          src={coin.icon}
                                          alt={coin.name}
                                          className="w-5 h-5 object-contain rounded"
                                      />
                                  ) : (
                                      <BsQuestionCircleFill className="w-5 h-5" />
                                  )}
                                  <span className="text-[14px] uppercase font-medium">
                                      {coin.coinData?.name}
                                  </span>
                              </div>
                              <div className="text-[14px] font-medium text-[#202630]/90 text-center min-w-[32%]">
                                  {formatPrice(coin.new_price)}
                              </div>
                              <div
                                  className={`text-[14px] font-medium min-w-[32%] text-right ${
                                      coin.change < 0
                                          ? "text-red-500"
                                          : "text-green-500"
                                  }`}
                              >
                                  {coin.change > 0
                                      ? `+${coin.change.toFixed(2)}%`
                                      : `${coin.change.toFixed(2)}%`}
                              </div>
                          </div>
                      ))}
            </div>
        </div>
    );
};

const MarketChangersCards = () => {
    const dispatch = useDispatch();
    const marketData = useSelector((state) => state.market.data?.market || []);
    const { loading, error } = useSelector((state) => state.market.loading);
    const [validIcons, setValidIcons] = useState({});

    useEffect(() => {
        dispatch(fetchMarketData());
    }, [dispatch]);

    const checkIconValidity = (url) => {
        const invalidUrls = ["https://api.buycex.com/Upload/coin/"];
        if (invalidUrls.includes(url)) {
            return Promise.resolve(false);
        }

        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = url;
        });
    };

    useEffect(() => {
        // Check validity of each icon URL
        const validateIcons = async () => {
            const results = {};
            for (const coin of marketData) {
                if (coin.icon) {
                    results[coin.id] = await checkIconValidity(coin.icon);
                }
            }
            setValidIcons(results);
        };

        if (marketData.length > 0) {
            validateIcons();
        }
    }, [marketData]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            dispatch(fetchMarketData());
        }, 60000);

        return () => clearInterval(intervalId);
    }, [dispatch]);

    const formatPrice = (price) => {
        const numPrice = Number(price);
        if (isNaN(numPrice)) return "N/A";
        if (numPrice >= 1e9) return `$${(numPrice / 1e9).toFixed(2)}B`;
        if (numPrice >= 1e6) return `$${(numPrice / 1e6).toFixed(2)}M`;
        if (numPrice >= 1e3) return `$${(numPrice / 1e3).toFixed(2)}K`;
        return `$${numPrice.toFixed(2)}`;
    };

    const trendingCoins = marketData
        .filter((coin) => coin.change > 0)
        .sort((a, b) => b.change - a.change)
        .slice(0, 3);
    const topGainers = marketData
        .filter((coin) => coin.change > 0)
        .sort((a, b) => b.change - a.change)
        .slice(0, 3);
    const newListed = marketData
        .sort((a, b) => Number(b.id) - Number(a.id))
        .slice(0, 3);
    const topVolume = marketData
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 3);

    if (error) {
        return (
            <p className="m-auto text-[14px] font-[500]">
                Error fetching market data.
            </p>
        );
    }

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="market-changer-card wrapper container">
            <div className="market-changers__title pt-2 mb-4"></div>
            <h2 className="font-bold text-3xl pb-4 lg:pb-0">Market Summary</h2>
            <div className="flex justify-between my-2 bg-solid pt-4 rounded-md max-lg:hidden p-2">
                <MarketCard
                    title="Hot Coins"
                    coins={trendingCoins}
                    loading={loading}
                    validIcons={validIcons}
                    formatPrice={formatPrice}
                />
                <MarketCard
                    title="Top Gainers"
                    coins={topGainers}
                    loading={loading}
                    validIcons={validIcons}
                    formatPrice={formatPrice}
                />
                <MarketCard
                    title="New Listed"
                    coins={newListed}
                    loading={loading}
                    validIcons={validIcons}
                    formatPrice={formatPrice}
                />
                <MarketCard
                    title="Top Volume"
                    coins={topVolume}
                    loading={loading}
                    validIcons={validIcons}
                    formatPrice={formatPrice}
                />
            </div>
            <div className="lg:hidden mb-8">
                <Slider {...sliderSettings}>
                    <MarketCard title="Hot Coins" coins={trendingCoins} loading={loading} validIcons={validIcons} formatPrice={formatPrice} />
                    <MarketCard title="Top Gainers" coins={topGainers} loading={loading} validIcons={validIcons} formatPrice={formatPrice} />
                    <MarketCard title="New Listed" coins={newListed} loading={loading} validIcons={validIcons} formatPrice={formatPrice} />
                    <MarketCard title="Top Volume" coins={topVolume} loading={loading} validIcons={validIcons} formatPrice={formatPrice} />
                </Slider>
            </div>
        </div>
    );
};

export default MarketChangersCards;
