import React, { useEffect, useState } from "react";
import "./HeroSection.scss";

import HeroImageOne from "assets/img/home-page/hero-section/hero-img-1.jpg";
import HeroImageTwo from "assets/img/home-page/hero-section/hero-img-2.jpg";
import HeroImageThird from "assets/img/home-page/hero-section/hero-img-3.jpg";
import { fetchUserProfile } from "store/actions/userProfileAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const flippingWords = ["Buy", "Sell", "Trade"];
const heroImages = [HeroImageOne, HeroImageTwo, HeroImageThird];

const HeroSection = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const userID = useSelector((state) => state.authUser?.user?.ID);
    const token = useSelector((state) => state.authUser?.user?.TOKEN);

    useEffect(() => {
        if (userID && token) {
            dispatch(fetchUserProfile(userID, token));
        }
    }, [dispatch, userID, token]);

    const userLoggedIn = userID && token;

    const [currentWord, setCurrentWord] = useState("Buy");
    const [flipState, setFlipState] = useState("flip-enter");
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageTransitionState, setImageTransitionState] = useState("fade-in");

    useEffect(() => {
        const wordInterval = setInterval(() => {
            setFlipState("flip-exit");

            setTimeout(() => {
                setCurrentWord((prev) => {
                    const nextIndex =
                        (flippingWords.indexOf(prev) + 1) %
                        flippingWords.length;
                    return flippingWords[nextIndex];
                });
                setFlipState("flip-enter");
            }, 600);
        }, 3000);

        return () => clearInterval(wordInterval);
    }, []);

    useEffect(() => {
        const imageInterval = setInterval(() => {
            setImageTransitionState("fade-out");

            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => {
                    return (prevIndex + 1) % heroImages.length;
                });
                setImageTransitionState("fade-in");
            }, 600);
        }, 3000);

        return () => clearInterval(imageInterval);
    }, []);

    const redirectDeposit = () => {
        history.push("/crypto/deposit");
    };

    const redirectTrade = () => {
        history.push("/trade/spot/btc_usdt");
    };

    const redirectRegister = () => {
        history.push("/register");
    };
    return (
        <section className="hero-section border-b flex relative">
            <div className="hero-section__wrapper container flex items-center justify-between gap-8 w-full max-h-[60vh] m-auto max-md:flex-col">
                <div className="hero-title-wrapper flex flex-col gap-4 w-[60%]">
                    <h1 className="hero-title flex flex-col gap-1 text-[48px] font-[600] leading-tight">
                        <span className="text-[48px] font-[900] text-[#EFB81C]">
                            Where the Future of
                        </span>
                        <span className="text-[48px]">Crypto Begins!</span>
                        
                    </h1>
                    <p className="hero-description">
                        <span>To</span>{" "}
                        <span className={`flip-text ${flipState}`}>
                            {currentWord}
                        </span>{" "}
                        <span>with secure and compliant trading.</span>
                    </p>
                    <div className="hero-btn-wrap flex items-center gap-4 mt-2">
                        {userLoggedIn ? (
                            <>
                                <button
                                    type="button"
                                    onClick={redirectDeposit}
                                    className="text-[16px] font-semibold px-6 py-2 bg-[#EFB81C] rounded-md"
                                >
                                    Deposit
                                </button>
                                <button
                                    type="button"
                                    onClick={redirectTrade}
                                    className="text-[16px] font-semibold px-6 py-2 bg-[#EFB81C10] hover:bg-[#EFB81C] border rounded-md"
                                >
                                    Trade Now
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    type="button"
                                    onClick={redirectRegister}
                                    className="text-[16px] font-semibold px-6 py-2 bg-[#EFB81C] rounded-md"
                                >
                                    Register
                                </button>
                            </>
                        )}
                    </div>
                </div>
                <div className="hero-image-wrapper relative w-[40%] min-h-[400px] rounded-xl overflow-hidden">
                    {heroImages.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`hero-img-${index}`}
                            className={`hero-image absolute top-0 left-0 w-full h-full rounded-xl object-contain transition-opacity duration-700 ${imageTransitionState} ${
                                index === currentImageIndex
                                    ? "active"
                                    : "inactive"
                            }`}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
