import {
    FETCH_LG_CURRENT_ORDER_REQUEST,
    FETCH_LG_CURRENT_ORDER_SUCCESS,
    FETCH_LG_CURRENT_ORDER_FAILURE,
    FETCH_LG_ORDER_HISTORY_REQUEST,
    FETCH_LG_ORDER_HISTORY_SUCCESS,
    FETCH_LG_ORDER_HISTORY_FAILURE,
    FETCH_PROJECT_REQUEST,
    FETCH_PROJECT_SUCCESS,
    FETCH_PROJECT_FAILURE,
    FETCH_SUBSCRIPTION_STATUS_REQUEST,
    FETCH_SUBSCRIPTION_STATUS_SUCCESS,
    FETCH_SUBSCRIPTION_STATUS_FAILURE,
} from "../types";

const initialState = {
    currentOrder: {
        data: [],
        loading: false,
        error: null,
    },
    orderHistory: {
        data: [],
        loading: false,
        error: null,
    },
    projects: null,
    subscriptionStatus: {
        isSubscribed: null,
        loading: false,
        error: null,
    },
};

const lgReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LG_CURRENT_ORDER_REQUEST:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    loading: true,
                    error: null,
                },
            };
        case FETCH_LG_CURRENT_ORDER_SUCCESS:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    loading: false,
                    data: action.payload,
                },
            };
        case FETCH_LG_CURRENT_ORDER_FAILURE:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    loading: false,
                    error: action.payload,
                },
            };
        case FETCH_LG_ORDER_HISTORY_REQUEST:
            return {
                ...state,
                orderHistory: {
                    ...state.orderHistory,
                    loading: true,
                    error: null,
                },
            };
        case FETCH_LG_ORDER_HISTORY_SUCCESS:
            console.log("Reducer received order history:", action.payload); // ✅ Debugging
            return {
                ...state,
                orderHistory: {
                    ...state.orderHistory,
                    loading: false,
                    data: action.payload,
                },
            };
        case FETCH_LG_ORDER_HISTORY_FAILURE:
            return {
                ...state,
                orderHistory: {
                    ...state.orderHistory,
                    loading: false,
                    error: action.payload,
                },
            };
        case FETCH_PROJECT_REQUEST:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    loading: true,
                    error: null,
                },
            };
        case FETCH_PROJECT_SUCCESS:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    loading: false,
                    data: action.payload,
                },
            };
        case FETCH_PROJECT_FAILURE:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    loading: false,
                    error: action.payload,
                },
            };
        case FETCH_SUBSCRIPTION_STATUS_REQUEST:
            return {
                ...state,
                subscriptionStatus: {
                    ...state.subscriptionStatus,
                    loading: true,
                    error: null,
                },
            };
            case FETCH_SUBSCRIPTION_STATUS_SUCCESS:
                console.log("Subscription API Response:", action.payload); // ✅ Debugging
                return {
                    ...state,
                    subscriptionStatus: {
                        ...state.subscriptionStatus,
                        loading: false,
                        isSubscribed: action.payload,
                    },
                };
            
        case FETCH_SUBSCRIPTION_STATUS_FAILURE:
            return {
                ...state,
                subscriptionStatus: {
                    ...state.subscriptionStatus,
                    loading: false,
                    error: action.payload,
                },
            };
        default:
            return state;
    }
};

export default lgReducer;
