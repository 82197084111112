import React, { useState, useEffect } from "react";
import { RxCopy } from "react-icons/rx";
import { BsPatchCheck, BsPatchCheckFill, BsXCircleFill } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { toast } from "react-hot-toast";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { BsExclamationOctagonFill } from "react-icons/bs";
import { FaRegCircleCheck } from "react-icons/fa6";
import { RiEditFill } from "react-icons/ri";

import DefaultUserIcon from "assets/img/user/buycex-default-user-profile-img.png";
import { useDispatch, useSelector } from "react-redux";
import { updateUsername } from "store/actions/userUpdateAction";
import { Button, Input, Modal } from "antd";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { fetchUserPayId } from "store/actions/UserPayIdAction";
import Skeleton from "react-loading-skeleton";
import { IoIosCopy } from "react-icons/io";

const UserProfileDetails = ({
    userID,
    userToken,
    email,
    addtime,
    username,
    notVerified,
    statusMessage,
    redirectAccountOverview,
}) => {
    const [isEmailVisible, setIsEmailVisible] = useState(false);
    const { loading: loadingPayId, data } = useSelector(
        (state) => state.userPayId
    );
    const toggleEmailVisibility = () => {
        setIsEmailVisible(!isEmailVisible);
    };
    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.dismiss("success-toast");
            toast.success("UID Copied to Clipboard", { id: "success-toast" });
        } catch (err) {
            toast.dismiss("error-toast");
            toast.error("Failed to copy UID: ", err, { id: "error-toast" });
        }
    };
    const EmailCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.dismiss("success-toast");
            toast.success("Email Copied to Clipboard", { id: "success-toast" });
        } catch (err) {
            toast.dismiss("error-toast");
            toast.error("Failed to copy Email: ", err, { id: "error-toast" });
        }
    };

    const maskedEmail = email ? `${email.slice(0, 3)}****@****.com` : "";

    const [newUsername, setNewUsername] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loadingname, setLoadingname] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const dispatch = useDispatch();
    const showModal = () => setIsModalVisible(true);
    const handleCancel = () => setIsModalVisible(false);

    const loading = useSelector((state) => state.user?.loading);
    const error = useSelector((state) => state.user?.error);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newUsername.length < 5) {
            toast.error("Minimum 5 characters should be added!");
        } else {
            setLoadingname(true);
            // Add your submit logic here
            setTimeout(() => setLoadingname(false), 2000); // Simulate API call
        }
        const token = userToken;
        const id = userID;
        setIsModalVisible(false);
        dispatch(updateUsername(newUsername, token, id));
    };

    const handleChange = (e) => {
        const value = e.target.value;
        if (/^[A-Za-z]*$/.test(value) && value.length <= 10) {
            setNewUsername(value);
            setIsButtonDisabled(value.length < 5);
        }
    };

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    useEffect(() => {
        if (userID && userToken) {
            dispatch(fetchUserPayId(userToken, userID));
        }
    }, [dispatch, userID, userToken]);

    const [showDetails, setShowDetails] = useState(false);

    const handleCopyUserId = (userId) => {
        if (userId) {
            navigator.clipboard.writeText(userId);
            toast.success("User Pay ID copied!");
        } else {
            toast.error("No User Pay ID to copy!");
        }
    };

    const handleToggleDetails = () => {
        setShowDetails(!showDetails);
    };

    return (
        <div className="flex flex-col">
            {/* User Profile and Details */}
            <div className="flex gap-2 h-[64px] w-full relative justify-between items-center">
                <div className="w-full flex items-center gap-2 border-0 lg:border-r border-black pr-0">
                    <button
                        type="button"
                        onClick={redirectAccountOverview}
                        className="w-max overflow-hidden rounded-full max-w-16 max-h-16 border-[2px] border-[#15141550]"
                    >
                        <img
                            src={DefaultUserIcon}
                            alt="User icon"
                            className="w-full h-auto rounded-full"
                            loading="lazy"
                        />
                    </button>
                    {/* User details => username and UID */}
                    <div className="user-side-menu__user-status-wrapper flex flex-col gap-1 w-full lg:w-full">
                        <button
                            type="button"
                            onClick={redirectAccountOverview}
                            className="text-[18px] font-semibold w-max flex items-center gap-1"
                        >
                            <span className="user-status-wrapper__username text-[22px] capitalize">
                                {newUsername || username || "Guest User"}
                            </span>

                            <RiEditFill
                                onClick={showModal}
                                className="cursor-pointer"
                            />
                        </button>
                        <Modal
                            title="Update Username"
                            visible={isModalVisible}
                            onCancel={handleCancel}
                            footer={null}
                        >
                            <form onSubmit={handleSubmit}>
                                <div className="flex flex-col">
                                    <div className="mt-4">
                                        <p className="font-medium mb-1">
                                            Username
                                        </p>
                                        <input
                                            type="text"
                                            value={newUsername}
                                            className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2"
                                            onChange={handleChange}
                                            placeholder="Enter new username"
                                            required
                                        />
                                        <span className="text-red-500 ml-2">
                                            5-10 characters. Special characters
                                            not allowed.
                                        </span>
                                    </div>
                                    <div className="flex gap-5 mt-8">
                                        <button
                                            type="submit"
                                            className={`bg-[#EFB81C] mt-5 py-2 text-center rounded-md w-1/2 font-semibold ${
                                                isButtonDisabled
                                                    ? "opacity-50 cursor-not-allowed"
                                                    : ""
                                            }`}
                                            disabled={isButtonDisabled}
                                        >
                                            {loadingname
                                                ? "Updating..."
                                                : "Update Username"}
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleCancel}
                                            className="border-2 border-[#81858c]  mt-5 py-2 text-center rounded-md w-1/2 font-semibold"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                            {error && (
                                <p style={{ color: "red", marginTop: "10px" }}>
                                    {error}
                                </p>
                            )}
                        </Modal>
                        <span
                            className={`user-status-wrapper__verification-checker w-fit opacity-100 ${
                                statusMessage === "Verified"
                                    ? "bg-green-100 text-green-900"
                                    : statusMessage === "In Review"
                                    ? "bg-yellow-100 text-[#EFB81C]"
                                    : statusMessage === "Rejected"
                                    ? "bg-red-100 text-red-900"
                                    : "bg-gray-100 text-gray-900"
                            } px-1 py-[0.05rem] rounded-md`}
                            data-tooltip-id="account-overview-verification-status"
                            data-tooltip-place="right"
                            data-tooltip-content={
                                statusMessage === "Verified"
                                    ? "Your identity has been verified successfully."
                                    : statusMessage === "In Review"
                                    ? "Your identity verification is currently in review."
                                    : statusMessage === "Rejected"
                                    ? "Your identity verification has been rejected. Please try again."
                                    : "Not verified! Please complete KYC to get verified."
                            }
                        >
                            {statusMessage === "Verified" ? (
                                <div className="flex items-center gap-1">
                                    <FaRegCircleCheck size={14} fill="green" />
                                    <p className="text-ellipsis whitespace-nowrap">
                                        Identity Verification
                                    </p>
                                </div>
                            ) : statusMessage === "In Review" ? (
                                <div className="flex items-center gap-1">
                                    <BsExclamationOctagonFill
                                        size={14}
                                        fill="#EFB81C"
                                    />
                                    <p className="text-ellipsis whitespace-nowrap">
                                        In Review
                                    </p>
                                </div>
                            ) : statusMessage === "Rejected" ? (
                                <div className="flex items-center gap-1">
                                    <BsXCircleFill size={14} fill="red" />
                                    <p className="text-ellipsis whitespace-nowrap">
                                        Rejected
                                    </p>
                                </div>
                            ) : (
                                <div className="flex items-center gap-1">
                                    <BsXCircleFill size={14} fill="gray" />
                                    <p>Not Verified</p>
                                </div>
                            )}
                        </span>
                        <Tooltip id="account-overview-verification-status" />
                    </div>
                    <div className="lg:hidden flex justify-end mb-2 w-full">
                        <button onClick={() => handleToggleDetails()}>
                            {showDetails ? (
                                <div className="rounded-md p-1 border">
                                    <MdKeyboardArrowUp />
                                </div>
                            ) : (
                                <div className="rounded-md p-1 border">
                                    <MdKeyboardArrowDown />
                                </div>
                            )}
                        </button>
                    </div>
                </div>

                {/* // */}
                <div className="hidden lg:flex items-center justify-center gap-2 h-[64px] w-full border-r border-black pr-0">
                    <p className="mt-1 px-2.5 rounded-full user-status-wrapper__uid h-[64px] justify-between pt-2 pb-2 flex flex-col gap-1.5 items-start w-max">
                        <p className="text-xs text-[#15141590]">User Pay ID</p>
                        <div className="flex items-center gap-1.5">
                            <span className="text-xl font-semibold">
                                <div className="flex gap-1 items-center">
                                    <p className="text-sm font-medium">
                                        {data?.data}
                                    </p>
                                    <RxCopy
                                        onClick={() =>
                                            handleCopyUserId(data?.data)
                                        }
                                        className="cursor-pointer text-[#707a8a] opacity-70"
                                    />
                                </div>
                            </span>
                        </div>
                    </p>
                </div>
                <div className="hidden lg:flex items-center justify-center gap-2 w-full border-r h-[64px] border-black pr-0">
                    <p className="mt-1 px-2.5 rounded-full user-status-wrapper__uid h-[64px] justify-between pt-2 pb-2 flex flex-col gap-1.5 items-start w-max">
                        <p className="text-xs text-[#15141590]">Email</p>
                        <div className="flex items-center gap-1.5">
                            <span className="text-xl font-semibold">
                                {email
                                    ? isEmailVisible
                                        ? email
                                        : maskedEmail
                                    : "Not Set"}
                                {email && (
                                    <button
                                        onClick={toggleEmailVisibility}
                                        className="text-[#151415] ml-2"
                                    >
                                        {isEmailVisible ? (
                                            <FiEye size={16} />
                                        ) : (
                                            <FiEyeOff size={16} />
                                        )}
                                    </button>
                                )}
                            </span>
                            <button
                                type="button"
                                onClick={() => EmailCopy(email)}
                            >
                                <RxCopy
                                    size={16}
                                    className=" cursor-pointer text-[12px]"
                                />
                            </button>
                        </div>
                    </p>
                </div>
                <div className="hidden lg:flex items-center justify-center gap-2 w-full h-[64px] border-black pr-0">
                    <p className="mt-1 px-2.5 rounded-full user-status-wrapper__uid h-[64px] justify-between pt-2 pb-2 flex flex-col gap-1.5 items-start w-max">
                        <p className="text-xs text-[#15141590]">Signup Time</p>
                        <div className="flex items-center gap-1.5">
                            <span className="text-lg font-medium">
                                {new Date(addtime)
                                    .toLocaleDateString("en-GB")
                                    .replace(/\//g, "-")}
                            </span>
                        </div>
                    </p>
                </div>
            </div>
            <div
                className={`${
                    showDetails ? "block" : "hidden"
                } lg:flex lg:flex-row flex-col mt-5 lg:mt-0 gap-4`}
            >
                <div className="lg:hidden flex items-center justify-between lg:justify-center gap-2 h-auto lg:h-[64px]  w-full border-0 lg:border-r border-black pr-0">
                    <p className="mt-1 px-2.5 rounded-full user-status-wrapper__uid h-[64px] justify-between pt-2 pb-2 flex flex-row lg:flex-col gap-1.5 items-start w-full">
                        <p className="text-xs text-[#15141590]">User Pay ID</p>
                        <div className="flex items-center gap-2">
                            <span className="text-xl font-semibold">
                            {data?.data}
                            </span>
                            <button
                                type="button"
                                onClick={() => handleCopyUserId(data?.data)}
                            >
                                <RxCopy
                                    size={16}
                                    className=" cursor-pointer text-[12px]"
                                />
                            </button>
                        </div>
                    </p>
                </div>
                <div className="lg:hidden flex items-center justify-between lg:justify-center gap-2 w-full border-0 lg:border-r h-[64px] border-black pr-0">
                    <p className="mt-1 px-2.5 rounded-full user-status-wrapper__uid h-[64px] justify-between pt-2 pb-2 flex flex-row lg:flex-col gap-1.5 items-start w-full">
                        <p className="text-xs text-[#15141590]">Email</p>
                        <div className="flex items-center gap-1.5">
                            <span className="text-xl font-semibold">
                                {email
                                    ? isEmailVisible
                                        ? email
                                        : maskedEmail
                                    : "Not Set"}
                                {email && (
                                    <button
                                        onClick={toggleEmailVisibility}
                                        className="text-[#151415] ml-2"
                                    >
                                        {isEmailVisible ? (
                                            <FiEye size={16} />
                                        ) : (
                                            <FiEyeOff size={16} />
                                        )}
                                    </button>
                                )}
                            </span>
                            <button
                                type="button"
                                onClick={() => EmailCopy(email)}
                            >
                                <RxCopy
                                    size={16}
                                    className=" cursor-pointer text-[12px]"
                                />
                            </button>
                        </div>
                    </p>
                </div>
                <div className="lg:hidden flex items-center justify-between lg:justify-center gap-2 w-full h-auto lg:h-[64px]  border-black pr-0">
                    <p className="mt-1 px-2.5 rounded-full user-status-wrapper__uid h-auto lg:h-[64px] justify-between pt-2 pb-2 flex flex-row lg:flex-col gap-1.5 items-start w-full">
                        <p className="text-xs text-[#15141590]">Signup Time</p>
                        <div className="flex items-center gap-1.5">
                            <span className="text-lg font-medium">
                                {new Date(addtime)
                                    .toLocaleDateString("en-GB")
                                    .replace(/\//g, "-")}
                            </span>
                        </div>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default UserProfileDetails;
