import React from "react";

// import MarketNavigation from "components/market/MarketNavigation";
import MarketChangersCards from "components/market/overview/components/MarketChangersCards";
import MarketOverviewTable from "components/market/overview/MarketOverviewTable";
import MarketCoinInfo from "components/market/overview/components/MarketCoinInfo";

const MarketOverview = () => {
    return (
        <div className="market-overview-wrapper px-10">
            {/* Sub Navigation component */}
            {/* <MarketNavigation /> */}

            {/* :: Trending, Top Gainers, New Listing and Top Volume */}
            <MarketChangersCards />
            <MarketCoinInfo/>

            {/* :: Market Table includes Navigation for Favorites, All Crypto, Spot Market, New Listed and It's filtered data table */}
            <MarketOverviewTable />
        </div>
    );
};

export default MarketOverview;
