import React, { useEffect, useState, useRef } from 'react';
import { TbCaretUpDownFilled } from "react-icons/tb";
import NoData from "assets/img/user/No-data.svg";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import fetchAssetsData from 'store/actions/AssetsUserAction';
import TransferModal from 'views/trade/PostAds/TransferModal';
import ConvertModal from 'views/trade/PostAds/ConvertModal';
import { FinanceUserSpot } from 'store/actions/AssetsUserAction';
import { IoMdMore } from "react-icons/io";

function CoinView({ isVisible, searchQuery, showGreaterThanOne }) {
    const [tableData, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();
    const { assetsData } = useSelector((state) => state.assetsData);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const { data } = useSelector((state) => state.financeUserSpotReducer);
    useEffect(() => {
        dispatch(FinanceUserSpot(userID, userToken,));
    }, [dispatch, userID, userToken, history]);
  
    useEffect(() => {
           if (data && data.coinList) {
             const coinList = Object.keys(data.coinList)
               .map((key, index) => {
                 const coin = data.coinList[key];
                 return {
                   id: index,
                   icon: coin.img || "default_coin_image.png",
                   coinName: coin.name.toUpperCase(),
                   coinType: coin.title.match(/\[([^\[\]]+)\]/)?.[1].trim() || "Unknown",
                   amount: parseFloat(coin.amount) || 0, 
                   coinPrice: parseFloat(coin.price) || 0,
                   usdt_conversion: parseFloat(coin.usdt_conversion) || 0,
                 };
               })
               .sort((a, b) => b.amount - a.amount); 
         
             setTableData(coinList); 
           }
         }, [data]);

    useEffect(() => {
        // Filter coins based on the search query and the checkbox state
        const filteredCoins = tableData.filter(coin => {
            const matchesSearch = coin.coinName.includes(searchQuery.toUpperCase());
            const greaterThanOne = showGreaterThanOne ? coin.amount > 1 : true;
            return matchesSearch && greaterThanOne;
        });
        setFilteredData(filteredCoins);
    }, [tableData, searchQuery, showGreaterThanOne]);

    const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
    const handleTransferClick = () => {
        setIsTransferModalVisible(true);
    };

    const handleTransferCancel = () => {
        setIsTransferModalVisible(false);
    };

    const [isConvertModalVisible, setIsConvertModalVisible] = useState(false);
    const handleConvertClick = () => {
        setIsConvertModalVisible(true);
    };

    const handleConvertCancel = () => {
        setIsConvertModalVisible(false);
    };

    const [openDropdown, setOpenDropdown] = useState({});

    const handleDropdownToggle = (id) => {
        setOpenDropdown((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenDropdown((prevState) => {
                    const updatedState = { ...prevState };
                    Object.keys(updatedState).forEach((key) => {
                        updatedState[key] = false;
                    });
                    return updatedState;
                });
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const paginatedData = filteredData.slice(startIndex, endIndex);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
  
    return (
        <div className='w-full'>
            {/* Header row */}
            <div className='flex justify-between w-full mb-8'>
                <div className='flex items-center gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Coins</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Amount</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5] whitespace-nowrap'>Coin Price</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-end gap-1 w-1/5 md:w-2/5'>
                    <p className='text-xs text-[#929AA5]'>Action</p>
                </div>
            </div>

            {/* Check if there are any filtered coins and display the appropriate message */}
            {paginatedData.length === 0 ? (
                <div className='text-center text-[14px] md:text-[16px]'>
                    <div className='flex flex-col gap-1 h-44 justify-center items-center'>
                        <img src={NoData} alt="No Data" />
                        <p className='text-[#707a8a] text-sm'>No records</p>
                    </div>
                </div>
            ) : (
                paginatedData.map((data) => (
                    <div key={data.id} className='flex w-full items-center justify-between mb-4'>
                        <div className='flex gap-2 w-1/5'>
                            <div className='flex items-center gap-2'>
                                <img
                                    src={`https://api.buycex.com/Upload/coin/${data.icon}`}
                                    alt=""
                                    className="w-6 h-6 rounded-full"
                                />
                                <div className="flex flex-col">
                                    <span className="md:text-[16px] text-sm font-medium">
                                        {data.coinName || 'Unknown Coin'}
                                    </span>
                                    <span className="text-[12px] md:text-[14px] text-[#aaaaaa]">
                                        {data.coinType || 'N/A'}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-1/5 items-end">
  <div className="flex justify-end gap-2 items-center">
    <p className="text-md font-normal">{isVisible ? data.amount : '****'}</p>
    <span className="text-md text-[#aaaaaa] font-normal"> {isVisible ? data.coinName : '****'} </span>
  </div>
  <p className="text-sm text-end text-[#707a8a]">
  {isVisible ? `$${data.usdt_conversion}` : '****'}
</p>

</div>
                        <div className='flex flex-col gap-1 w-1/5'>
                            <p className='text-[14px] md:text-[16px] text-end font-normal'>${isVisible ? data.coinPrice : '****'}</p>
                        </div>
                        <div className="flex justify-end gap-1 w-1/5 md:w-2/5">
                            <div className="hidden md:flex gap-2">
                                <button className="text-sm flex items-center px-2 py-1 border rounded-md">Buy   {data.coinName || 'Unknown Coin'}</button>
                                <button className="text-sm flex items-center px-2 py-1 border rounded-md">Sell   {data.coinName || 'Unknown Coin'}</button>
                                <button className="text-sm flex items-center px-2 py-1 border rounded-md" onClick={handleConvertClick}>Convert</button>
                                <ConvertModal visible={isConvertModalVisible} onCancel={handleConvertCancel} />
                                <button className="text-sm flex items-center px-2 py-1 border rounded-md" onClick={handleTransferClick}>Transfer</button>
                                <TransferModal visible={isTransferModalVisible} onCancel={handleTransferCancel} />
                            </div>

                            <div className="md:hidden">
                                <button className="text-sm flex items-center px-2 py-1 border rounded-md" onClick={() => handleDropdownToggle(data.id)}>
                                    <IoMdMore className="text-lg" />
                                </button>

                                {openDropdown[data.id] && (
                                    <div ref={dropdownRef} className="absolute mt-2 right-0 w-40 bg-white shadow-lg rounded-md border border-gray-200">
                                        <button className="text-sm w-full px-3 py-2 text-left">Buy   {data.coinName || 'Unknown Coin'}</button>
                                        <button className="text-sm w-full px-3 py-2 text-left">Sell   {data.coinName || 'Unknown Coin'}</button>
                                        <button className="text-sm w-full px-3 py-2 text-left" onClick={handleConvertClick}>Convert</button>
                                        <button className="text-sm w-full px-3 py-2 text-left" onClick={handleTransferClick}>Transfer</button>
                                    </div>
                                )}
                                <ConvertModal visible={isConvertModalVisible} onCancel={handleConvertCancel} />
                                <TransferModal visible={isTransferModalVisible} onCancel={handleTransferCancel} />
                            </div>
                        </div>


                    </div>
                ))
            )}
            <div className="flex justify-center mt-4">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-3 py-1 mx-1 text-xs font-semibold border rounded ${currentPage === index + 1 ? 'bg-yellow-500 text-black' : 'bg-gray-100 text-gray-700'}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default CoinView;
