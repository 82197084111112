import React, { useState } from 'react'
import SelectCoin from './SelectCoin';
import SelectChain from './SelectChain';
import { GrHelpBook } from 'react-icons/gr';
import { MdLibraryBooks } from 'react-icons/md';

function TraceDeposit() {
    const coins = [
        { value: "btc", label: "Bitcoin", icon: "https://crypto.com/icon-btc.png" },
        { value: "eth", label: "Ethereum", icon: "https://crypto.com/icon-eth.png" },
        { value: "bnb", label: "Binance Coin", icon: "https://crypto.com/icon-bnb.png" },
    ];
    const chains = [
        { value: "eth", label: "Ethereum", icon: "https://crypto.com/icon-eth.png" },
        { value: "bsc", label: "Binance Smart Chain", icon: "https://crypto.com/icon-bnb.png" },
        { value: "polygon", label: "Polygon", icon: "https://crypto.com/icon-matic.png" },
    ];

    const [selectedCoin, setSelectedCoin] = useState(null);
    const [selectedChain, setSelectedChain] = useState(null);

    return (
        <div className='container'>
            <div>
                <p className='text-[#EFB81C] text-sm font-medium my-10'>My Deposit Hasn't Arrived</p>
                <h1 className='text-2xl font-bold mt-10 mb-3'>Missing Tag/Memo</h1>
                <p className='bg-[#EFB81C]/30 inline-block px-2 text-sm'>just ensure your address details are correct to prevent deposit issues. Thank you for your cooperation.</p>

                <div className='flex w-full gap-10'>
                    <div className='w-1/2'>
                        <div className='flex flex-col gap-2 mt-8'>
                            <label className='text-sm font-medium'>Coin</label>
                            <SelectCoin
                                options={coins}
                                selectedItem={selectedCoin}
                                setSelectedItem={setSelectedCoin}
                                placeholder="Choose a coin"
                                handleItemClick={(coin) => console.log("Selected:", coin)}
                            />
                        </div>
                        <div className='flex flex-col gap-2 mt-8'>
                            <label className='text-sm font-medium'>Chain Type</label>
                            <SelectChain
                                options={chains}
                                selectedItem={selectedChain}
                                setSelectedItem={setSelectedChain}
                                placeholder="Choose a chain"
                                handleItemClick={(chain) => console.log("Selected Chain:", chain)}
                            />
                        </div>
                        <div className='flex flex-col gap-2 mt-8'>
                            <label className='text-sm font-medium'>TxID/TxHash</label>
                            <input
                                type="text"
                                placeholder="Please Enter"
                                className="flex items-center justify-between placeholder:text-gray-300 placeholder:font-semibold border border-black hover:ring-1 ring-[#EFB81C] hover:border-[#EFB81C] rounded-lg px-4 py-3 cursor-pointer bg-white dark:bg-gray-800 dark:text-white"
                            />
                        </div>
                        <button className='bg-[#EFB81C] px-6 py-2 rounded-sm my-10 font-medium text-sm'>Submit</button>
                    </div>
                    <div className='border rounded-xl flex flex-col gap-6 self-start w-1/2 mt-8 p-10'>
                        <h2 className='font-bold text-2xl'>Related Actions</h2>
                        <div className='flex items-center gap-2 opacity-40 hover:opacity-100 cursor-pointer'>
                            <GrHelpBook />
                            <p className='text-sm font-medium'>submit an appeal</p>
                        </div>
                        <div className='flex items-center gap-2 opacity-40 hover:opacity-100 cursor-pointer'>
                            <MdLibraryBooks />
                            <p className='text-sm font-medium'>FAQ</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TraceDeposit