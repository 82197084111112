import React, { useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';

import { Select, Input } from "antd";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    font-size: 18px;
    font-weight: 800;
    color: black; /* Default for light mode */
  }

  /* Dark mode */
  .dark & .ant-select-selection-item {
    color: white;
  }

  .ant-select-arrow {
    color: black; /* Light mode arrow color */
  }

  .dark & .ant-select-arrow {
    color: white; /* Dark mode arrow color */
  }
`;

function HeroCard() {
    const BTC_PRICE = 70419.20;
    const [selectedCurrency, setSelectedCurrency] = useState('USDT');
    const [inputValue, setInputValue] = useState('1');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const calculateQuantity = (value, currency, frequency) => {
        const baseValue = parseFloat(value);

        // Adjusting calculations based on frequency selection
        let adjustedValue;
        switch (frequency) {
            case "Daily":
                adjustedValue = baseValue;  // No adjustment needed for daily
                break;
            case "Weekly":
                adjustedValue = baseValue * 7;  // Weekly = daily * 7
                break;
            case "Monthly":
                adjustedValue = baseValue * 30;  // Monthly = daily * 30 (approximation)
                break;
            default:
                adjustedValue = baseValue; // Fallback to daily
        }

        if (currency === 'USDT') {
            return (adjustedValue / BTC_PRICE).toFixed(8);
        } else {
            return (adjustedValue * BTC_PRICE).toFixed(2);
        }
    };


    const getRate = (currency) => {
        if (currency === 'USDT') {
            return (
                <div className='flex justify-between text-xl font-semibold mt-2'>
                    <span className='text-sm font-semibold'>1 USDT Value</span>
                    <span className='text-sm font-semibold'>    {(1 / BTC_PRICE).toFixed(8)} BTC</span>
                </div>
            );
        } else {
            return (
                <div className='flex justify-between text-xl font-semibold mt-2'>
                    <span className='text-sm font-semibold'>1 BTC Value</span>
                    <span className='text-sm font-semibold'>{BTC_PRICE.toFixed(2)} USDT</span>
                </div>
            );
        }
    };

    const handleSelectCurrency = (currency) => {
        setSelectedCurrency(currency);
        setInputValue('1');
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handlePresetValueClick = (value) => {
        setInputValue(value.toString());
    };

    const handleSubmit = () => {
        setIsSubmitted(true);
    };

    const [selectedFrequency, setSelectedFrequency] = useState("Daily");

    const handleFrequencyChange = (value) => {
        setSelectedFrequency(value);
    };

    const imageCurrency = selectedCurrency === "BTC" ? "USDT" : "BTC";

    return (
        <div className='text-black dark:text-white'>
            <section className="w-full">
                <div className="border dark:border-[#454545] dark:bg-[#0b0c0f] bg-[#e5e7eb] rounded-3xl shadow-lg">
                    <div className="flex gap-0 h-[60px] rounded-t-3xl bg-gray-200 dark:bg-black w-full relative">
                        <div
                            onClick={() => handleSelectCurrency('USDT')}
                            className={`text-xl sm:text-2xl font-semibold flex justify-center items-center py-1.5 w-1/2 rounded-tl-3xl rounded-tr-3xl ${selectedCurrency === 'USDT' ? 'bg-white dark:bg-[#151415]' : 'text-[#adb1b8]'}`}
                        >
                            USDT
                        </div>

                        <div
                            style={{ transform: "skewX(15deg)" }}
                            className={`absolute left-[45%] -translate-x-1/2 h-[60px] w-10 rounded-tr-3xl ${selectedCurrency === 'USDT' ? "bg-white dark:bg-[#090a0c]" : "bg-transparent"}`}
                        ></div>

                        <div
                            style={{ transform: "skewX(-15deg)" }}
                            className={`absolute left-[45%] translate-x-1/2 h-[60px] w-10 rounded-tl-3xl ${selectedCurrency === 'BTC' ? "bg-white dark:bg-[#090a0c]" : "bg-transparent"}`}
                        ></div>

                        <div
                            onClick={() => handleSelectCurrency('BTC')}
                            className={`text-xl sm:text-2xl font-semibold flex justify-center items-center py-1.5 w-1/2 rounded-tl-3xl rounded-tr-3xl ${selectedCurrency === 'BTC' ? 'bg-white dark:bg-[#151415]' : 'text-[#adb1b8]'}`}
                        >
                            BTC
                        </div>
                    </div>

                    <div className={`flex w-full rounded-b-3xl py-5 sm:py-10 px-4 sm:px-8 ${selectedCurrency === 'USDT' ? 'bg-white rounded-tr-3xl' : 'bg-white rounded-tl-3xl'}`}>
                        {/* Step indicator */}
                        {/* <div className="flex flex-col bg-transparent w-[16%] items-center gap-8">
                            <div>
                                <div className="flex items-center justify-end">
                                    <div className="flex mt-2 sm:mt-0 flex-col items-center">
                                        <div className={`relative flex items-center justify-center rounded-full w-6 sm:w-8 h-6 sm:h-8 ${isSubmitted ? 'border dark:bg-white/60' : 'dark:bg-white bg-black'}`}>
                                            <span className="absolute transform text-md font-semibold">
                                                {isSubmitted ? '1' : <FaCheck className={`dark:text-black text-white`} />}
                                            </span>
                                        </div>
                                        <div className={`w-[1px] ${isSubmitted ? 'h-[1.8rem]' : 'h-[14rem]'} opacity-80 bg-[#454545]`}></div>
                                        <div className="flex items-center justify-center w-6 sm:w-8 h-6 sm:h-8">
                                            <div className={`relative flex items-center justify-center rounded-full w-6 sm:w-8 h-6 sm:h-8 ${isSubmitted ? 'bg-black dark:bg-white' : 'border dark:bg-white/60'}`}>
                                                <span className="absolute transform text-md font-semibold">
                                                    {isSubmitted ? <FaCheck className={`dark:text-black text-white ${isSubmitted ? 'visible' : 'invisible'}`} /> : '2'}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* Main Card Content */}
                        <div className={`w-full flex flex-col mt-1 min-h-full ${isSubmitted ? 'justify-normal gap-4' : 'justify-between'}`}>
                            {/* Step 1: Enter Amount */}
                            <div>
                                <div className='flex items-center mb-4 w-full justify-between'>
                                    <label className="block text-md font-medium text-black dark:text-white">Enter amount</label>
                                    {isSubmitted ? <BsChevronDown /> : <BsChevronUp />}
                                </div>
                                {isSubmitted ? (
                                    <>
                                    </>
                                ) : (
                                    // Show input field and submit button
                                    <>
                                        <div className='border border-[#EFB81c] flex items-center rounded-lg'>
                                            <input
                                                type="number"
                                                value={inputValue}
                                                onChange={handleInputChange}
                                                className='text-lg sm:text-xl w-2/3 font-semibold pl-8 py-3.5 rounded-lg focus:outline-none bg-transparent dark:bg-black text-black dark:text-white custom-input'
                                            />

                                            <span className='p-2 mr-3 w-1/3 rounded-lg bg-[#f7f7f7] flex justify-between items-center text-ellipsis whitespace-nowrap'>
                                                <StyledSelect
                                                    className="bg-[#f7f7f7] dark:bg-[#151415] dark:text-white"
                                                    style={{
                                                        borderRadius: "10px",
                                                        width: "35%",
                                                        minWidth: "120px",
                                                        height: "auto",
                                                    }}
                                                    variant="borderless"
                                                    value={selectedFrequency}
                                                    onChange={handleFrequencyChange}
                                                >
                                                    <Option value="Daily">Daily</Option>
                                                    <Option value="Weekly">Weekly</Option>
                                                    <Option value="Monthly">Monthly</Option>
                                                </StyledSelect>
                                            </span>

                                        </div>
                                        <div className="flex gap-2 mt-2 w-full justify-between">
                                            {selectedCurrency === 'BTC'
                                                ? [0.01, 0.05, 0.1, 0.15, 0.2, 0.5].map(value => (
                                                    <button key={value}
                                                        onClick={() => handlePresetValueClick(value)}
                                                        className='px-2 py-0.5 border dark:border-[#454545] w-1/6 text-xs sm:text-sm dark:bg-[#151415] rounded-lg text-black dark:text-white font-medium'>
                                                        {value}
                                                    </button>
                                                ))
                                                : [1, 5, 10, 15, 20, 50].map(value => (
                                                    <button key={value}
                                                        onClick={() => handlePresetValueClick(value)}
                                                        className='px-2 sm:px-4 py-0.5 border dark:border-[#454545] w-1/6 text-xs sm:text-sm dark:bg-[#151415] rounded-lg text-black dark:text-white font-medium'>
                                                        {value}
                                                    </button>
                                                ))
                                            }
                                        </div>

                                        <div className='flex justify-end items-center gap-2 w-full mt-2'>
                                            <span className='text-sm font-medium opacity-75 text-end'>
                                                {inputValue} {selectedCurrency} ≈ {calculateQuantity(inputValue, selectedCurrency, selectedFrequency)}
                                            </span>
                                            <img
                                                src={`https://global.buycex.com/Upload/coin/${imageCurrency}.png`}
                                                alt={imageCurrency}
                                                className="w-6 h-6"
                                            />
                                        </div>

                                        <button onClick={handleSubmit} className='bg-[#EFB81C] rounded-xl py-2 text-center w-full my-2 font-medium'>Submit</button>
                                    </>
                                )}
                            </div>

                            {/* Step 2: Order Preview */}
                            <div>
                                <div className={`flex items-center ${isSubmitted ? 'py-0 pb-4' : 'pt-6 pb-1'} w-full justify-between`}>
                                    <label className="block text-md font-medium text-black dark:text-white">Order preview</label>
                                    {isSubmitted ? <BsChevronUp /> : <BsChevronDown />}
                                </div>
                                {isSubmitted && (
                                    <>
                                        <div className='border dark:border-[#454545] px-3 py-3 rounded-lg'>
                                            <div className='flex justify-between items-center text-xl font-semibold'>
                                                <span className='text-sm'>{inputValue} {selectedCurrency}</span>
                                                <div className='flex items-center gap-1'>
                                                <span className='text-sm'>{calculateQuantity(inputValue, selectedCurrency)}</span>
                                                <img
                                                src={`https://global.buycex.com/Upload/coin/${imageCurrency}.png`}
                                                alt={imageCurrency}
                                                className="w-5 h-5"
                                            />
                                                </div>
                                            </div>
                                            <p className='text-xl font-semibold mt-2'>{getRate(selectedCurrency)}</p>
                                            <div className='flex justify-between mt-2 items-center text-xl font-semibold'>
                                                <span className='text-sm'>Deducting Wallet</span>
                                                <span className='text-sm'>Funding</span>
                                            </div>
                                            <div className='flex justify-between mt-2 items-center text-xl font-semibold'>
                                                <span className='text-sm'>Fee</span>
                                                <span className='text-sm'>0.00</span>
                                            </div>
                                            <div className='flex justify-between mt-2 items-center text-xl font-semibold'>
                                                <span className='text-sm'>Frequency</span>
                                                <span className="text-sm">{selectedFrequency}</span>
                                            </div>
                                        </div>
                                        <button className='bg-[#EFB81C] rounded-xl py-2 text-center w-full mt-4 font-medium'>Set Auto Pay</button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <style jsx>{`
    .custom-input::-webkit-inner-spin-button, 
    .custom-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .custom-input {
        -moz-appearance: textfield; /* For Firefox */
    }
`}</style>
        </div>
    );
}

export default HeroCard;
