import React, { useState } from 'react';
import { MdSearch } from "react-icons/md";
import FundingValues from './FundingsValues';
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom/cjs/react-router-dom';

const FundingTable = ({ isVisible }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [hideLowValueAssets, setHideLowValueAssets] = useState(false);
    const [showGreaterThanOne, setShowGreaterThanOne] = useState(false);
    const [showSearchInput, setShowSearchInput] = useState(false);

    const renderContent = () => {
        return (
            <FundingValues
                isVisible={isVisible}
                searchQuery={searchQuery}  // Pass search query here
                showGreaterThanOne={showGreaterThanOne}
                hideLowValueAssets={hideLowValueAssets} // Pass this state too
            />
        );
    };

    // Toggle the state for hiding low-value assets
    const handleCheckboxClick = () => {
        setHideLowValueAssets(prev => !prev);
    };

    return (
        <div className='p-3 sm:p-6 mt-6 border rounded-2xl'>
            <div className='flex flex-col md:flex-row justify-between'>
                <div className='flex gap-5'>
                    <p className={`font-semibold text-2xl cursor-pointer pb-3`}>Funding</p>
                </div>

                <div className='flex justify-between md:justify-normal gap-5'>
                <div className='flex justify-end items-center border-gray-200'>
                <div className='relative flex items-center' onMouseEnter={() => setShowSearchInput(true)} onMouseLeave={() => setShowSearchInput(false)}>
                    {showSearchInput ? (
                        <input
                            type="text"
                            className="transition-all duration-300 ease-in-out w-0 px-3 py-1 rounded-md border border-gray-300 focus:outline-none"
                            style={{
                                width: showSearchInput ? '150px' : '0',
                                opacity: showSearchInput ? '1' : '0',
                                paddingLeft: showSearchInput ? '8px' : '0',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                            }}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search assets..."
                            autoFocus
                        />
                    ) : (
                        <MdSearch className="text-gray-600 text-lg cursor-pointer transition-all duration-300 hover:text-black" />
                    )}
                </div>
                <div className='flex items-center cursor-pointer' onClick={handleCheckboxClick}>
                    <div className='flex items-center'>
                        <input
                            type="checkbox"
                            checked={showGreaterThanOne}
                            onChange={() => setShowGreaterThanOne(!showGreaterThanOne)}
                            className="mr-2"
                        />
                        <label className='text-sm font-medium'>Hide assets &lt; 1 USD</label>
                    </div>
                </div>
            </div>
                <Link to='/market/overview' className='flex items-center'>
                    <p className='text-xs md:text-sm font-medium text-[#EFB81C]'>View All </p>
                    <MdKeyboardArrowRight className='text-lg text-[#EFB81C]' />
                </Link>
                </div>
            </div>

            <div className="mt-6">{renderContent()}</div>
        </div>
    );
};

export default FundingTable;
