import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import TransferModal from "views/trade/PostAds/TransferModal";
import { createstake, userbalance } from "store/actions/AssetsUserAction";
import toast from "react-hot-toast";

const LockModal = ({ isVisible, handleClose, data, id }) => {
    const dispatch = useDispatch();
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);

    const [availableBalance, setAvailableBalance] = useState("0");
    const [amount, setAmount] = useState("");
    const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);

    useEffect(() => {
        if (isVisible) {
            dispatch(userbalance(userID, userToken))
                .then((response) => {
                    const userBalances =
                        response?.data?.data?.user_balance || {};
                    const coinName = data?.pool_name?.toLowerCase().trim();
                    if (Object.keys(userBalances).includes(coinName)) {
                        setAvailableBalance(userBalances[coinName] || "0");
                    } else {
                        setAvailableBalance("0");
                    }
                })
                .catch((error) =>
                    console.error("Error fetching balance:", error)
                );
        }
    }, [isVisible, dispatch, userID, userToken, data]);

    const handleButtonClick = () => {
        setIsTransferModalVisible(true);
    };

    const handleLock = () => {
        console.log("Locking Data:", { data, amount });

        if (!amount || isNaN(amount)) {
            console.error("Please enter a valid amount");
            return;
        }

        const formData = new FormData();
        formData.append("launch_pool_id", data?.pool_id || id);
        formData.append("amount", amount);

        dispatch(createstake(userID, userToken, formData))
            .then((response) => {
                console.log("API Response:", response);

                if (response?.success) {
                    handleClose();
                    setAmount("");
                    toast.success(response.data.data.data);
                } else {
                    toast.error(response.error.data.data);
                }
            })
            .catch((error) => console.error("Error:", error));
    };

    return (
        <Modal
            title={
                <div className="flex items-center gap-3">
                    <span className="text-2xl font-bold">
                        Locking {data?.pool_name}
                    </span>
                </div>
            }
            open={isVisible}
            onCancel={handleClose}
            footer={null}
        >
            <div className="relative w-full">
                <input
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Amount"
                    className="w-full border rounded-md my-6 placeholder:text-[16px] focus:outline-none py-3 px-3 pr-16 text-[16px]"
                />
                <button
                    onClick={() => {
                        console.log(
                            "Max clicked, setting amount to:",
                            availableBalance
                        );
                        setAmount(availableBalance);
                    }}
                    className="absolute right-20 top-1/2 transform -translate-y-1/2 border-r px-3 py-1 text-[16px] text-[#EFB81c] font-semibold"
                >
                    Max
                </button>
                <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 text-[16px]">
                    {data?.pool_name}
                </span>
            </div>

            <div className="flex items-center justify-between">
                <span className="text-sm dark:text-white">
                    <span className="opacity-60 dark:text-white text-sm">
                        Available:
                    </span>{" "}
                    {availableBalance} {data?.pool_name}
                </span>
                <span className="text-[#EFb81c]">
                    <span
                        onClick={handleButtonClick}
                        className="border-r dark:border-[#454545]/60 px-2 cursor-pointer !text-sm"
                    >
                        Transfer
                    </span>{" "}
                    <span className="pl-1 !text-sm">Buy {data?.pool_name}</span>
                </span>
            </div>

            <button
                onClick={handleLock}
                className="bg-[#EFB81c] rounded-[0.375rem] dark:text-white w-full h-10 font-semibold mt-16"
            >
                Lock
            </button>

            <TransferModal
                visible={isTransferModalVisible}
                onCancel={() => setIsTransferModalVisible(false)}
            />
        </Modal>
    );
};

export default LockModal;
