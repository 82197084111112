import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    verifyCodeFromEmail,
    SetEmail,
    sendCodeToEmail,
    verifySaveEmail,
    verifyG2faCode,
} from "store/actions/authAction";
import { TbBrandGoogle, TbLock } from "react-icons/tb";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { IoMailOutline } from "react-icons/io5";
import { Modal, Button } from "antd";
import toast from "react-hot-toast";
import ModalVerification from "components/auth/validations/email/emailVerificationHelp";

function CryptoEmailVerificationModal({ isVisible, onClose, onSuccess, otp, setOtp, g2faOtp, setG2faOtp, fundPassword, setFundPassword, handleWithdraw }) {

    const [showModal, setShowModal] = useState(false);
    const [timer, setTimer] = useState(60);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const state = useSelector((state) => state.Newemail.email);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const stateS = useSelector((state) => state.userInfo.userProfile.email);
    const email = useSelector((state) => state.authCheck.authStatus.email);
    const [googleAuthCode, setGoogleAuthCode] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const isOtpValid = otp.trim().length > 0; // Validate OTP is not empty
        const isGoogleCodeValid = googleAuthCode.trim().length > 0; // Validate Google Auth code is not empty
        setIsFormValid(isOtpValid && isGoogleCodeValid); // Enable button only if both are valid
    }, [otp, googleAuthCode]);

    const dispatch = useDispatch();


    useEffect(() => {
        let interval = null;
        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        setIsButtonDisabled(false);
                        return 60;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isButtonDisabled]);


    const verifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Verify 2FA Code
        if (!g2faOtp) {
            toast.error("Please enter the 2FA code.");
            setLoading(false);
            return;
        }

        const g2faPayload = {
            user_id: localStorage.getItem("user_id_2fa") || userID,
            ga: parseInt(g2faOtp),
        };

        await new Promise((resolve) => {
            dispatch(
                verifyG2faCode(
                    g2faPayload,
                    () => {
                        // toast.success("2FA code verified successfully!");
                        resolve(true);
                    },
                    () => {
                        toast.error("2FA verification failed!");
                        resolve(false);
                    }
                )
            );
        });

        const payload = {
            user_id: userID,
            code: otp,
        };
        const payloadS = {
            email: state,
            user_id: userID,
            code: otp,
        };

        dispatch(
            verifyCodeFromEmail(payload, true, () => {
                setLoading(false);
                onSuccess();
            })
        );
    };


    const handleSendCode = () => {
        if (!isButtonDisabled) {
            const payload = {
                email: state,
                user_id: userID,
            };
            const payloadE = {
                email: email,
                user_id: userID,
            };
            dispatch(sendCodeToEmail(payloadE)); // Dispatch for sending code to email
            setIsButtonDisabled(true);
        }
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);

        const payload = {
            user_id: userID,
            code: otp,
        };

        const isSuccess = await dispatch(verifyCodeFromEmail(payload, true, () => onSuccess()));
        setLoading(false);

        if (isSuccess) {
            onSuccess(); // Proceed to the next step upon successful verification
        }
    };

    return (
        <Modal
            title="Secuity Verification"
            visible={isVisible}
            onCancel={onClose}
            footer={null}
        >
            <div className="flex flex-col gap-4 my-auto">


                <form onSubmit={verifyOtp}>
                    {/* Fund Password Section */}
                    <div className="flex flex-col gap-2 items-start justify-center">
                        <div className="flex items-center gap-1 mt-3 text-[#81858c]">
                            <TbLock />
                            <p>Fund Password</p>
                        </div>
                        <div className="relative w-full">
                            <input
                                type={showPassword ? "text" : "password"}
                                value={fundPassword}
                                onChange={(e) => setFundPassword(e.target.value)}
                                required
                                className="bg-gray-100 rounded-md focus:outline-none px-3 w-full mb-3 py-2 no-arrows"
                                placeholder="Please enter your Fund Password"
                                style={{ appearance: "textfield" }}
                            />
                            <div
                                className="absolute top-3 right-3 flex items-center cursor-pointer text-gray-500"
                                onClick={() => setShowPassword((prev) => !prev)}
                            >
                                {showPassword ? <AiOutlineEyeInvisible className="opacity-60" size={18} /> : <AiOutlineEye className="opacity-60" size={18} />}
                            </div>
                        </div>
                    </div>

                    {/* OTP Verification Section */}
                    <div className="flex flex-col gap-2 items-start justify-center">
                        <div className="flex text-[#81858c] items-center gap-1">
                            <IoMailOutline />
                            <h6 className="text-sm text-[#81858c]">
                                A code has been sent to the{" "}
                                <span className="text-md font-semibold text-black">{email}</span>.
                            </h6>
                        </div>
                        <div className="flex justify-between bg-gray-100 rounded-md py-2 px-4 w-full">
                            <input
                                type="text"
                                placeholder="Enter Verification code"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                className="bg-transparent text-black font-medium focus:outline-none border-none"
                            />
                            <div>
                                <span
                                    className={`text-[#F1BA19] font-medium text-sm ${isButtonDisabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
                                    onClick={handleSendCode}
                                >
                                    Send Verification Code{" "}
                                    {isButtonDisabled ? `(${timer}s)` : ""}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* Google Verification Section */}
                    <div className="flex flex-col gap-2 items-start justify-center">
                        <div className="flex items-center gap-1 mt-3 text-[#81858c]">
                            <TbBrandGoogle />
                            <p>Google verification code</p>
                        </div>
                        <input
                            type="number"
                            value={g2faOtp}
                            onChange={(e) => setG2faOtp(e.target.value)}
                            className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2 no-arrows"
                            placeholder="Please enter your Google Authenticator code"
                            style={{ appearance: "textfield" }}
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="flex flex-col gap-2 items-start justify-center">
                        <button
                            onClick={handleWithdraw}
                            type="submit"
                            className="btn bg-[#EFB81C] py-2 mt-4 rounded-md text-black font-semibold text-center w-full focus:no-underline focus:outline-none"
                        >
                            Verify
                        </button>
                    </div>
                </form>

                <div className="flex self-end">
                    <button
                        onClick={() => setShowModal(true)}
                        className="block text-[#F1BA19] hover:no-underline text-start font-semibold w-full"
                        style={{ cursor: "pointer" }}
                    >
                        Having problems with verification?
                    </button>
                    <ModalVerification
                        visible={showModal}
                        onClose={() => setShowModal(false)}
                        email={email}
                    />
                </div>
                <style>
                    {`
                .no-arrows::-webkit-inner-spin-button,
                .no-arrows::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                .no-arrows {
                    -moz-appearance: textfield; /* For Firefox */
                }
                `}
                </style>
            </div>
        </Modal>
    );
}

export default CryptoEmailVerificationModal;
