import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Input } from 'antd';
import { IoClose } from 'react-icons/io5';

import { TbTransferVertical } from "react-icons/tb";

import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import { IoInformationCircle } from "react-icons/io5";

import { fetchAssetsData } from "store/actions/AssetsUserAction";
import { fetchBalanceTypes } from 'store/actions/convertbalanceActions';
import { doTrade, fetchCoinBalance,getConvertedAmount } from 'store/actions/cryptoConvertAction';
import CryptoTransactionModal from 'components/cryptoConvertComponents/CryptoTransactionModal';
import ConvertPreviewModal from 'components/cryptoConvertComponents/ConvertPreviewModal';
import CurrencySelectModal from './CurrencySelectModal';
import CurrencyInput from './CurrencyInput';
const ConvertModal = ({ visible, onCancel }) => {
    const { assetsData, loading: assetsLoading } = useSelector((state) => state.assetsData || {});
    const [fromValue, setFromValue] = useState('');
    const [toValue, setToValue] = useState('');
    const [selectedCurrencies, setSelectedCurrencies] = useState({
        from: null, // Will be dynamically set
        to: null, // Will be dynamically set
    });
     const [inputError, setInputError] = useState(false);
const [showModal, setShowModal] = useState(false);
            const [currencyConversionRates, setCurrencyConversionRates] = useState({});
             const [filteredCurrencies, setFilteredCurrencies] = useState([]);
const userID = useSelector((state) => state.authUser?.user?.ID);
  const token  = useSelector((state) => state.authUser?.user?.TOKEN);
   const { tradeData, balances } = useSelector((state) => state.cryptoConvert);
  const dispatch = useDispatch();
       useEffect(() => {
            if (!userID || !token ) {
                history.push('/crypto-convert');
                return;
            }
            dispatch(fetchAssetsData(userID, token , "fund"));
        }, [dispatch, userID, token , history]);

   
    const [isCurrencyModalVisible, setCurrencyModalVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentInput, setCurrentInput] = useState('from');
        
 const [transactionModal, setTransactionModal] = useState(false);
 const [fromCoinsData, setFromCoinsData] = useState([]);


 const [valueFrom, setValueFrom] = useState('');
  const { balanceTypes, loading, error } = useSelector((state) => state.balance);
    const handleChange = (setter) => (e) => setter(e.target.value);
    const handleCurrencyClick = (type) => {
        setCurrentInput(type);
        setCurrencyModalVisible(true);
    };
    const  selectedCoinFrom= selectedCurrencies.from?.value?.toLowerCase();
    const selectedCoinTo = selectedCurrencies.to?.value?.toLowerCase();
    const [valueTo, setValueTo] = useState('')
    const balance = balances[selectedCoinFrom]?.balance || 0;

    const handlePreviewClick = () => {
  
        setShowModal(true);
      
    };


    useEffect(() => {
        if (selectedCoinFrom || selectedCoinTo) {
            dispatch(fetchCoinBalance({
                user_id: userID,
                token: token,
                coinname: selectedCoinFrom || selectedCoinTo
            }));
        }
    }, [selectedCoinFrom, selectedCoinTo, userID, token, dispatch]);


  


    useEffect(() => {
        dispatch(fetchBalanceTypes(token, userID));
    }, [dispatch, token, userID]);


  
    useEffect(() => {
        if (assetsData?.cryptoList && assetsData?.fiatList) {
            const fromCoins = [];
            const toCoins = [];

            // Process cryptoList
            Object.keys(assetsData.cryptoList).forEach((coinKey) => {
                const coin = assetsData.cryptoList[coinKey];
                fromCoins.push({
                    value: coinKey.toUpperCase(),
                    ticker: coinKey.toUpperCase(),
                    label: coinKey.toUpperCase(),
                    icon: coin.img,
                });
                toCoins.push({
                    value: coinKey.toUpperCase(),
                    ticker: coinKey.toUpperCase(),
                    label: coinKey.toUpperCase(),
                    icon: coin.img,
                });
            });

            // Process fiatList
            Object.keys(assetsData.fiatList).forEach((fiatKey) => {
                const fiat = assetsData.fiatList[fiatKey];
                fromCoins.push({
                    value: fiatKey.toUpperCase(),
                    ticker: fiatKey.toUpperCase(),
                    label: fiat.title,
                    icon: fiat.img,
                });
                toCoins.push({
                    value: fiatKey.toUpperCase(),
                    ticker: fiatKey.toUpperCase(),
                    label: fiat.title,
                    icon: fiat.img,
                });
            });

           

            // Dynamically set the selected currencies
            setSelectedCurrencies({
                from: fromCoins[0] || null,
                to: toCoins[1] || null,
            });
            const allCurrencies = [...fromCoins, ...toCoins];
        const filtered = allCurrencies.filter(({ label, value, icon }) =>
            label.toLowerCase().includes(searchQuery.toLowerCase()) ||
            value.toLowerCase().includes(searchQuery.toLowerCase())
        );

        setFilteredCurrencies(filtered);
        }
    }, [assetsData]);

    useEffect(() => {
        const fetchConversionRates = async () => {
            // Ensure selectedCurrencies have valid values
           
            if (!selectedCoinFrom || !selectedCoinTo) {
                console.error('Invalid coins selected for conversion');
                return;
            }
    
            const payload = {
                from_coin: selectedCoinFrom,
                to_coin: selectedCoinTo,
                amount: valueFrom || 1, // Default to 1 for conversion rate
                user_id: userID,
            };
    
            console.log("Payload for API request: ", payload);
    
            try {
                const response = await dispatch(getConvertedAmount(payload, token )); // Use token  here
                console.log("API Response: ", response);
    
                if (response?.status === 1 && response?.data?.converted_amount) {
                    setCurrencyConversionRates(response.data);
                    setValueTo(response?.data?.converted_amount);
                    console.log("Updated currencyConversionRates: ", currencyConversionRates);
                } else {
                    console.warn("Unexpected response format or status: ", response);
                }
            } catch (error) {
                console.error("Error fetching conversion rates: ", error);
                setCurrencyConversionRates("");
            }
        };
    
        fetchConversionRates();
    }, [dispatch, selectedCurrencies, valueFrom, userID, token ]);
    
    
    const handleConvert = (e) => {
        e.preventDefault();  // Prevent form reload
    
        console.log("Payload Data for:", selectedCoinFrom);
    
        if (!selectedCoinFrom || !selectedCoinTo ) {
            console.error("Missing required fields!");
            return;
        }
    
        const payload = {
            user_id: userID,
            from_coin: selectedCoinFrom,
            to_coin: selectedCoinTo,
            amount: valueFrom,
        };
    
        console.log("Submitting payload:", payload);
    
        dispatch(doTrade(payload, token, setShowModal, setTransactionModal));
        
        setShowModal(false);  // Close modal after submission
    };
    


    const handleCurrencySelect = (currency) => {
        setSelectedCurrencies((prev) => ({
            ...prev,
            [currentInput]: currency
        }));
        setCurrencyModalVisible(false);
    };

    const handleTransferClick = () => {
        setFromValue(toValue);
        setToValue(fromValue);
        setSelectedCurrencies((prev) => ({
            from: prev.to,
            to: prev.from
        }));
    };

    const [checkedItems, setCheckedItems] = useState({
        spot: false,
        funding: false,
    });

    const handleCheckboxToggle = (name) => {
        setCheckedItems((prevItems) => ({
            ...prevItems,
            [name]: !prevItems[name],
        }));
    };

    const getConversionRateText = () => {
        if (!fromCoinsData || !selectedCoinTo) return "--";
        console.log("!fromCoinsData || !toCoinsData",fromCoinsData ,selectedCoinTo)
        const rate = currencyConversionRates?.from_coin_price;
        console.log("rate", rate)
        // Ensure rate is a valid number before calling toFixed
        const validRate = parseFloat(rate); // Convert to number
        if (!isNaN(validRate)) {
            return `1 ${selectedCoinFrom} ≈ ${validRate.toFixed(6)} ${selectedCoinTo}`;
        }
    
        return "--";
    };

    
       

    return (
        <>
        <form className="space-y-4" onSubmit={handleConvert}>
            <Modal visible={visible} footer={null} onCancel={onCancel} closeIcon={<IoClose />} title="Convert">
                <div className='flex items-center justify-between w-full my-6'>
                    <div className='flex items-center gap-1'>
                        <p className='text-sm text-[#707a8a]'>Wallet</p>
                        <IoInformationCircle className='text-gray-400' />
                    </div>
                    <div className="flex gap-5">
                        <div className="flex items-center gap-1 cursor-pointer" onClick={() => handleCheckboxToggle('spot')}>
                            {checkedItems.spot ? <MdCheckBox className="text-xl text-[#EFB81C]" /> : <MdCheckBoxOutlineBlank className="text-xl" />}
                            <p className="text-sm">Spot</p>
                        </div>
                        <div className="flex items-center gap-1 cursor-pointer" onClick={() => handleCheckboxToggle('funding')}>
                            {checkedItems.funding ? <MdCheckBox className="text-xl text-[#EFB81C]" /> : <MdCheckBoxOutlineBlank className="text-xl" />}
                            <p className="text-sm">Funding</p>
                        </div>
                    </div>
                </div>
                <CurrencyInput
    label="From"
    value={valueFrom} // Bind the value to the valueFrom state
    onChange={handleChange((value) => setValueFrom(value))} // Update valueFrom on change
    selectedCurrency={selectedCurrencies.from}
    onSelect={() => handleCurrencyClick('from')}
    balance={balance}
/>
                <div className='flex justify-center my-5'>
                    <TbTransferVertical className='text-[42px] bg-gray-100 p-2 text-gray-400 rounded-full' onClick={handleTransferClick} />
                </div>
                <CurrencyInput
    label="To"
    value={valueTo} // Bind the value to the valueTo state
    onChange={handleChange((value) => setValueTo(value))} // Update valueTo on change
    selectedCurrency={selectedCurrencies.to}
    onSelect={() => handleCurrencyClick('to')}
    balance={balance}
/>
                <div className='flex items-center justify-between w-full my-6'>
                    <div className='flex items-center gap-1'>
                        <p className='text-sm text-[#707a8a]'>Rate</p>
                        <IoInformationCircle className='text-gray-400' />
                    </div>
                    <p className='text-sm font-semibold text-[#707a8a]'>    <span className="font-semibold text-center text-gray-500">{getConversionRateText()}</span>
                    </p>
                </div>
                <button className='font-semibold bg-[#EFB81C] w-full text-center rounded-md py-3' onClick={handlePreviewClick}>
        Enter Amount
      </button>

      {transactionModal && (
        <CryptoTransactionModal 
        currencyConversionRates={currencyConversionRates}
        getConversionRateText={getConversionRateText}
          isOpen={transactionModal} 
          onClose={() => setTransactionModal(false)} 
        />
      )}
      {/* ConvertPreviewModal */}
      <ConvertPreviewModal 
        showModal={showModal}
        handleCloseModal={() => setShowModal(false)} // Close modal when invoked
        selectedCoinFrom={selectedCurrencies.from}
        selectedCoinTo={selectedCurrencies.to}
        currencyConversionRates={currencyConversionRates}
        getConversionRateText={getConversionRateText}
        inputError={inputError}
        balance={balance}
        loading={loading}
        handleSubmit= {handleConvert}
      />  
      {/* Show Modal when transactionModal is true */}


      
      
         </Modal>

          



    

            <CurrencySelectModal
                visible={isCurrencyModalVisible}
                onCancel={() => setCurrencyModalVisible(false)}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                filteredCurrencies={filteredCurrencies}
                onCurrencySelect={handleCurrencySelect}
            />


</form>
        </>
    );
};


export default ConvertModal;
