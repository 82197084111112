import React, { useState, useRef,useEffect } from 'react';
import { TbCaretUpDownFilled } from "react-icons/tb";
import NoData from "assets/img/user/No-data.svg";
import { FaDollarSign, FaPoundSign, FaRupeeSign } from 'react-icons/fa';
import { MdOutlineCurrencyYuan } from "react-icons/md";
import TransferModal from 'views/trade/PostAds/TransferModal';
import ConvertModal from 'views/trade/PostAds/ConvertModal';
import { IoMdMore } from "react-icons/io";

function FiatBalanceValues({ isVisible, searchQuery, data, showGreaterThanOne, hideLowValueAssets }) {
    const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
    const [isConvertModalVisible, setIsConvertModalVisible] = useState(false);

    const handleTransferClick = () => setIsTransferModalVisible(true);
    const handleTransferCancel = () => setIsTransferModalVisible(false);

    const handleConvertClick = () => setIsConvertModalVisible(true);
    const handleConvertCancel = () => setIsConvertModalVisible(false);

    const popularCoins = Object.keys(data?.coin?.fiatList || {}).map((coinKey,index) => {
        const coinData = data.coin.fiatList[coinKey];

        return {
            id: index,
            name: coinData.name.toUpperCase(),
            icon: getIcon(coinData.img), // Pass the image URL to getIcon
            color: 'green', // Customize as needed
            descriptions: {
                coin: coinData.title || "Unknown",
                amount: isVisible ? `$${parseFloat(coinData.amount || '0').toFixed(2)}` : '****',
                subprice: isVisible ? `$${parseFloat(coinData.zhehe || '0').toFixed(2)}` : '****',
            },
            price: coinData.zhehe || '0.0',
            amount: coinData.amount || '0.0',
            available: coinData.available || '0.0',
            frozen: coinData.frozen || '0.0',
        };
    });

    // Log the result to verify the transformation
    console.log("Mapped popularCoins array:", popularCoins);

    // Utility function to select the right icon (now uses img URL)
    function getIcon(imgUrl) {
        return imgUrl ? <img src={`https://api.buycex.com/Upload/coin/${imgUrl}`} alt="coin icon" className="w-6 h-6 rounded-full" /> : null;
    }

    const [openDropdown, setOpenDropdown] = useState({});

    const handleDropdownToggle = (id) => {
        setOpenDropdown((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenDropdown((prevState) => {
                    const updatedState = { ...prevState };
                    Object.keys(updatedState).forEach((key) => {
                        updatedState[key] = false;
                    });
                    return updatedState;
                });
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; 

    // Filter coins based on search query
    const filteredCoins = popularCoins.filter((coin) => {
        const matchesSearch = coin.name.toLowerCase().includes(searchQuery.toLowerCase());
        return matchesSearch;
    });

    // Calculate pagination details
    const totalPages = Math.ceil(filteredCoins.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentCoins = filteredCoins.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };


    return (
        <div className='w-full'>
            {/* Header row */}
            <div className="w-full overflow-x-auto">
            <div className='flex min-w-[800px] mb-8'>
                <div className='flex items-center gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Currency</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Amount</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Available</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Frozen</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-end gap-1 w-2/5'>
                    <p className='text-xs text-[#929AA5]'>Action</p>
                </div>
            </div>

            {/* Display filtered coins or No Data image */}
            {currentCoins.length === 0 ? (
                <div className='text-center text-md'>
                    <div className='flex flex-col gap-1 h-44 justify-center items-center'>
                        <img src={NoData} alt="No Data" />
                        <p className='text-[#707a8a] text-sm'>No records</p>
                    </div>
                </div>
            ) : (
                currentCoins.map((coin, index) => (
                    <div key={index} className='flex min-w-[800px] items-center mb-4'>
                        <div className='flex gap-2 w-1/5'>
                            <div className='flex items-center gap-2'>
                                <div className='text-lg min-w-7 min-h-7' style={{ color: coin.color }}>
                                    {coin.icon}
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <p className='text-sm md:text-[16px] font-medium'>{coin.name}</p>
                                <p className='text-[12px] md:text-sm text-[#929AA5]'>{coin.descriptions.coin}</p>
                            </div>
                        </div>
                        <div className='flex flex-col gap-1 w-1/5'>
                            <p className='text-md text-end font-normal'>{isVisible ? coin.amount : '****'}</p>
                            <p className='text-sm text-end text-[#707a8a]'>{isVisible ? coin.descriptions.amount : '****'}</p>
                        </div>
                        <div className='flex flex-col gap-1 w-1/5'>
                            <p className='text-md text-end font-normal'>{isVisible ? coin.available : '****'}</p>
                        </div>
                        <div className='flex flex-col gap-1 w-1/5'>
                            <p className='text-md text-end font-normal'>{isVisible ? coin.price : '****'}</p>
                        </div>
                        <div className='flex justify-end w-2/5'>
                            <div className='hidden lg:flex justify-end gap-2 w-2/5'>
                                <button className='text-sm flex items-center px-2 py-1 border rounded-md'>Topup</button>
                                <button className='text-sm flex items-center px-2 py-1 border rounded-md' onClick={handleTransferClick}>Transfer</button>
                                <TransferModal visible={isTransferModalVisible} onCancel={handleTransferCancel} />
                                <button className='text-sm flex items-center px-2 py-1 border rounded-md' onClick={handleConvertClick}>Convert</button>
                                <ConvertModal visible={isConvertModalVisible} onCancel={handleConvertCancel} />
                            </div>
                            <div className="lg:hidden">
                                <button className="text-sm flex items-center px-2 py-1 border rounded-md" onClick={() => handleDropdownToggle(coin.id)}>
                                    <IoMdMore className="text-lg" />
                                </button>

                                {openDropdown[coin.id] && (
                                    <div ref={dropdownRef} className="absolute mt-2 right-0 w-40 bg-white shadow-lg rounded-md border border-gray-200">
                                        <button className='text-sm w-full flex items-center px-2 py-1.5 hover:bg-gray-50'>Topup</button>
                                        <button className='text-sm w-full flex items-center px-2 py-1.5 hover:bg-gray-50' onClick={handleTransferClick}>Transfer</button>
                                        <button className='text-sm w-full flex items-center px-2 py-1.5 hover:bg-gray-50' onClick={handleConvertClick}>Convert</button>
                                    </div>
                                )}
                                <TransferModal visible={isTransferModalVisible} onCancel={handleTransferCancel} />
                                <ConvertModal visible={isConvertModalVisible} onCancel={handleConvertCancel} />
                            </div>
                        </div>
                    </div>
                ))
            )}
            </div>
             {/* Pagination Controls */}
             {filteredCoins.length > itemsPerPage && (
                    <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={`px-3 py-1 mx-1 text-xs font-semibold border rounded ${currentPage === index + 1 ? 'bg-yellow-500 text-black' : 'bg-gray-100 text-gray-700'}`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
                )}
        </div>
    );
}

export default FiatBalanceValues;
