import React, { useState } from 'react';
import { MdSearch } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import FiatBalanceValues from './FiatBalanceValues';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const FiatBalanceTable = ({ isVisible ,data}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [hideLowValueAssets, setHideLowValueAssets] = useState(false);
    const [showGreaterThanOne, setShowGreaterThanOne] = useState(false);
    const [showSearchInput, setShowSearchInput] = useState(false);
console.log("data1254",data)
    // Function to render the CoinView content
    const renderContent = () => {
        return (
            <FiatBalanceValues
                isVisible={isVisible}
                searchQuery={searchQuery}
                data={data}  // Pass search query here
                showGreaterThanOne={showGreaterThanOne}
                hideLowValueAssets={hideLowValueAssets} // Pass this state too
            />
        );
    };

    // Toggle the state for hiding low-value assets
    const handleCheckboxClick = () => {
        setHideLowValueAssets(prev => !prev);
    };

    return (
        <div className='p-3 md:p-6 mt-6 border rounded-2xl'>
            <div className='flex justify-between flex-col md:flex-row'>
                <div className='flex gap-5'>
                    <p className={`font-semibold text-2xl cursor-pointer pb-3`}>Fiat Balance</p>
                </div>

                <div className='flex justify-between md:justify-normal items-center gap-5'>
                <div className='flex justify-end items-center border-gray-200'>
                <div className='relative flex items-center' onMouseEnter={() => setShowSearchInput(true)} onMouseLeave={() => setShowSearchInput(false)}>
                    {showSearchInput ? (
                        <input
                            type="text"
                            className="transition-all duration-300 ease-in-out w-0 px-3 py-1 rounded-md border border-gray-300 focus:outline-none"
                            style={{
                                width: showSearchInput ? '150px' : '0',
                                opacity: showSearchInput ? '1' : '0',
                                paddingLeft: showSearchInput ? '8px' : '0',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                            }}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search assets..."
                            autoFocus
                        />
                    ) : (
                        <MdSearch className="text-gray-600 text-lg cursor-pointer transition-all duration-300 hover:text-black" />
                    )}
                </div>
                <div className='flex items-center cursor-pointer' onClick={handleCheckboxClick}>                        
                </div>
            </div>
            <Link to='/market/overview' className='flex items-center'>
                        <p className='text-sm font-medium text-[#EFB81C]'>View All </p>
                        <MdKeyboardArrowRight className='text-lg text-[#EFB81C]' />
                    </Link>
                </div>
            </div>

            <div className="mt-6">{renderContent()}</div>
        </div>
    );
};

export default FiatBalanceTable;
