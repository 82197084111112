import { da } from "date-fns/locale";
import { toast } from "react-hot-toast";

import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { 
    DO_TRADE_FAILURE, DO_TRADE_SUCCESS, DO_TRADE_REQUEST,
    CALL_TRADE_FAILURE, CALL_TRADE_SUCCESS, CALL_TRADE_REQUEST,
    CALC_ASSET_VALUE_FAILURE, CALC_ASSET_VALUE_SUCCESS, CALC_ASSET_VALUE_REQUEST,
    GET_CONVERTED_AMOUNT_FAILURE, GET_CONVERTED_AMOUNT_SUCCESS, GET_CONVERTED_AMOUNT_REQUEST
 } from "../types";

const cryptoConvertRepository = RepositoryFactory.get("cryptoConvert");

// Action to perform a trade


export const doTrade = (payload, token,setShowModal,setTransactionModal) => async (dispatch) => {
    dispatch({ type: DO_TRADE_REQUEST });
    console.log("Request Sent....", payload);

    if (payload.amount === "0" || payload.amount === "") {
        dispatch({
            type: DO_TRADE_FAILURE,
            payload: { info: "Amount entered is not sufficient!" },
        });
        console.log("Data on failure:", {
            info: "Amount entered is not sufficient!",
        });

        // Show toast for failure
        toast.error("Amount entered is not sufficient!");

        return;
    }

    try {
        // Convert payload to URL-encoded format
        // (Optional step if you want to encode the data, depending on your needs)

        // Call the repository function with formPayload
        const { data } = await cryptoConvertRepository.doTrade(payload, token);

        // Handle response based on status
        if (data.status === 1) {
            dispatch({ type: DO_TRADE_SUCCESS, payload: data });
            console.log("Data on success:", data.info);
            setTransactionModal(true);
            // Show toast for success
            toast.success(`Trade Successful: ${data.info || "Success!"}`);
            console.log("Conversion submitted!");
           
            
           
        } else {
            dispatch({ type: DO_TRADE_FAILURE, payload: data });
            console.log("Data on failure:", data.info);

            // Show toast for failure
            toast.error(`Trade Failed: ${data.info || "Error!"}`);
        }
    } catch (error) {
        // Detailed error handling
        if (error.response) {
            console.log('Error Response:', error.response.data);  // Server's response
            dispatch({ type: DO_TRADE_FAILURE, payload: error.response.data });

            // Show toast for server error
            toast.error(`Error: ${error.response.data.message || "Server Error"}`);
        } else if (error.request) {
            console.log('Error Request:', error.request);  // No response received
            dispatch({ type: DO_TRADE_FAILURE, payload: 'No response from the server' });

            // Show toast for no response error
            toast.error("No response from the server");
        } else {
            console.log('Error Message:', error.message);  // General error message
            dispatch({ type: DO_TRADE_FAILURE, payload: error.message });

            // Show toast for general error
            toast.error(`Error: ${error.message}`);
        }
    }
};






// Action to call a trade
export const callTrade = (payload, token) => async (dispatch) => {
    dispatch({ type: CALL_TRADE_REQUEST });
    try {
        const { data } = await cryptoConvertRepository.callTrade(payload, token);
        if (data.status === 1) {
            dispatch({ type: CALL_TRADE_SUCCESS, payload: data });
            console.log("Trade executed successfully:", data.info);
        } else {
            dispatch({ type: CALL_TRADE_FAILURE, payload: data.info });
            console.log("Failed to execute trade:", data.info);
        }
    } catch (error) {
        dispatch({ type: CALL_TRADE_FAILURE, payload: error.message });
        console.log("Error executing trade:", error);
    }
};

// Action to fetch coin balance
// Action to fetch coin balance
export const fetchCoinBalance = (payload) => async (dispatch) => {
    const { coinname } = payload;
    console.log("Fetching balance for:", coinname);

    dispatch({ type: CALC_ASSET_VALUE_REQUEST, coin: coinname });

    try {
        const { data } = await cryptoConvertRepository.calcEstimatedAssetvalueData(payload);

        if (data.status === 1) {
            const balanceData = data.data[coinname]; // Access the coin-specific data
          

            // Dispatch with proper payload structure
            dispatch({ 
                type: CALC_ASSET_VALUE_SUCCESS, 
                payload: { 
                    coin: coinname, 
                    balance: balanceData.balance, // Access the correct balance
                    img: balanceData.img,
                    title: balanceData.title,
                    price: balanceData.price,
                    conversion: balanceData.conversion,
                    type: balanceData.type
                } 
            });
        } else {
            console.log('Failed to fetch balance:', data.info);
            dispatch({ type: CALC_ASSET_VALUE_FAILURE, payload: data.info });
        }
    } catch (error) {
        console.log('Error fetching balance:', error.message);
        dispatch({ 
            type: CALC_ASSET_VALUE_FAILURE, 
            payload: error.message, 
            coin: coinname 
        });
    }
};

export const getConvertedAmount = (payload, token) => async (dispatch) => {
    dispatch({ type: GET_CONVERTED_AMOUNT_REQUEST });
 

    try {
        const { data } = await cryptoConvertRepository.getConvertedAmount(
            payload.from_coin,
            payload.to_coin,
            payload.amount,
            token,
            payload.user_id
        );
        console.log("data converted amount:", data);
        if (data.status === 1) {
            dispatch({ type: GET_CONVERTED_AMOUNT_SUCCESS, payload: data });
            console.log("Converted amount fetched successfully:", data);
            return data;
        } else {
            dispatch({ type: GET_CONVERTED_AMOUNT_FAILURE, payload: data.info });
            console.log("Failed to fetch converted amount:", data.info);
            return Promise.reject(data.info); 
        }
    } catch (error) {
        if (error.response) {
            console.log('Error Response:', error.response.data);
            dispatch({ type: GET_CONVERTED_AMOUNT_FAILURE, payload: error.response.data });
        } else if (error.request) {
            console.log('Error Request:', error.request);
            dispatch({ type: GET_CONVERTED_AMOUNT_FAILURE, payload: 'No response from the server' });
        } else {
            console.log('Error Message:', error.message);
            dispatch({ type: GET_CONVERTED_AMOUNT_FAILURE, payload: error.message });
        }
    }
};