import React, { useState } from 'react';
import { TbCaretUpDownFilled } from "react-icons/tb";
import { ImCoinDollar } from "react-icons/im";
import { HiTrendingUp } from "react-icons/hi";

function NewListing({ data }) {

    var coinList = [];
    if (Object.keys(data).length > 0) {
        coinList = Object.keys(data)
            .map((key, index) => ({
                id: index,
                icon: data[key].img || "default_coin_image.png",
                coinName: data[key].name.toUpperCase(),
                coinType: data[key].title.match(/\[([^\[\]]+)\]/)?.[1].trim() || "Unknown",
                amount: parseFloat(data[key].amount),
                coinPrice: parseFloat(data[key].price),
                market: parseFloat(data[key].market_change),
                usdt_conversion:parseFloat(data[key].usdt_conversion) || 0,
            }))
            .sort((a, b) => b.amount - a.amount); // Sort by amount in descending order
    }
    const currentDate = new Date();
    const previousYearDate = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const paginatedCoinList = coinList.slice(startIndex, endIndex);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (


        <div className='w-full'>
            {/* Header row */}
            <div className="w-full overflow-y-auto min-h-52 overflow-x-auto">
                <div className="flex min-w-[600px] mb-8">
                    <div className="flex items-center gap-1 w-1/5">
                        <p className="text-xs text-[#929AA5]">Coins</p>
                        <TbCaretUpDownFilled className="text-xs text-[#929AA5]" />
                    </div>
                    <div className="flex items-center justify-end gap-1 w-1/5">
                        <p className="text-xs text-[#929AA5]">Amount</p>
                        <TbCaretUpDownFilled className="text-xs text-[#929AA5]" />
                    </div>
                    <div className="flex items-center justify-end gap-1 w-1/5">
                        <p className="text-xs text-[#929AA5]">Coin Price</p>
                        <TbCaretUpDownFilled className="text-xs text-[#929AA5]" />
                    </div>
                    <div className="flex items-center justify-end gap-1 w-1/5">
                        <p className="text-xs text-[#929AA5]">24H Change</p>
                        <TbCaretUpDownFilled className="text-xs text-[#929AA5]" />
                    </div>
                    <div className="flex justify-end gap-1 w-1/5">
                        <p className="text-xs text-[#929AA5]">Date Listed</p>
                        <TbCaretUpDownFilled className="text-xs text-[#929AA5]" />
                    </div>
                    <div className="md:flex hidden items-center justify-end gap-1 w-1/5">
                        <p className="text-xs text-[#929AA5]">Trade</p>
                    </div>
                </div>


                {/* Map through popular coins array */}
                {paginatedCoinList.length > 0 && paginatedCoinList.map((data) => (
                        <div key={data.id} className="flex min-w-[600px] items-center mb-4">
                            <div className="flex gap-2 w-1/5">
                                <div className="flex items-center gap-2">
                                    <img
                                        src={`https://api.buycex.com/Upload/coin/${data.icon}`}
                                        alt=""
                                        className="w-6 h-6 rounded-full"
                                    />
                                    <div className="flex flex-col">
                                        <span className="text-[16px] font-bold">{data.coinName}</span>
                                        <span className="text-[14px] text-[#aaaaaa]">{data.coinType}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-1/5 items-end">
  <div className="flex justify-end gap-2 items-center">
    <p className="text-md font-normal">{data.amount}</p>
    <span className="text-md text-[#aaaaaa] font-normal">{data.coinName}</span>
  </div>
  <p className="text-sm text-end text-[#707a8a]">
  {`$${data.usdt_conversion}` }
</p>
</div>
                            <div className="flex flex-col gap-1 w-1/5">
                                <p className="text-[14px] md:text-[16px] text-end font-normal">
                                    ${data.coinPrice}
                                </p>
                            </div>
                            <div className="flex flex-col gap-1 w-1/5">
                                <div className="flex gap-2 justify-end items-center">
                                    <p
                                        className={`text-[14px] md:text-[16px] font-semibold ${data.market < 0 ? "text-red-500" : "text-green-500"
                                            }`}
                                    >
                                        {data.market != null && !isNaN(data.market)
                                            ? `${data.market}%`
                                            : "0%"}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col gap-1 w-1/5">
                                <p className="text-[14px] md:text-[16px] text-end font-normal">
                                    {previousYearDate.toDateString()}
                                </p>
                            </div>
                            <div className="md:flex justify-end hidden w-1/5">
                            <a href={`/trade/spot/${data.coinName}`}>
                            <button
                        className="text-gray-900 bg-white border-[0.5px] border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-1.5 me-2 mb-2 dark:!bg-transparent dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        
                    >
                      Trade
                    </button>
                            </a>
                            </div>
                        </div>
                    ))}
                    <div className="flex justify-center mt-4">
                {Array.from({ length: Math.ceil(coinList.length / itemsPerPage) }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-3 py-1 mx-1 text-xs font-semibold border rounded ${currentPage === index + 1 ? 'bg-yellow-500 text-black' : 'bg-gray-100 text-gray-700'}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
            </div>

        </div>
    );
}

export default NewListing;
