import React from 'react'
import HeroSection from './HeroSection'
import HowItWork from './HowItWork'
import SavingGrow from './SavingGrow'
import Faqs from 'components/FAQs';

function BitSaver() {
  const faqsData = [
    {
      question: 'How to Deposit ARS on Buycex?',
      answer: 'Depositing ARS on Buycex is a straightforward process designed to make it easy for users to start trading digital assets.',
    },
    {
      question: 'How to Use Fiat Deposit?',
      answer: 'Fiat deposit allows you to convert your local currency into cryptocurrency with ease.',
    },
    {
      question: 'How to Deposit BRL on Buycex?',
      answer: 'Depositing BRL on Buycex is quick and easy, enabling you to start trading in no time.',
    },
    {
      question: 'How to Buy Coins With Fiat Balance?',
      answer: 'Buying coins with your fiat balance is simple and secure on our platform.',
    },
  ];
  return (
    <div className='dark:bg-black'>
        <HeroSection/>
        <SavingGrow/>
        <HowItWork/>
        <div className='container'>
        <Faqs faqsData={faqsData} />
        </div>
    </div>
  )
}

export default BitSaver