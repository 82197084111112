import React, { useEffect, useState } from 'react';
import { TbCaretUpDownFilled } from "react-icons/tb";
import { FaBitcoin, FaEthereum } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import TransferModal from 'views/trade/PostAds/TransferModal';

function PositionTable({ searchTerm, isVisible, toggleVisibility  }) {
  const [data, setData] = useState([]);
  const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
  
  const handleTransferClick = () => {
    setIsTransferModalVisible(true);
  };

  const handleTransferCancel = () => {
    setIsTransferModalVisible(false);
  };

  const popularCoins = [
    {
      name: 'BTC',
      icon: <FaBitcoin className='text-2xl text-[#F7931A]' />,
      amount: '0.00000000',
      price: '0.00000000',
      growth: '0.00000000',
      descriptions: {
        coin: 'Bitcoin',
        amount: '/',
      }
    },
    {
      name: 'ETH',
      icon: <FaEthereum className='text-2xl text-[#3C3C3D]' />,
      amount: '0.00000000',
      price: '0.00000000',
      growth: '0.00000000',
      descriptions: {
        coin: 'Ethereum',
        amount: '/',
      }
    },
  ];

  const filteredData = popularCoins.filter(coin =>
    coin.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className="w-full overflow-x-auto">
      <div className='flex min-w-[700px] mt-6 mb-8'>
        <div className='flex items-center gap-1 w-1/5'>
          <p className='text-xs text-[#929AA5]'>Coins</p>
          <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
        </div>
        <div className='flex items-center justify-end gap-1 w-1/4'>
          <p className='text-xs text-[#929AA5]'>Position</p>
          <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
        </div>
        <div className='flex items-center justify-end gap-1 w-1/4'>
          <p className='text-xs text-[#929AA5]'>Position Value</p>
          <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
        </div>
        <div className='flex items-center justify-end gap-1 w-1/4'>
          <p className='text-xs text-[#929AA5]'>Index Price</p>
          <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
        </div>
        <div className='flex items-center justify-end gap-1 w-1/4'>
          <p className='text-xs text-[#929AA5]'>Liquidation Price</p>
          <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
        </div>
        <div style={{ boxShadow: '-5px 0 5px -5px rgba(0, 0, 0, 0.2)' }} className='flex justify-end gap-1 w-2/5'>
          <p className='text-xs text-[#929AA5]'>Action</p>
        </div>
      </div>

      {/* Rendering each coin data */}
      <div>
        {filteredData.length > 0 ? (
          filteredData.map((coin, index) => (
            <div key={index} className='flex min-w-[700px] mb-4 items-center'>
              <div className='flex items-center gap-2 w-1/5'>
                <span>{coin.icon}</span>
                <span className='text-sm md:text-[16px] font-medium'>{coin.name}</span>
              </div>
              <div className='flex items-center justify-end w-1/4'>
              <span className='text-sm'>{isVisible ?<p>{coin.amount}</p> : '********'}</span>
              </div>
              <div className='flex items-center justify-end w-1/4'>
              <span className='text-sm'>{isVisible ?<p>{coin.price}</p> : '********'}</span>
              </div>
              <div className='flex items-center justify-end w-1/4'>
              <span className='text-sm'>{isVisible ?<p>{coin.growth}</p> : '********'}</span>
              </div>
              <div className='flex items-center justify-end w-1/4'>
                <p className='text-sm'>{isVisible ?'/' : '********'}</p>
              </div>
              <div className='flex items-center justify-end w-2/5'>
                <button className='px-2 py-1 text-[#EFB81C] text-sm font-semibold rounded' onClick={handleTransferClick}>Transfer</button>
                <TransferModal visible={isTransferModalVisible} onCancel={handleTransferCancel} />
                <button className='px-2 py-1 text-[#EFB81C] text-sm font-semibold rounded'>Borrow/Repay</button>
                <BsThreeDotsVertical />
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500 mt-4">No data found</p> // No data message
        )}
      </div>
    </div>
    </div>
  );
}

export default PositionTable;
