import React, { useState } from 'react';
import { MdSearch } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import FutureTableValues from './FutureTableValues';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const FutureTable = ({ isVisible }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const renderContent = () => {
        return (
            <FutureTableValues
                isVisible={isVisible}
                searchQuery={searchQuery}
            />
        );
    };

    return (
        <div className='p-3 md:p-6 mt-6 border rounded-2xl'>
            <div>

                <div className='flex items-center gap-5'>
                    <div className='flex justify-end items-center border-gray-200'>
                        <div className='relative flex items-center'>
                            {/* Search Icon inside Input */}
                            <MdSearch className='absolute left-2 text-gray-400' />
                            <input
                                type="text"
                                className="transition-all duration-300 ease-in-out w-0 pl-8 py-1 rounded-sm bg-gray-100  border-gray-300 focus:outline-none"
                                style={{
                                    width: '200px',
                                    opacity: '1',
                                    paddingLeft: '2.5rem', // Increase padding to accommodate the search icon
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search"
                                autoFocus
                            />
                        </div>
                    </div>
                    <Link to='/market/overview' className='flex items-center'>
                        <p className='text-sm font-medium text-[#EFB81C]'>View All </p>
                        <MdKeyboardArrowRight className='text-lg text-[#EFB81C]' />
                    </Link>
                </div>
            </div>

            <div className="mt-6">{renderContent()}</div>
        </div>
    );
};

export default FutureTable;
