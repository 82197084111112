import React, { useState } from 'react';
import { SlMinus, SlPlus } from 'react-icons/sl';

const faqsData = [
  { question: 'What is Buycex Launchguard?', answer: 'Buycex launchguard is a platform where early-stage crypto projects can obtain funding. With Buycex\'\s global presence and growing role, these projects will grab the attention of professional investors worldwide and launch into the market. Buycex launchguard rigorously evaluates and selects high-quality projects in their early stages. In addition, it will disclose key information to help investors grasp projects overall information and future trends. It provides a great opportunity for investors to partake in these projects and gain access to tokens early.' },
  { question: 'How do I buy these tokens?', answer: 'By Clicking on Buy now button you will get a popup there you can buy.' },
  { question: 'What is blockchain?', answer: 'Blockchain is a distributed ledger technology that maintains a secure and immutable record of transactions.' },
];

function Faqs() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className='dark:bg-black '>
    <div className='py-32 container'>
      <h3 className='font-semibold text-3xl text-start pb-6 max-w-5xl mx-auto'>FAQ</h3>
      <div className='max-w-5xl mx-auto space-y-4'>
        {faqsData.map((faq, index) => (
          <div key={index} className='bg-[#f7f7f7] dark:bg-[#17181E] rounded-3xl overflow-hidden'>
            <button
              className='w-full flex justify-between items-center px-6 py-6'
              onClick={() => toggleFaq(index)}
            >
              <span className='text-xl font-semibold'>
                {index + 1}. {faq.question}
              </span>
              <div className={`rounded-full transition-transform`}>
                {openIndex === index ? <SlMinus size={30}/> : <SlPlus size={30}/>}
              </div>
            </button>
            <h4
              className={`mx-6 text-gray-700 dark:text-gray-500 text-[16px] transition-all duration-300 ease-in-out ${openIndex === index ? 'max-h-96 border-t !py-6 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}
            >
              {faq.answer}
            </h4>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}

export default Faqs;
