import React, { useState } from 'react';
import { SlMinus, SlPlus } from 'react-icons/sl';

const faqsData = [
  { question: 'What is BuyCex launchPool?', answer: 'BuyCex launchPool allows users to lock your crypto assets to receive token airdrops. By locking KCS, USDT, and other tokens, you obtain new tokens at no additional cost.' },
  { question: 'Who can participate in BuyCex launchPool?', answer: 'As long as you have completed Identity Verification for your BuyCex account and reside in an eligible jurisdiction, you are eligible to participate in BuyCex launchPool. Please refer to the Terms of Use for more details.'  },
  { question: 'How do I participate in launchPool?', answer: 'You can lock your KCS, USDT, or other tokens listed on the launchPool page during the event period. If eligible, the airdrop tokens will be credited to your Trading Account after the locking period ends. You may also claim the accrued airdrop tokens by following the prompts on the event page.' },
  { question: 'Are the airdrops different for each pool?', answer: 'Yes, the airdropped tokens allocated to each pool vary. Your airdrop is distributed based on the proportion of your locked amount relative to the total amount locked by all users in the pool. The amount of airdropped tokens can vary depending on several factors: Each pool has a different amount of airdrop tokens allotted. The total amount of tokens locked in each pool varies. By completing tasks, you may receive multipliers on your allocated share of airdrops. Together, they determine the number of tokens that each participant may earn from each pool.' },
  { question: 'When will the launchPool tokens be listed on BuyCex?', answer: 'For the exact timing, please refer to the listing details on the Announcements page.' },
];

function Faqs() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className='bg-white dark:bg-black'>
    <div className='py-32 container dark:bg-black'>
      <h3 className='font-semibold text-2xl sm:text-4xl text-start pb-6 max-w-4xl mx-auto'>FAQ</h3>
      <div className='max-w-4xl mx-auto space-y-4'>
        {faqsData.map((faq, index) => (
          <div key={index} className='bg-[#f7f7f7] dark:bg-[#17181E] rounded-3xl overflow-hidden'>
            <button
              className='w-full flex justify-between items-center px-3 sm:px-6 py-3 sm:py-6'
              onClick={() => toggleFaq(index)}
            >
              <span className='!text-lg !md:text-2xl font-semibold'>
                {index + 1}. {faq.question}
              </span>
              <div className={`rounded-full transition-transform`}>
                {openIndex === index ? <SlMinus size={30}/> : <SlPlus size={30}/>}
              </div>
            </button>
            <h4
              className={`mx-6 text-gray-700 dark:text-gray-500 !text-sm md:text-[16px] transition-all duration-300 ease-in-out ${openIndex === index ? 'max-h-96 border-t !py-6 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}
            >
              {faq.answer}
            </h4>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}

export default Faqs;
