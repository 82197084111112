import React, { useEffect } from "react";
import { BsDatabaseExclamation } from "react-icons/bs";
import { fetchspotAssetData } from "store/actions/AssetsUserAction";
import { useDispatch, useSelector } from "react-redux";

const AssetsTable = () => {
    const dispatch = useDispatch();
    
    const { loading, assetsData, error } = useSelector(
        (state) => state.assetsData
    );

    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);

    useEffect(() => {
        if (userID && userToken) {
            dispatch(fetchspotAssetData(userID, userToken));
        }
    }, [dispatch, userID, userToken]);

    return (
        <div className="w-full border border-[#E0E0E0] dark:border-[#313131] rounded-lg overflow-hidden">
            <div className="w-full bg-[#F9FAFB] dark:bg-[#1E1E1E] text-[12px] font-medium text-[#151415] dark:text-[#71777A] px-4 py-2.5 flex">
                <div className="w-1/5">Coin Name</div>
                <div className="w-1/5">Assets Net Value</div>
                <div className="w-1/5">Balance</div>
                <div className="w-1/5">Borrowed Amount</div>
                <div className="w-1/5">Transferrable Amount</div>
            </div>

            <div className="h-[40lvh] overflow-y-auto">
                {loading ? (
                    <div className="flex items-center justify-center h-full text-gray-500">Loading...</div>
                ) : error ? (
                    <div className="flex items-center justify-center h-full text-red-500">{error}</div>
                ) : assetsData?.length > 0 ? (
                    assetsData.map((asset, index) => (
                        <div
                            key={index}
                            className="flex text-[12px] text-[#151415] dark:text-[#EAEAEA] px-4 py-2 border-b border-[#E0E0E0] dark:border-[#313131] hover:bg-[#F3F4F6] dark:hover:bg-[#252525] transition"
                        >
                            <div className="w-1/5">{asset.coin_name}</div>
                            <div className="w-1/5">{asset.assets_net_value}</div>
                            <div className="w-1/5">{asset.balance}</div>
                            <div className="w-1/5">{asset.borrowed_amount}</div>
                            <div className="w-1/5">{asset.transferrable_amount}</div>
                        </div>
                    ))
                ) : (
                    <div className="flex flex-col items-center justify-center h-full gap-2 text-gray-500">
                        <BsDatabaseExclamation size={28} fill="#EFB81C50" />
                        <p>No Records Found</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AssetsTable;
