import React, { useEffect, useState } from "react";
import { FaUserInjured } from "react-icons/fa";
import { MdVerified, MdVerifiedUser } from "react-icons/md";
import { IoIosCopy } from "react-icons/io";
import {
    BsPatchCheckFill,
    BsPatchExclamationFill,
    BsPatchQuestionFill,
} from "react-icons/bs";
import { SlBadge } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchUserDashboard,
    fetchUserProfile,
} from "store/actions/userProfileAction";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import toast from "react-hot-toast";
import { fetchUserPayId } from "store/actions/UserPayIdAction";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function UserInfo() {
    const user = useSelector((state) => state.authCheck?.authStatus || {});
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const { loading: loadingDashboard, userDashboard } = useSelector((state) => state.userDashboard);
    const { loading: loadingPayId, data, error } = useSelector((state) => state.userPayId);
    const dispatch = useDispatch();

    // Fetch user profile and dashboard on component mount
    useEffect(() => {
        dispatch(fetchUserProfile(userID, userToken));
        dispatch(fetchUserDashboard(userID, userToken));
    }, [dispatch, userID, userToken]);

    // const { userDashboard } = useSelector((state) => state.userDashboard);

    const [showDetails, setShowDetails] = useState(false);

    const handleToggleDetails = () => {
        setShowDetails(!showDetails);
    };

    useEffect(() => {
        if (userID && userToken) {
            dispatch(fetchUserPayId(userToken, userID));
        }
    }, [dispatch, userID, userToken]);

    //   if (loading) return <p>Loading...</p>;
    //   if (error) return <p>Error: {error}</p>;

    const handleCopyUserId = (userId) => {
        if (userId) {
            navigator.clipboard.writeText(userId);
            toast.success("User Pay ID copied!");
        } else {
            toast.error("No User Pay ID to copy!");
        }
    };

    // Fetch verify_status from the user data
    const verifyStatus = userDashboard?.user?.verify_status; // Assume this is part of the API response

    console.log("verifyStatus", verifyStatus);
    // Get the vip_level from the userDashboard data need this one
    const vipLevel = userDashboard?.user?.vip_level || "Regular User"; // Fallback to "Regular User" if not available

    // Define statusMap based on the fetched verify_status
    const statusMap = {
        "Not Submitted": {
            icon: <BsPatchExclamationFill size={10} fill="red" />,
            text: "Not Submitted",
            textColor: "text-[red]",
        },
        Approved: {
            icon: <BsPatchCheckFill size={10} fill="green" />,
            text: "Approved",
            textColor: "text-[green]",
        },
        "In Review": {
            icon: <BsPatchQuestionFill size={10} fill="orange" />,
            text: "In Review",
            textColor: "text-[orange]",
        },
        Rejected: {
            icon: <BsPatchExclamationFill size={10} fill="red" />,
            text: "Rejected",
            textColor: "text-[red]",
        },
    };

    const defaultStatus = statusMap[verifyStatus] || statusMap["In Review"];
    const securityStatus = userDashboard?.user?.security_status || "Low";

    const getStatus = (securityStatus) => {
        let statusText = securityStatus || "Low"; // Default to "Low" if undefined
        let statusColor = "";

        switch (statusText) {
            case "High":
                statusColor = "green";
                break;
            case "Medium":
                statusColor = "#EFB81C";
                break;
            case "Low":
                statusColor = "red";
                break;
            default:
                statusColor = "red";
        }

        return (
            <div className="flex items-center gap-1">
                <span>
                    <BsPatchCheckFill size={10} fill={statusColor} />
                </span>
                <span className={`text-sm font-semibold text-[${statusColor}]`}>
                    {statusText}
                </span>
            </div>
        );
    };

    return (
        <div className="mt-0 lg:mt-10 w-full">
            <div className="flex flex-col md:flex-row items-start lg:items-center">
                <div className="flex justify-between">
                    <div className="flex gap-6 items-center justify-center">
                        <FaUserInjured className="text-[3.5rem] bg-gray-100 rounded-xl pt-3" />
                        <div className="flex flex-col gap-0 justify-center w-48">
                            <p className="text-[18px] font-semibold">
                                {user?.username
                                    ? user.username
                                    : <Skeleton className="mb-1" width={150} height={20} />}
                            </p>

                            {user?.usertype == 0 ? (
                                <p>
                                    <span className="bg-black text-white text-sm font-semibold rounded-full px-1">
                                        0
                                    </span>{" "}
                                    Non-
                                    <span className="font-semibold text-[16px]">
                                        Owner
                                    </span>
                                </p>
                            ) : (
                                <div className="flex gap-1 items-center">
                                    <SlBadge size={20} fill="green" />
                                    <p className="text-sm text-green-700 font-semibold">
                                        BuyCex Owner
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* Icon to toggle the details */}
                    <div className="md:hidden flex justify-end mb-2 w-full">
                        <button onClick={() => handleToggleDetails()}>
                            {showDetails ? (
                                <div className="rounded-md p-1 border">
                                    <MdKeyboardArrowUp />
                                </div>
                            ) : (
                                <div className="rounded-md p-1 border">
                                    <MdKeyboardArrowDown />
                                </div>
                            )}
                        </button>
                    </div>
                </div>
                <div className="w-[10%] hidden md:block">
                    <span className="h-[100px] border border-l border-slate-400 opacity-55"></span>
                </div>
                <div className="w-full">
                    {/* Details Section */}
                    <div
                        className={`${showDetails ? "block" : "hidden"
                            } md:flex md:flex-row flex-col gap-4`}
                    >
                        <div className="w-full flex justify-between my-4 md:my-0 md:block md:w-1/3">
                            <p className="text-[#929AA5] font-semibold text-sm mb-1">
                                User Pay ID
                            </p>
                            <div className="flex gap-1 items-center">
                                {loadingPayId ? (
                                    <Skeleton width={120} height={20} />
                                ) : (
                                    <div className="flex gap-1 items-center">
                                        <p className="text-sm font-medium">{data?.data || <Skeleton width={100} />}</p>
                                        <IoIosCopy
                                            onClick={() => handleCopyUserId(data?.data)}
                                            className="cursor-pointer text-[#707a8a] opacity-70"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="w-full flex justify-between my-4 md:my-0 md:block md:w-1/3">
                            <p className="text-[#929AA5] font-semibold text-sm mb-1">
                                VIP Level
                            </p>
                            {vipLevel === "Regular User" ? (
                                <p className="text-sm font-semibold">
                                    Regular User
                                </p>
                            ) : (
                                <div className="flex gap-1 items-center">
                                    <BsPatchCheckFill size={10} fill="green" />
                                    <p className="text-sm text-green-700 font-semibold">
                                        Premium
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="w-full flex justify-between my-4 md:my-0 md:block md:w-1/3">
                            <p className="text-[#929AA5] font-semibold text-sm mb-1">
                                Verify Status
                            </p>
                            <p className="text-sm flex items-center gap-1 ml-2.5 text-center font-medium">
                                <span className="text-sm">{defaultStatus.icon}</span>
                                <span
                                    className={`text-sm ${defaultStatus.textColor}`}
                                >
                                    {defaultStatus.text}
                                </span>
                            </p>
                        </div>
                        <div className="w-full flex justify-between my-4 md:my-0 md:block md:w-1/3">
                            <p className="text-[#929AA5] font-semibold text-sm mb-1">
                                Security Status
                            </p>
                            <div className="text-sm flex items-center gap-1 ml-2.5 text-center">
                                {getStatus(securityStatus)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserInfo;
