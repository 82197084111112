import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import toast from "react-hot-toast"; // Already in use
import { fetchWalletData } from 'store/actions/walletActions';
import { doTransfer } from 'store/actions/TransferAction'; // Import the transfer action
import { FaAngleDoubleRight } from "react-icons/fa";
import WithdrawalRoutingNavbar from 'components/withdrawalRoutingNavbar';
import { TbFileTime, TbCirclesRelation } from "react-icons/tb";
import { IoMdSwap } from "react-icons/io";
import coin_1 from 'assets/img/any-pair-convert.svg';
import coin_2 from 'assets/img/gurranted.svg';
import coin_3 from 'assets/img/instant-price.svg';
import FaQs from 'components/FAQs';
import ConvertModal from '../ConvertModal';
import { FaCaretDown, FaCaretUp } from "react-icons/fa6";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CryptoTransferForm  = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authUser?.user || {});
  const userId = user?.ID;
  const token = user?.TOKEN;
  const { loading, walletData } = useSelector((state) => state.wallet);
  const transferState = useSelector((state) => state.transfer); // Get transfer state
  const history = useHistory();

  const [selectedCoinSymbol, setSelectedCoinSymbol] = useState('');
  const [selectedCoinBalance, setSelectedCoinBalance] = useState(0);
  const [selectedCoinIcon, setSelectedCoinIcon] = useState('');
  const [selectedCoinTitle, setSelectedCoinTitle] = useState('');
  const [amount, setAmount] = useState('');
  const [fromInput, setFromInput] = useState('spot');
  const [toInput, setToInput] = useState('p2p');
  const [toWalletDropdownOpen, setToWalletDropdownOpen] = useState(false);
  const [sliderValue, setSliderValue] = useState(100);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fromWalletDropdownOpen, setfromWalletDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const walletOptions = ['p2p','spot'];

  const IMAGE_BASE_URL = "https://api.buycex.com/Upload/coin/";
  console.log("data token fetching", token, userId, fromInput, toInput)
  useEffect(() => {
    if (token && userId) {
      dispatch(fetchWalletData(fromInput, toInput, token, userId));
    }
  }, [dispatch, token, userId, fromInput, toInput]);
  
  console.log("Asset data ", walletData.assets);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const toggleToWalletDropdown = () => setToWalletDropdownOpen(!toWalletDropdownOpen);
  const toggleFromWalletDropdown = () => setfromWalletDropdownOpen(!fromWalletDropdownOpen);
  const handleCoinChange = (coin) => {
    setSelectedCoinSymbol(coin.name);
    setSelectedCoinBalance(coin.balance);
    setSelectedCoinIcon(coin.icon);
    setSelectedCoinTitle(coin.title);
    setAmount(coin.balance); // Set amount to the balance of the selected coin
    setDropdownOpen(false);
  };
  const handleFromWalletChange = (wallet) => {
    setFromInput(wallet);
    if (wallet === toInput) setToInput('');
    setfromWalletDropdownOpen(false) // Clear conflicting `toInput`
  };
  const handleToWalletChange = (wallet) => {
    setToInput(wallet);
    if (wallet === fromInput) setFromInput(''); // Clear conflicting `fromInput`
    setToWalletDropdownOpen(false);
  };

  const handleSliderChange = (e) => {
    const percent = e.target.value;
    const calcAmount = (selectedCoinBalance * percent) / 100;
    setSliderValue(percent);
    setAmount(calcAmount.toFixed(8));
  };
  const handleSwap = () => {
    setFromInput(toInput);
    setToInput(fromInput);
    setAmount('');
  };

  const handleTransfer = () => {
    if (!selectedCoinSymbol) {
      toast.error('Please select a coin');
      return;
    }

    // Ensure selectedCoinBalance is a valid number
    if (typeof selectedCoinBalance !== 'number' || isNaN(selectedCoinBalance)) {
      toast.error('Invalid balance. Please try again.');
      return;
    }

    // Validate amount
    if (amount <= 0 || amount > selectedCoinBalance) {
      toast.error('Invalid amount. Please enter a valid amount.');
      return;
    }



    // Dispatch the transfer action
    dispatch(doTransfer(fromInput, toInput, selectedCoinSymbol, amount, token, userId));
  };


  useEffect(() => {
    if (transferState.successMessage) {
      toast.success(transferState.successMessage);
  
      // Reset Form
      setSelectedCoinSymbol('');
      setSelectedCoinBalance(0);
      setSelectedCoinIcon('');
      setSelectedCoinTitle('');
      setAmount('');
      setSliderValue(100);
  
      // Refetch Wallet Data
      dispatch(fetchWalletData(fromInput, toInput, token, userId));
    }
  
    if (transferState.error) {
      toast.error(`Error: ${transferState.error}`);
    }
  }, [transferState.successMessage, transferState.error, dispatch, fromInput, toInput, token, userId]);
  

  const filteredCoins = walletData?.assets?.filter((coin) =>
    coin.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (coin.symbol && coin.symbol.toLowerCase().includes(searchQuery.toLowerCase()))
  ) || [];

  const filteredToOptions = walletOptions.filter((option) => option !== fromInput);
  const filteredFromOptions = walletOptions.filter((option) => option !== toInput);
  useEffect(() => {
    if (!token || !userId) {
        // Redirect to /login if the user is not authenticated
        history.push("/login");
    }
}, [token, userId, history]);


  return (
    <>
 
      



        <div className="relative z-10 dark:bg-zinc-900 px-4 sm:px-3 py-6 rounded-2xl mx-auto border-2 w-full min-w-full sm:min-w-[400px] sm:w-2/6">
          <div className="flex justify-start">

            {/* From Wallet Section */}
            <div className="form-group w-full relative rounded-2xl py-3 pr-1">
              <p className="block text-sm font-medium text-gray-400 mb-2">From</p>
              <div
                className="px-3 flex w-full justify-between items-center py-2 z-50 bg-[#f9f9fb] dark:bg-[#404347] font-medium capitalize text-neutral-900 dark:text-white rounded-md cursor-pointer"
                onClick={toggleFromWalletDropdown}
              >
                {fromInput || "Select From Wallet"}{fromWalletDropdownOpen ? (
                  <FaCaretUp className="opacity-50" size={16} />
                ) : (
                  <FaCaretDown className="opacity-50" size={16} />
                )}
              </div>
              {fromWalletDropdownOpen && (
                <div className="absolute bg-white dark:bg-[#404347] capitalize text-neutral-900 dark:text-white rounded-md mt-2 w-full shadow-lg">
                  {filteredFromOptions.map((wallet) => (
                    <div
                      key={wallet}
                      className="p-2 hover:bg-gray-100 text-sm font-medium opacity-70 cursor-pointer"
                      onClick={() => handleFromWalletChange(wallet)}
                    >
                      {wallet}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Swap Button */}
            <div
              className=" flex justify-center items-center mx-auto self-start mt-[46px] bg-[#EFB81C] rounded-full p-1"
              onClick={handleSwap}
            >
              <IoMdSwap className="w-4 h-4 text-white" />
            </div>

            {/* To Wallet Section */}
            <div className="form-group w-full relative rounded-2xl py-3 pl-1">
              <p className="block text-sm font-medium text-gray-400 mb-2">To</p>
              <div
                className="px-3 py-2 flex justify-between items-center bg-[#f9f9fb] dark:bg-[#404347] font-medium capitalize text-neutral-900 dark:text-white rounded-md cursor-pointer"
                onClick={toggleToWalletDropdown}
              >
                {toInput || "Select To Wallet"}
                {toWalletDropdownOpen ? (
                  <FaCaretUp className="opacity-50" size={16} />
                ) : (
                  <FaCaretDown className="opacity-50" size={16} />
                )}
              </div>
      
              {toWalletDropdownOpen && (
                <div className="absolute bg-white capitalize z-50 dark:bg-[#404347] text-neutral-900 dark:text-white rounded-md mt-2 w-full shadow-lg">
                  {filteredToOptions.map((wallet) => (
                    <div
                      key={wallet}
                      className="p-2 hover:bg-gray-100 text-sm font-medium opacity-70 cursor-pointer"
                      onClick={() => handleToWalletChange(wallet)}
                    >
                      {wallet}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>


          {/* Coin Selection Dropdown */}
          <div className="form-group w-full rounded-2xl py-3">
            <p className="block text-sm font-medium text-gray-400 mb-2">Coin</p>
            <div className="dropdown w-full">
              <button
                className="form-control justify-between font-medium w-full bg-[#f9f9fb] dark:bg-[#404347] text-neutral-900 dark:text-white py-2 px-3 rounded-md flex items-center"
                type="button"
                onClick={toggleDropdown}
              >
                {selectedCoinSymbol && selectedCoinTitle ? (
                  <div className="flex items-center">
                    <img
                      src={`${IMAGE_BASE_URL}${selectedCoinIcon}`}
                      alt={selectedCoinSymbol}
                      style={{ width: '24px', marginRight: '10px' }}
                      className="w-6 h-6 mr-3 rounded-full"
                    />
                    <div className="flex flex-col">
                      <span className="font-medium text-[16px]">{selectedCoinTitle}</span>
                      {/* <span className="text-xs">{`${selectedCoinSymbol.toUpperCase()} ${selectedCoinBalance}`}</span> */}
                    </div>
                  </div>
                ) : (
                  "Select Coin"
                )}
                {dropdownOpen ? (<FaCaretUp className="opacity-50" size={16} />
                ) : (
                  <FaCaretDown className="opacity-50" size={16} />
                )}
              </button>

              {dropdownOpen && (
                <div className="dropdown-menu w-full rounded-lg shadow-lg p-4 mt-2 !bg-white">
                  <input
                    type="text"
                    placeholder="Search coin"
                    className="form-control border outline-none text-sm w-full mb-2 p-2 rounded-lg focus:ring-1 focus:ring-[#EFB81C]"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  {filteredCoins.length > 0 ? (
                    filteredCoins.map((coin) => (
                      <a
                        key={`${coin.symbol}-${coin.name}`}
                        className="dropdown-item flex items-center justify-between px-4 py-2 mb-2 bg-[#f9f9fb] dark:bg-[#404347] text-neutral-900 dark:text-white cursor-pointer rounded"
                        onClick={() => handleCoinChange(coin)}
                      >
                        <div className="flex items-center">
                          <img
                            src={`${IMAGE_BASE_URL}${coin.icon}`}
                            alt={coin.name}
                            className="w-6 h-6 mr-3 rounded-full"
                          />
                          <div className="flex flex-col">
                            <span className="font-medium">{coin.title}</span>
                            <span className="text-xs">{`${coin.symbol?.toUpperCase()} ${coin.balance}`}</span>
                          </div>
                        </div>
                      </a>
                    ))
                  ) : (
                    <p className="text-sm text-white">No coins found</p>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Amount Input */}
          <div className="form-group mb-4 py-3">
            <div className='flex w-full justify-between'>
              <label className="text-sm font-medium text-gray-400 dark:text-white mb-2" htmlFor="amount">Amount</label>
            </div>
            <div className='flex bg-[#f9f9fb] pr-3 rounded-md'>
            <input
              id="amount"
              className="form-control outline-none w-full bg-[#f9f9fb] dark:bg-[#404347] text-neutral-900 dark:text-white py-2 px-3 font-medium rounded-md"
              type="text"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Please enter amount"
              required
            />
            <div className='flex gap-2 items-center'><button onClick={() => setAmount(selectedCoinBalance.toString())} className='font-medium text-[#EFB81C]'>All</button><span className='uppercase text-[16px] font-medium'>{`${selectedCoinSymbol.toUpperCase()}`}</span></div>
            </div>
            <div className="text-xs flex justify-between mt-[16px] pt-2 w-full text-gray-400 dark:text-neutral-300">
                <span className='font-medium text-xs'>Transferable Amount</span>
                <span id="coinbalance">{selectedCoinBalance}<span className="text-xs font-medium ml-1">{`${selectedCoinSymbol.toUpperCase()}`}</span></span>
              </div>

          </div>

          {/* Slider for Percentage */}
          {/* <div className="form-group mb-4">
            <label className="text-sm text-neutral-900 dark:text-white" htmlFor="my_range">
              Select Percentage
            </label>
            <input
              type="range"
              id="my_range"
              className="form-range w-full h-2 rounded-lg bg-gray-300 focus:outline-none focus:ring-2 focus:ring-[#00c48c]"
              min="0"
              max="100"
              value={sliderValue}
              onChange={handleSliderChange}
            />
            <span className="block text-sm text-neutral-900 dark:text-white">{sliderValue}%</span>
          </div> */}

          <button
            type="button"
            className="btn btn-primary w-full py-2 mb-2 rounded-md bg-[#EFB81C] text-black font-semibold"
            onClick={handleTransfer}
          >
            {transferState.loading ? 'Processing...' : 'Confirm'}
          </button>

          {/* {transferState.error && <p className="text-red-500 mt-4">Error: {transferState.error}</p>}
          {transferState.successMessage && <p className="text-green-500 mt-4">Success: {transferState.successMessage}</p>} */}
        </div>
      

  
    </>
  );
};

export default CryptoTransferForm ;
