import ScanCode from 'assets/img/scan-bar-code.svg'
import { AiFillAndroid } from 'react-icons/ai'
import { FaApple, FaGooglePlay } from 'react-icons/fa'
import Iphone from 'assets/img/iPhone.png'
import supportIcon from 'assets/img/support-icon.png'
import JoinCommunity from 'assets/img/Join-community.png'

function KeepTrading() {
  return (
    <div className='dark:bg-black py-4 md:py-32'>
        <div className='w-full container flex flex-col lg:flex-row items-center rounded-3xl mx-auto'>
            <div className='w-full lg:w-2/3 flex flex-col gap-5 py-8'>
            <h3 className='text-[24px] sm:text-4xl font-extrabold'>Keep Trading on-the Go. <br/>Anywhere, anytime</h3>
            <h6 className='text-[#454545] dark:text-[#737373] capitalize'>Trade across platforms on  Buycex with  our app and website</h6>
            <div className='flex flex-col lg:flex-row items-start lg:items-center gap-8'>
                <img src={ScanCode} className='h-[193px] w-[193px]'/>
                <div className='flex flex-col'>
                <h6 className='text-xl font-bold'>Scan QR Code</h6>
                <p className='text-[#454545] dark:text-[#737373] capitalize text-md'>Download Buycex app for android/IOS</p>
                <div className='flex pt-6 justify-between'>
                <div className='flex flex-col gap-2 items-center'>
                    <FaApple size={24}/>
                    <h4 className='text-sm dark:text-[#737373]'>App Store</h4>
                </div>
                <div className='flex flex-col gap-2 items-center'>
                    <FaGooglePlay size={24}/>
                    <h4 className='text-sm dark:text-[#737373]'>Google Play</h4>
                </div>
                <div className='flex flex-col gap-2 items-center'>
                    <AiFillAndroid size={24}/>
                    <h4 className='text-sm dark:text-[#737373]'>Android SDK</h4>
                </div>
                </div>
                </div>
            </div>
            </div> 
            <div className='w-full max-h-[520px] lg:w-1/3 bg-[#EFB81C] z-10 rounded-3xl'>
            <img src={Iphone} alt='Iphone'className='max-h-[520px] justify-self-center flex'/>
            </div>
        </div>
        <div className='container mx-auto mt-5 rounded-3xl py-6 md:py-[62px]'>
            <h3 className='text-[24px] sm:text-4xl font-extrabold'>Keep Trading on-the Go. <br/>Anywhere, anytime</h3>
            <div className='flex flex-col lg:flex-row mt-8 gap-5 w-full'>
                <div className='w-full lg:w-1/2 border dark:border-[#454545]/30 border-[#f5f5f5] bg-transparent hover:bg-[#f7f7f7] transition-all duration-300 ease-in-out dark:bg-[#17181E]/40 flex flex-col justify-between rounded-3xl p-[40px]'>
                <div className='w-[90%]'>
                <label className='text-xl font-extrabold'>24/7 Customer Service</label>
                <h6 className='leading-snug dark:text-[#737373] pt-2 text-sm'>Your questions, answered. Contact Buycex customer support with your questions at any time.</h6>
                </div>
                <div className='flex flex-col-reverse md:flex-row items-start md:items-center justify-between w-full'>
                <button className='rounded-[0.375rem] bg-[#EFB81C] mt-0 md:mt-20 font-extrabold text-black px-8 py-2'>Get Help</button>
                    <img src={supportIcon} alt='supportIcon' className='h-[150px] w-[150px] my-4'/>
                </div>
                </div>
                <div className='w-full lg:w-1/2 border dark:border-[#454545]/30 border-[#f5f5f5] bg-transparent hover:bg-[#f7f7f7] transition-all duration-300 ease-in-out dark:bg-[#17181E]/40 flex flex-col justify-between rounded-3xl p-[40px]'>
                <div className='w-[90%]'>
                <label className='text-xl font-extrabold'>Join Our Community</label>
                <h6 className='leading-snug dark:text-[#737373] pt-2 text-sm'>The Buycex Global Community is home to millions of users from 200+ countries, with support for 20+ languages.</h6>
                </div>
                <div className='flex flex-col-reverse md:flex-row items-start md:items-center justify-between w-full'>
                <button className='rounded-[0.375rem] bg-[#EFB81C] mt-0 md:mt-20 font-extrabold text-black px-8 py-2'>Join Now</button>
                    <img src={JoinCommunity} alt='supportIcon' className='h-[150px] w-[150px] my-4'/>
                </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default KeepTrading