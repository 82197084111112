import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CoinSelectWithdrawModal from './CoinSelectWithdrawModal';
import { Input } from 'antd';
import { MdOutlineBrightnessHigh } from "react-icons/md";
import NetworkSelectWithdrawModal from './NetworkSelectWithdrawModal';
import { Radio } from "antd";
import styled from "styled-components";
import { FiRefreshCcw } from "react-icons/fi";
import { fetchAssetsContext } from "store/actions/AssetsUserAction";
import CoinSelect from 'components/cryptoDeposit/CoinSelect';
import NetworkSelectDropdown from "components/cryptoWithdraw/NetworkSelectWithdraw";
import toast from "react-hot-toast";
import CryptoEmailVerificationModal from "../cryptoEmailVerificationModal";
import TransactionDetailsModal from "../TransactionDetailsModal";
import { upCryptoWithdrawal } from "store/actions/WithdrawAction";
function Address({ onClose }) {
   const [selectedCoin, setSelectedCoin] = useState(null);
  const [isWalletButtonActive, setIsWalletButtonActive] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
     const [networks, setNetworks] = useState([]);
         const [otp, setOtp] = useState("");
            const [fundPassword, setFundPassword] = useState("");
             const [g2faOtp, setG2faOtp] = useState("");
      const [withdrawAddress, setWithdrawAddress] = useState("");
         const [withdrawAmount, setWithdrawAmount] = useState("");
             const [isModalVisible, setModalVisible] = useState(false);
                 const [withdrawResponse, setWithdrawResponse] = useState("");
    const [coins, setCoins] = useState([]);
     const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
         const [feesFlat, setFeesFlat] = useState("");
           const [isEmailVerificationModalVisible, setIsEmailVerificationModalVisible] = useState(false);
 const dispatch = useDispatch();
    const { assetsContext, loading: assetsLoading } = useSelector((state) => state.assetsContext || {});
   const [balance, setBalance] = useState("");
   const handleImageClick = (coin) => {
    setSelectedNetwork(null);
    setSelectedCoin(coin);
};
const handleEmailVerificationSuccess = () => {
  setIsEmailVerificationModalVisible(false);


};

const handleEmailVerificationClose = () => {
  setIsEmailVerificationModalVisible(false);
};
const handleCoinChange = (selectedOption) => {
    setSelectedCoin(selectedOption);
};

const handleNetworkChange = (selectedOption) => {
    setSelectedNetwork(selectedOption);
};


const handleButtonClick = () => {
  if (!selectedCoin) {
      toast.error("Please select a Coin");
      return;
  }
  if (!selectedNetwork) {
      toast.error("Please select a network.");
      return;
  }

  if (!withdrawAddress) {
      toast.error("Please provide a withdrawal address.");
      return;
  }
  if (!withdrawAmount) {
      toast.error("Please provide a withdrawal amount.");
      return;
  }
  if (!withdrawAmount || isNaN(withdrawAmount) || withdrawAmount <= 0) {
      toast.error("Enter a valid amount for transfer.");
      return;
  }
  if (selectedCoin.value === "LTC" && !withdrawAddress.startsWith("L")) {
      toast.error("Invalid withdrawal address format for LTC.");
      return;
  }

  if (selectedCoin.value === "TRX") {
      if (!withdrawAddress.startsWith("TP")) {
          toast.error("Invalid withdrawal address format for TRX.");
          return;
      }

      if (withdrawAddress.length !== 34) {
          toast.error("Withdrawal address for TRX must be 34 characters long.");
          return;
      }
  }

  if (selectedCoin.value === "XRP") {
      if (!withdrawAddress.startsWith("r")) {
          toast.error("Invalid withdrawal address format for XRP.");
          return;
      }

      if (withdrawAddress.length !== 34) {
          toast.error("Withdrawal address for XRP must be 34 characters long.");
          return;
      }

      if (!destinationTag) {
          toast.error("Provide a destination tag for XRP.");
          return;
      }
  }

  if (selectedCoin.value === "TUSDT") {
      if (!withdrawAddress.startsWith("TP")) {
          toast.error("Invalid withdrawal address format for TUSDT.");
          return;
      }

      if (withdrawAddress.length !== 34) {
          toast.error("Withdrawal address for TUSDT must be 34 characters long.");
          return;
      }
  }


  setIsEmailVerificationModalVisible(true);

};
    useEffect(() => {
        if (!ID || !TOKEN) {
            history.push('/login');
            return;
        }
        dispatch(fetchAssetsContext(ID, TOKEN, "spot","withdraw"));
    }, [dispatch, ID, TOKEN, history]);
      useEffect(() => {
          if (!TOKEN || !ID) {
              console.log("Redirecting to sign-in");
              history.push("/login");
              return;
          }
          if (assetsContext?.cryptoList) {
              const coinsData = [];
  
              // Process cryptoList
              Object.keys(assetsContext.cryptoList).forEach((coinKey) => {
                  const coin = assetsContext.cryptoList[coinKey];
  
  
  
                  coinsData.push({
                      value: coinKey.toUpperCase(),
                      label: coinKey.toUpperCase(),
                      icon: `${coin.img}`,
  
                  });
              });
  
              // Set the coins data to state
              setCoins(coinsData);
          }
      }, [assetsContext]);


       useEffect(() => {
              if (!TOKEN || !ID) {
                  console.log("Redirecting to sign-in");
                  history.push("/login");
                  return;
              }
          
              if (selectedCoin) {
                  fetch(
                      `https://api.buycex.com/Wallet/cryptodeposit?coin=${selectedCoin.value.toLowerCase()}`,
                      {
                          headers: {
                              TOKEN: TOKEN,
                              ID: ID,
                          },
                      }
                  )
                      .then((response) => {
                          if (!response.ok) {
                              throw new Error(`HTTP error! Status: ${response.status}`);
                          }
                          return response.json();
                      })
                      .then((data) => {
                          if (data && data.data) {
                              // Extract the network data
                              const networks = Object.keys(data.data.network).map((key) => {
                                  const coin = data.data.network[key];
                                  return { value: coin.name, label: coin.title };
                              });
          
                              // Update the networks state
                              setNetworks(networks);
                          } else {
                              console.log("No data available in response");
                          }
                      })
                      .catch((error) => {
                          console.error("Error fetching data:", error);
                      });
              }
          }, [TOKEN, ID, selectedCoin, history]);

           useEffect(() => {
                  if (!TOKEN || !ID) {
                      history.push("/login");
                      return;
                  }
          
                  if (selectedCoin && TOKEN && ID) {
                      fetch(
                          `https://api.buycex.com/Crypto/coinbalance/coin/${selectedCoin.value}`,
                          {
                              headers: {
                                  TOKEN: TOKEN,
                                  ID: ID,
                              },
                          }
                      )
                          .then((response) => response.json())
                          .then((data) => {
          
                
                              if (data && data.data) {
                                 
                                
                                  setBalance(data.data.total);
                                  setLimit(data.data.zhehe);
                                  setFeesPercent(data.data.fees_percent);
                                  setFeesFlat(data.data.fees_flat);
          
                              } else {
                                  console.log("No data available");
                              }
                          })
                          .catch((error) => {
                              console.error("Error fetching data:", error);
                          });
                  }
              }, [selectedCoin, TOKEN, ID, history]);
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleWithdraw = async () => {

    if (!withdrawAmount || isNaN(withdrawAmount) || withdrawAmount <= 0) {
        toast.error("Enter a valid amount for transfer.");
        return;
    }


    if (!otp) {
        toast.error("Enter the OTP you received via email.");
        return;
    }


    if (!withdrawAddress) {
        toast.error("Please provide a withdrawal address.");
        return;
    }

    if (selectedCoin.value === "LTC" && !withdrawAddress.startsWith("L")) {
        toast.error("Invalid withdrawal address format for LTC.");
        return;
    }

    if (selectedCoin.value === "TRX") {
        if (!withdrawAddress.startsWith("TP")) {
            toast.error("Invalid withdrawal address format for TRX.");
            return;
        }

        if (withdrawAddress.length !== 34) {
            toast.error("Withdrawal address for TRX must be 34 characters long.");
            return;
        }
    }

    if (selectedCoin.value === "XRP") {
        if (!withdrawAddress.startsWith("r")) {
            toast.error("Invalid withdrawal address format for XRP.");
            return;
        }

        if (withdrawAddress.length !== 34) {
            toast.error("Withdrawal address for XRP must be 34 characters long.");
            return;
        }

        if (!destinationTag) {
            toast.error("Provide a destination tag for XRP.");
            return;
        }
    }

    if (selectedCoin.value === "TUSDT") {
        if (!withdrawAddress.startsWith("TP")) {
            toast.error("Invalid withdrawal address format for TUSDT.");
            return;
        }

        if (withdrawAddress.length !== 34) {
            toast.error("Withdrawal address for TUSDT must be 34 characters long.");
            return;
        }
    }


    if (!fundPassword) {
        toast.error("Provide your transaction password.");
        return;
    }


    if (!selectedCoin || !selectedCoin.value) {
        toast.error("Select a currency for withdrawal.");
        return;
    }


    toast.loading("Processing withdrawal...");
    try {
        const cryptoWithdrawalInputs = {
            coin: selectedCoin.value.toLowerCase(),
            network: selectedCoin.value.toLowerCase(),
            num: withdrawAmount,
            paypassword: fundPassword,
            cellphone_verify: otp, 
            addr: withdrawAddress,
            dest_tag: "ww" | "",
            gacode: g2faOtp,
        };

        console.log("Crypto Withdrawal Inputs:", cryptoWithdrawalInputs);
        const response = await dispatch(
            upCryptoWithdrawal(TOKEN, ID, cryptoWithdrawalInputs)
        );

        console.log("Crypto Withdrawal response:", response);
        toast.dismiss();
        if (response?.data?.status === 1) {
            toast.success(response.data.info || "Crypto withdrawal processed successfully.");
            setWithdrawResponse(response?.data?.data);
            setModalVisible(true);
            setWithdrawAddress("");
            setWithdrawAmount("");
            setG2faOtp("");
            setOtp("");
            setFundPassword("");
        } else {
            toast.error(`Error: ${response?.data?.info || "Something went wrong."}`);
        }
    } catch (error) {
        toast.dismiss(); // Dismiss loading
        console.error("Error:", error);
        toast.error("An error occurred while processing the withdrawal.");
    }
};

  const toggleWalletButton = () => {
    setIsWalletButtonActive(!isWalletButtonActive);
  };

  return (
    <>
      <div className="flex flex-col lg:flex-row w-full gap-6 justify-between">
        <div className="w-full mt-5">
        <CoinSelect
                                                coins={coins}
                                                selectedCrypto={selectedCoin}
                                                setSelectedCrypto={setSelectedCoin}
                                                handleImageClick={handleImageClick}
                                            />
        </div>
      </div>
      <div className="w-full mt-5">
        <div className='flex justify-between w-full'>
          <label className="block text-xs font-normal text-[#81858c] dark:text-white">
            Wallet Address
          </label>
          <label className="block mb-1 text-xs font-normal text-[#EFB81C] dark:text-white">
            Add
          </label>
        </div>
       

        <input
                                                                type="text"
                                                                className={`flex items-center bg-[#f3f5f7] text-sm w-full justify-between ${selectedCoin ? 'border border-black placeholder:text-[#81858c] hover:ring-1 ring-[#EFB81C] hover:border-[#EFB81C] font-semibold' : 'border'} rounded-lg px-4 py-4 cursor-pointer bg-white dark:bg-gray-800 dark:text-white`}
                                                                placeholder="Withdrawal address"
                                                                value={withdrawAddress}
                                                                onChange={(e) =>
                                                                    setWithdrawAddress(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                required
                                                            />
     
      </div>
      <div className="w-full mt-5">
      <div className='flex justify-between w-full'>
          <label className="block text-xs font-normal text-[#81858c] dark:text-white">
          Select Network
          </label>
        
        </div>
      <NetworkSelectDropdown
                                                    networks={networks}
                                                    selectedCoin={selectedCoin}
                                                    selectedNetwork={selectedNetwork}
                                                    setSelectedNetwork={setSelectedNetwork}
                                                />
      </div>
      <div className="flex mt-5 flex-col w-full">
        <div className='flex w-full justify-between'>
          <div className='flex items-center gap-1 mb-1'><label className='text-[#81858c] text-xs'>Amount</label><p className='text-[#81858c] underline-offset-4 underline decoration-dotted text-xs'>(Raise Amount)</p></div>
          {selectedNetwork && (
          <p className='text-xs text-[#81858c]'>{balance} {selectedCoin.value}</p>

          
       
                                                   
                                                )}
        </div>
        {selectedNetwork && (
            <input
                type="number"
                className={`flex items-center bg-[#f3f5f7] text-sm w-full justify-between ${
                    selectedNetwork
                        ? 'border border-black placeholder:text-[#81858c] hover:ring-1 ring-[#EFB81C] hover:border-[#EFB81C]'
                        : 'border'
                } rounded-lg px-4 py-4 cursor-pointer bg-white dark:bg-gray-800 dark:text-white`}
                placeholder="Withdrawable amount"
                value={withdrawAmount || ''}
                onChange={(e) => {
                    const value = parseFloat(e.target.value);

                    // Prevent negative values
                    if (isNaN(value) || value < 0) {
                        setWithdrawAmount(''); // Clear the value if negative
                        return;
                    }

                    if (value <= balance) {
                        setWithdrawAmount(value); // Update state with valid value
                    } else {
                        toast.error("The amount must be less than or equal to your balance.", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setWithdrawAmount('');
                    }
                }}
                required
            />
        )}      </div>
      <div className='flex items-center mt-1 justify-between py-1 w-full'>
      <div className='flex items-center gap-5'>
      <div className='flex items-center'>
        <StyledRadio
          type="radio"
          name="option"
          value="funding"
          checked={selectedOption === 'funding'}
          onChange={handleChange}
        >
        <p className='text-sm'>Funding</p>
        </StyledRadio>
      </div>
      <div className='flex items-center'>
        <StyledRadio
          type="radio"
          name="option"
          value="spot" 
          checked={selectedOption === 'spot'}
          onChange={handleChange}
        >
        <p className='text-sm'>Spot</p>
        </StyledRadio>
      </div>
    </div>
        <div className='flex items-center gap-2'>
          <p className='text-xs text-black'>0</p>
          <FiRefreshCcw className='text-[#efb81c]' />
        </div>
      </div>
      {selectedNetwork && (
      <div className='flex items-center my-5 justify-between w-full'>
        <p className='text-xs text-[#81858c]'>Transaction Fee</p>
        <p className='text-xs text-black'>{feesFlat} {selectedCoin.value}</p>
      </div>
        )}
      <div>
        <div className='flex items-center justify-between w-full'>
          {selectedNetwork && (
          <div className='flex flex-col'>
       
            <p className='text-lg font-semibold text-black'>{withdrawAmount || 0} {selectedCoin.value}</p>
            <p className='text-[#81858c] text-xs'>Amount Received <span className='text-[#efb81c] text-xs'>Set Up</span></p>
       
            </div>
                  )} 
          <button                  onClick={handleButtonClick} className='px-4 py-2 font-semibold text-sm text-black bg-[#efb81c] rounded-md'>
            Confirm
          </button>
        </div>
      </div>

      <CryptoEmailVerificationModal
                                                    isVisible={isEmailVerificationModalVisible}
                                                    onClose={handleEmailVerificationClose}
                                                    onSuccess={handleEmailVerificationSuccess}
                                                    otp={otp}
                                                    setOtp={setOtp}
                                                    g2faOtp={g2faOtp}
                                                    setG2faOtp={setG2faOtp}
                                                    fundPassword={fundPassword}
                                                    setFundPassword={setFundPassword}
                                                    handleWithdraw={handleWithdraw}
                                                />

                                                <TransactionDetailsModal
                                                    isVisible={isModalVisible}
                                                    onClose={() => {
                                                        setModalVisible(false);
                                                        setWithdrawResponse("");
                                                    }}
                                                    withdrawResponse={withdrawResponse}

                                                />
    </>
  );
}

const StyledRadio = styled(Radio)`
    .ant-radio-inner {
        border-color: #efb81c;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #efb81c;
        background-color: #efb81c;
    }

    .ant-radio-checked .ant-radio-inner::after {
        background-color: #fff;
    }

    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2);
    }

    .ant-radio-wrapper {
        color: #1f2937;
    }
`;
export default Address;
