import React, { useState } from "react";
import { useSelectedCoin } from "../context/SelectedCoinContext";
import { FaArrowLeft } from "react-icons/fa6";

import TradeAreaSpot from "./TradeAreaSpot";
import TradeAreaCross2X from "./TradeAreaCross";
import TradeAreaIsolated from "./TradeAreaIsolated";
import CoverTradeModel from "./modals/CoverTradeModel";
import TransferModel from "./modals/TransferModel";
import TenxModel from "./modals/TenxModel";
import DepositModel from "./modals/DepositModel";
import CrossModel from "./modals/CrossModel";
import ProfitModel from "./modals/ProfitModel";

const PerpetualTradeArea = () => {
    // Get selected coin data
    const { selectedCoin } = useSelectedCoin();

    console.log("SelectedCoin:", selectedCoin);
    const [tradeTab, setTradeTab] = useState("Trade");
    const [orderTab, setOrderTab] = useState("Spot");
    const [orderAction, setOrderAction] = useState("Buy");
    const [orderActionType, setOrderActionType] = useState("Limit");
    const [actionTypeOptions, setActionTypeOptions] = useState("Conditional");
    const [selectWidth, setSelectWidth] = useState("100px");
    const [showConvertModel, setShowConvertModel] = useState(false);
    const [showTransferModel, setShowTransferModel] = useState(false);
    const [showTenxModle, setShowTenxModel] = useState(false);
    const [showDepositModel, setShowDepositModel] = useState(false);
    const [showCrossModel, setShowCrossModel] = useState(false);
    const [showTools, setShowTools] = useState(false);

    const handleOptionChange = (event) => {
        const selectedValue = event.target.value;
        setActionTypeOptions(selectedValue);

        // Set width based on selected option
        switch (selectedValue) {
            case "Stop":
                setSelectWidth("55px");
                break;
            case "TP/SL":
                setSelectWidth("70px");
                break;
            case "Conditional":
                setSelectWidth("100px");
                break;
            case "Trailing Stop":
                setSelectWidth("100px");
                break;
            case "Iceberg":
                setSelectWidth("80px");
                break;
            case "TWAP":
                setSelectWidth("70px");
                break;
            case "Scaled Order":
                setSelectWidth("100px");
                break;
            default:
                setSelectWidth("50px");
                break;
        }
    };
    // const orderTabData = [
    //     { idName: "Spot", label: "Spot" },
    //     { idName: "Cross 2X", label: `Cross 2x` },
    //     { idName: "Isolated", label: "Isolated" },
    // ];

    const orderTabData = [
        { idName: "Spot", label: "Spot" },
        { idName: "Spot", label: `Margin 10x` },
        { idName: "Spot", label: "Convert" },
    ];

    const orderActionTypeData = [
        { id: "Limit", label: "Limit" },
        { id: "Market", label: "Market" },
        { id: "Stop", label: "Stop" },
    ];

    // Set Order tab
    const handleTradeNavClick = (tabName) => {
        setOrderTab(tabName);
    };

    // Set Order type
    const handleTypeSelect = (type) => {
        setOrderActionType(type);
    };

    // Change OrderAction Type
    const handleAction = (type) => {
        setOrderAction(type);
    };

    return (
        <div className="w-full dark:bg-[#101014]  dark:text-[#71777A]">
            <div className="flex items-center justify-around trade-area__spot-btn-wrap bg-gray-100  dark:bg-[#25282C] dark:text-[#FFFFFF] rounded-md text-sm mx-2 mt-2">
                {/* Button: Buy */}
                <button
                    type="button"
                    className={`py-1.5 px-4 w-full rounded-md ${
                        tradeTab === "Trade"
                            ? "bg-gray-300 dark:bg-[#404854]"
                            : "bg-gray-100 dark:bg-[#25282C]"
                    }`}
                    onClick={() => setTradeTab("Trade")}
                >
                    Trade
                </button>

                {/* Button: Sell */}
                <button
                    type="button"
                    className={`py-1.5 px-4 w-full rounded-md ${
                        tradeTab === "Tools"
                            ? "bg-gray-300 dark:bg-[#404854]"
                            : "bg-gray-100 dark:bg-[#25282C]"
                    }`}
                    onClick={() => setTradeTab("Tools")}
                >
                    Tools
                </button>
            </div>
            {tradeTab === "Trade" ? (
                <>
                    <div className="trade-selector-nav">
                        {/* Buttons to Switch between trade and tools */}

                        {/* Shows Main tab Selector (Spot, Cross 2X, Isolated) */}
                        {/* <div className="flex items-center w-full gap-4 px-2 py-2 trade-selector-nav-wrapper">
                            {orderTabData.map((tab) => (
                                <button
                                    key={tab.idName}
                                    type="button"
                                    className={`nav-item w-max px-0.5 py-1.5 text-[14px] font-bold border-b-[2px] ${
                                        orderTab === tab.label
                                            ? "border-[#EFB81C]"
                                            : "border-transparent text-[#15141550]"
                                    }`}
                                    onClick={() =>
                                        handleTradeNavClick(tab.label)
                                    }
                                >
                                    {tab.label}
                                </button>
                            ))}
                        </div> */}

                        <div>
                            {/* Buttons to Switch between BUY and Sell Order */}
                            <div className="flex items-center justify-around gap-2 px-2 py-2 trade-area__spot-btn-wrap mt-2">
                                {/* Button: Buy */}
                                <button
                                    type="button"
                                    className={`py-2 text-xs px-4 w-full rounded-md ${
                                        orderAction === "Buy"
                                            ? "bg-gray-300 text-black dark:text-[#fff] font-bold  dark:bg-[#404854]"
                                            : "bg-gray-100 dark:bg-[#25282C]"
                                    }`}
                                    onClick={() => {
                                        handleAction("Buy"); // This keeps your state management
                                        setShowCrossModel(true); // Add this line to show the model on every click
                                    }}
                                >
                                    Cross
                                </button>
                                {showCrossModel && (
                                    <CrossModel
                                        setShowCrossModel={setShowCrossModel}
                                    />
                                )}

                                {/* Button: Sell */}
                                <button
                                    type="button"
                                    className={`py-2 text-xs px-4 w-full rounded-md ${
                                        orderAction === "Sell"
                                            ? "bg-gray-300 text-black dark:text-[#fff] font-bold dark:bg-[#404854]"
                                            : "bg-gray-100 dark:bg-[#25282C]"
                                    }`}
                                    onClick={() => {
                                        handleAction("Sell"); // This keeps your state management
                                        setShowTenxModel(true); // Add this line to show the model on every click
                                    }}
                                >
                                    10.00x
                                </button>

                                {showTenxModle && (
                                    <TenxModel
                                        setShowTenxModel={setShowTenxModel}
                                    />
                                )}
                                <ProfitModel selectedCoin={selectedCoin} />
                            </div>
                        </div>

                        {/* Shows Selector for Order Action Type ("Limit", "Market", "OCO") */}
                        <div className="flex items-center w-full gap-4 px-2 py-2 trade-area__type-selector">
                            {orderActionTypeData.map((type) => (
                                <button
                                    key={type.id}
                                    type="button"
                                    className={` py-1.5 border-b text-[12px] font-[500] w-max 
                            ${
                                orderActionType === type.id
                                    ? "border-[#EFB81C] text-[#151415] dark:text-[#FFFFFF]"
                                    : "border-transparent text-[#15141580]  dark:text-[#71777A]"
                            } hover:text-[#151415] transition-colors duration-200`}
                                    onClick={() => handleTypeSelect(type.id)}
                                    aria-pressed={orderActionType === type.id}
                                >
                                    {type.label === "Stop" ? (
                                        <>
                                            <select
                                                name=""
                                                id=""
                                                className={`border-none  dark:bg-[#101014]  ${
                                                    orderActionType === type.id
                                                        ? "text-[#151415]  dark:text-[#FFFFFF] "
                                                        : "text-[#15141580]  dark:text-[#71777A]"
                                                }`}
                                                value={actionTypeOptions}
                                                onChange={handleOptionChange}
                                                style={{ width: selectWidth }}
                                            >
                                                <option value="Conditional">
                                                    Conditional
                                                </option>
                                                <option value="TP/SL">
                                                    TP/SL
                                                </option>
                                                <option value="Stop">Stop</option>

                                                <option value="Trailing Stop">
                                                    Trailing Stop
                                                </option>
                                                <option value="Iceberg">
                                                    Iceberg
                                                </option>
                                                <option value="TWAP">
                                                    TWAP
                                                </option>
                                                <option value="Scaled Order">
                                                    Scaled Order
                                                </option>
                                            </select>
                                        </>
                                    ) : (
                                        <>{type.label}</>
                                    )}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Shows Trade Area based on selected order type to make orders */}
                    <div className="px-2 py-4">
                        {/* For Spot Orders */}
                        {orderTab === "Spot" && (
                            <TradeAreaSpot
                                orderAction={orderAction}
                                orderActionType={orderActionType}
                                setShowDepositModel={setShowDepositModel}
                                setShowTransferModel={setShowTransferModel}
                            />
                        )}

                        {/* For Cross Orders */}
                        {orderTab === "Margin 10x" && (
                            <TradeAreaCross2X
                                orderAction={orderAction}
                                orderActionType={orderActionType}
                            />
                        )}

                        {/* For Isolated Orders */}
                        {orderTab === "Convert" && (
                            <TradeAreaIsolated
                                orderAction={orderAction}
                                orderActionType={orderActionType}
                            />
                        )}
                    </div>
                    {/* last section */}
                    <div className=" mt-2 flex items-center justify-around gap-2 px-2 py-2 trade-area__spot-btn-wrap">
                        <>
                            {/* Button: Buy */}
                            <button
                                type="button"
                                className={` h-10 w-full rounded-md text-left px-2  bg-gradient-to-r from-red-300 to-gray-200 
                              `}
                            >
                                <div className="text-xs font-semibold">
                                    {selectedCoin.name}
                                </div>
                                <div className="text-red-500 text-xs font-semibold">
                                    -2.15%
                                </div>
                            </button>

                            {/* Button: Sell */}
                            <button
                                type="button"
                                className={`h-10 w-full px-2 text-left rounded-md bg-gradient-to-r from-red-300 to-gray-200
                             `}
                            >
                                <div className="text-xs font-semibold">
                                    {selectedCoin.name}
                                </div>
                                <div className="text-red-500 text-xs font-semibold">
                                    -2.15%
                                </div>
                            </button>
                        </>
                    </div>
                    {/* unified trading account  */}
                    <div className="mt-2 px-2 py-2 flex justify-between items-center">
                        <div className="text-sm font-semibold">
                            Unified Trading Account
                        </div>
                        <div className="text-sm font-semibold text-yellow-400">
                            Buy crypto
                        </div>
                    </div>
                    <div className=" px-2 py-2 flex justify-between items-center">
                        <div className="text-xs font-medium text-gray-500">
                            Margin Mode
                        </div>
                        <div className="text-xs font-medium ">Cross Margin</div>
                    </div>
                    <div className=" px-2 py-2 mt-2 flex justify-between items-center border-t border-gray-300">
                        <div className="text-xs font-medium text-gray-500 flex flex-col gap-1">
                            <div className="border-b border-dashed border-gray-500 p-1 w-fit">
                                Initial Margin
                            </div>
                            <div className="border-b border-dashed border-gray-500 p-1 w-fit">
                                Maintenance Margin
                            </div>
                        </div>
                        <div className="text-xs font-medium flex flex-col gap-1 text-green-500">
                            <div>0%</div>
                            <div>0%</div>
                        </div>
                    </div>
                    <div className=" px-2 py-2 mt-2 flex justify-between items-center border-t border-gray-300">
                        <div className="text-xs font-medium text-gray-500 flex flex-col gap-1">
                            <div className="border-b border-dashed border-gray-500 p-1 w-fit">
                                USDT Balance
                            </div>
                            <div className="border-b border-dashed border-gray-500 p-1 w-fit">
                                ETH Balance
                            </div>
                        </div>
                        <div className="text-xs font-medium flex flex-col gap-1 ">
                            <div>0 USDT</div>
                            <div>0 ETH</div>
                        </div>
                    </div>
                    <div className="flex items-center justify-around gap-2 px-2 py-2 trade-area__spot-btn-wrap">
                        {/* Button: Buy */}
                        <button
                            onClick={() => setShowDepositModel(true)}
                            type="button"
                            className={`py-2 text-xs px-4 w-full rounded-md bg-gray-100 font-semibold dark:bg-[#404854] dark:text-[#fff]  `}
                        >
                            Deposit
                        </button>
                        {showDepositModel && (
                            <DepositModel
                                selectedCoin={selectedCoin}
                                showDepositModel={setShowDepositModel}
                            />
                        )}

                        {/* Button: Sell */}
                        <button
                            onClick={() => setShowConvertModel(true)}
                            type="button"
                            className={`py-2 text-xs px-4 w-full rounded-md bg-gray-100 font-semibold dark:bg-[#404854] dark:text-[#fff] `}
                        >
                            Convert
                        </button>
                        {showConvertModel && (
                            <CoverTradeModel
                                selectedCoin={selectedCoin}
                                showConvertModel={setShowConvertModel}
                            />
                        )}
                        <button
                            onClick={() => setShowTransferModel(true)}
                            type="button"
                            className={`py-2 px-4 w-full rounded-md bg-gray-100 font-semibold  dark:bg-[#404854] dark:text-[#fff] text-xs `}
                        >
                            Transfer
                        </button>
                        {showTransferModel && (
                            <TransferModel
                                selectedCoin={selectedCoin}
                                showTransferModel={setShowTransferModel}
                            />
                        )}
                    </div>
                    <div className="mt-2 px-2 py-2 ">
                        <div className="text-sm font-semibold">
                            Contract Details BTCUSDT
                        </div>
                    </div>
                    <div className=" px-2 py-2 flex justify-between items-center">
                        <div className="text-xs font-medium text-gray-500">
                            Expiration Date
                        </div>
                        <div className="text-xs font-medium ">Perpetual</div>
                    </div>
                    <div className=" px-2 py-2 flex justify-between items-center">
                        <div className="text-xs font-medium text-gray-500">
                            Index Price
                        </div>
                        <div className="text-xs font-medium ">70,490.77</div>
                    </div>
                    <div className=" px-2 py-2 flex justify-between items-center">
                        <div className="text-xs font-medium text-gray-500">
                            Mark Price
                        </div>
                        <div className="text-xs font-medium ">70,490.77</div>
                    </div>
                    <div className=" px-2 py-2 flex justify-between items-center">
                        <div className="text-xs font-medium text-gray-500">
                            Open Interest
                        </div>
                        <div className="text-xs font-medium ">
                            73,336.136 BTC
                        </div>
                    </div>
                    <div className=" px-2 py-2 flex justify-between items-center">
                        <div className="text-xs font-medium text-gray-500">
                            24H Turnover
                        </div>
                        <div className="text-xs font-medium ">
                            9,714,830,344.18 USDT
                        </div>
                    </div>
                    <div className=" px-2 py-2 flex justify-between items-center">
                        <div className="text-xs font-medium text-gray-500">
                            24H Volume
                        </div>
                        <div className="text-xs font-medium ">
                            135,292.535 BTC
                        </div>
                    </div>
                    <div className=" px-2 py-2 flex justify-between items-center">
                        <div className="text-xs font-medium text-gray-500">
                            Risk Limit
                        </div>
                        <div className="text-xs font-medium ">
                            2,000,000 USDT
                        </div>
                    </div>
                    <div className=" px-2 py-2 flex justify-between items-center">
                        <div className="text-xs font-medium text-gray-500">
                            Contract Value
                        </div>
                        <div className="text-xs font-medium ">1 BTC</div>
                    </div>
                </>
            ) : (
                <>
                    {showTools ? (
                        <>
                            <div className="px-2 mt-2">
                                <h2 className="font-semibold text-lg flex items-center gap-2">
                                    <span
                                        onClick={() => setShowTools(false)}
                                        className="cursor-pointer "
                                    >
                                        <FaArrowLeft />
                                    </span>
                                    <span>Chase</span>
                                </h2>
                                <div className="mt-4">
                                    <TradeAreaSpot
                                        orderAction={orderAction}
                                        orderActionType={orderActionType}
                                    />
                                </div>
                                {/* unified trading account  */}
                                <div className="mt-2 px-2 py-2 flex justify-between items-center">
                                    <div className="text-sm font-semibold">
                                        Unified Trading Account
                                    </div>
                                    <div className="text-sm font-semibold text-yellow-400">
                                        Buy crypto
                                    </div>
                                </div>
                                <div className=" px-2 py-2 flex justify-between items-center">
                                    <div className="text-xs font-medium text-gray-500">
                                        Margin Mode
                                    </div>
                                    <div className="text-xs font-medium ">
                                        Cross Margin
                                    </div>
                                </div>
                                <div className=" px-2 py-2 mt-2 flex justify-between items-center border-t border-gray-300">
                                    <div className="text-xs font-medium text-gray-500 flex flex-col gap-1">
                                        <div className="border-b border-dashed border-gray-500 p-1 w-fit">
                                            Initial Margin
                                        </div>
                                        <div className="border-b border-dashed border-gray-500 p-1 w-fit">
                                            Maintenance Margin
                                        </div>
                                    </div>
                                    <div className="text-xs font-medium flex flex-col gap-1 text-green-500">
                                        <div>0%</div>
                                        <div>0%</div>
                                    </div>
                                </div>
                                <div className=" px-2 py-2 mt-2 flex justify-between items-center border-t border-gray-300">
                                    <div className="text-xs font-medium text-gray-500 flex flex-col gap-1">
                                        <div className="border-b border-dashed border-gray-500 p-1 w-fit">
                                            USDT Balance
                                        </div>
                                        <div className="border-b border-dashed border-gray-500 p-1 w-fit">
                                            ETH Balance
                                        </div>
                                    </div>
                                    <div className="text-xs font-medium flex flex-col gap-1 ">
                                        <div>0 USDT</div>
                                        <div>0 ETH</div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-around gap-2 px-2 py-2 trade-area__spot-btn-wrap">
                                    {/* Button: Buy */}
                                    <button
                                        onClick={() =>
                                            setShowDepositModel(true)
                                        }
                                        type="button"
                                        className={`py-1.5 px-4 w-full rounded-md bg-gray-100 dark:bg-[#404854] dark:text-[#fff] text-xs font-semibold`}
                                    >
                                        Deposit
                                    </button>
                                    {showDepositModel && (
                                        <DepositModel
                                            selectedCoin={selectedCoin}
                                            showDepositModel={
                                                setShowDepositModel
                                            }
                                        />
                                    )}

                                    {/* Button: Sell */}
                                    <button
                                        onClick={() =>
                                            setShowTransferModel(true)
                                        }
                                        type="button"
                                        className={`py-1.5 px-4 w-full rounded-md bg-gray-100 dark:bg-[#404854] dark:text-[#fff] text-xs font-semibold`}
                                    >
                                        Transfer
                                    </button>
                                    {showTransferModel && (
                                        <TransferModel
                                            selectedCoin={selectedCoin}
                                            showTransferModel={
                                                setShowTransferModel
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="px-2">
                                <h2 className="text-xs font-bold mt-4 text-gray-500 mb-2">
                                    Track Price
                                </h2>
                                <div
                                    onClick={() => setShowTools(true)}
                                    className="bg-gray-100 px-4 py-2 rounded-lg cursor-pointer dark:bg-[#25282C]"
                                >
                                    <h2 className="text-[14px] font-bold dark:text-[#fff]">
                                        Chase Limit Order
                                    </h2>
                                    <p className="text-[12px] mt-1 text-gray-500 dark:text-[#71777A]">
                                        Tracks Best Ask/Bid with Limit Orders
                                    </p>
                                </div>
                                <h2 className="text-xs font-bold mt-8 text-gray-500 mb-2  ">
                                    Split Orders
                                </h2>
                                <div
                                    onClick={() => setShowTools(true)}
                                    className="bg-gray-100 dark:bg-[#25282C] dark:text-[#FFFFFF] px-4 py-2 rounded-lg cursor-pointer"
                                >
                                    <h2 className="text-[14px] font-bold dark:text-[#fff]">
                                        TWAP
                                    </h2>
                                    <p className="text-[12px] mt-1 text-gray-500  dark:text-[#71777A]">
                                        Large orders splitting / Scheduled
                                        orders placing / Slipping reducing
                                    </p>
                                </div>
                                <div
                                    onClick={() => setShowTools(true)}
                                    className="bg-gray-100 dark:bg-[#25282C] dark:text-[#FFFFFF] px-4 py-2 rounded-lg mt-2 cursor-pointer"
                                >
                                    <h2 className="text-[14px] font-bold">
                                        Ice berg
                                    </h2>
                                    <p className="text-[12px] mt-1 text-gray-500  dark:text-[#71777A]">
                                        Hidden Orders and Customize Placing
                                        Orders
                                    </p>
                                </div>
                                <div
                                    onClick={() => setShowTools(true)}
                                    className="bg-gray-100 dark:bg-[#25282C] dark:text-[#FFFFFF] px-4 py-2 rounded-lg mt-2 cursor-pointer"
                                >
                                    <h2 className="text-[14px] font-bold">
                                        Scaled Order
                                    </h2>
                                    <p className="text-[12px] mt-1 text-gray-500  dark:text-[#71777A]">
                                        Split Orders / Laddered Entries
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default PerpetualTradeArea;
