import React, { useState, useEffect } from "react";
import ClaimHistory from "./ClaimHistory/ClaimHistory";
import YieldSummary from "./YieldSummary/YieldSummary";


function LaunchpoolSummary() {
    const [activeItem, setActiveItem] = useState("Claim History");
    const discoverNavItems = ["Claim History", "Yield Summary"];

  

    const renderContent = () => {
        switch (activeItem) {
            case "Claim History":
                return <ClaimHistory />;
            case "Yield Summary":
                return <YieldSummary />;
            default:
                return <ClaimHistory />;
        }
    };

    return (
        <div className="px-3">
            <div className="container mt-5 !p-0">
                <nav className="flex gap-3 w-[60%] border-gray-200 pt-2">
                    {discoverNavItems.map((item) => (
                        <button
                            key={item}
                            onClick={() => setActiveItem(item)}
                            className={`relative py-2 px-2 text-md font-semibold ${
                                activeItem === item ? "" : "text-gray-400"
                            }`}
                        >
                            {item}
                            {activeItem === item && (
                                <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                            )}
                        </button>
                    ))}
                </nav>

                <div className="mt-6">{renderContent()}</div>
            </div>
        </div>
    );
}

export default LaunchpoolSummary;
