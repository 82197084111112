import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa6";
import HeroTable from "./index2/HeroTable";
import { FaGift } from "react-icons/fa";
import { TfiAnnouncement } from "react-icons/tfi";
import { useEffect, useRef, useState } from "react";
import { TiArrowRight } from "react-icons/ti";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { googleRegisterNew } from "store/actions/authAction";
import axios from "axios";
import { RiArrowRightLine } from "react-icons/ri";

function HeroSection({ username }) {
  const [input, setInput] = useState("");
  const dispatch = useDispatch();
  const { isLoading, googleData } = useSelector(
    (state) => state?.googleRegister
  );

  const register = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log("Login successful, codeResponse:", codeResponse);
      const { access_token } = codeResponse;

      try {
        const response = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Accept: "application/json",
            },
          }
        );
        const profileData = response.data;
        const email = profileData.email;

        dispatch(googleRegisterNew(email, () => history.push("/")));
      } catch (error) {
        console.log("Profile fetch error:", error);
        dispatch({
          type: "FETCH_GOOGLE_REGISTER_FAILURE",
          payload: error.message,
        });
      }
    },
    onError: (error) => {
      console.log("Login Failed:", error);
      dispatch({
        type: "FETCH_GOOGLE_REGISTER_FAILURE",
        payload: error.message,
      });
    },
  });

  const handleSignUp = () => {
    if (input) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (emailRegex.test(input)) {
        localStorage.setItem("userEmail", input);
        localStorage.setItem("signupMethod", "email");
        toast.success("Email saved! Redirecting...");
      } else if (!isNaN(input) && input.length >= 7) {
        localStorage.setItem("userPhone", input);
        localStorage.setItem("signupMethod", "phone");
        toast.success("Phone number saved! Redirecting...");
      } else {
        toast.error("Please enter a valid email or phone number.");
        return;
      }

      setTimeout(() => {
        window.location.href = "/register";
      }, 1000);
    }
  };


  const notification = [
    { msg: "Contact, with up to 75x leverage" },
    { msg: "[LATAM] Ask Me Anything About P2P and Earn Up to 60 USDT" },
    { msg: "[CIS] Ask Me Anything About P2P and Earn Up to 60 USDT" },
    { msg: "Contact, with up to 75x leverage" },
    { msg: "[LATAM] Ask Me Anything About P2P and Earn Up to 60 USDT" },
    { msg: "[CIS] Ask Me Anything About P2P and Earn Up to 60 USDT" },
    { msg: "Contact, with up to 75x leverage" },
    { msg: "[LATAM] Ask Me Anything About P2P and Earn Up to 60 USDT" },
    { msg: "[CIS] Ask Me Anything About P2P and Earn Up to 60 USDT" },
  ]

  const scrollRef = useRef(null);
  const [duration, setDuration] = useState(30);
  const flippingWords = ["Anywhere,", "Anytime,"];
  const [currentWord, setCurrentWord] = useState(flippingWords[0]);
  const [flipState, setFlipState] = useState("flip-enter");
  const [slideClass, setSlideClass] = useState("");
  const textRef = useRef(null);

  useEffect(() => {
    const wordInterval = setInterval(() => {
      setFlipState("flip-exit");

      setTimeout(() => {
        setCurrentWord((prev) => {
          const nextIndex = (flippingWords.indexOf(prev) + 1) % flippingWords.length;
          return flippingWords[nextIndex];
        });

        setFlipState("flip-enter");

        setSlideClass(currentWord === "Anywhere," ? "slide-more" : "slide-less");
      }, 600);
    }, 3000);

    return () => clearInterval(wordInterval);
  }, [currentWord]);

  useEffect(() => {
    if (scrollRef.current) {
      const contentWidth = scrollRef.current.scrollWidth;
      const containerWidth = scrollRef.current.clientWidth;

      const speed = contentWidth / 50;
      setDuration(speed);
    }
  }, [notification.length]);

  const [timeLeft, setTimeLeft] = useState({
    hours: 11,
    minutes: 11,
    seconds: 11,
  });

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimeLeft((prevTime) => {
        let { hours, minutes, seconds } = prevTime;

        if (hours === 0 && minutes === 0 && seconds === 0) {
          clearInterval(countdown);
          return { hours: 0, minutes: 0, seconds: 0 };
        }

        if (seconds === 0) {
          if (minutes === 0) {
            return { hours: hours - 1, minutes: 59, seconds: 59 };
          } else {
            return { hours, minutes: minutes - 1, seconds: 59 };
          }
        } else {
          return { hours, minutes, seconds: seconds - 1 };
        }
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  return (
    <>
      <div className="bg-black">
        <div className="h-auto lg:h-[450px] container flex flex-col lg:flex-row gap-10 lg:py-0 py-8 items-start sm:items-center justify-between">
          <div className="w-full lg:w-1/2">
            <div className="flex flex-col">
              <div className="flex flex-col gap-5 w-full">
                <span
                  className={`text-3xl sm:text-[48px] leading-snug w-full md:w-[80%] text-start sm:text-left font-semibold text-white transition-opacity duration-300 `}
                >
                  Where the Future of Crypto Begins!
                </span>

                <div>
                  <div className="flipping-container w-full justify-start inline-flex">
                    <h4 className="text-white text-xs sm:text-[16px] font-semibold cursor-pointer text-start sm:text-left">
                      Trade Crypto{" "}
                      <span ref={textRef} className={`flip-text text-xs sm:text-[16px] text-[#EFB81c] ${flipState}`}>
                        {currentWord}
                      </span>
                      <span className={`sliding-phrase text-xs sm:text-[16px] pl-2 ${slideClass}`}>
                        Capture Every Opportunity.
                      </span>
                    </h4>
                  </div>

                  {username ? "" :
                    <div className="text-white/60 gap-1.5 items-start pt-3 sm:items-center justify-center sm:justify-start w-full inline-flex pb-2">
                      <FaGift className="text-white/40" /> <h4 className="text-xs sm:text-[16px] text-center">Sign up and win $<span className="text-xs sm:text-[16px]">10,000+</span> in welcome rewards</h4>
                    </div>
                  }
                </div>
              </div>
              {username ? "" :
                <div className="flex flex-col sm:flex-row mt-5 gap-3">
                  <div className="flex w-full sm:w-80 relative">
                    <input
                      className="bg-white placeholder:text-gray-500 font-medium rounded-[0.375rem] text-sm px-4 w-full sm:w-72 h-12 pr-24 focus:outline-none"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      placeholder="Email or Phone"
                    />
                    <button onClick={handleSignUp} className="w-24 h-12 font-medium text-black absolute right-0 top-0 rounded-[0.375rem] bg-[#EFB81C]">
                      Sign Up
                    </button>
                  </div>
                  <div className="flex items-center justify-center gap-3">
                    <div className="p-3 bg-white self-start rounded-full cursor-pointer">
                      <FaApple size={22} />
                    </div>
                    <div className="p-3 bg-white rounded-full cursor-pointer">
                      <FcGoogle onClick={() => register()} size={22} />
                    </div>
                  </div>
                </div>
              }
              <div>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-2/3 lg:w-[40%]">
            <HeroTable />
          </div>
        </div>
      </div>
      <div className="bg-[#f7f7f7] dark:bg-[#090a0c] p-2">
        <div className="h-auto md:h-16 flex flex-col sm:flex-row justify-between px-3 sm:px-3 items-start gap-4 md:gap-0 sm:items-center py-1">
          {/* First div */}
          <div className="flex items-center gap-2 sm:gap-4 w-full md:w-1/3">
            <img src="https://global.buycex.com/Upload/coin/677da3cd24a43.png" className="h-6 sm:h-8 w-6 sm:w-8" />
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <h3 className="font-bold text-sm sm:text-lg">Buycex</h3>
                <span className="bg-[#e0f7f0] text-ellipsis whitespace-nowrap text-[#01bc8d] font-medium px-2 rounded-md">New Listing</span>
              </div>
              <span className="opacity-70">Buycex</span>
            </div>
          </div>

          {/* Second div (Timer) */}
          <div className="flex flex-col w-full md:w-1/3 items-start md:items-center">
            <div className="flex items-center justify-center gap-1 sm:gap-3">
              <div className="bg-white dark:bg-black border-none dark:border dark:border-[#454545]/30 font-bold p-1 sm:p-2 rounded-md">
                {String(timeLeft.hours).padStart(2, "0")}
                <span className="font-normal opacity-40 pl-2">h</span>
              </div>
              <span className="text-[16px] font-bold">:</span>
              <div className="bg-white dark:bg-black border-none dark:border dark:border-[#454545]/30 font-bold p-1 sm:p-2 rounded-md">
                {String(timeLeft.minutes).padStart(2, "0")}
                <span className="font-normal opacity-40 pl-2">m</span>
              </div>
              <span className="text-[16px] font-bold">:</span>
              <div className="bg-white dark:bg-black border-none dark:border dark:border-[#454545]/30 font-bold p-1 sm:p-2 rounded-md">
                {String(timeLeft.seconds).padStart(2, "0")}
                <span className="font-normal opacity-40 pl-2">s</span>
              </div>
            </div>
            <span className="opacity-70">Coming Soon</span>
          </div>

          {/* Third div (Button) */}
          <div className="w-full md:w-1/3 flex justify-end">
            <button className="bg-[#EFB81c] rounded-md px-3 py-1 w-full md:w-36 font-semibold flex justify-center items-center gap-1">
              Subscribe
              <RiArrowRightLine />
            </button>
          </div>
        </div>
      </div>

      <style>
  {`
    @keyframes slide {
      0% { transform: translateX(0); }
      100% { transform: translateX(-50%); }
    }
  `}
</style>

    </>
  );
}

export default HeroSection;
