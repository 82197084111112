import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

function PersonalInformation({
    truename,
    dob,
    address,
    IdCard,
    email,
    cellphone,
}) {
    const maskedEmail = email ? `${email.slice(0, 3)}****@****.com` : "";

    return (
        <div>
            <div className="my-3">
                <span className="text-[24px] font-semibold">
                    Personal Information
                </span>
                <div className="border rounded-xl p-8 mt-5">
                    <div className="flex text-sm mb-4 font-medium w-full justify-between">
                        <p className="text-[#969a9d]">Legal Name</p>
                        <p className="text-[#666769]">{truename ? truename : <Link to="/identity-verification" className="text-[#EFB81C] underline">Add KYC</Link>}</p>
                    </div>
                    <div className="flex text-sm mb-4 font-medium w-full justify-between">
                        <p className="text-[#969a9d]">Date of Birth</p>
                        <p className="text-[#666769]">{dob ? dob : <Link to="/identity-verification" className="text-[#EFB81C] underline">Add KYC</Link>}</p>
                    </div>
                    <div className="flex text-sm mb-4 font-medium w-full justify-between">
                        <p className="text-[#969a9d]">Address</p>
                        <div>
                        <p className="text-[#666769]">{address ? address : <Link to="/identity-verification" className="text-[#EFB81C] underline">Add KYC</Link>}</p>
                        </div>
                    </div>
                    <div className="flex text-sm mb-4 font-medium w-full justify-between">
                        <p className="text-[#969a9d]">
                            Identification Documents
                        </p>
                        <p className="text-[#666769] text-end">
                            {IdCard && IdCard !== "******"
                                ? `National ID Upload & Verify, ${IdCard}`
                                : <Link to="/identity-verification" className="text-[#EFB81C] underline">Add KYC</Link>}
                        </p>
                    </div>
                    <div className="flex text-sm mb-4 font-medium w-full justify-between">
                        <p className="text-[#969a9d]">Email Address</p>
                        <p className="text-[#666769]">{maskedEmail ? maskedEmail : "Not Set"}</p>
                    </div>
                    <div className="flex text-sm mb-4 font-medium w-full justify-between">
                        <p className="text-[#969a9d]">Phone Number</p>
                        <p className="text-[#666769]">
                            {cellphone ? `+${cellphone}` : "Not Set"}
                        </p>
                    </div>
                    <div className="flex flex-col md:flex-row text-sm font-medium w-full items-end justify-between">
                        <p className="w-full md:w-1/2 text-sm sm:text-lg font-bold">
                            BuyCex Exchange Investment %
                        </p>
                        <div className="flex text-sm items-end font-medium w-full md:w-1/5 justify-between">
                            <p className="text-[#22c55e]">0.01%</p>
                            <div className="flex flex-col items-end">
                                <p className="text-[#666769] text-[16px] font-semibold">
                                    Amount
                                </p>
                                <p className="text-[#22c55e]">1200 USDT</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PersonalInformation;
