import { useParams } from "react-router-dom";
import { IoTimeOutline } from "react-icons/io5";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useEffect, useRef, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import { MdOutlinePersonOutline } from "react-icons/md";
import { SiLitecoin } from "react-icons/si";
import { useSelector } from "react-redux";
import TransferModal from "views/trade/PostAds/TransferModal";
import LockModal from "../LockModal";

const PoolInfo = ({ title, value, align = "start" }) => (
    <div className="flex flex-col">
        <p className="text-[13px] opacity-60">{title}</p>
        <p className={`text-md pb-8 font-semibold text-${align}`}>{value}</p>
    </div>
);

const launchData = [
    {
        id: "COSVM",
        title: "COSVM",
        status:"Ended",
        img: "https://global.buycex.com/Upload/coin/65f80eb6e11c9.png",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
        eventCards: [
            { value: "12,345,678", subtitle: "Total Pool Amount (CVM)" },
            { value: "15 day(s)", subtitle: "Event Period" },
            { value: "5d 12h 45m 30s", subtitle: "End Time: 03/05 16:00 (UTC)" },
        ],
        pools: [
            {
                title: "CVM Pool",
                img: "https://global.buycex.com/Upload/coin/65f80eb6e11c9.png",
                subtitle: "Lock CVM for CVM",
                participants: 500,
                rewards: "3,000,000",
                locked: "2,100,000",
                myLocked: "500",
                claimed: "250",
                starttime: "02/21 13:00:00 (UTC)",
                endtime: "02/27 13:00:00 (UTC)",
            },
            {
                title: "USDT Pool",
                img: "https://global.buycex.com/Upload/coin/6780483188651.png",
                subtitle: "Stake CVM for Rewards",
                participants: 650,
                rewards: "1,500,000",
                locked: "1,200,000",
                myLocked: "300",
                claimed: "100",
                starttime: "02/21 13:00:00 (UTC)",
                endtime: "02/27 13:00:00 (UTC)",
            },
            {
                title: "BUYCEX Pool C",
                img: "https://global.buycex.com/Upload/coin/6780483188651.png",
                subtitle: "Earn CVM with Staking",
                participants: 800,
                rewards: "2,800,000",
                locked: "1,500,000",
                myLocked: "700",
                claimed: "400",
                starttime: "02/21 13:00:00 (UTC)",
                endtime: "02/27 13:00:00 (UTC)",
            }
        ]
    },
    {
        id: "DOGE",
        title: "DOGE Pool",
        status:"Ended",
        img: "https://global.buycex.com/Upload/coin/DOGE.png",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
        eventCards: [
            { value: "9,876,543", subtitle: "Total Pool Amount (DOGE)" },
            { value: "20 day(s)", subtitle: "Event Period" },
            { value: "8d 10h 30m 20s", subtitle: "End Time: 04/10 14:00 (UTC)" },
        ],
        pools: [
            {
                title: "DOGE Pool",
                img: "https://global.buycex.com/Upload/coin/DOGE.png",
                subtitle: "Lock DOGE for DOGE",
                participants: 400,
                rewards: "2,500,000",
                locked: "1,800,000",
                starttime: "02/21 13:00:00 (UTC)",
                endtime: "02/27 13:00:00 (UTC)",
            },
            {
                title: "DOGE Pool",
                img: "https://global.buycex.com/Upload/coin/DOGE.png",
                subtitle: "Stake DOGE for Rewards",
                participants: 700,
                rewards: "1,800,000",
                locked: "1,400,000",
                starttime: "02/21 13:00:00 (UTC)",
                endtime: "02/27 13:00:00 (UTC)",
            },
            {
                title: "DOGE Pool",
                img: "https://global.buycex.com/Upload/coin/DOGE.png",
                subtitle: "Stake DOGE for Rewards",
                participants: 700,
                rewards: "1,800,000",
                locked: "1,400,000",
                starttime: "02/21 13:00:00 (UTC)",
                endtime: "02/27 13:00:00 (UTC)",
            }
        ]
    },
    {
        id: "DOGE",
        title: "DOGE Pool",
        status:"Ended",
        img: "https://global.buycex.com/Upload/coin/DOGE.png",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
        eventCards: [
            { value: "9,876,543", subtitle: "Total Pool Amount (DOGE)" },
            { value: "20 day(s)", subtitle: "Event Period" },
            { value: "8d 10h 30m 20s", subtitle: "End Time: 04/10 14:00 (UTC)" },
        ],
        pools: [
            {
                title: "DOGE Pool",
                img: "https://global.buycex.com/Upload/coin/DOGE.png",
                subtitle: "Lock DOGE for DOGE",
                participants: 400,
                rewards: "2,500,000",
                locked: "1,800,000",
                starttime: "02/21 13:00:00 (UTC)",
                endtime: "02/27 13:00:00 (UTC)",
            },
            {
                title: "DOGE Pool",
                img: "https://global.buycex.com/Upload/coin/DOGE.png",
                subtitle: "Stake DOGE for Rewards",
                participants: 700,
                rewards: "1,800,000",
                locked: "1,400,000",
                starttime: "02/21 13:00:00 (UTC)",
                endtime: "02/27 13:00:00 (UTC)",
            },
            {
                title: "DOGE Pool",
                img: "https://global.buycex.com/Upload/coin/DOGE.png",
                subtitle: "Stake DOGE for Rewards",
                participants: 700,
                rewards: "1,800,000",
                locked: "1,400,000",
                starttime: "02/21 13:00:00 (UTC)",
                endtime: "02/27 13:00:00 (UTC)",
            }
        ]
    }
];

const LaunchPoolHistory = () => {
    const { coinId } = useParams();
    const currentLaunch = launchData.find((coin) => coin.id === coinId);

    if (!currentLaunch) {
        return <h2>Launch pool not found!</h2>;
    }

    const poolRefs = useRef([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveIndex(poolRefs.current.indexOf(entry.target));
                    }
                });
            },
            { threshold: 0.6 }
        );

        poolRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => {
            poolRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    const scrollToPool = (index) => {
        poolRefs.current[index]?.scrollIntoView({ behavior: "smooth" });
    };

    const user = useSelector((state) => state.authUser.user);

    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
            const dropdownRefs = useRef([]);
        
            const toggleDropdown = (index) => {
                setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
            };
        
            useEffect(() => {
                const handleClickOutside = (event) => {
                    if (
                        openDropdownIndex !== null &&
                        dropdownRefs.current[openDropdownIndex] &&
                        !dropdownRefs.current[openDropdownIndex].contains(event.target)
                    ) {
                        setOpenDropdownIndex(null);
                    }
                };
                document.addEventListener("mousedown", handleClickOutside);
                return () => {
                    document.removeEventListener("mousedown", handleClickOutside);
                };
            }, [openDropdownIndex]);
        
            const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
        
            const handleButtonClick = () => {
                setIsTransferModalVisible(true);
            };
        
            const [isLockModalVisible, setIsLockModalVisible] = useState(false);
            const [selectedCoin, setSelectedCoin] = useState({ name: "", image: "" });
        
            const coin = {
                name: "BUYCEX",
                image: "https://global.buycex.com/Upload/coin/677da3cd24a43.png",
            };
        
            const showLockModal = () => {
                setSelectedCoin(coin); 
                setIsLockModalVisible(true);
            };
        
            const handleCancel = () => {
                setIsLockModalVisible(false);
            };

    return (
        <div className="container">
            <div className=" py-6">
                <div className="flex gap-2 items-center text-sm"><Link to="/launchpool" className="opacity-60"><h3>Launchpool</h3></Link>/<Link to="#"><h3>{currentLaunch.title}</h3></Link></div>
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-4 py-8 w-2/3">
                        <div className="w-1/2 border rounded-md flex items-center justify-center px-4 py-8"><img src={currentLaunch.img} className="w-14 h-14" alt={currentLaunch.title} /></div>
                        <div>
                            <div className="flex items-center gap-2"><h3 className="font-bold text-xl">{currentLaunch.title}</h3> <span className="px-2 text-gray-500 font-semibold rounded-md bg-gray-200/60">{currentLaunch.status}</span></div>
                            <p className="text-sm text-[#1D1D1D99] font-medium py-2">{currentLaunch.description}</p>
                            <div className="flex items-center gap-3">
                                <p className="border rounded-full p-1 text-xs opacity-55">Webiste</p>
                                <p className="border rounded-full p-1 text-xs opacity-55">X (Twitter)</p>
                                <p className="border rounded-full p-1 text-xs opacity-55">Whitepaper</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-[24%]">
                        <div className="border rounded-[0.375rem] flex flex-col px-2 py-3 justify-center items-center">
                            <span className="text-sm">Event Period</span>
                            <span className="text-xl font-bold">01/21 11:00 ~ 02/10 11:00 UTC</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sticky top-14 bg-white border-b h-14 flex gap-4 z-10">
                {currentLaunch.pools.map((pool, index) => (
                    <button
                        key={index}
                        className={`text-md font-semibold h-14 mx-2 transition-colors duration-200 
                        ${activeIndex === index ? "border-b-2 border-[#EFB81c] text-black" : "text-gray-400"}`}
                        onClick={() => scrollToPool(index)}
                    >
                        {pool.title}
                    </button>
                ))}
            </div>

            <div className="flex flex-col gap-4 py-8">
                {currentLaunch.pools.map((pool, index) => (
                    <div key={index} ref={(el) => (poolRefs.current[index] = el)} className="flex-1 bg-white border hover:bg-[#f7f7f7] transition-all duration-300 ease-in-out cursor-pointer min-h-20 rounded-lg">
                        <div className="relative left-0 top-0 flex justify-end">
                        <span className="text-gray-500 bg-opacity-10 font-medium bg-gray-200/60 px-2 mb-2 rounded-tr-md rounded-bl-md">
                            Ended
                        </span>
                    </div>
                    <div className="px-4">
                        <div className="flex justify-between items-center w-full">
                            <div className="flex flex-col">
                                <div className="flex items-center gap-4">
                                    <img src={pool.img} className="w-6 h-6" alt="BUYCEX" />
                                    <h3 className="font-bold text-xl">{pool.title}</h3>
                                </div>
                                <p className="text-sm text-[#1D1D1D99] font-medium py-1">{pool.subtitle}</p>
                                <p className="flex items-center mt-4">
                                    <MdOutlinePersonOutline fill="#1D1D1D99" /> <span className="text-[#1D1D1D99] text-sm">{pool.participants}</span>
                                </p>
                            </div>
                            <div className="border opacity-60 h-28 w-28 flex items-center justify-center p-1 mt-2 rounded-full">
                                <SiLitecoin fill="#10d173" size={32} />
                                <SiLitecoin size={32} className="ml-[-2px]" />
                            </div>
                        </div>
                        <div className="border-b">
                            <div className="flex flex-wrap justify-between w-[80%]">
                                <div className="mt-10">
                                    <PoolInfo title="Total BCX Rewards" value={pool.rewards} />
                                    <PoolInfo title="Total Locked (BCX)" value={pool.locked} align="start" />
                                </div>
                                <div className="mt-10">
                                    <PoolInfo title="Locked (BCX)" value={pool.myLocked} />
                                    <PoolInfo title="Claimed Rewards (BCX)" value={pool.claimed} align="start" />
                                </div>
                                <div className="mt-10">
                                    <PoolInfo title="Start Time" value={pool.starttime} />

                                    <PoolInfo title="Participants" value={pool.participants} />
                                </div>
                                <div className="mt-10">
                                    <PoolInfo title="End Time" value={pool.endtime} align="start" />
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full justify-between">
                            <div className="py-8 w-1/2">
                                <div className="flex gap-3 items-center">
                                    <img src={pool.img} className="w-6 h-6" alt="BUYCEX" />
                                    <h3 className="text-md font-bold">My Assets</h3>
                                </div>
                                <div className="py-6">
                                    <span className="text-sm opacity-60">My Locked ({pool.stand})</span>
                                    <p className="font-bold opacity-60">--</p>
                                </div>
                                <div className="relative" ref={(el) => (dropdownRefs.current[index] = el)}>
                                        {user && (
                                            <div className="flex justify-between w-1/2">
                                                <p className="flex items-center text-sm gap-2">
                                                <span className="text-sm opacity-60">Available: </span>
                                                <span
                                                    className="flex items-center gap-0.5 cursor-pointer"
                                                >
                                                    <span className="text-sm font-medium">0 BCX</span>
                                                </span>
                                                <TransferModal visible={isTransferModalVisible} onCancel={() => setIsTransferModalVisible(false)} />
                                            </p>
                                             <span className="text-[#EFb81c] font-medium"><span onClick={handleButtonClick} className="border-r px-2 cursor-pointer !text-sm">Transfer</span> <span className="pl-1 !text-sm">Buy {pool.title}</span></span>
                                            </div>
                                        )}

                                        {openDropdownIndex === index && (
                                            <div className="absolute left-0 mt-2 w-32 bg-white border rounded-lg shadow-md">
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        handleButtonClick();
                                                    }}
                                                    className="w-full text-left px-4 py-2 hover:bg-gray-100">Transfer</button>
                                                <Link to="/trade/spot"><button className="w-full text-left px-4 py-2 hover:bg-gray-100">Buy BCX</button></Link>
                                            </div>
                                        )}
                                    </div>
                                {user ? <>
                                <div className="flex w-2/3 gap-4 mt-4">
                                    <button onClick={showLockModal} className="rounded-md w-1/2 bg-[#EFB81c] font-semibold py-2">Lock</button>
                                    <LockModal isVisible={isLockModalVisible} handleClose={handleCancel} coinName={currentLaunch.title} coinImage={currentLaunch.img} handleButtonClick={handleButtonClick}/>
                                    <button className="rounded-md w-1/2 bg-gray-200 text-gray-400 font-semibold py-2">Redeem</button>
                                </div>
                                </>
                               : <Link to="/login"><button className="rounded-md w-1/2 mt-8 bg-[#EFB81c] font-semibold py-2">Login</button></Link>}
                            </div>
                            <div className="py-8 w-1/2">
                                <div className="flex gap-3 items-center">
                                    <img src={pool.img} className="w-6 h-6" alt="BUYCEX" />
                                    <h3 className="text-md font-bold">My Rewards</h3>
                                </div>
                                <div className="flex gap-8">
                                    <div className="py-6">
                                        <span className="text-sm opacity-60">Unclaimed Rewards ({pool.stand})</span>
                                        <p className="font-bold opacity-60">--</p>
                                    </div>
                                    <div className="py-6">
                                        <span className="text-sm opacity-60">Claimed Rewards ({pool.stand})</span>
                                        <p className="font-bold opacity-60">--</p>
                                    </div>
                                </div>
                                {user ? <>
                                    <div className="flex w-2/3 gap-4 mt-9">
                                    <button className="rounded-md w-1/2 bg-[#EFB81c] opacity-40 font-semibold py-2">Claim</button>
                                    <button className="rounded-md w-1/2 bg-gray-200 font-semibold py-2">Trade</button>
                                </div>
                                </>
                               : <Link to="/login"><button className="rounded-md w-1/2 mt-8 bg-[#EFB81c] font-semibold py-2">Login</button></Link>}
                            </div>
                        </div>
                    </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LaunchPoolHistory;