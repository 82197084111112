import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import ConvertBcxPopup from "views/profile/Earn/BcxToken/ConvertBcxPopup";

function BcxTokenSection() {
    const [activeButton, setActiveButton] = useState("Earning Item");
    const [activePopup, setActivePopup] = useState(false);

    const showPopup = () => {
        setActivePopup(true);
    };

    const handleOk = () => {
        setActivePopup(false);
    };
    const handleCancel = () => {
        setActivePopup(false);
    };
    const handleButtonClick = (buttonType) => {
        showPopup();
        setActiveButton(buttonType);
        // setConvertBCXModalVisible(true);
    };
    return (
        <div>
            <div className="bg-[#fff] border rounded-[16px] p-4 earn-overview my-6">
                <div className="flex justify-between">
                    <div>
                        <h2 className="text-[#121214] font-semibold text-[24px]">
                            Buycex Token
                        </h2>
                    </div>
                </div>
                <div className="mt-2">
                    <div className="flex border-b p-3 font-semibold border-solid gap-1 !text-[#81858c] ">
                        <div className="flex-1 text-xs font-normal">Coin</div>
                        <div className="flex-1 text-xs font-normal">Price</div>
                        <div className="flex-1 text-xs font-normal">
                            Total Volume
                        </div>
                        <div className="flex-1 text-xs font-normal">
                            Total Participants
                        </div>
                        <div className="flex-1 text-xs font-normal text-right">
                            Options
                        </div>
                    </div>
                    <div className="flex px-3 py-7 font-semibold  gap-1">
                        <div className="flex-1 flex items-center gap-3">
                            <img
                                src={
                                    "https://global.buycex.com/Upload/coin/674f81bfbf8d3.png"
                                }
                                className="w-[24px] h-[24px] object-cover"
                            />
                            <p>BuyCex (BCX)</p>
                        </div>
                        <div className="flex-1">$0.00</div>
                        <div className="flex-1">0.00</div>
                        <div className="flex-1">0.00</div>
                        <div className="flex-1 flex justify-end">
                            <button
                                onClick={() =>
                                    handleButtonClick("Earning Item")
                                }
                                className="bg-[#EFB81C] rounded-md text-[12px] w-[100px] h-[32px] text-center text-[#000] font-medium flex items-center justify-center "
                            >
                                Convert to BCX
                            </button>
                            <ConvertBcxPopup
                                activePopup={activePopup}
                                onOk={handleOk}
                                onCancel={handleCancel}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BcxTokenSection;
