import React, { useEffect, useState } from 'react';
import { Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import CustomDropdownWithImage from '../CustomDropdownWIthImage/CustomDropdownWithImage';
import SwapButton from './swapButton';
import { convertTradeHistory } from 'store/actions/commonActions';
import { TbFileTime, TbCirclesRelation } from "react-icons/tb";
import { FaPlusCircle } from 'react-icons/fa';
import CryptoTransactionModal from './CryptoTransactionModal';
import ConvertPreviewModal from './ConvertPreviewModal';
const CryptoConvertForm = ({
  selectedCoinFrom,
  setSelectedCoinFrom,
  selectedCoinTo,
  setSelectedCoinTo,
  valueFrom,
  setValueFrom,
  valueTo,
  handleConvert,
  handleValueChangeFrom,
  handleSwap,
  getConversionRateText,
  selectedCoinFromOptions,
  selectedCoinToOptions,
  excludeValuesTo,
  excludeValuesFrom,
  currencyConversionRates,
  showModal,
  setShowModal,
  setTransactionModal,
  transactionModal
}) => {
  const { loading, error, tradeData, balances } = useSelector((state) => state.cryptoConvert);
  const [inputError, setInputError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [recentTransaction, setRecentTransaction] = useState(null);

  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
  const dispatch = useDispatch();
  const formatErrorMessage = (error) => {
    if (typeof error === 'string') {
      return error; // Return the string directly
    }

    if (typeof error === 'object' && error !== null) {
      // If the error has an `info` property, use it
      if (error.info) {
        return error.info;
      }
      // If the error has a `message` property, use it
      if (error.message) {
        return error.message;
      }
      // As a fallback, stringify the entire object
      return JSON.stringify(error);
    }

    // Default case: convert non-string values to a string
    return String(error);
  };


  useEffect(() => {
    if (userID && userToken) {
      dispatch(convertTradeHistory(userID, userToken));
    }
  }, [dispatch, userID, userToken]);
 

console.log("currencyConversionRates",currencyConversionRates)
  useEffect(() => {
    if (tradeData && tradeData.status === 1 && tradeData?.data?.res?.trade) {
        const tradeDetails = tradeData?.data?.res?.trade;
        if (tradeDetails) {
            setRecentTransaction(tradeDetails);
            console.log("Trade Details:", tradeDetails);
            setIsModalOpen(true); // Show modal if trade was successful
        }
    } else if (tradeData && tradeData.status === 0) {
        console.error("Trade Data Status 0:", tradeData); // Log the error
        setRecentTransaction({ error: tradeData.message || "Insufficient orders." });
        setIsModalOpen(true); // Show modal for insufficient orders
    }
}, [tradeData]);

const handlePreviewClick = () => {
  
    setShowModal(true);
  
};

const handleCloseModal = () => {
  setShowModal(false);
};

const handleSubmit = () => {
  // Add submit logic here
  console.log("Conversion submitted!");
  setShowModal(false);
};
  


const balance = balances[selectedCoinFrom?.value?.toLowerCase()]?.balance || 0;
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (!/^\d*\.?\d*$/.test(inputValue)) return;
    const selectedCoinBalance = balances[selectedCoinFrom.value.toLowerCase()] || { balance: 0 };
    const availableBalance = selectedCoinBalance.balance || 0;

    if (parseFloat(inputValue) > availableBalance) {
      setInputError(true);
    } else {
      setInputError(false);
    }

    handleValueChangeFrom(e); // Update the value in parent component state
  };

  const handleAllButtonClick = () => {
    const selectedCoinBalance = balances[selectedCoinFrom.value.toLowerCase()] || { balance: 0 };
    const availableBalance = selectedCoinBalance.balance || 0;
    setValueFrom(availableBalance.toString());
  };

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  const errorMessage = typeof error === 'object' && error?.info
    ? error?.info
    : JSON.stringify(error);


  return (
    <form className="space-y-4" onSubmit={handleConvert}>
      {/* Modal to show recent transaction details */}
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        className="fixed inset-0 flex items-center justify-center z-50"
        backdropClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md border-2 border-dashed border-gray-400">

          <div className="relative z-10 text-center text-[#f4f4f4] dark:text-[#151415]">
            <p className="text-4xl font-semibold uppercase text-[#EFB81C]"> Convert  Transaction </p>
            <p className="text-xs text-black md:text-xl font-medium">
              Complete
            </p>
          </div>

          <ModalBody className="space-y-4">
    {recentTransaction?.error ? (
        <p className="text-red-500 text-center font-medium">
            {recentTransaction.error}
        </p>
    ) : (
        recentTransaction && (
            <div className="text-gray-600 text-sm">
                <div className="flex justify-between border-b border-gray-300 pb-2">
                    <span className="font-medium">Transaction ID:</span>
                    <span>{recentTransaction.id || "N/A"}</span>
                </div>
                <div className="flex justify-between border-b border-gray-300 py-2">
                    <span className="font-medium">Market:</span>
                    <span>{recentTransaction.market || "N/A"}</span>
                </div>
                <div className="flex justify-between border-b border-gray-300 py-2">
                    <span className="font-medium">Price:</span>
                    <span>{recentTransaction.price || "N/A"}</span>
                </div>
                <div className="flex justify-between border-b border-gray-300 py-2">
                    <span className="font-medium">Quantity:</span>
                    <span>{recentTransaction.num || "N/A"}</span>
                </div>
                <div className="flex justify-between border-b border-gray-300 py-2">
                    <span className="font-medium">Total:</span>
                    <span>{recentTransaction.mum || "N/A"}</span>
                </div>
                <div className="flex justify-between border-b border-gray-300 py-2">
                    <span className="font-medium">Fee:</span>
                    <span>{recentTransaction.fee || "N/A"}</span>
                </div>
                <div className="flex justify-between pt-2">
                    <span className="font-medium">Time:</span>
                    <span>
                        {recentTransaction.addtime
                            ? new Date(recentTransaction.addtime * 1000).toLocaleString()
                            : "N/A"}
                    </span>
                </div>
            </div>
        )
    )}
</ModalBody>







          <ModalFooter className="flex justify-end space-x-2">
            {/* Close Button */}
            <button
              className="bg-[#EFB81C] text-white px-3 py-2 rounded-lg inline-flex items-center text-sm hover:bg-[#d9a718] transition-colors"
              onClick={toggleModal}
            >
              Close
            </button>

            {/* Conversion History Button */}
            <button
              className="bg-[#EFB81C] px-3 py-2 rounded-lg inline-flex items-center text-sm hover:bg-[#d9a718] transition-colors"
            >
              <TbFileTime className="mr-1" />Conversion history
            </button>
          </ModalFooter>

        </div>
      </Modal>



      {/* From Section */}
      <div className={`bg-[#f9f9fb] dark:bg-[#404347] rounded-md p-3 !mt-2 ${inputError ? 'incorrect-data-highlight-div' : ''}`}>
        <div className="flex justify-between w-full">
          <p className="block text-sm font- text-gray-600">Spend</p>
          <div className='flex items-center gap-1'>
            <p className="text-xs flex justify-end w-full text-gray-400">
              Balance: {balances[selectedCoinFrom?.value?.toLowerCase()]?.balance || "0"} {selectedCoinFrom?.value}
            </p>
            <FaPlusCircle className='text-[#EFB81C] cursor-pointer' />
          </div>
        </div>
        <div className="flex flex-row justify-between items-center pt-3">
          <CustomDropdownWithImage
            options={selectedCoinFromOptions}
            selectedValue={selectedCoinFrom}
            setSelectedValue={setSelectedCoinFrom}
            excludeValues={excludeValuesTo}
          />
          <div className="w-full flex justify-between">
            <input
              type="text"
              value={valueFrom}
              onChange={handleInputChange}
              placeholder="0.01 - 34500"
              className={`bg-inherit w-full placeholder:text-gray-300 font-medium text-gray-800 text-end dark:text-white rounded outline-none border-none ${inputError ? 'border-red-500' : ''}`}
            />
            {/* <span className="flex gap-2 pl-3 relative">
              <span className="absolute left-0 top-1/2 -translate-y-1/2 h-1/2 w-px bg-black"></span>
              <button
                type="button"
                className="text-yellow-700 cursor-pointer"
                onClick={handleAllButtonClick}
              >
                ALL
              </button>
            </span> */}

          </div>
        </div>
        {inputError && <p className="text-red-500 text-xs mt-2">Insufficient funds</p>}
      </div>
      <SwapButton handleSwap={handleSwap} />
      {/* To Section */}

      <div className="bg-[#f9f9fb] dark:bg-[#404347] rounded-md !mt-[50px] pt-3 pb-3">
        <div className="flex justify-between px-3 pt-3">
          <p className="block text-sm font- text-gray-600">Receive</p>
          <p className="text-xs text-end flex justify-end w-full text-gray-400">
            Receivables 
          </p>
        </div>
        <div className="flex flex-row-reverse items-center pt-3 justify-between px-3">
          <div className="w-full flex justify-between">
            <input value={valueTo} placeholder="0.000001 - 240000" className={`font-medium bg-inherit w-full text-end placeholder:text-gray-300 text-gray-800 dark:text-white rounded outline-none border-none`} />
            <span className="gap-2 pl-3 hidden relative">
              <span className="absolute left-0 top-1/2 -translate-y-1/2 h-1/2 w-px bg-black"></span>
              <button
                type="button"
                className="text-yellow-700 invisible cursor-pointer"
              >
                ALL
              </button>
            </span>
          </div>
          <CustomDropdownWithImage
            options={selectedCoinToOptions}
            selectedValue={selectedCoinTo}
            setSelectedValue={setSelectedCoinTo}
            excludeValues={excludeValuesFrom}
          />
          {/* <span>{valueTo}</span> */}
        </div>
      </div>
      <div className="flex items-center py-2 justify-between w-full">
      <p className="text-xs text-gray-400 underline-offset-4 decoration-dotted underline">Rates</p>
        <span className="text-center text-gray-500">{getConversionRateText()}</span>
      </div>

      {/* {error && <div className="text-red-500 text-center mb-4">{error}</div>} */}
      
      <Button
  type="button"
  className={`w-full bg-[#EFB81C] text-black p-2 !mb-2 rounded font-medium`}
  onClick={handlePreviewClick}
>
  Preview
</Button>

<ConvertPreviewModal 
        showModal={showModal}
        handleCloseModal={() => setShowModal(false)}
        selectedCoinFrom={selectedCoinFrom}
        selectedCoinTo={selectedCoinTo}
        currencyConversionRates={currencyConversionRates}
        getConversionRateText={getConversionRateText}
        inputError={inputError}
        balance={balance}
        loading={loading}
     
      />


{/* Show Modal when transactionModal is true */}
{transactionModal && (
        <CryptoTransactionModal 
        currencyConversionRates={currencyConversionRates}
        getConversionRateText={getConversionRateText}
          isOpen={transactionModal} 
          onClose={() => setTransactionModal(false)} 
        />
      )}

    </form>
  );
};

export default CryptoConvertForm;
