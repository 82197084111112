import React, { useEffect, useState } from "react";
import NoData from "assets/img/user/No-data.svg";
import { useDispatch, useSelector } from "react-redux";
import { FinanceOpenOrders } from "store/actions/AssetsUserAction";

const OpenOrdersTable = () => {
    const dispatch = useDispatch();
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const [orderListData, setOrderListData] = useState([]);
    const [marketListData, setMarketListData] = useState([]);
    const [options2, setOptions2] = useState(["All"]);
    const [filteredOrderListData, setFilteredOrderListData] = useState([]);

    const options1 = ["All","Limit",  "Stop Limit", "Stop Market", "Limit-Maker", "Trailing Stop"];
    // const options1 = ["All", "Transaction","Completed","Revoked"];
    const options3 = ["All", "Buy", "Sell"];    
    const getMarketName = (market) => {
        const marketName = Object.values(marketListData).find((item) => item.name === market);
        
        if (!marketName || !marketName.xnb || !marketName.rmb) {
    
            return null; 
        }
    
        const marketNameXNB = marketName.xnb.toUpperCase();
        const marketNameRMB = marketName.rmb.toUpperCase();
        
   
        return marketNameRMB + "/" + marketNameXNB;
    };
    
    
    useEffect(() => {
        // Only fetch data if marketListData is empty
        if (!marketListData.length && userID && userToken) {
            const payload = { user_id: userID };
            
            dispatch(FinanceOpenOrders(userID, userToken, payload))
                .then((response) => {
                    if (response) {
                        const orderList = response.data?.data?.entrust || [];
                        const marketList = response.data?.data?.market_list || [];
                        
                        setOrderListData(orderList);
                        setMarketListData(marketList);
                        setFilteredOrderListData(orderList.slice(0, 15));
                        console.log("marketList", marketList)
                        // Extract unique market names for options
                        const uniqueMarkets = [
                            "All",
                            ...new Set(
                              Object.values(marketList).map(market => {
                                const [base, quote] = market.name.split('_'); // Split the market name by '_'
                                return `${quote.toUpperCase()}/${base.toUpperCase()}`; // Format as QUOTE/BASE
                              })
                            )
                          ];
                          
                          setOptions2(uniqueMarkets);
                          
                        
                    } else {
                        console.error(response);s
                    }
                });
        }
    }, [dispatch, userID, userToken]); // Remove marketListData.length from dependencies
    
    
    
    

    

    
   

    const explodeMarketName = (market) => {
        var splitMarket = market.split("/");
        return splitMarket[1].toLowerCase() + "_" + splitMarket[0].toLowerCase();
    };
    

    return (
        <div>
            

            {/* Table for displaying order data */}
            <div className="text-xs text-[#707a8a] w-full overflow-x-auto">
    <table className="min-w-full border-collapse w-[95%]">
        <thead>
            <tr className="border-b">
              
                <th className="text-start py-3 px-2">Date</th>
                <th className="text-start py-3 px-2">Pair</th>
                <th className="text-start py-3 px-2">Type</th>
                <th className="text-start py-3 px-2">Side</th>
                <th className="text-start py-3 px-2">Price</th>
         
                <th className="text-start py-3 px-2">Amount</th>
                <th className="text-start py-3 px-2">Amount per Iceberg Order</th>
                <th className="text-start py-3 px-2">Dealt</th>
                <th className="text-start py-3 px-2">Total</th>
            </tr>
        </thead>
        <tbody>
            {filteredOrderListData.length > 0 ? (
                filteredOrderListData.map((order) => (
                    <tr key={order.id} className="border-b">
                       
                        <td className="text-[14px] text-black py-3 px-2">{order.addtime}</td>
                        <td className="text-[14px] text-black py-3 px-2">{getMarketName(order.market)}</td>
                        <td className="text-[14px] py-3 px-2">
                            <span
                                className={`${
                                    order.tradetype === "Buy" ? "text-red-500" : "text-[#2EBD85]"
                                }`}
                            >
                                {order.tradetype}
                            </span>
                        </td>
                        <td className="text-[14px] py-3 px-2">
    <span className={order.type === "1" ? "text-red-500" : "text-[#2EBD85]"}>
        {order.type === "1" ? "Buy" : "Sell"}
    </span>
</td>


                        <td className="text-[14px] text-black py-3 px-2">{order.price}</td>
                     
                        <td className="text-[14px] text-black py-3 px-2">{order.num}</td>
                        <td className="text-[14px] text-black py-3 px-2">{order.condition}</td>
                        <td className="text-[14px] text-black py-3 px-2">{order.deal}</td>
                        <td className="text-[14px] text-black py-3 px-2">{order.price * order.num}</td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan="8" className="text-center">
                        <div className="flex flex-col items-center mt-10">
                            <img src={NoData} alt="No Data" className="mb-2" />
                            <p className="text-[#707a8a] text-sm">No orders available</p>
                        </div>
                    </td>
                </tr>
            )}
        </tbody>
    </table>
</div>

        </div>
    );
};

export default OpenOrdersTable;
