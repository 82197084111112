// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import newEmailReducer from "./Newemail";
import myreducer from "./RealAuthReducer";
import fiatDepositReducer from "./fiatDepositReducer";
import fiatListReducer from "./fiatListReducer";
import fiatWithdrawalReducer from "./fiatWithdrawalReducer";
import marketReducer from "./marketReducer";
import favoriteReducer from "./favoritesMarketReducer";
import cryptoConvertReducer from "./cryptoConvertReducer";
import assetsUserReducer from "./AssetsUserReducer";
import assetsContextReducer from "./assetsContextReducer";
import { userProfileReducer, userDashboardReducer } from "./UserProfileReducer";
import chartDataReducer from "./ChartDataReducer";
import {
    fiatOrderReducer,
    myOpenOrderReducer,
    orderHistoryReducer,
    orderStatusReducer,
    orderViewReducer,
    receiveDataReducer,
} from "./OrderUserReducer";
import p2pReducer, {
    myAddsReducer,
    P2pOrderHistory,
} from "../reducers/p2pReducer";
import userReducer from "./AccountuserReducer";
import idVerificationReducer from "../reducers/idVerificationReducer";
import imageReducer from "./imageReducer";
import {
    changePasswordReducer,
    authCheckReducer,
    get2FAQRReducer,
    set2FAReducer,
    saveAntiPishingReducer,
    secureLoggingReducer,
    disableAccountReducer,
    fetchAntiPhishingCode,
    banks,
} from "../reducers/userSecuritySettingsReducer";
import tradeReducer from "./tradeReducer";
import assetReducer from "./assetsTradeReducer";
import buySellReducer from "./buySellReducer";
import { authReducer, googleReducer } from "./authReducer";
import walletReducer from "./walletReducer";
import openOrdersReducer from "./openOrderReducer";
import traceReducer from "./userAssetCoinReducer";
import orderBookReducer from "./orderBookReducer";
import transferReducer from "./transferReducer";
import convertbalanceReducer from "./convertbalanceReducer";
import newDashboardReducer from "./newDashboardReducer";
import earnStakingReducer from "./earnStackingReducer";
import earnStakingRedu from "./earnStackingRedu";
import issuesReducer from "./StartupIssueReducer";
import competitionReducer from "./competitionReducer";
import cryptoDepositReducer from "./cryptoDepositReducer";

import financeReducer, {
    financeOpenHistoryReducer,
    financeOrdersHistoryReducer,
    financeUserFundingReducer,
    fiatBalanceReducer,
    financeUserEarnReducer,
    financeOrdersTradeHistoryReducer,
    financeUserSpotReducer,
} from "./financeReducer";
import {
    transferHistoryReducer,
    convertTradeHistoryReducer,
} from "./commonReducer";
import stepOneReducer from "./stepOneSlice";
import { otcHistory } from "./OTCReducer";
import {
    cryptoDepositHistoryReducer,
    cryptoWithdrawHistoryReducer,
} from "./commonReducer";
import userUpdateReducer from "./userUpdateReducer";
import settingReducer from "./settingReducer";
import authPhoneReducer from "./authphoneReducer";

import merchantBitReducer from "./MerchantReducer";
import walletwhitelistReducer from "./walletaddreswhitelistReducer";

import {
    updatewalletReducer,
    withdrawReducer,
    cryptoWithdrawReducer,
    withdrawalHistoryReducer,
} from "./WithdrawReducer";
import latestProfitReducer from "./latestProfitReducer";
import bcxReducer from "./bcxReducer";
import lgReducer from "./LaunchGuardReducer";
import userPayIdReducer from "./UserPayIdReducer";

const rootReducer = combineReducers({
    P2pOrderHistory: P2pOrderHistory,
    financeHistory: financeReducer,
    financeOpenOrder: financeOpenHistoryReducer,
    financeUserSpotReducer: financeUserSpotReducer,
    financeUserFunding: financeUserFundingReducer,
    financeUserEarn: financeUserEarnReducer,
    fiatBalance: fiatBalanceReducer,
    financeOrdersHistory: financeOrdersHistoryReducer,
    financeOrdersTradeHistory: financeOrdersTradeHistoryReducer,
    otcHistory: otcHistory,
    cryptoDepositHistory: cryptoDepositHistoryReducer,
    cryptoDeposit: cryptoDepositReducer,
    cryptoWithdrawHistory: cryptoWithdrawHistoryReducer,
    transferHistory: transferHistoryReducer,
    convertTradeHistory: convertTradeHistoryReducer,
    authUser: authUserReducer,
    checkcellphone: authPhoneReducer,
    myreducer: myreducer,
    Newemail: newEmailReducer,
    market: marketReducer,
    fiatDeposit: fiatDepositReducer,
    fiatList: fiatListReducer,
    fiatWithdrawal: fiatWithdrawalReducer,
    auth: authReducer,
    favorites: favoriteReducer,
    updatewallet: updatewalletReducer,
    cryptoConvert: cryptoConvertReducer,
    assetsData: assetsUserReducer,
    assetsContext:assetsContextReducer,
    earnStaking: earnStakingReducer,
    newDashboardData: newDashboardReducer,
    userInfo: userProfileReducer,
    cryptoWithdraw: cryptoWithdrawReducer,
    userDashboard: userDashboardReducer,
    withdrawalHistory: withdrawalHistoryReducer,
    chart: chartDataReducer,
    orderStatus: orderStatusReducer,
    receiveData: receiveDataReducer,
    p2p: p2pReducer,
    myOpenOrder: myOpenOrderReducer,
    orderHistory: orderHistoryReducer,
    fiatOrder: fiatOrderReducer,
    changePassword: changePasswordReducer,
    authCheck: authCheckReducer,
    IdVerification: idVerificationReducer,
    images: imageReducer,
    get2FAQR: get2FAQRReducer,
    set2FA: set2FAReducer,
    userReducer: userReducer,
    saveAntiPishing: saveAntiPishingReducer,
    secureLogin: secureLoggingReducer,
    disableAccount: disableAccountReducer,
    antiPhishingCode: fetchAntiPhishingCode,
    googleRegister: googleReducer,
    orderViewing: orderViewReducer,
    trade: tradeReducer,
    assets: assetReducer,
    buySell: buySellReducer,
    wallet: walletReducer,
    transfer: transferReducer,
    balance: convertbalanceReducer,
    openOrders: openOrdersReducer,
    trace: traceReducer,
    orderBook: orderBookReducer,
    myAds: myAddsReducer,
    banks: banks,
    earnStakingRedu: earnStakingRedu,
    issues: issuesReducer,
    competition: competitionReducer,
    stepone: stepOneReducer,
    userUpdate: userUpdateReducer,
    merchantBit: merchantBitReducer,
    walletwhitelist: walletwhitelistReducer,
    setting: settingReducer,
    dowithdraw: withdrawReducer,
    latestProfit: latestProfitReducer,
    bcxHistory: bcxReducer,
    lgData: lgReducer,
    userPayId: userPayIdReducer
});

export default rootReducer;
