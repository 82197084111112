import RepositoryFactory from "repository/RepositoryFactory";
import {
    FETCH_LG_CURRENT_ORDER_REQUEST,
    FETCH_LG_CURRENT_ORDER_SUCCESS,
    FETCH_LG_CURRENT_ORDER_FAILURE,
    FETCH_LG_ORDER_HISTORY_REQUEST,
    FETCH_LG_ORDER_HISTORY_SUCCESS,
    FETCH_LG_ORDER_HISTORY_FAILURE,
    FETCH_PROJECT_REQUEST,
    FETCH_PROJECT_SUCCESS,
    FETCH_PROJECT_FAILURE,
    PLACE_BUY_ORDER_REQUEST,
    PLACE_BUY_ORDER_SUCCESS,
    PLACE_BUY_ORDER_FAILURE,
    UNLOCK_REQUEST,
    UNLOCK_SUCCESS,
    UNLOCK_FAILURE,
    CANCEL_ORDER_REQUEST,
    CANCEL_ORDER_SUCCESS,
    CANCEL_ORDER_FAILURE,
    SUBSCRIBE_LAUNCHGUARD_REQUEST, 
    SUBSCRIBE_LAUNCHGUARD_SUCCESS, 
    SUBSCRIBE_LAUNCHGUARD_FAILURE, 
    CANCEL_SUBSCRIPTION_REQUEST,   
    CANCEL_SUBSCRIPTION_SUCCESS,   
    CANCEL_SUBSCRIPTION_FAILURE,
    FETCH_SUBSCRIPTION_STATUS_REQUEST,
    FETCH_SUBSCRIPTION_STATUS_SUCCESS,
    FETCH_SUBSCRIPTION_STATUS_FAILURE,
} from "../types";
import toast from "react-hot-toast";

const lgRepository = RepositoryFactory.get("laungeGuard");

// ✅ Fetch Current Orders
export const fetchCurrentOrders = (userId, token) => async (dispatch) => {
    dispatch({ type: FETCH_LG_CURRENT_ORDER_REQUEST });
    try {
        const response = await lgRepository.currentOrder(userId, token);

        const lgCurrentOrder = response.data.data;
     
        if (response.data.status === 1) {
            dispatch({
                type: FETCH_LG_CURRENT_ORDER_SUCCESS,
                payload: lgCurrentOrder,
            });
        } else {
            dispatch({
                type: FETCH_LG_CURRENT_ORDER_FAILURE,
                payload: lgCurrentOrder,
            });
        }
    } catch (error) {
        dispatch({
            type: FETCH_LG_CURRENT_ORDER_FAILURE,
            payload: error.message,
        });
    }
};

// ✅ Fetch Order History
export const fetchOrderHistory = (userId, token) => async (dispatch) => {
    dispatch({ type: FETCH_LG_ORDER_HISTORY_REQUEST });
    try {
        const response = await lgRepository.orderHistory(userId, token);
        const lgOrderHistory = response.data.data;
        

        if (response.data.status === 1) {
            dispatch({
                type: FETCH_LG_ORDER_HISTORY_SUCCESS, 
                payload: lgOrderHistory,
            });
        } else {
            dispatch({
                type: FETCH_LG_ORDER_HISTORY_FAILURE, 
                payload: lgOrderHistory,
            });
        }
    } catch (error) {
        dispatch({
            type: FETCH_LG_ORDER_HISTORY_FAILURE,  
            payload: error.message,
        });
    }
};


// ✅ Fetch Projects
export const fetchLgProjects = (userId, token) => async (dispatch) => {
    dispatch({ type: FETCH_PROJECT_REQUEST });
    try {
        const response = await lgRepository.lgProjects(userId, token);
        const lgProjects = response.data.data;
      
        if (response.data.status === 1) {
            dispatch({
                type: FETCH_PROJECT_SUCCESS,
                payload: lgProjects,
            });
        } else {
            dispatch({
                type: FETCH_PROJECT_SUCCESS,
                payload: lgProjects,
            });
        }
    } catch (error) {
        dispatch({
            type: FETCH_PROJECT_FAILURE,
            payload: error.message,
        });
    }
};

export const subscribeLaunchguard = (userId, token) => async (dispatch) => {
    dispatch({ type: SUBSCRIBE_LAUNCHGUARD_REQUEST });
    try {
        const response = await lgRepository.subscribeLaunchguard(userId, token);

        if (response.data.status === 1) {
            dispatch({
                type: SUBSCRIBE_LAUNCHGUARD_SUCCESS,
                payload: response.data.data,
            });
            toast.success("Successfully subscribed to Launchguard! 🚀");
        } else {
            dispatch({
                type: SUBSCRIBE_LAUNCHGUARD_FAILURE,
                payload: response.data.message,
            });
            toast.error(response.data.message || "Subscription failed! ❌");
        }
    } catch (error) {
        dispatch({
            type: SUBSCRIBE_LAUNCHGUARD_FAILURE,
            payload: error.message,
        });
        toast.error("An error occurred while subscribing! ❌");
    }
};

// ✅ Cancel Subscription
export const cancelSubscription = (userId, token, id) => async (dispatch) => {
    dispatch({ type: CANCEL_SUBSCRIPTION_REQUEST });
    try {
        const response = await lgRepository.cancelSubscription(userId, token, id);
     
        if (response.data.status === 1) {
            dispatch({
                type: CANCEL_SUBSCRIPTION_SUCCESS,
                payload: response.data.data,
            });
            toast.success("Subscription successfully canceled! ✅");
        } else {
            dispatch({
                type: CANCEL_SUBSCRIPTION_FAILURE,
                payload: response.data.message,
            });
            toast.error(response.data.message || "Failed to cancel subscription! ❌");
        }
    } catch (error) {
        dispatch({
            type: CANCEL_SUBSCRIPTION_FAILURE,
            payload: error.message,
        });
        toast.error("An error occurred while canceling subscription! ❌");
    }
};

export const fetchSubscriptionStatus = (userId, token) => async (dispatch) => {
    dispatch({ type: FETCH_SUBSCRIPTION_STATUS_REQUEST });

    try {
        const response = await lgRepository.mySubscriptions(userId, token);
        const subscriptionData = response.data.data?.subscriptions;
        console.log("respone subscriptionData subscribe launch",subscriptionData)
        dispatch({
            type: FETCH_SUBSCRIPTION_STATUS_SUCCESS,
            payload: subscriptionData?.status === "1", // true if subscribed
        });
    } catch (error) {
        dispatch({
            type: FETCH_SUBSCRIPTION_STATUS_FAILURE,
            payload: error.message,
        });
    }
};
// ✅ Place Buy Order
export const placeBuyOrder = (userId, token, payload) => async (dispatch) => {
    dispatch({ type: PLACE_BUY_ORDER_REQUEST });

    try {
        console.log("Request payload:", userId, token, payload);
        const response = await lgRepository.placeBuyOrder(userId, token, payload);
        console.log("API Response:", response);

        if (response.data.status === 1) {
            dispatch({
                type: PLACE_BUY_ORDER_SUCCESS,
                payload: response.data.data,
            });
        } else {
            dispatch({
                type: PLACE_BUY_ORDER_FAILURE,
                payload: response.data.message,
            });
        }

        return response.data; // Return response data to be used in the component
    } catch (error) {
        dispatch({
            type: PLACE_BUY_ORDER_FAILURE,
            payload: error.message,
        });

        return { status: 0, message: error.message }; // Return error object
    }
};


// ✅ Unlock Order
export const unlockOrder = (userId, token, id) => async (dispatch) => {
    dispatch({ type: UNLOCK_REQUEST });
    try {
        const response = await lgRepository.unlock(userId,id, token);
        console.log("claim",response)
        if (response.data.status === 1) {
            dispatch({
                type: UNLOCK_SUCCESS,
                payload: response.data.data,
            });
            toast.success("Token successfully claimed! 🎉"); // Success Toast
        } else {
            dispatch({
                type: UNLOCK_FAILURE,
                payload: response.data.message,
            });
            toast.error(response.data.data || "Failed to claim token! ❌"); // Error Toast
        }
    } catch (error) {
        dispatch({
            type: UNLOCK_FAILURE,
            payload: error.message,
        });
        toast.error("An error occurred while claiming token! ❌"); // Error Toast
    }
};


// ✅ Cancel Order
export const cancelOrder = (userId, token, id) => async (dispatch) => {
    dispatch({ type: CANCEL_ORDER_REQUEST });
    try {
        const response = await lgRepository.cancelOrder(userId, token, id);
        console.log("response ", response.data);
        if (response.data.status === 1) {
            dispatch({
                type: CANCEL_ORDER_SUCCESS,
                payload: response.data.data,
            });
            toast.success("Order successfully canceled! ✅"); // Success Toast
        } else {
            dispatch({
                type: CANCEL_ORDER_FAILURE,
                payload: response.data.data,
            });
            toast.error(response.data.data || "Failed to cancel order! ❌"); // Error Toast
        }
    } catch (error) {
        dispatch({
            type: CANCEL_ORDER_FAILURE,
            payload: error.message,
        });
        toast.error("An error occurred while canceling order! ❌"); // Error Toast
    }
};

