export const LOGIN = "LOGIN";
export const SET_EMAIL = "SET_EMAIL";
export const SET_FUND_PASSWORD = "SET_FUND_PASSWORD";
export const CHANGE_FUND_PASSWORD = "CHANGE_FUND_PASSWORD";
export const CHECK_FUND_PASSWORD = "CHECK_FUND_PASSWORD";
export const SET_PASSWORD = "SET_PASSWORD";
export const LOGIN_REDIRECT = "LOGIN_REDIRECT";
export const VERIFY_G2FA_CODE = "VERIFY_G2FA_CODE";
export const VERIFY_CODE = "VERIFY_CODE";
export const CONFIRM_CODE = "CONFIRM_CODE";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SIGNUP = "SIGNUP";
export const GET_MAIL = "GET_MAIL";
export const PHONE_SIGNUP = "PHONE_SIGNUP";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const LOADING = "LOADING";
export const LOGOUT = "LOGOUT";
export const PHONE_2FA = "PHONE_2FA";
export const PHONE_2FA_FAIL = "PHONE_2FA_FAIL";

export const AUTH_SET_LOADING = "AUTH_SET_LOADING";
export const AUTH_NOTIFICATION = "AUTH_NOTIFICATION";
export const EMAIL_2FA = "EMAIL_2FA";
export const EMAIL_2FA_FAIL = "EMAIL_2FA_FAIL";

// marketpage action
export const FETCH_MARKET_DATA = "FETCH_MARKET_DATA";
export const FETCH_MARKET_DATA_ERROR = "FETCH_MARKET_DATA_ERROR";
export const FETCH_MARKET_DATA_LOADING = "FETCH_MARKET_DATA_LOADING";

// p2p Action
export const FETCH_P2P_DATA = "FETCH_P2P_DATA";
export const FETCH_P2P_DATA_ERROR = "FETCH_P2P_DATA_ERROR";
export const FETCH_P2P_DATA_LOADING = "FETCH_P2P_DATA_LOADING";

export const FETCH_USER_P2P_SUCCESS = "FETCH_USER_P2P_SUCCESS";
export const FETCH_USER_P2P_FAILURE = "FETCH_USER_P2P_FAILURE";

export const FETCH_BANK_STATUS_REQUEST = "FETCH_BANK_STATUS_REQUEST";
export const FETCH_BANK_STATUS_SUCCESS = "FETCH_BANK_STATUS_SUCCESS";
export const FETCH_BANK_STATUS_FAILURE = "FETCH_BANK_STATUS_FAILURE";

export const GET_PAYMENT_METHODS_REQUEST = "GET_PAYMENT_METHODS_REQUEST";
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";
export const GET_PAYMENT_METHODS_FAILURE = "GET_PAYMENT_METHODS_FAILURE";

export const FETCH_TRADE_STATUS_REQUEST = "FETCH_TRADE_STATUS_REQUEST";
export const FETCH_TRADE_STATUS_SUCCESS = "FETCH_TRADE_STATUS_SUCCESS";
export const FETCH_TRADE_STATUS_FAILURE = "FETCH_TRADE_STATUS_FAILURE";

export const FETCH_FIAT_LIST_REQUEST = "FETCH_FIAT_LIST_REQUEST";
export const FETCH_FIAT_LIST_SUCCESS = "FETCH_FIAT_LIST_SUCCESS";
export const FETCH_FIAT_LIST_FAILURE = "FETCH_FIAT_LIST_FAILURE";

export const FETCH_FIAT_CURRENCY_LIST_SUCCESS =
    "FETCH_FIAT_CURRENCY_LIST_SUCCESS";
export const FETCH_FIAT_CURRENCY_LIST_FAILURE =
    "FETCH_FIAT_CURRENCY_LIST_FAILURE";

export const UPDATE_USER_CURRENCY = "UPDATE_USER_CURRENCY";

export const FIAT_WITHDRAWAL_REQUEST = "FIAT_WITHDRAWAL_REQUEST";
export const FIAT_WITHDRAWAL_SUCCESS = "FIAT_WITHDRAWAL_SUCCESS";
export const FIAT_WITHDRAWAL_FAILURE = "FIAT_WITHDRAWAL_FAILURE";

export const GET_FIAT_DEPOSIT_HISTORY_REQUEST =
    "GET_FIAT_DEPOSIT_HISTORY_REQUEST";
export const GET_FIAT_DEPOSIT_HISTORY_SUCCESS =
    "GET_FIAT_DEPOSIT_HISTORY_SUCCESS";
export const GET_FIAT_DEPOSIT_HISTORY_FAILURE =
    "GET_FIAT_DEPOSIT_HISTORY_FAILURE";

export const DO_TRADE_REQUEST = "DO_TRADE_REQUEST";
export const DO_TRADE_SUCCESS = "DO_TRADE_SUCCESS";
export const DO_TRADE_FAILURE = "DO_TRADE_FAILURE";

export const CALL_TRADE_REQUEST = "CALL_TRADE_REQUEST";
export const CALL_TRADE_SUCCESS = "CALL_TRADE_SUCCESS";
export const CALL_TRADE_FAILURE = "CALL_TRADE_FAILURE";

export const CALC_ASSET_VALUE_REQUEST = "CALC_ASSET_VALUE_REQUEST";
export const CALC_ASSET_VALUE_SUCCESS = "CALC_ASSET_VALUE_SUCCESS";
export const CALC_ASSET_VALUE_FAILURE = "CALC_ASSET_VALUE_FAILURE";

// /give Balance Types api
export const FETCH_BALANCE_TYPES_REQUEST = "FETCH_BALANCE_TYPES_REQUEST";
export const FETCH_BALANCE_TYPES_SUCCESS = "FETCH_BALANCE_TYPES_SUCCESS";
export const FETCH_BALANCE_TYPES_FAILURE = "FETCH_BALANCE_TYPES_FAILURE";

// wallet balacne.js
export const FETCH_WALLET_REQUEST = "FETCH_WALLET_REQUEST";
export const FETCH_WALLET_SUCCESS = "FETCH_WALLET_SUCCESS";
export const FETCH_WALLET_FAILURE = "FETCH_WALLET_FAILURE";

// src/redux/types.js
export const DO_TRANSFER_REQUEST = "DO_TRANSFER_REQUEST";
export const DO_TRANSFER_SUCCESS = "DO_TRANSFER_SUCCESS";
export const DO_TRANSFER_FAILURE = "DO_TRANSFER_FAILURE";

// User Types
export const FETCH_USER_PROFILE_REQUEST = "FETCH_USER_PROFILE_REQUEST";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE";

export const FETCH_USER_DASHBOARD_REQUEST = "FETCH_USER_DASHBOARD_REQUEST";
export const FETCH_USER_DASHBOARD_SUCCESS = "FETCH_USER_DASHBOARD_SUCCESS";
export const FETCH_USER_DASHBOARD_FAILURE = "FETCH_USER_DASHBOARD_FAILURE";

export const GET_CONVERSION_DATA_REQUEST = "GET_CONVERSION_DATA_REQUEST";
export const GET_CONVERSION_DATA_SUCCESS = "GET_CONVERSION_DATA_SUCCESS";
export const GET_CONVERSION_DATA_FAILURE = "GET_CONVERSION_DATA_FAILURE";
export const GET_STATIC_CONVERSION_RATE_SUCCESS =
    "GET_STATIC_CONVERSION_RATE_SUCCESS";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const FETCH_AUTH_STATUS_REQUEST = "FETCH_AUTH_STATUS_REQUEST";
export const FETCH_AUTH_STATUS_SUCCESS = "FETCH_AUTH_STATUS_SUCCESS";
export const FETCH_AUTH_STATUS_FAILURE = "FETCH_AUTH_STATUS_FAILURE";

// Id verification Types

export const BASIC_VERIFICATION_REQUEST = "BASIC_VERIFICATION_REQUEST";
export const BASIC_VERIFICATION_SUCCESS = "BASIC_VERIFICATION_SUCCESS";
export const BASIC_VERIFICATION_FAILURE = "BASIC_VERIFICATION_FAILURE";
export const GET_2FA_QR_REQUEST = "GET_2FA_QR_REQUEST";
export const GET_2FA_QR_SUCCESS = "GET_2FA_QR_SUCCESS";
export const GET_2FA_QR_FAILURE = "GET_2FA_QR_FAILURE";

export const SET_2FA_REQUEST = "SET_2FA_REQUEST";
export const SET_2FA_SUCCESS = "SET_2FA_SUCCESS";
export const SET_2FA_FAILURE = "SET_2FA_FAILURE";

export const SAVE_ANTI_PISHING_REQUEST = "SAVE_ANTI_PISHING_REQUEST";
export const SAVE_ANTI_PISHING_SUCCESS = "SAVE_ANTI_PISHING_SUCCESS";
export const SAVE_ANTI_PISHING_FAILURE = "SAVE_ANTI_PISHING_FAILURE";

export const TOGGLE_SECURE_LOGGING = "TOGGLE_SECURE_LOGGING";

export const DISABLE_USER_ACCOUNT_REQUEST = "DISABLE_USER_ACCOUNT_REQUEST";
export const DISABLE_USER_ACCOUNT_SUCCESS = "DISABLE_USER_ACCOUNT_SUCCESS";
export const DISABLE_USER_ACCOUNT_FAILURE = "DISABLE_USER_ACCOUNT_FAILURE";

export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";

export const FETCH_ASSETS_REQUEST = "FETCH_ASSETS_REQUEST";
export const FETCH_ASSETS_SUCCESS = "FETCH_ASSETS_SUCCESS";
export const FETCH_ASSETS_FAILURE = "FETCH_ASSETS_FAILURE";

export const UP_TRADE_REQUEST = "UP_TRADE_REQUEST";
export const UP_TRADE_SUCCESS = "UP_TRADE_SUCCESS";
export const UP_TRADE_FAILURE = "UP_TRADE_FAILURE";

export const FETCH_OPEN_ORDERS_REQUEST = "FETCH_OPEN_ORDERS_REQUEST";
export const FETCH_OPEN_ORDERS_SUCCESS = "FETCH_OPEN_ORDERS_SUCCESS";
export const FETCH_OPEN_ORDERS_FAILURE = "FETCH_OPEN_ORDERS_FAILURE";

export const FETCH_TRACE_REQUEST = "FETCH_TRACE_REQUEST";
export const FETCH_TRACE_SUCCESS = "FETCH_TRACE_SUCCESS";
export const FETCH_TRACE_FAILURE = "FETCH_TRACE_FAILURE";

export const DO_TRACE_REQUEST = "DO_TRACE_REQUEST";
export const DO_TRACE_SUCCESS = "DO_TRACE_SUCCESS";
export const DO_TRACE_FAILURE = "DO_TRACE_FAILURE";

export const FETCH_ORDER_BOOK_REQUEST = "FETCH_ORDER_BOOK_REQUEST";
export const FETCH_ORDER_BOOK_SUCCESS = "FETCH_ORDER_BOOK_SUCCESS";
export const FETCH_ORDER_BOOK_FAILURE = "FETCH_ORDER_BOOK_FAILURE";

export const FETCH_MARKET_TRADES_REQUEST = "FETCH_MARKET_TRADES_REQUEST";
export const FETCH_MARKET_TRADES_SUCCESS = "FETCH_MARKET_TRADES_SUCCESS";
export const FETCH_MARKET_TRADES_FAILURE = "FETCH_MARKET_TRADES_FAILURE";

// For the launchap issue api
export const FETCH_ISSUES_REQUEST = "FETCH_ISSUES_REQUEST";
export const FETCH_ISSUES_SUCCESS = "FETCH_ISSUES_SUCCESS";
export const FETCH_ISSUES_FAILURE = "FETCH_ISSUES_FAILURE";
// types.js

export const FETCH_COMPETITION_REQUEST = "FETCH_COMPETITION_REQUEST";
export const FETCH_COMPETITION_SUCCESS = "FETCH_COMPETITION_SUCCESS";
export const FETCH_COMPETITION_FAILURE = "FETCH_COMPETITION_FAILURE";

// Add new types for updating competition
export const UPDATE_COMPETITION_REQUEST = "UPDATE_COMPETITION_REQUEST";
export const UPDATE_COMPETITION_SUCCESS = "UPDATE_COMPETITION_SUCCESS";
export const UPDATE_COMPETITION_FAILURE = "UPDATE_COMPETITION_FAILURE";

// ... other existing types

// for the spot trade category
export const SET_SELECTED_MAIN_FILTER = "SET_SELECTED_MAIN_FILTER";
// for dark theme
export const TOGGLE_THEME = "TOGGLE_THEME";

export const UPDATE_USERNAME_REQUEST = "UPDATE_USERNAME_REQUEST";
export const UPDATE_USERNAME_SUCCESS = "UPDATE_USERNAME_SUCCESS";
export const UPDATE_USERNAME_FAILURE = "UPDATE_USERNAME_FAILURE";

export const UPDATE_USER_NICK_NAME_REQUEST = "UPDATE_USER_NICK_NAME_REQUEST";
export const UPDATE_USER_NICK_NAME_SUCCESS = "UPDATE_USER_NICK_NAME_SUCCESS";
export const UPDATE_USER_NICK_NAME_FAILURE = "UPDATE_USER_NICK_NAME_FAILURE";

// get anti phishing code

export const FETCH_ANTI_PHISHING_CODE_REQUEST =
    "FETCH_ANTI_PHISHING_CODE_REQUEST";
export const FETCH_ANTI_PHISHING_CODE_SUCCESS =
    "FETCH_ANTI_PHISHING_CODE_SUCCESS";
export const FETCH_ANTI_PHISHING_CODE_FAILURE =
    "FETCH_ANTI_PHISHING_CODE_FAILURE";

// check cell phone

// types.js

export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

// types.js
export const CHECK_CELLPHONE_REQUEST = "CHECK_CELLPHONE_REQUEST";
export const CHECK_CELLPHONE_SUCCESS = "CHECK_CELLPHONE_SUCCESS";
export const CHECK_CELLPHONE_FAILURE = "CHECK_CELLPHONE_FAILURE";

export const SEND_NEW_CELLPHONE_REQUEST = "SEND_NEW_CELLPHONE_REQUEST";
export const SEND_NEW_CELLPHONE_SUCCESS = "SEND_NEW_CELLPHONE_SUCCESS";
export const SEND_NEW_CELLPHONE_FAILURE = "SEND_NEW_CELLPHONE_FAILURE";

export const UPDATE_CELLPHONE_REQUEST = "UPDATE_CELLPHONE_REQUEST";
export const UPDATE_CELLPHONE_SUCCESS = "UPDATE_CELLPHONE_SUCCESS";
export const UPDATE_CELLPHONE_FAILURE = "UPDATE_CELLPHONE_FAILURE";

export const GET_USER_WALLETS_REQUEST = "GET_USER_WALLETS_REQUEST";
export const GET_USER_WALLETS_SUCCESS = "GET_USER_WALLETS_SUCCESS";
export const GET_USER_WALLETS_FAILURE = "GET_USER_WALLETS_FAILURE";

export const ADD_WALLET_WHITELIST_REQUEST = "ADD_WALLET_WHITELIST_REQUEST";
export const ADD_WALLET_WHITELIST_SUCCESS = "ADD_WALLET_WHITELIST_SUCCESS";
export const ADD_WALLET_WHITELIST_FAILURE = "ADD_WALLET_WHITELIST_FAILURE";

export const DELETE_WALLET_WHITELIST_REQUEST =
    "DELETE_WALLET_WHITELIST_REQUEST";
export const DELETE_WALLET_WHITELIST_SUCCESS =
    "DELETE_WALLET_WHITELIST_SUCCESS";
export const DELETE_WALLET_WHITELIST_FAILURE =
    "DELETE_WALLET_WHITELIST_FAILURE";

export const EDIT_WALLET_WHITELIST_REQUEST = "EDIT_WALLET_WHITELIST_REQUEST";
export const EDIT_WALLET_WHITELIST_SUCCESS = "EDIT_WALLET_WHITELIST_SUCCESS";
export const EDIT_WALLET_WHITELIST_FAILURE = "EDIT_WALLET_WHITELIST_FAILURE";
export const RESET_PASSWORD_EMAIL_REQUEST = "RESET_PASSWORD_EMAIL_REQUEST";
export const RESET_PASSWORD_EMAIL_SUCCESS = "RESET_PASSWORD_EMAIL_SUCCESS";
export const RESET_PASSWORD_EMAIL_FAILURE = "RESET_PASSWORD_EMAIL_FAILURE";

export const WITHDRAW_REQUEST = "WITHDRAW_REQUEST";
export const WITHDRAW_SUCCESS = "WITHDRAW_SUCCESS";
export const WITHDRAW_FAILURE = "WITHDRAW_FAILURE";

export const UPDATE_WALLET_REQUEST = "UPDATE_WALLET_REQUEST";
export const UPDATE_WALLET_SUCCESS = "UPDATE_WALLET_SUCCESS";
export const UPDATE_WALLET_FAILURE = "UPDATE_WALLET_FAILURE";

export const CRYPTO_WITHDRAW_REQUEST = "CRYPTO_WITHDRAW_REQUEST";
export const CRYPTO_WITHDRAW_SUCCESS = "CRYPTO_WITHDRAW_SUCCESS";
export const CRYPTO_WITHDRAW_FAILURE = "CRYPTO_WITHDRAW_FAILURE";

export const FETCH_WITHDRAWALS_REQUEST = "FETCH_WITHDRAWALS_REQUEST";
export const FETCH_WITHDRAWALS_SUCCESS = "FETCH_WITHDRAWALS_SUCCESS";
export const FETCH_WITHDRAWALS_FAILURE = "FETCH_WITHDRAWALS_FAILURE";

export const FETCH_LATEST_PROFIT_REQUEST = "FETCH_LATEST_PROFIT_REQUEST";
export const FETCH_LATEST_PROFIT_SUCCESS = "FETCH_LATEST_PROFIT_SUCCESS";
export const FETCH_LATEST_PROFIT_FAILURE = "FETCH_LATEST_PROFIT_FAILURE";

export const FETCH_BCX_FINANCE_HISTORY_REQUEST =
    "FETCH_BCX_FINANCE_HISTORY_REQUEST";
export const FETCH_BCX_FINANCE_HISTORY_SUCCESS =
    "FETCH_BCX_FINANCE_HISTORY_SUCCESS";
export const FETCH_BCX_FINANCE_HISTORY_FAILURE =
    "FETCH_BCX_FINANCE_HISTORY_FAILURE";
export const GET_CONVERTED_AMOUNT_REQUEST = "GET_CONVERTED_AMOUNT_REQUEST";
export const GET_CONVERTED_AMOUNT_SUCCESS = "GET_CONVERTED_AMOUNT_SUCCESS";
export const GET_CONVERTED_AMOUNT_FAILURE = "GET_CONVERTED_AMOUNT_FAILURE";

export const GET_CONVERT_BCX_REQUEST = "GET_CONVERT_BCX_REQUEST";
export const GET_CONVERT_BCX_SUCCESS = "GET_CONVERT_BCX_SUCCESS";
export const GET_CONVERT_BCX_FAILURE = "GET_CONVERT_BCX_FAILURE";

//Launvh guard current orders
export const FETCH_LG_CURRENT_ORDER_REQUEST = "FETCH_LG_CURRENT_ORDER_REQUEST";
export const FETCH_LG_CURRENT_ORDER_SUCCESS = "FETCH_LG_CURRENT_ORDER_SUCCESS";
export const FETCH_LG_CURRENT_ORDER_FAILURE = "FETCH_LG_CURRENT_ORDER_FAILURE";

export const FETCH_LG_ORDER_HISTORY_REQUEST = "FETCH_LG_ORDER_HISTORY_REQUEST";
export const FETCH_LG_ORDER_HISTORY_SUCCESS = "FETCH_LG_ORDER_HISTORY_SUCCESS";
export const FETCH_LG_ORDER_HISTORY_FAILURE = "FETCH_LG_ORDER_HISTORY_FAILURE";
//Launch guard projects
export const FETCH_PROJECT_REQUEST = "FETCH_PROJECT_REQUEST";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
export const FETCH_PROJECT_FAILURE = "FETCH_PROJECT_FAILURE";

export const FETCH_USER_PAY_ID_REQUEST = "FETCH_USER_PAY_ID_REQUEST";
export const FETCH_USER_PAY_ID_SUCCESS = "FETCH_USER_PAY_ID_SUCCESS";
export const FETCH_USER_PAY_ID_FAILURE = "FETCH_USER_PAY_ID_FAILURE";


export const FETCH_CRYPTO_DEPOSIT_REQUEST = "FETCH_CRYPTO_DEPOSIT_REQUEST";
export const FETCH_CRYPTO_DEPOSIT_SUCCESS = "FETCH_CRYPTO_DEPOSIT_SUCCESS";
export const FETCH_CRYPTO_DEPOSIT_FAILURE = "FETCH_CRYPTO_DEPOSIT_FAILURE";

// Place Buy Order
export const PLACE_BUY_ORDER_REQUEST = "PLACE_BUY_ORDER_REQUEST";
export const PLACE_BUY_ORDER_SUCCESS = "PLACE_BUY_ORDER_SUCCESS";
export const PLACE_BUY_ORDER_FAILURE = "PLACE_BUY_ORDER_FAILURE";

// Unlock Order (Claim Token)
export const UNLOCK_REQUEST = "UNLOCK_REQUEST";
export const UNLOCK_SUCCESS = "UNLOCK_SUCCESS";
export const UNLOCK_FAILURE = "UNLOCK_FAILURE";

// Cancel Order (Request Fund)
export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";

// ✅ Subscribe to Launchguard (New)
export const SUBSCRIBE_LAUNCHGUARD_REQUEST = "SUBSCRIBE_LAUNCHGUARD_REQUEST";
export const SUBSCRIBE_LAUNCHGUARD_SUCCESS = "SUBSCRIBE_LAUNCHGUARD_SUCCESS";
export const SUBSCRIBE_LAUNCHGUARD_FAILURE = "SUBSCRIBE_LAUNCHGUARD_FAILURE";

// ✅ Cancel Subscription (New)
export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";

export const FETCH_SUBSCRIPTION_STATUS_REQUEST = "FETCH_SUBSCRIPTION_STATUS_REQUEST";
export const FETCH_SUBSCRIPTION_STATUS_SUCCESS = "FETCH_SUBSCRIPTION_STATUS_SUCCESS";
export const FETCH_SUBSCRIPTION_STATUS_FAILURE = "FETCH_SUBSCRIPTION_STATUS_FAILURE";






