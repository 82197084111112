import React from "react";

import Header from "components/header/Header";
import MarketFooter from "components/market/MarketFooter";

import ChatSupport from "components/global/bot/chat/ChatBot";

const Market = (props) => {
    return (
        <>
            {/* Main Header Component */}
            <Header />
            {/* Page Content */}
            <main className="bg-white min-h-screen">{props.children}</main>
            {/* Market Footer Component */}
            <MarketFooter />

            {/* Chat Support Bot */}
            <ChatSupport />
        </>
    );
};

export default Market;
