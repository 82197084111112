import React from 'react'
// import journeyLineBottom from 'assets/img/bg-lines-journey.png'
// import journeyLineTop from 'assets/img/bg-lines-journey-top.png'
// import journeyLineMobile from 'assets/img/journeyLineMobile.png'
import { Link } from 'react-router-dom/cjs/react-router-dom'

function StartJourney({ username }) {
  return (
    <div className='bg-[#f7f7f7] dark:bg-[#090a0c] h-[211px] sm:h-80 flex justify-center sm:justify-between relative'>
      {/* Large screen images */}
      {/* <img src={journeyLineBottom} className='hidden sm:block w-1/4' alt='journeyLine' /> */}
      <div className='flex flex-col gap-8 w-full items-center justify-center text-center'>
        <h4 className='text-black dark:text-white text-[24px] flex justify-self-center sm:text-4xl font-extrabold'>Start Your Crypto Journey Now!</h4>
        <Link to={username ? "/trade/spot" : "/register"}>
          <button className="rounded-[0.375rem] bg-[#EFB81C] font-extrabold text-black px-8 py-2">
            {username ? "Trade Now" : "Sign Up Now"}
          </button>
        </Link>
      </div>
      {/* <img src={journeyLineTop} className='hidden sm:block w-1/4' alt='journeyLine' /> */}

      {/* Mobile image */}
      {/* <img src={journeyLineMobile} className='sm:hidden absolute top-0 left-1/2 transform -translate-x-1/2 w-full h-[211px]' alt='journeyLineMobile' /> */}
    </div>
  )
}

export default StartJourney
