import React, { useEffect, useState } from 'react';
import { BsRocketTakeoff } from 'react-icons/bs';
import { FaChevronRight } from 'react-icons/fa';
import MiningCoins from './MiningCoins';
import Pool from './Pool';
import { GiMining } from 'react-icons/gi';
const heroImages = [HeroImageOne, HeroImageTwo, HeroImageThird];
import HeroImageOne from "assets/img/Aboutsection4.png";
import HeroImageTwo from "assets/img/algo-orders.png";
import HeroImageThird from "assets/img/Aboutsection3.png";

function CryptoMining() {
    const flippingWords = [
        "Maximize",
        "Elevate",
        "Amplify",
        "Unlock"
    ];
    const [currentWord, setCurrentWord] = useState("Accelerate");
    const [flipState, setFlipState] = useState("flip-enter");
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageTransitionState, setImageTransitionState] = useState("fade-in");
    
    useEffect(() => {
        const wordInterval = setInterval(() => {
            setFlipState("flip-exit");

            setTimeout(() => {
                setCurrentWord((prev) => {
                    const nextIndex =
                        (flippingWords.indexOf(prev) + 1) %
                        flippingWords.length;
                    return flippingWords[nextIndex];
                });
                setFlipState("flip-enter");
            }, 600);
        }, 3000);

        return () => clearInterval(wordInterval);
    }, []);
    useEffect(() => {
            const imageInterval = setInterval(() => {
                setImageTransitionState("fade-out");
    
                setTimeout(() => {
                    setCurrentImageIndex((prevIndex) => {
                        return (prevIndex + 1) % heroImages.length;
                    });
                    setImageTransitionState("fade-in");
                }, 600);
            }, 3000);
    
            return () => clearInterval(imageInterval);
        }, []);
    return (
        <div>
            <section className="hero-section-black flex relative">
                <div className="hero-section__wrapper-black container flex items-center justify-between gap-8 w-full m-auto max-md:flex-col">
                    <div className="hero-title-wrapper-black flex flex-col gap-2 w-[60%]">
                        <h1 className='hero-title-black flex gap-2'>
                            <span className='text-[32px] font-[900] text-[#EFB81C]'>BuyCex</span><span className='text-white text-[32px]'>Mining</span>
                        </h1>
                        <p className='text-md font-medium text-[#adb1b8] capitalize'>Supports new merged mining</p>
                        <div className='flex w-[70%] justify-between items-center'>
                            <h1 className='hero-title-black uppercase font-bold text-white'><p>Pepecoin & junkcoin!</p></h1>
                        </div>
                        <p className='text-md text-[#adb1b8] font-semibold'>Mine LTC and Earn Rewards in LTC, DODGE, BELLS, LKY, JKC & PEP</p>
                        <p className="hero-description-black mt- font-semibold">
                            <span className={`flip-text-black ${flipState}`}>{currentWord}</span>{"  "}
                            <span className='text-white'> Your Crypto Potential with BuyCex Mining</span>
                        </p>
                    </div>
                    {/* Right Section: Hero Image */}
                    <div className="flex flex-col items-center w-[40%]">
                        <div className="hero-image-wrapper-black relative w-full h-[10rem] rounded-xl overflow-hidden">
                            {heroImages.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`hero-img-${index}`}
                                    className={`hero-image-black absolute top-0 left-0 w-full rounded-xl object-contain transition-opacity duration-700 ${imageTransitionState} ${index === currentImageIndex ? "active" : "inactive"
                                        }`}
                                />
                            ))}
                        </div>
                        <button className='font-semibold text-sm border-2 rounded-full border-[#EFB81C] text-[#EFB81C] px-4 py-1'>
                            Become a Member Now!
                        </button>
                    </div>
                </div>
            </section>
            <div className='flex gap-12 py-8 mb-16 container'>
                <div className='border p-6 flex h-28 rounded-xl flex-1'>
                    <div className='flex items-center justify-between w-full'>
                        <div className='flex gap-10 items-center'>
                            <BsRocketTakeoff fill='#EFB81C' size={40} />
                            <div className='flex flex-col h-14 justify-between'>
                                <p className='font-semibold'>BTC Transaction Accelerator</p>
                                <p className='text-[#81858c] text-sm'>Accelerate your transaction now</p>
                            </div>
                        </div>
                        <FaChevronRight />
                    </div>

                </div>
                <div className='border p-6 flex h-28 rounded-xl flex-1'>
                    <div className='flex items-center justify-between w-full'>
                        <div className='flex gap-10 items-center'>
                            <BsRocketTakeoff fill='#EFB81C' size={40} />
                            <div className='flex flex-col h-14 justify-between'>
                                <p className='font-semibold'>Ordinals Inscribe</p>
                                <p className='text-[#81858c] text-sm'>Inscribe anything you want on bitcoin</p>
                            </div>
                        </div>
                        <FaChevronRight />
                    </div>

                </div>
            </div>
            <div className='container'>
                <div className="flex justify-between w-full py-3">
                    <p className="text-3xl font-bold">Mining Coins</p>
                    <button className="flex gap-2 items-center px-4 rounded-md bg-[#EFB81C] text-sm font-medium">
                        <GiMining />
                        Mining Dashboard
                    </button>
                </div>
                <MiningCoins />
                <Pool />
            </div>
        </div>
    );
}

export default CryptoMining;
