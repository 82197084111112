import React, { useState } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import { FaArrowRight } from "react-icons/fa6";
import buySellBg from 'assets/img/buy-sell-bg.png'
import buySellBgMobile from 'assets/img/buy-sell-bg-mobile.png'
import RefreshIcon from 'assets/img/refresh-coin.png'
import { FiArrowUpRight } from 'react-icons/fi';

function BuySell() {
    const coins = [
        { name: 'BTC', image: 'https://api.buycex.com/Upload/coin/BTC.png' },
        { name: 'ETH', image: 'https://api.buycex.com/Upload/coin/ETH.png' },
        { name: 'XRP', image: 'https://api.buycex.com/Upload/coin/XRP.png' },
        { name: 'TRON', image: 'https://api.buycex.com/Upload/coin/TRX.png' },
        { name: 'BNB', image: 'https://api.buycex.com/Upload/coin/BNB.png' }
    ];

    const fiats = [
        { name: 'USD', image: 'https://api.buycex.com/Upload/coin/65fc704078da2.png' },
        { name: 'INR', image: 'https://api.buycex.com/Upload/coin/660c8eb792b10.png' },
        { name: 'BDT', image: 'https://api.buycex.com/Upload/coin/660c8fc8a7273.png' },
        { name: 'AED', image: 'https://api.buycex.com/Upload/coin/660c89d0b0433.jpg' },
        { name: 'EUR', image: 'https://api.buycex.com/Upload/coin/660c892ab200d.png' }
    ];

    const [selectedCoinPay, setSelectedCoinPay] = useState(fiats[0]); // Default to fiat for Pay
    const [selectedCoinReceive, setSelectedCoinReceive] = useState(coins[0]); // Default to coin for Receive

    const [isDropdownOpenPay, setIsDropdownOpenPay] = useState(false);
    const [isDropdownOpenReceive, setIsDropdownOpenReceive] = useState(false);

    const handleSelectCoin = (value, type) => {
        if (type === 'pay') {
            setSelectedCoinPay(value);
            setIsDropdownOpenPay(false);
        } else if (type === 'receive') {
            setSelectedCoinReceive(value);
            setIsDropdownOpenReceive(false);
        }
    };

    return (
        <div className='dark:bg-black '>
            <div className='container py-6'>
                <div className='bg-buySell py-10 xl:py-0 rounded-3xl w-full flex items-center'>
                    <div className='px-4 sm:px-9 w-full'>
                        <div className='flex flex-col xl:flex-row w-full gap-4 items-center justify-between'>
                            <div className='w-full xl:w-[34%]'>
                                <h2 className='text-black text-center xl:text-start text-[24px] xl:text-[34px] font-bold'>Buy & Sell</h2>
                            </div>

                            {/* Pay Section */}
                            <div className='w-full lg:w-1/2 xl:w-[33%] bg-white border rounded-[0.375rem] flex px-6 py-3'>
                                <div className='flex items-center w-full justify-between gap-4'>
                                    <div className='flex-1'>
                                        <p className='text-xs text-[#81858c]'>Pay</p>
                                        <input className='placeholder:text-sm sm:placeholder:text-lg font-medium placeholder:text-[#78716C] focus:outline-none w-full' placeholder='Enter Amount' />
                                    </div>
                                    <div className='relative'>
                                        <div
                                            className='bg-white text-black flex items-center gap-1.5 w-28 font-semibold rounded-[0.375rem] px-4 py-1 cursor-pointer'
                                            onClick={() => setIsDropdownOpenPay(!isDropdownOpenPay)}
                                        >
                                            <img src={selectedCoinPay.image} alt={selectedCoinPay.name} className='w-6 h-6' />
                                            {selectedCoinPay.name}
                                            <FaCaretDown className={`transition-transform opacity-70 duration-300 ${isDropdownOpenPay ? 'rotate-180' : 'rotate-0'}`} />
                                        </div>
                                        {isDropdownOpenPay && (
                                            <div className='absolute top-10 left-0 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10'>
                                                {fiats.map((fiat, index) => (
                                                    <div
                                                        key={index}
                                                        className='flex items-center px-4 py-2 hover:bg-gray-200 cursor-pointer'
                                                        onClick={() => handleSelectCoin(fiat, 'pay')}
                                                    >
                                                        <img
                                                            src={fiat.image}
                                                            alt={fiat.name}
                                                            className='w-6 h-6 mr-2'
                                                        />
                                                        {fiat.name}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <img src={RefreshIcon} alt='RefreshIcon' className='h-10 w-10' />

                            {/* Receive Section */}
                            <div className='w-full lg:w-1/2 xl:w-[33%] bg-white border rounded-[0.375rem] flex px-6 py-3'>
                                <div className='flex items-center w-full justify-between gap-4'>
                                    <div className='flex-1'>
                                        <p className='text-xs text-[#81858c]'>Receive</p>
                                        <input className='placeholder:text-sm sm:placeholder:text-lg font-medium placeholder:text-[#78716C] focus:outline-none w-full' placeholder='Enter Amount' />
                                    </div>
                                    <div className='relative'>
                                        <div
                                            className='bg-white text-black flex items-center font-semibold gap-1.5 rounded-[0.375rem] px-4 py-1 cursor-pointer'
                                            onClick={() => setIsDropdownOpenReceive(!isDropdownOpenReceive)}
                                        >
                                            <img src={selectedCoinReceive.image} alt={selectedCoinReceive.name} className='w-6 h-6' />
                                            {selectedCoinReceive.name}
                                            <FaCaretDown className={`transition-transform opacity-70 duration-300 ${isDropdownOpenReceive ? 'rotate-180' : 'rotate-0'}`} />
                                        </div>
                                        {isDropdownOpenReceive && (
                                            <div className='absolute top-10 left-0 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10'>
                                                {coins.map((coin, index) => (
                                                    <div
                                                        key={index}
                                                        className='flex items-center px-4 py-2 hover:bg-gray-200 cursor-pointer'
                                                        onClick={() => handleSelectCoin(coin, 'receive')}
                                                    >
                                                        <img
                                                            src={coin.image}
                                                            alt={coin.name}
                                                            className='w-6 h-6 mr-2'
                                                        />
                                                        {coin.name}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='bg-[#EFB81C] p-4 cursor-pointer rounded-[0.375rem]'><FiArrowUpRight className='text-black' size={24} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <style>
                {`
                .bg-buySell {
                    background-image: url(${buySellBg});
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 160px;
                }

                @media (max-width: 1280px) {
                    .bg-buySell {
                        background-image: url(${buySellBgMobile});
                        height: 100%;
                    }
                }
                `}
            </style>
        </div>
    );
}

export default BuySell;
