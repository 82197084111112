import { 
    DO_TRADE_REQUEST,
    DO_TRADE_SUCCESS,
    DO_TRADE_FAILURE,
} from "../types";
import toast from "react-hot-toast";
import upTradeData from "repository/buySellRepository";

export const doTrade = (payload, token, userId) => async (dispatch) => {
    dispatch({ type: DO_TRADE_REQUEST });

    try {
        console.log("Trade Data Payload:", payload);

        const response = await upTradeData(payload, token, userId); // ✅ Pass formatted payload

        console.log("Trade API Response:", response);

        if (response.data.status === 1) {
            toast.success(response.data.data || "Trade executed successfully!");
            dispatch({
                type: DO_TRADE_SUCCESS,
                payload: response.data,
            });

            return response;
        } else {
            toast.error(response.data.data || "Trade execution failed.");
            dispatch({
                type: DO_TRADE_FAILURE,
                payload: response.data,
            });
            return response;
        }
    } catch (error) {
        console.error("Trade execution failed:", error);
        toast.error("Trade failed. Please try again later.");
        dispatch({
            type: DO_TRADE_FAILURE,
            error,
        });
        throw error;
    }
};
