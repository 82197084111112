import React from "react";
import {
    BsCaretDownFill,
    BsCaretUpFill,
    BsQuestionCircleFill,
    BsStarFill,
} from "react-icons/bs";
import { useMarketData } from "../hooks/useMarketData";
import { useSort } from "../hooks/useSortData";

const AllCryptoDataTable = ({ navActive }) => {
    const {
        marketData,
        validIcons,
        formatPrice,
        extractCoinName,
        handleDetailsRedirect,
        handleTradeRedirect,
        isFavoritePair,
        toggleFavoritePair,
        svgDataList,
    } = useMarketData(navActive);

    const { data: sortedMarketData, sortOrder, sortData } = useSort(marketData);

    return (
        <div className="market-data-table-wrapper ">
            <table className="w-full market-data-table table__all-crypto">
                <thead className="market-data-table__header">
                    <tr className="flex justify-between market-data-table__header-row">
                        <th className="market-data-table__header-row__heading">
                            <button
                                type="button"
                                className="flex items-center w-full gap-1 data-filter-btn"
                                onClick={() => sortData("volume")}
                            >
                                <h4 className="heading-title">Name</h4>
                                <div className="flex flex-col gap-0">
                                    <BsCaretUpFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "asc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                    <BsCaretDownFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "desc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                </div>
                            </button>
                        </th>
                        <th className="market-data-table__header-row__heading">
                            <button
                                type="button"
                                className="flex items-center justify-end w-full gap-1 data-filter-btn"
                                onClick={() => sortData("new_price")}
                            >
                                <h4 className="heading-title">Price</h4>
                                <div className="flex flex-col gap-0">
                                    <BsCaretUpFill
                                        size={6}
                                        color={
                                            sortOrder.new_price === "asc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                    <BsCaretDownFill
                                        size={6}
                                        color={
                                            sortOrder.new_price === "desc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                </div>
                            </button>
                        </th>
                        <th className="market-data-table__header-row__heading">
                            <button
                                type="button"
                                className="flex items-center justify-end w-full gap-1 data-filter-btn"
                                onClick={() => sortData("change")}
                            >
                                <h4 className="heading-title">24h Change</h4>
                                <div className="flex flex-col gap-0">
                                    <BsCaretUpFill
                                        size={6}
                                        color={
                                            sortOrder.change === "asc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                    <BsCaretDownFill
                                        size={6}
                                        color={
                                            sortOrder.change === "desc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                </div>
                            </button>
                        </th>
                        <th className="market-data-table__header-row__heading">
                            <button
                                type="button"
                                className="flex items-center justify-end w-full gap-1 data-filter-btn"
                                onClick={() => sortData("volume")}
                            >
                                <h4 className="heading-title">24h Volume</h4>
                                <div className="flex flex-col gap-0">
                                    <BsCaretUpFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "asc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                    <BsCaretDownFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "desc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                </div>
                            </button>
                        </th>
                        <th className="market-data-table__header-row__heading">
                            <button
                                type="button"
                                className="flex items-center justify-end w-full gap-1 data-filter-btn"
                                onClick={() => sortData("volume")}
                            >
                                <h4 className="heading-title">Market Cap</h4>
                                <div className="flex flex-col gap-0">
                                    <BsCaretUpFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "asc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                    <BsCaretDownFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "desc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                </div>
                            </button>
                        </th>
                        <th className="market-data-table__header-row__heading">
                            <button
                                type="button"
                                className="flex items-center justify-end w-full gap-1 data-filter-btn"
                                onClick={() => sortData("volume")}
                            >
                                <h4 className="heading-title">24h Chart</h4>
                                <div className="flex flex-col gap-0">
                                    <BsCaretUpFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "asc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                    <BsCaretDownFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "desc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                </div>
                            </button>
                        </th>
                        <th
                            className="market-data-table__header-row__heading"
                            colSpan="2"
                        >
                            <h4 className="heading-title">Actions</h4>
                        </th>
                    </tr>
                </thead>

                <tbody className="market-data-table__body">
                    {sortedMarketData.map((coin, index) => (
                        <tr
                            key={coin.id}
                            className="flex justify-between market-data-table__body-row"
                        >
                            <td className="market-data-table__body-row__data">
                                <div className="flex items-center gap-2">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            toggleFavoritePair(coin.id)
                                        }
                                        className="favorite-toggler w-max h-max"
                                    >
                                        <BsStarFill
                                            size={12}
                                            fill={
                                                isFavoritePair(coin.id)
                                                    ? "#EFB81C"
                                                    : "#15141540"
                                            }
                                        />
                                    </button>
                                    {validIcons[coin.id] ? (
                                        <img
                                            src={coin.icon}
                                            alt={coin.name}
                                            className="w-5 h-5"
                                        />
                                    ) : (
                                        <BsQuestionCircleFill
                                            className="w-5 h-5"
                                            fill="#15141540"
                                        />
                                    )}
                                    <span className="text-[14px] font-[500] flex items-center gap-2 uppercase">
                                        {coin.coinData?.name}
                                        <span className="text-[#a0a0a0] capitalize text-start">
                                            {coin.coinData?.title}
                                        </span>
                                    </span>
                                </div>
                            </td>

                            <td className="market-data-table__body-row__data">
                                <span className="text-[14px] font-medium">
                                    {coin.new_price
                                        ? "$" + coin.new_price
                                        : "-"}
                                </span>
                            </td>

                            <td className="market-data-table__body-row__data">
                                <span
                                    className={`text-[14px] font-medium ${
                                        coin.change < 0
                                            ? "text-red-500"
                                            : "text-green-500"
                                    }`}
                                >
                                    {coin.change > 0
                                        ? `+${coin.change.toFixed(2)}%`
                                        : `${coin.change.toFixed(2)}%`}
                                </span>
                            </td>

                            <td className="market-data-table__body-row__data">
                                <span className="text-[14px] font-medium">
                                    {coin.max_price
                                        ? "$" + coin.max_price
                                        : "-"}
                                </span>
                            </td>

                            <td className="market-data-table__body-row__data">
                                <span className="text-[14px] font-medium">
                                    {coin.volume
                                        ? formatPrice(coin.volume)
                                        : "-"}
                                </span>
                            </td>

                            <td className="market-data-table__body-row__data flex">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: svgDataList[index],
                                    }}
                                />
                            </td>
                            <td className="market-data-table__body-row__data">
                                <div className="flex items-center gap-1 ml-auto w-max">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            handleDetailsRedirect(coin.id)
                                        }
                                        className="text-[14px] font-[500] px-4 py-1 rounded-md text-[#EFB81C]"
                                    >
                                        Details
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() =>
                                            handleTradeRedirect(coin.id)
                                        }
                                        className="text-[14px] font-[500] px-4 py-1 rounded-md bg-[#EFB81C]"
                                    >
                                        Trade
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AllCryptoDataTable;
