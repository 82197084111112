import React, { useState, useRef, useEffect } from "react";
import { RiBitCoinFill, RiMoneyDollarCircleFill } from "react-icons/ri";
import { FaAngleRight } from "react-icons/fa";
import TradeNowPopup from "./TradeNowPopup";
import { Flex, Progress } from 'antd';
import { TfiAnnouncement } from "react-icons/tfi";
import { Link } from "react-router-dom/cjs/react-router-dom";

const conicColors = {
  '0%': '#f7f7f7',
  '50%': '#ffe58f',
  '100%': '#EFB81C',
};

function UpcomingProject({ upcoming_projects }) {

  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef(null);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [tradeValue, setTradeValue] = useState(null);
  const [countdowns, setCountdowns] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2;

  const showModal = (item) => {
    setIsPopupActive(true);
    setTradeValue({ ...item, icon: <RiBitCoinFill size={18} /> });
  };

  const handleCancel = () => {
    setIsPopupActive(false);
  };

  const handleOk = () => {
    setIsPopupActive(false);
  };

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(
        textRef.current.scrollHeight > textRef.current.clientHeight
      );
    }
  }, [isExpanded]);

  const handleMoreClick = () => {
    setIsExpanded(!isExpanded);
  };
  const sectionsData = upcoming_projects?.map((project) => ({
    id: project.id,
    icon: (
      <img
        src={`https://global.buycex.com/Upload/issue/${project.image}`}
        alt={project.name}
        className="w-10 h-10 rounded-full object-cover"
      />
    ),
    title: project.name,
    subtitle: `Coin: ${project.coinname}`,
    price: project.price,
    exchange_listing_date: project.exchange_listing_date ? project.exchange_listing_date : "Announced Soon",
    beginTime:project.beginTime,
    volume: project.num,
    subscribed: project.deal,
    avaialbe:"In Progress",
    limit: project.num
  }));
  const calculateCountdown = (dateString) => {
    const targetDate = new Date(dateString);
    const now = new Date();
    const difference = targetDate - now;

    if (difference <= 0) {
      return "00d 00h 00m 00s";
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };
 

  useEffect(() => {
    const interval = setInterval(() => {
        setCountdowns((prevCountdowns) => {
            const newCountdowns = { ...prevCountdowns };

            if (sectionsData && Array.isArray(sectionsData)) {
                sectionsData.forEach((section, index) => {
                    newCountdowns[index] = calculateCountdown(section.endTime);
                });
            }

            return newCountdowns;
        });
    }, 1000);

    return () => clearInterval(interval);
}, [sectionsData]); 

 
        

  


  const totalPages = Math.ceil((sectionsData?.length || 0) / itemsPerPage);

  const paginatedData = (sectionsData || []).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div className="bg-[#F5F7FA] dark:bg-black">
      <div className="container">
        <p className="text-lg sm:text-[24px] dark:text-white font-semibold pt-8 pb-4 px-[16px] sm:px-0">Upcoming Projects</p>
        {paginatedData?.map((section, index) => (
          <div key={section.id} className="pb-6">
           
            <div className="bg-white dark:bg-[#090a0c] rounded-2xl px-6 py-4">
              <div className="flex w-full justify-between">
                <div className="text-[#EFB81C] font-medium text-xs bg-[#EFB81C] bg-opacity-10 px-2 py-1 flex rounded-md justify-self-start">
                  Coming up
                </div>
                <button
                  onClick={() => showModal(section)}
                  className="bg-[#EFB81C] invisible px-3 py-0.5 rounded-md text-sm text-black font-medium"
                >
                  Buy now
                </button>
              </div>
              {tradeValue !== null && (
                <TradeNowPopup
                  isPopupActive={isPopupActive}
                  onOk={handleCancel}
                  onCancel={handleOk}
                  tradeValue={tradeValue}
                />
              )}
              <div className="flex flex-col lg:flex-row justify-between">
                  <div className="lg:w-1/3 w-full">
                    <div className="flex items-center gap-3 mt-5">
                      {section.icon}
                      <div className="flex flex-col">
                        <p className="font-bold text-2xl">
                          {section.title}
                        </p>
                        <span className="text-gray-400">
                          {section.subtitle}
                        </span>
                      </div>
                    </div>
                    <p>
                      <p
                        ref={textRef}
                        className={`text-gray-600 text-sm font-medium mt-4 ${isExpanded ? "" : "line-clamp-2"
                          }`}
                      >
                        {section.description}
                      </p>
                      {isTruncated && (
                        <p
                          onClick={handleMoreClick}
                          className="inline-flex text-xs font-medium items-center text-[#EFB81C] cursor-pointer"
                        >
                          {isExpanded ? "Less" : "More"}{" "}
                          <FaAngleRight className="ml-0.5" />
                        </p>
                      )}
                    </p>
                  </div>
                <div className="flex justify-between flex-col gap-10 h-full">
                  <div className="flex gap-6 justify-between w-full mt-10 flex-wrap self-start">
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col">
                        <p className="text-sm text-gray-400">
                          Total Supply
                        </p>
                        <p className="font-medium">
                          {section.volume}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm text-gray-400">
                          Price
                        </p>
                        <p className="font-medium">
                          ${section.price}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col">
                        <p className="text-sm text-gray-400">
                          Buy Limit
                        </p>
                        <p className="font-medium">
                          ${section.limit}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm text-gray-400">
                          Listing Date
                        </p>
                        <p className="font-medium">
                         {section.exchange_listing_date}
                          
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col">
                        <p className="text-sm text-gray-400">
                          Remaining time
                        </p>
                        <p className="font-medium">
                          {countdowns[index] || "Loading..."} 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-1/6 self-start lg:mt-10 mt-6">
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col">
                      <p className="text-sm text-gray-400">
                        Subscibed
                      </p>
                      <p className="font-medium">
                        {section.subscribed}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        ))}
      </div>
      <div className="flex justify-center dark:bg-black py-5 pb-5">
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            className={`px-3 py-1 mx-1 text-xs font-semibold border dark:border-[#454545]/60 rounded ${currentPage === index + 1
                ? "bg-yellow-500 text-black"
                : "bg-gray-100 text-gray-700"
              }`}
          >
            {index + 1}
          </button>
        ))}
      </div>

    </div>
  );
}

export default UpcomingProject;
