import React, { useEffect, useState } from "react";
const heroImages = [HeroImageOne, HeroImageTwo, HeroImageThird];
import HeroImageOne from "assets/img/Aboutsection4.png";
import HeroImageTwo from "assets/img/algo-orders.png";
import HeroImageThird from "assets/img/Aboutsection3.png";
import Bcxtoken from 'assets/img/bcx-token.png'
import { LuScanFace } from "react-icons/lu";
import { FaArrowRight } from "react-icons/fa";
import { RiShieldUserLine } from "react-icons/ri";
import { MdEmojiFlags } from "react-icons/md";
import { BsArrowUpCircle } from "react-icons/bs";
import { BiTargetLock } from "react-icons/bi";
import step1 from 'assets/img/Infinity-step-1.svg'
import step2 from 'assets/img/Infinity-step-2.svg'
import step3 from 'assets/img/Infinity-step-3.svg'
import arrow from 'assets/img/Infinity-arrow.svg'
import Faqs from "./Faqs";
import JoinNow from "./JoinNow";
import Benefits from "./Benefits";

const topbuttons = [
    {
        id: 1,
        title: "Trade Now",
        link: "/trade/spot",
        icon: "//s1.bycsi.com/bybit/deadpool/image-f921f812edc64b5c8a1809617f250a8e.svg",
    },
    {
        id: 2,
        title: "Convert",
        link: "/user/assets/spot",
        icon: "//s1.bycsi.com/bybit/deadpool/image-96389ab17bda4838b8479dc0b1871e2c.svg",
    },
];
const BcxToken = () => {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageTransitionState, setImageTransitionState] = useState("fade-in");

    useEffect(() => {
        const imageInterval = setInterval(() => {
            setImageTransitionState("fade-out");

            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => {
                    return (prevIndex + 1) % heroImages.length;
                });
                setImageTransitionState("fade-in");
            }, 600);
        }, 3000);

        return () => clearInterval(imageInterval);
    }, []);
    return (
        <div className="w-full bg-white">
            <section className="hero-section-black flex relative">
                <div className="hero-section__wrapper-black container flex items-center justify-between gap-8 w-full m-auto max-md:flex-col">
                    <div className="hero-title-wrapper-black flex flex-col gap-2 w-[60%]">
                        <h1 className='hero-title-black flex gap-2'>
                            <span className='text-[32px] font-[900] text-[#EFB81C]'>Shape the Future, Own the Legacy of Buycex</span>
                        </h1>
                        <p className='text-md font-medium text-[#adb1b8] capitalize'>Own the future of Buycex — earn revenue, drive innovation, and shape what’s next.</p>

                        <div className="flex gap-3 mt-12">
                            <button className="font-medium bg-[#EFB81C] rounded-md px-4 py-1 flex items-center gap-1"><BiTargetLock /><span className="text-[16px]">Get Infinity</span></button>
                        </div>
                    </div>
                    {/* Right Section: Hero Image */}
                    <div className="flex flex-col items-center w-[40%]">
                        <div className="hero-image-wrapper-black relative w-full h-[10rem] rounded-xl overflow-hidden">
                            {heroImages.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`hero-img-${index}`}
                                    className={`hero-image-black absolute top-0 left-0 w-full rounded-xl object-contain transition-opacity duration-700 ${imageTransitionState} ${index === currentImageIndex ? "active" : "inactive"
                                        }`}
                                />
                            ))}
                        </div>
                        <button className='font-semibold text-sm border-2 rounded-full border-[#EFB81C] text-[#EFB81C] px-4 py-1'>
                            Become a Member Now!
                        </button>
                    </div>
                </div>
            </section>
            <div className="h-32 relative -top-10 bg-white border rounded-3xl max-w-6xl mx-auto">
                <div className="flex justify-between items-center h-32">
                <div className="flex flex-col justify-center flex-1 items-center">
                    <img src={step1} alt="step1" />
                    <div className="inline-flex gap-1"><span className="text-[#EFB81c] text-lg font-semibold">1.</span><span className="text-lg font-semibold">Own Infinity Bond</span></div>
                </div>
                <img src={arrow} alt="arrow" />
                <div className="flex flex-col justify-center flex-1 items-center">
                    <img src={step2} alt="step2" />
                    <div className="inline-flex gap-1"><span className="text-[#EFB81c] text-lg font-semibold">2.</span><span className="text-lg font-semibold">Earn Revenue</span></div>
                </div>
                <img src={arrow} alt="arrow" />
                <div className="flex flex-col justify-center flex-1 items-center">
                    <img src={step3} alt="step3" />
                    <div className="inline-flex gap-1"><span className="text-[#EFB81c] text-lg font-semibold">3.</span><span className="text-lg font-semibold">Shape the Future</span></div>
                </div>
                </div>
            </div>
            <div className="container">
                <div className="py-6 flex items-center justify-between">
                    <div className="w-2/3">
                        <h1 className="text-2xl font-bold py-8">What is Infinity Club?</h1>
                        <div className="flex flex-col gap-2.5">
                            <p className="font-semibold text-md">Infinity Club is Buycex’s exclusive program where users can purchase Infinity Bonds and earn a share of Buycex's revenue while gaining voting rights.</p>
                        </div>
                    </div>
                    <img src={Bcxtoken} alt="Bcxtoken" className="w-[360px]" />
                </div>
                <div className="py-6">
                    <h1 className="text-2xl font-bold py-8">How it Works?</h1>
                    <div className="flex gap-1 items-center w-full">
                        <div className="w-1/3 min-h-[132px]  border rounded-xl p-6">
                            <div className="flex gap-2 items-center font-bold text-lg"><BsArrowUpCircle size={32}/><p>Exclusive Ownership</p></div>
                            <p className="text-[#81858c] text-sm py-1">Your gateway to exclusive ownership.</p>
                        </div>
                        <div className="p-2 rounded-full ring-4 ring-slate-100 bg-slate-300 relative -mx-3"><FaArrowRight fill="#fff" /></div>
                        <div className="w-1/3 min-h-[132px]  border rounded-xl p-6">
                            <div className="flex gap-2 items-center font-bold text-lg"><RiShieldUserLine size={32}/><p>Earn Rewards</p></div>
                            <p className="text-[#81858c] text-sm py-1">Unlock a share of 25% of platform revenues.</p>
                        </div>
                        <div className="p-2 rounded-full ring-4 ring-slate-100 bg-slate-300 relative -mx-3"><FaArrowRight fill="#fff" /></div>
                        <div className="w-1/3 min-h-[132px]  border rounded-xl p-6">
                            <div className="flex gap-2 items-center font-bold text-lg"><MdEmojiFlags size={32}/><p>Shape the Future </p></div>
                            <p className="text-[#81858c] text-sm py-1">Your voice matters — participate in key governance decisions</p>
                        </div>
                    </div>
                </div>
            </div>
            <Benefits/>
            <Faqs/>
            <JoinNow/>
        </div>
    );
};

export default BcxToken;
