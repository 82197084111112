import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RepositoryFactory } from "repository/RepositoryFactory";
import BuyComponent from "./ExpressComponents/BuyComponent";
import SellComponent from "./ExpressComponents/SellComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import HowBuy from "./ExpressComponents/HowBuy";
import Faqs from "components/FAQs";

const rates = {
    BTC: 26800,
    ETH: 1700,
    BNB: 215,
    CVM: 0.5,
    USDT: 1, // base value for USDT
};

const fiatCurrencies = [
    { name: "usd", title: "USD", img: "65fc704078da2.png" },
    { name: "inr", title: "Indian Rupees", img: "660c8eb792b10.png" },
    { name: "bdt", title: "BDT", img: "660c8fc8a7273.png" },
    { name: "aed", title: "AED", img: "660c89d0b0433.jpg" },
    { name: "eur", title: "EURO", img: "660c892ab200d.png" },
    { name: "gbp", title: "GBP", img: "660c88a063280.png" },
    { name: "idr", title: "IDR", img: "660c902d2bfc5.png" },
    { name: "afn", title: "AFN", img: "660c93625c2ac.jpg" },
    { name: "amd", title: "AMD", img: "660c97ec93a1c.jpg" },
    { name: "aoa", title: "AOA", img: "660c9814ba8e4.png" },
    { name: "ars", title: "ARS", img: "660c98447c66c.png" },
    { name: "aud", title: "AUD", img: "660c985fa133e.png" },
    { name: "azn", title: "AZN", img: "660c987bccf0d.png" },
    { name: "bgn", title: "BGN", img: "660c994159403.png" },
    { name: "bhd", title: "BHD", img: "660c9966a6216.png" },
    { name: "bnd", title: "BND", img: "660c9986ddd29.png" },
    { name: "bob", title: "BOB", img: "660c99a06c5e7.png" },
    { name: "brl", title: "BRL", img: "660c99bb5c0ac.png" },
    { name: "byn", title: "BYN", img: "660c99db5990b.png" },
    { name: "cad", title: "CAD", img: "660c9f39bbfe9.png" },
];

const allowed_cryptos = [
    {
        name: "BTC",
        title: "BTC",
        img: "BTC.png",
    },
    {
        name: "ETH",
        title: "ETH",
        img: "ETH.png",
    },
    {
        name: "BNB",
        title: "BNB",
        img: "BNB.png",
    },
    {
        title: "CVM",
        name: "CVM",
        img: "65f80eb6e11c9.png",
    },
    {
        name: "USDT",
        title: "USDT",
        img: "USDT.png",
    },
];

function Express() {
    const [isBuyMode, setIsBuyMode] = useState(true);
    const [selectedCoin, setSelectedCoin] = useState("BTC");
    const [selectedReceiveCoin, setSelectedReceiveCoin] = useState("ETH");
    const [amount, setAmount] = useState("");
    const [convertedAmount, setConvertedAmount] = useState("");

    const [p2pfiatCurrencies, setP2pFiatCurrencies] = useState(fiatCurrencies);
    const [paymentMethods, setPaymentMethods] = useState([]);

    const history = useHistory();

    const [buyCoins, setBuyCoins] = useState({
        buySpendCoing: {
            value: p2pfiatCurrencies[0]?.name,
            label: (
                <div className="flex items-center">
                    <img
                        src={`https://api.buycex.com/Upload/coin/${p2pfiatCurrencies[0]?.img}`}
                        alt="BTC"
                        className="w-6 h-6 mr-2"
                    />
                    {p2pfiatCurrencies[0]?.title}
                </div>
            ),
        },
        buyReceiveCopin: {
            value: "ETH",
            label: (
                <div className="flex items-center">
                    <img
                        src={`https://api.buycex.com/Upload/coin/ETH.png`}
                        alt="BTC"
                        className="w-6 h-6 mr-2"
                    />
                    ETH
                </div>
            ),
        },
    });
    const [sellCoins, setSellCoins] = useState({
        sellSpendCoing: {
            value: "ETH",
            label: (
                <div className="flex items-center">
                    <img
                        src={`https://api.buycex.com/Upload/coin/ETH.png`}
                        alt="BTC"
                        className="w-6 h-6 mr-2"
                    />
                    ETH
                </div>
            ),
        },
        sellReceiveCopin: {
            value: p2pfiatCurrencies[0]?.name,
            label: (
                <div className="flex items-center">
                    <img
                        src={`https://api.buycex.com/Upload/coin/${p2pfiatCurrencies[0]?.img}`}
                        alt="BTC"
                        className="w-6 h-6 mr-2"
                    />
                    {p2pfiatCurrencies[0]?.title}
                </div>
            ),
        },
    });

    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
    
    // useEffect(() => {
    //     if (!TOKEN || !ID) {
    //         history.push("/login");
    //     }
    // }, [TOKEN, ID, history]);

    useEffect(() => {
        const p2pRepository = RepositoryFactory.get("p2p");

        const fetchFiatList = async () => {
            const response = await p2pRepository.quick(ID, TOKEN);
            const res = response.data.data;

            if (res?.FiatList?.length) {
                setP2pFiatCurrencies(res?.FiatList?.map((item) => item));
            }
        };

        fetchFiatList();
    }, [TOKEN]);

    useEffect(() => {
        const p2pRepository = RepositoryFactory.get("p2p");

        const fetchPaymentMethods = async () => {
            const response = await p2pRepository.getP2pPaymentMethodsNew(
                ID,
                TOKEN
            );
            const res = response.data.data;

            if (Object.values(res)?.length) {
                setPaymentMethods(Object.values(res));
            }
        };

        fetchPaymentMethods();
    }, [TOKEN]);

    const handleToggle = async () => {
        await setIsBuyMode(!isBuyMode);
        setConvertedAmount(""); // Reset conversion when switching modes
        setAmount(""); // Reset amount on mode toggle
    };

    const handleAmountChange = (e) => {
        const inputAmount = e.target.value;
        setAmount(inputAmount);

        const conversionRate =
            rates[selectedCoin] && rates[selectedReceiveCoin]
                ? rates[selectedCoin] / rates[selectedReceiveCoin]
                : 1;

        const converted = (inputAmount * conversionRate).toFixed(4);
        setConvertedAmount(converted);
    };

    const handleCoinChange = async (value) => {
        let foundedCurrency;

        if (isBuyMode) {
            foundedCurrency = p2pfiatCurrencies?.find(
                ({ name }) => value === name
            );

            await setBuyCoins({
                ...buyCoins,
                buySpendCoing: {
                    value: foundedCurrency?.name,
                    label: (
                        <div className="flex items-center">
                            <img
                                src={`https://api.buycex.com/Upload/coin/${foundedCurrency?.img}`}
                                alt="BTC"
                                className="w-6 h-6 mr-2"
                            />
                            {foundedCurrency?.title}
                        </div>
                    ),
                },
            });

            await setSelectedCoin(value);

            return;
        } else {
            foundedCurrency = allowed_cryptos?.find(
                ({ name }) => value === name
            );

            await setSellCoins({
                ...sellCoins,
                sellSpendCoing: {
                    value: foundedCurrency?.name,
                    label: (
                        <div className="flex items-center">
                            <img
                                src={`https://api.buycex.com/Upload/coin/${foundedCurrency?.img}`}
                                alt="BTC"
                                className="w-6 h-6 mr-2"
                            />
                            {foundedCurrency?.title}
                        </div>
                    ),
                },
            });

            await setSelectedCoin(value);

            return;
        }
    };

    const handleReceiveCoinChange = async (value) => {
        let foundedCurrency;

        if (isBuyMode) {
            foundedCurrency = allowed_cryptos?.find(
                ({ name }) => value === name
            );

            await setBuyCoins({
                ...buyCoins,
                buyReceiveCopin: {
                    value: foundedCurrency?.name,
                    label: (
                        <div className="flex items-center">
                            <img
                                src={`https://api.buycex.com/Upload/coin/${foundedCurrency?.img}`}
                                alt="BTC"
                                className="w-6 h-6 mr-2"
                            />
                            {foundedCurrency?.title}
                        </div>
                    ),
                },
            });

            await setSelectedReceiveCoin(value);

            return;
        } else {
            foundedCurrency = p2pfiatCurrencies?.find(
                ({ name }) => value === name
            );

            await setSellCoins({
                ...sellCoins,
                sellReceiveCopin: {
                    value: foundedCurrency?.name,
                    label: (
                        <div className="flex items-center">
                            <img
                                src={`https://api.buycex.com/Upload/coin/${foundedCurrency?.img}`}
                                alt="BTC"
                                className="w-6 h-6 mr-2"
                            />
                            {foundedCurrency?.title}
                        </div>
                    ),
                },
            });

            await setSelectedReceiveCoin(value);

            return;
        }
    };

    const faqsData = [
        {
          question: 'How to Deposit ARS on Buycex?',
          answer: 'Depositing ARS on Buycex is a straightforward process designed to make it easy for users to start trading digital assets.',
        },
        {
          question: 'How to Use Fiat Deposit?',
          answer: 'Fiat deposit allows you to convert your local currency into cryptocurrency with ease.',
        },
        {
          question: 'How to Deposit BRL on Buycex?',
          answer: 'Depositing BRL on Buycex is quick and easy, enabling you to start trading in no time.',
        },
        {
          question: 'How to Buy Coins With Fiat Balance?',
          answer: 'Buying coins with your fiat balance is simple and secure on our platform.',
        },
      ];

    return (
        <div>
            <div className="pb-20">
                <div className="container">
                    <div className="flex flex-col md:flex-col justify-center md:justify-center md:items-center lg:flex-row gap-8 md:gap-8 lg:gap-20 lg:justify-between w-full mx-auto lg:max-w-6xl pt-[36px]">
                        <div className="w-full text-center md:w-full lg:w-1/2 md:text-center lg:text-left">
                            <span className="text-[32px] lg:text-[56px] md:text-[32px] font-[800]">
                                Express Trading
                            </span>
                            <p className="text-[14px] lg:text-[24px] md:text-[14px] font-medium mt-2">
                                One Click Buy and Sell Crypto Currency With Most
                                Popular Local Payment Method.
                            </p>
                        </div>

                        {/* Right side placeholder */}
                        <div className="w-full md:w-[70%] lg:w-[38%]">
                            <div className="bg-[#e9edf2] dark:bg-[#17181e] border dark:border-[#454545] shadow-lg rounded-3xl">
                                <div className="flex h-[60px] rounded-2xl w-full">
                                    <div className="w-full flex justify-between">
                                        {/* Buy Section */}
                                        <div className="flex w-[50%] h-[60px]">
                                            <p
                                                className={`text-xl sm:text-2xl font-semibold rounded-tl-3xl rounded-tr-3xl flex h-[60px] justify-center items-center text-center w-full cursor-pointer ${isBuyMode ? "bg-white dark:bg-[#090a0c] transition-all duration-300 ease-in-out text-black dark:text-white" : "text-[#adb1b8]"
                                                    }`}
                                                onClick={() => !isBuyMode && handleToggle()}
                                            >
                                                Buy
                                            </p>
                                            <div
                                                style={{ transform: "skewX(15deg)" }}
                                                className={`h-[60px] relative left-[-10px] rounded-tr-2xl bottom-0 w-6 ${isBuyMode ? "bg-white dark:bg-[#090a0c] transition-all duration-300 ease-in-out" : "bg-transparent"
                                                    }`}
                                            ></div>
                                        </div>

                                        {/* Sell Section */}
                                        <div className="flex w-[50%] h-[60px]">
                                            <div
                                                style={{ transform: "skewX(-15deg)" }}
                                                className={`h-[60px] relative left-[10px] rounded-tl-2xl bottom-0 w-6 ${!isBuyMode ? "bg-white dark:bg-[#090a0c] transition-all duration-300 ease-in-out" : "bg-transparent"
                                                    }`}
                                            ></div>
                                            <p
                                                className={`text-xl w-full sm:text-2xl font-semibold rounded-tr-3xl rounded-tl-3xl flex h-[60px] justify-center items-center text-center cursor-pointer ${!isBuyMode ? "bg-white dark:bg-[#090a0c] transition-all duration-300 ease-in-out text-black dark:text-white" : "text-[#adb1b8]"
                                                    }`}
                                                onClick={() => isBuyMode && handleToggle()}
                                            >
                                                Sell
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div
                                    className={`py-5 sm:py-10 px-4 sm:px-8 bg-white dark:bg-[#090a0c] transition-all duration-300 ease-in-out rounded-b-3xl ${isBuyMode ? "rounded-tr-3xl" : "rounded-tl-3xl"
                                        }`}
                                >
                                    {isBuyMode ? (
                                        <>
                                            <BuyComponent
                                                p2pfiatCurrencies={p2pfiatCurrencies}
                                                amount={amount}
                                                handleAmountChange={handleAmountChange}
                                                handleCoinChange={handleCoinChange}
                                                buyCoins={buyCoins.buySpendCoing}
                                                setBuyCoins={setBuyCoins}
                                                isBuyMode={isBuyMode}
                                            />
                                            <div className="flex px-5 opacity-85 items-center gap-2 mt-2">
                                                <span className="text-sm font-medium">
                                                    1 {selectedCoin} ≈ {rates[selectedCoin]} USDT
                                                </span>
                                            </div>
                                            <SellComponent
                                                convertedAmount={convertedAmount}
                                                handleReceiveCoinChange={handleReceiveCoinChange}
                                                p2pfiatCurrencies={allowed_cryptos}
                                                sellCoins={buyCoins.buyReceiveCopin}
                                                setSellCoins={setSellCoins}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <BuyComponent
                                                p2pfiatCurrencies={allowed_cryptos}
                                                amount={amount}
                                                handleAmountChange={handleAmountChange}
                                                handleCoinChange={handleCoinChange}
                                                buyCoins={sellCoins.sellSpendCoing}
                                            />
                                            <div className="flex px-5 opacity-85 items-center gap-2 mt-2">
                                                <span className="text-sm font-medium">
                                                    1 {selectedCoin} ≈ {rates[selectedCoin]} USDT
                                                </span>
                                            </div>
                                            <SellComponent
                                                convertedAmount={convertedAmount}
                                                handleReceiveCoinChange={handleReceiveCoinChange}
                                                sellCoins={sellCoins.sellReceiveCopin}
                                                p2pfiatCurrencies={p2pfiatCurrencies}
                                            />
                                        </>
                                    )}

                                    <div className="flex px-5 pb-6 opacity-85 items-center gap-2 mt-2">
                                        <span className="text-sm font-medium">
                                            1 {selectedCoin} ≈ {rates[selectedCoin]} USDT
                                        </span>
                                    </div>

                                    {TOKEN ? (
                                        <button
                                            className="bg-[#EFB81C] text-black px-2 py-3 font-bold w-full rounded-lg mt-8"
                                            onClick={() => history.push("/trade/p2p")}
                                        >
                                            Submit
                                        </button>
                                    ) : (
                                        <button className="bg-[#EFB81C] text-black px-2 py-3 font-bold w-full rounded-lg mt-8">
                                            Log In
                                        </button>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-10">
                <HowBuy />
                <Faqs faqsData={faqsData} />
            </div>
            <style>
                {
                    `
                    .buy-btn{
    background: var(--color-BasicBg);
    border-radius: 16px;
    bottom: 0;
    content: " ";
    position: absolute;
    right: -24px;
    top: 0;
    transform: skewX(15deg);
    width: 24px;
}
                    `
                }
            </style>
        </div>
    );
}

export default Express;
