import React, { useState } from 'react';
import { SlMinus, SlPlus } from 'react-icons/sl';

const Faqs = ({ faqsData = [] }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className='bg-white dark:bg-black'>
      <div className='py-32 dark:bg-black'>
        <h3 className='font-semibold text-2xl sm:text-4xl text-start pb-6 max-w-4xl mx-auto'>FAQ</h3>
        <div className='max-w-4xl mx-auto space-y-4'>
          {faqsData.map((faq, index) => (
            <div key={index} className='bg-[#f7f7f7] dark:bg-[#17181E] rounded-3xl overflow-hidden'>
              <button
                className='w-full flex justify-between items-center px-3 sm:px-6 py-3 sm:py-6'
                onClick={() => toggleFaq(index)}
              >
                <span className='text-sm md:text-xl text-start font-semibold'>
                  {index + 1}. {faq.question}
                </span>
                <div className={`rounded-full transition-transform`}>
                  {openIndex === index ? <SlMinus size={30} /> : <SlPlus size={30} />}
                </div>
              </button>
              <h4
                className={`mx-6 text-gray-700 dark:text-gray-500 !text-sm md:text-[16px] transition-all duration-300 ease-in-out ${
                  openIndex === index
                    ? 'max-h-96 border-t dark:border-[#454545] !py-6 opacity-100'
                    : 'max-h-0 opacity-0 overflow-hidden'
                }`}
              >
                {faq.answer}
              </h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
