import React, { useState } from 'react';
import { SlMinus, SlPlus } from 'react-icons/sl';

const faqsData = [
  { question: 'What is an Infinity Bond?', answer: 'The Infinity Club is an exclusive membership program within the Buycex ecosystem. Users can join by purchasing Infinity Bonds.' },
  { question: 'How do I join the Infinity Club?', answer: 'Infinity Club is Buycex’s exclusive program where users can purchase Infinity Bonds and earn a share of Buycex\'\s revenue while gaining voting rights.' },
  { question: 'How does revenue sharing work?', answer: 'Infinity Bond is a unique asset tied to Buycex\'\s ecosystem — a form of public ownership token.Users buy 1 Infinity Bond for $10 (value in BCX tokens or other accepted crypto). Supply: A total of 5 milion Infinity Bonds are available.' },
];

function Faqs() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className='py-32 px-4 dark:bg-black'>
      <h3 className='font-semibold text-3xl text-start pb-6 max-w-5xl mx-auto'>FAQ</h3>
      <div className='max-w-5xl mx-auto space-y-4'>
        {faqsData.map((faq, index) => (
          <div key={index} className='bg-[#f7f7f7] dark:bg-[#17181E] rounded-3xl overflow-hidden'>
            <button
              className='w-full flex justify-between items-center px-6 py-6'
              onClick={() => toggleFaq(index)}
            >
              <span className='text-xl font-semibold'>
                {index + 1}. {faq.question}
              </span>
              <div className={`rounded-full transition-transform`}>
                {openIndex === index ? <SlMinus size={30}/> : <SlPlus size={30}/>}
              </div>
            </button>
            <h4
              className={`mx-6 text-gray-700 dark:text-gray-500 text-[16px] transition-all duration-300 ease-in-out ${openIndex === index ? 'max-h-96 border-t !py-6 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}
            >
              {faq.answer}
            </h4>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faqs;
