import React, { useState, useEffect } from 'react';
import HistoryDropdown from '../SpotHistory/DropDownUI';
import { DatePicker } from 'antd';
import { createGlobalStyle, styled } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { trade } from "store/actions/p2pActions";
import NoData from "assets/img/user/No-data.svg";
import { IoCopySharp } from "react-icons/io5";
import RepositoryFactory from '../../../../repository/Repository';

const { RangePicker } = DatePicker;

const P2pHistory = () => {
    const [activeItem, setActiveItem] = useState('All Orders'); 
    const [activeSubItem, setActiveSubItem] = useState("All");
    const [dateRange, setDateRange] = useState([]);

    const discoverNavItems = ['All Orders',"Processing"];
    // const allOrdersSubItems = ['All', 'Completed', 'Canceled'];
    const allOrdersSubItems = activeItem === 'All Orders' 
        ? ['All', 'Completed', 'Canceled'] 
        : ['All', 'Unpaid', 'Paid', 'Appeal in Progress'];
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const history = useHistory();
    const dispatch = useDispatch();
    const { P2pOrderHistory } = useSelector((state) => state.P2pOrderHistory);
    const [data, setData] = useState([]);
    const [coinOptions, setCoinOptions] = useState(["All"])

    useEffect(() => {
        const fetchCoins = async () => {
            try {
                const response = await RepositoryFactory.get('/Index/coininfo', {
                    headers: {
                        'token': userToken,
                        'id': userID,
                    }
                });

                const coinData = Object.values(response.data?.data); 
                const coinNames = coinData.map((coin) => coin.name);

                setCoinOptions(["All", ...coinNames]);
            } catch (error) {
                console.error("Error fetching coin data:", error);
            }
        };
        fetchCoins();
    }, []);
    

    useEffect(() => {
        dispatch(trade(userID, userToken));
    }, [dispatch, userID, userToken, history]);

    useEffect(() => {
        let filteredData = P2pOrderHistory?.p2pOrders || [];
    
        if (activeSubItem === 'Completed') {
            filteredData = filteredData.filter(item => item.status === '4');
        } else if (activeSubItem === 'Canceled') {
            filteredData = filteredData.filter(item => item.status === '2');
        } else if (activeItem === 'Processing') {
            if (activeSubItem === 'All') {
                filteredData = filteredData.filter(item => item.status === '0' || item.status === '1' || item.status === '3');
            }
            if (activeSubItem === 'Unpaid') {
                filteredData = filteredData.filter(item => item.status === '0');
            } else if (activeSubItem === 'Paid') {
                filteredData = filteredData.filter(item => item.status === '1');
            } else if (activeSubItem === 'Appeal in Progress') {
                filteredData = filteredData.filter(item => item.status === '0');
            }
        } else if (activeSubItem === 'All') {
    
            filteredData = filteredData.filter(item => item.status === '2' || item.status === '4');
        }
    
        if (dateRange.length === 2) {
            const [startDate, endDate] = dateRange;
            filteredData = filteredData.filter(item => {
                const itemDate = new Date(item.addtime * 1000);
                return itemDate >= startDate && itemDate <= endDate;
            });
        }
    
        setData(filteredData);
    }, [activeItem, activeSubItem, P2pOrderHistory, dateRange]);
    

    const onDateChange = (dates) => {
        const formartDates = dates ? [dates[0].toDate(), dates[1].toDate()] : [];
        setDateRange(formartDates);
    };

    const handleSubItemSelect = (subItem) => {
        setActiveSubItem(subItem);
    };

    const renderSubItems = () => {
        const subItems = allOrdersSubItems;
        const options1 = ["HMSTR", "XRP", "WRX", "USDT", "USDC", "TRX", "TON"];
        const options2 = ["All", "Buy/Deposit", "Sell/Withdraw"];

        const handleSelect1 = (selected) => {
            let filteredData = P2pOrderHistory?.p2pOrders || [];
        
            // **Filter based on activeItem & activeSubItem**
            if (activeItem === "Processing") {
                if (activeSubItem === "All") {
                    filteredData = filteredData.filter(item =>
                        item.status === '0' || item.status === '1' || item.status === '3'
                    );
                } else if (activeSubItem === "Unpaid") {
                    filteredData = filteredData.filter(item => item.status === '0');
                } else if (activeSubItem === "Paid") {
                    filteredData = filteredData.filter(item => item.status === '1');
                } else if (activeSubItem === "Appeal in Progress") {
                    filteredData = filteredData.filter(item => item.status === '3');
                }
            } else if (activeItem === "All Orders") {
                if (activeSubItem === "Completed") {
                    filteredData = filteredData.filter(item => item.status === '4');
                } else if (activeSubItem === "Canceled") {
                    filteredData = filteredData.filter(item => item.status === '2');
                } else if (activeSubItem === "All") {
                    filteredData = filteredData.filter(item => item.status === '2' || item.status === '4');
                }
            }
        
            // **Filter by selected coin**
            if (selected !== "All") {
                filteredData = filteredData.filter(item => item.coin === selected);
            }
        
            setData(filteredData);
        };
        

        const handleSelectOrderType = (selected) => {
            let filteredData = P2pOrderHistory?.p2pOrders || [];
        
            if (activeItem === "Processing") {
                if (activeSubItem === "All") {
                    filteredData = filteredData.filter(item => 
                        item.status === '0' || item.status === '1' || item.status === '3'
                    );
                } else if (activeSubItem === "Unpaid") {
                    filteredData = filteredData.filter(item => item.status === '0');
                } else if (activeSubItem === "Paid") {
                    filteredData = filteredData.filter(item => item.status === '1');
                } else if (activeSubItem === "Appeal in Progress") {
                    filteredData = filteredData.filter(item => item.status === '3');
                }
            } else if (activeItem === "All Orders") {
                if (activeSubItem === "Completed") {
                    filteredData = filteredData.filter(item => item.status === '4');
                } else if (activeSubItem === "Canceled") {
                    filteredData = filteredData.filter(item => item.status === '2');
                } else if (activeSubItem === "All") {
                    filteredData = filteredData.filter(item => item.status === '2' || item.status === '4');
                }
            }
        
            if (selected === "Buy/Deposit") {
                filteredData = filteredData.filter(item => item.ad_type === '1');
            } else if (selected === "Sell/Withdraw") {
                filteredData = filteredData.filter(item => item.ad_type === '2');
            }
        
            setData(filteredData);
        };
        
        
        


        return (
            <div className='flex gap-2 items-center'>
                <div className='inline-flex flex-wrap border rounded-lg pt-[0.20rem] pb-[0.25rem] px-1 gap-2 mt-4'>
                    {subItems.map((subItem) => (
                        <button
                            key={subItem}
                            onClick={() => handleSubItemSelect(subItem)}
                            className={`w-auto py-1.5 px-6 text-sm font-medium ${activeSubItem === subItem ? 'bg-[#F5F5F5] rounded-md' : 'text-gray-400'}`}
                        >
                            {subItem}
                        </button>
                    ))}
                </div>

                <div className='mt-4 flex gap-2'>
                    <HistoryDropdown
                        label="Coins"
                        options={coinOptions}
                        initialSelected="All"
                        onSelect={handleSelect1}
                        isSearchable={true}
                        width="200px"
                    />
                    <HistoryDropdown
                        label="Order Type"
                        options={options2}
                        initialSelected="All"
                        onSelect={handleSelectOrderType}
                        isSearchable={false}
                        width="250px"
                    />
                    <GlobalStyles />
                    <StyledRangePicker onChange={onDateChange} className="px-2 w-[600px] h-[40px] bg-white sm:w-1/3 py-[0.55rem] border-l" />
                    {/* <button onClick={resetButton} className='text-[#EFB81C] font-medium'>Reset</button> */}
                </div>
            </div>
        );
    };

    const renderContent = () => (
        <div className="text-xs mt-8 text-[#707a8a] w-full overflow-x-auto">
            <div className="flex flex-col w-[95%]">
                <div className="flex justify-between border-b pb-3 w-full">
                    <p className="flex-1 text-start">Type/Date</p>
                    <p className="flex-1 text-start">Price</p>
                    <p className="flex-1 text-start">Fiat / Crypto Amount</p>
                    <p className="flex-[2] text-start">Order Number/ Counterparty</p>
                    <p className="flex-1 text-start">Status</p>
                    <p className="flex-1 text-center">Actions</p>
                </div>

                {data.length > 0 ? (
                    data.map((item, index) => (
                        <div key={index} className="flex justify-between w-full py-3">
                            <div className="flex-1">
                                <p className={`text-[14px] py-3 ${item.ad_type === "1" ? 'text-red-500' : 'text-[#2EBD85]'}`}>
                                    {item.ad_type === "1" ? "Buy" : "Sell"} 
                                    <span className="text-[14px] text-black"> {item.coin?.toUpperCase()}</span>
                                </p>
                                <p className="text-[14px]">{new Date(item.addtime * 1000).toLocaleString() || 'N/A'}</p>
                            </div>
                            <p className="flex-1 text-start flex items-center text-[14px] text-black">
                                {parseFloat(item.fixed_price).toFixed(2)}
                            </p>
                            <div className="flex-1 text-start text-[14px] text-black">
                                <p className="text-[14px] py-3 text-black">
                                    {parseFloat(item.fiat_qty).toFixed(2)} {item.fiat?.toUpperCase()}
                                </p>
                                <p className="text-[14px]">
                                    {parseFloat(item.coin_qty).toFixed(2)} {item.coin?.toUpperCase()}
                                </p>
                            </div>
                            <div className="flex-[2] text-start text-[14px] text-black">
                                <div className="flex gap-1 text-gray-400 items-center">
                                    <p className="text-[14px] py-3 text-black underline">{item.orderid}</p>
                                    <IoCopySharp />
                                </div>
                            </div>
                            <p className="flex-1 text-start text-[14px] flex items-center text-black">
                            {item.status === "0" ? "Pending" : 
 item.status === "1" ? "Paid" : 
 item.status === "2" ? "Canceled" : 
 item.status === "3" ? "Disputed" : 
 item.status === "4" ? "Completed" : 
 "Unknown"}
          </p>
                            <p className="flex-1 text-center text-[14px] flex items-center justify-center underline text-yellow-500">
                                View
                            </p>
                        </div>
                    ))
                ) : (
                    <div className="w-full flex justify-center items-center h-96">
                        <div className="flex flex-col gap-1 h-44 justify-center items-center">
                            <img src={NoData} alt="No Data" />
                            <p className="text-[#707a8a] text-sm">No records</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <div className='px-3'>
            <div className='container mt-5 !p-0'>
                <nav className='flex gap-3 w-[60%] border-gray-200 pt-2'>
                    {discoverNavItems.map((item) => (
                        <button
                            key={item}
                            onClick={() => {
                                setActiveItem(item);
                                setActiveSubItem("All"); 
                            }}
                            className={`relative py-2 px-2 text-md font-semibold ${activeItem === item ? '' : 'text-gray-400'}`}
                        >
                            {item}
                            {activeItem === item && (
                                <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                            )}
                        </button>
                    ))}
                </nav>

                {renderSubItems()}
                <div className="mt-6">{renderContent()}</div>
            </div>
        </div>
    );
};

const GlobalStyles = createGlobalStyle`
  .ant-picker:hover {
    border-color: #EFB81C !important;
  }
  .ant-picker-focused {
    border-color: #EFB81C !important;
    box-shadow: 0 0 0 2px #EFB81C !important;
  }
`;

const StyledRangePicker = styled(RangePicker)`
  border-radius: 6px;
`;

export default P2pHistory;
