import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import { createGlobalStyle, styled } from 'styled-components';
import AssetDropdown from './DropDownUI';
import { useDispatch, useSelector } from 'react-redux';
import { FinanceOrdersTradeHistory } from 'store/actions/AssetsUserAction';
import NoData from 'assets/img/user/No-data.svg';

const { RangePicker } = DatePicker;

function TradeHistory() {
  const [options1, setOptions1] = useState(["All"]);
    const options2 = ["All", "Pre-Market", "USDT", "FSUSD", "USDC", "TUSD", "BNB", "BTC", "ETH"];
    const options3 = ["All", "Buy", "Sell"];
    const dispatch = useDispatch();
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const [orderListData, setOrderListData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 100;
    const totalPages = Math.ceil(orderListData.length / recordsPerPage);
 const [filteredOrderListData, setFilteredOrderListData] = useState([]);
    const startIndex = (currentPage - 1) * recordsPerPage;
    const currentRecords = filteredOrderListData.slice(startIndex, startIndex + recordsPerPage);
    const marketPairs = {
        ada_usdt: { id: '23', name: 'ada_usdt' },
        bcx_usdt: { id: '25', name: 'bcx_usdt' },
        btc_usdt: { id: '18', name: 'btc_usdt' },
        cvm_usdt: { id: '24', name: 'cvm_usdt' },
        doge_btc: { id: '16', name: 'doge_btc' },
        eth_usdt: { id: '19', name: 'eth_usdt' },
        ltc_btc: { id: '15', name: 'ltc_btc' },
        usdt_rub: { id: '21', name: 'usdt_rub' },
    };
        const [dateRange, setDateRange] = useState([null, null]); 
    const handleDateChange = (dates) => {
        setDateRange(dates);
      }; 
  useEffect(() => {
    // Ensure dateRange is a valid array with two elements
    if (Array.isArray(dateRange) && dateRange[0] && dateRange[1]) {
      const filteredOrders = orderListData.filter((order) => {
        const orderDate = new Date(parseInt(order.addtime) * 1000); 
        return orderDate >= dateRange[0] && orderDate <= dateRange[1];
      });
      setFilteredOrderListData(filteredOrders);
    } else {
      setFilteredOrderListData(orderListData); 
    }
  }, [dateRange, orderListData]);
    useEffect(() => {
        dispatch(FinanceOrdersTradeHistory(userID, userToken))
            .then((response) => {
                if (response) {
                
                    const orderList = response.data?.data?.list || [];
                    setOrderListData(orderList);
                  
                    const uniqueMarkets = [
                        "All",
                        ...new Set(
                          Object.values(marketPairs).map(market => {
                            const [base, quote] = market.name.split('_'); // Split the market name by '_'
                            return `${quote.toUpperCase()}/${base.toUpperCase()}`; // Format as QUOTE/BASE
                          })
                        )
                      ];
                     
                      setOptions1(uniqueMarkets);
                } else {
                    console.error(response);
                }
            });
    }, [dispatch, userID, userToken]);
    const handleSelectPair = (selected) => {
        if (selected === "All") {
            setFilteredOrderListData(orderListData);
        } else {
            const explodeMarketNameValue = explodeMarketName(selected);
            const filteredOrders = orderListData.filter(order => order.market === explodeMarketNameValue);
            setFilteredOrderListData(filteredOrders);
        }
        setCurrentPage(1); // Reset to the first page after filtering
    };
    
    const handleSelectType = (selected) => {
        let filteredOrders = filteredOrderListData.length > 0 ? filteredOrderListData : orderListData;
    
        if (selected === "All") {
            setFilteredOrderListData(orderListData);
        } else if (selected === "Buy") {
            filteredOrders = filteredOrders.filter(order => order.type === "1");
            setFilteredOrderListData(filteredOrders);
        } else if (selected === "Sell") {
            filteredOrders = filteredOrders.filter(order => order.type === "2");
            setFilteredOrderListData(filteredOrders);
        }
        setCurrentPage(1); // Reset to the first page after filtering
    };
    
    const explodeMarketName = (market) => {
        var splitMarket = market.split("/");
        return splitMarket[1].toLowerCase() + "_" + splitMarket[0].toLowerCase();
    };
   



    return (
        <>
            <div className="w-full flex justify-between items-center">
                <div className="flex items-center w-[83%] gap-3">
                    <GlobalStyles />
                    <StyledRangePicker
  className="px-2 w-[600px] h-[40px] bg-white sm:w-1/3 py-[0.55rem] border-l"
  onChange={handleDateChange} // Handle date change
/>
<AssetDropdown
            label="Pair"
            options={options1}
            initialSelected="All"
            onSelect={handleSelectPair}
            isSearchable={true}
          />
                    
                    <AssetDropdown
            label="Side"
            options={options3}
            initialSelected="All"
            onSelect={handleSelectType}
            isSearchable={false}
            width="!w-[200px]"
          />       </div>
            </div>

            <div className="text-xs mt-8 w-full overflow-x-auto">
                <div className="flex justify-between border-b pb-3 w-[95%] trade-table">
                    <p style={{ width: '10%' }}>Order No</p>
                    <p style={{ width: '10%' }}>Date</p>
                    <p style={{ width: '10%' }}>Pair</p>
                    <p style={{ width: '10%' }}>Side</p>
                    <p style={{ width: '10%' }}>Price</p>
                    <p style={{ width: '10%' }}>Fees</p>
                    <p style={{ width: '10%' }}>Filled</p>
                   
                    <p style={{ width: '10%' }}>Role</p>
                    <p style={{ width: '10%' }}>Total</p>
                    <p style={{ width: '10%' }}>Total in USDT</p>
                </div>

                {currentRecords.length > 0 ? (
                    currentRecords.map((order, index) => (
                        <div key={index} className="flex justify-between py-2 border-b w-[95%] trade-table">
                            <p style={{ width: '10%' }}>{order.id || '-'}</p>
                            <p style={{ width: '10%' }}>{new Date(parseInt(order.addtime) * 1000).toLocaleString() || '-'}</p>
                            <p style={{ width: '10%' }}>{order.market?.toUpperCase() || '-'}</p>
                            <p className="w-[8%] text-gray-700">{order.type === "1" ? "Buy" : "Sell"}</p>
                            <p style={{ width: '10%' }}>{parseFloat(order.price).toFixed(2) || '-'}</p>
                            <p style={{ width: '10%' }}>
  {order.type === "1" ? parseFloat(order.fee_buy).toFixed(2) || '-' : parseFloat(order.fee_sell).toFixed(2) || '-'}
</p>

                            <p style={{ width: '10%' }}>{order.fill || '-'}</p>
                            <p style={{ width: '10%' }}>
  {order.type === "1" ? "Taker" : "Maker"}
</p>

                            <p style={{ width: '10%' }}>{parseFloat(order.price * order.num).toFixed(2) || '-'}</p>
                            <p style={{ width: '10%' }}>{parseFloat(order.price * order.num).toFixed(2) || '-'}</p>
                        </div>
                    ))
                ) : (
                    <div className="w-full flex justify-center items-center h-96">
                        <div className="flex flex-col gap-1 h-44 justify-center items-center">
                            <img src={NoData} alt="No Data" />
                            <p className="text-[#707a8a] text-sm">No records</p>
                        </div>
                    </div>
                )}
            </div>

            {filteredOrderListData.length > 0 && (
    <div className="flex justify-between items-center mt-4">
        <button 
            disabled={currentPage === 1} 
            onClick={() => setCurrentPage(currentPage - 1)} 
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50"
        >
            Previous
        </button>
        <span className="text-sm">
            Page {currentPage} of {Math.ceil(filteredOrderListData.length / recordsPerPage)}
        </span>
        <button 
            disabled={currentPage === Math.ceil(filteredOrderListData.length / recordsPerPage)} 
            onClick={() => setCurrentPage(currentPage + 1)} 
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50"
        >
            Next
        </button>
    </div>
)}

        </>
    );
}

const GlobalStyles = createGlobalStyle`
  .ant-picker:hover { border-color: #EFB81C !important; }
  .ant-picker-focused { border-color: #EFB81C !important; box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important; }
  .ant-picker-active-bar { background: #EFB81C !important; }
  .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #EFB81C !important;
    color: white !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #EFB81C !important;
    border-radius: 4px;
    content: "";
  }
  .ant-picker-dropdown .ant-picker-header-view > button:hover { color: #EFB81C !important; }
`;

const StyledRangePicker = styled(RangePicker)`
  .ant-picker-input > input:focus { box-shadow: none !important; border-color: #EFB81C !important; }
`;

// Styles for equal column widths in the table
const TradeTable = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 1rem;
  text-align: center;
`;

export default TradeHistory;
