import React from 'react';

const ConvertPreviewModal = ({ showModal, handleCloseModal, selectedCoinFrom, selectedCoinTo, currencyConversionRates, getConversionRateText, inputError, balance, loading, handleSubmit }) => {
  return (
    showModal && (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md relative">
          
          {/* Header */}
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-gray-800">Confirm</h2>
            <button onClick={handleCloseModal} className="text-gray-500 hover:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* Conversion Details */}
          <div className="flex flex-col items-center my-4">
            <div className="flex items-center gap-4">
              {selectedCoinFrom?.icon && (
                <div className="flex flex-col items-center">
                  <img src={selectedCoinFrom?.icon} alt={selectedCoinFrom?.value} className="w-8 h-8" />
                  <span className="text-gray-500 text-sm mt-1">From</span>
                  <span className="text-lg font-semibold">{currencyConversionRates.amount} {currencyConversionRates.from_coin?.toUpperCase()}</span>
                </div>
              )}

              <span className="text-gray-500 text-xl font-bold">→</span>

              {selectedCoinTo?.icon && (
                <div className="flex flex-col items-center">
                  <img src={selectedCoinTo?.icon} alt={selectedCoinTo?.value} className="w-8 h-8" />
                  <span className="text-gray-500 text-sm mt-1">To</span>
                  <span className="text-lg font-semibold">{currencyConversionRates.converted_amount} {currencyConversionRates.to_coin?.toUpperCase()}</span>
                </div>
              )}
            </div>
          </div>

          {/* Rate & Payment Details */}
          <div className="p-4 bg-gray-100 rounded-md text-gray-700 text-sm">
            <p className="flex justify-between">
              <span>Rate:</span> 
              <span className="font-semibold text-center text-gray-500">{getConversionRateText()}</span>
            </p>
            <p className="flex justify-between">
              <span>Payment Method:</span> 
              <span className="font-semibold">Spot wallet + Funding wallet</span>
            </p>
            <p className="flex justify-between">
              <span>Transaction Fees:</span> 
              <span className="font-semibold">0 {currencyConversionRates.from_coin.toUpperCase()}</span>
            </p>
          </div>

          {/* Warning Message */}
          {inputError && 
          <div className="mt-4 p-3 bg-yellow-100 border border-yellow-400 text-yellow-800 rounded-md text-sm">
            <p className="text-red-500 text-xs mt-2">Your account has insufficient balance. Please <a href="/fund-account" className="text-blue-500 underline">fund your account</a>.</p> 
          </div>}

          {/* Action Buttons */}
          <div className="mt-4 flex justify-between w-full">
            {inputError || balance === 0 ? (
              <button className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 w-full text-center">
                Increase  {currencyConversionRates.from_coin?.toUpperCase()}
              </button>
            ) : (
              <button
                type="submit"
                className={`w-full px-6 py-2 rounded-lg font-medium bg-[#EFB81C] text-black shadow-md hover:bg-yellow-400 transition duration-300 ${inputError ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                disabled={inputError}
                onClick={handleSubmit}
              >
                {loading ? <Spinner size="sm" /> : 'Convert'}
              </button>
            )}
          </div>
        </div>
      </div>
    )
  );
}

export default ConvertPreviewModal;
