import React from 'react'
import HeroCard from './HeroCard'
import { BsClockFill, BsShieldLockFill } from 'react-icons/bs'
import { BiSupport } from 'react-icons/bi'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { MdChevronRight } from 'react-icons/md'
import { FaArrowRight } from 'react-icons/fa'

function HeroSection() {
    return (
        <div className='dark:bg-black min-h-screen'>
            <div className='py-16 flex flex-col lg:flex-row items-center justify-between w-full mx-auto lg:max-w-6xl'>
                <div className='w-full lg:w-1/2'>
                    <span class="text-[32px] lg:text-[56px] md:text-[32px] font-[800]">Start saving in BTC with 1 USDT</span>
                    <p className='text-[14px] lg:text-[24px] md:text-[14px] font-medium mt-2'>Smart, Simple, and Automated BTC Savings!</p>
                </div>
                <div className='w-full md:w-[70%] lg:w-[38%]'>
                    <div className='flex gap-3 items-center justify-end justify-self-end'>
                        <Link to="/user/earn/bit-saver" className="flex justify-end justify-self-end items-center mb-4 text-[#EFB81C]"><h3 className='text-sm'>Manage</h3><MdChevronRight /></Link>
                        <Link to="/earn/bit-saver/savings" className="flex justify-end justify-self-end items-center mb-4 text-[#EFB81C]">
                            <h3 className='text-sm'>View History</h3>
                            <MdChevronRight />
                        </Link>
                    </div>
                    <HeroCard />
                </div>
            </div>
        </div>
    )
}

export default HeroSection