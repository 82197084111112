import React from 'react'
import { GiProfit } from 'react-icons/gi'
import { MdRocketLaunch } from 'react-icons/md'
import { TbSettingsAutomation } from 'react-icons/tb'

function SavingGrow() {
  return (
    <div className='container min-h-screen dark:bg-black'>
        {/* <div className='max-w-xs mx-auto border dark:border-[#454545] rounded-tr-xl h-full sm:h-36 rounded-tl-xl'>
            <div className='flex flex-col'>
                <div className='h-14 flex items-center justify-center bg-gray-300 dark:bg-[#0b0c0f] rounded-tr-xl rounded-tl-xl'><h3 className='text-lg text-center'>Month</h3></div>
                <div className='flex gap-3 h-full items-center justify-between sm:px-4 px-2 py-3 sm:py-6'>
                    <div className='w-10 h-10 flex items-center justify-center bg-[#EFB81C] rounded-full'><p className='font-medium'>1</p></div>
                    <div className='w-10 h-10 flex items-center justify-center bg-[#EFB81C] rounded-full'><p className='font-medium'>2</p></div>
                    <div className='w-10 h-10 flex items-center justify-center bg-[#EFB81C] rounded-full'><p className='font-medium'>3</p></div>
                    <div className='w-10 h-10 flex items-center justify-center bg-[#EFB81C] rounded-full'><p className='font-medium'>4</p></div>
                    <div className='w-10 h-10 flex items-center justify-center bg-[#EFB81C] rounded-full'><p className='font-medium'>5</p></div>
                </div>
            </div>
        </div> */}
        <div className='px-3 py-3 sm:px-8 sm:py-8 rounded-b-2xl sm:rounded-2xl'>
            <div className='flex justify-center items-center'>
        <span className='text-2xl md:text-3xl text-center sm:text-start font-semibold text-black dark:text-white py-1'>Before you know, your <span className='text-[#EFB81C] text-xl md:text-3xl font-semibold'>savings will grow.</span></span>
        </div>
        <div className='flex flex-col md:flex-row gap-8 py-8 justify-between'>
            <div className='flex-1 hover:bg-[#f7f7f7] p-6 flex flex-col gap-2 items-center border dark:border-[#454545] rounded-xl'>
            <div className='w-10 h-10'><TbSettingsAutomation size={32}/></div>
                <p className='text-md text-center font-medium'>Automatically saves your money in <span className='text-[#EFB81C] text-[16px]'>USDT</span>, every day.</p>
            </div>
            <div className='flex-1 hover:bg-[#f7f7f7] p-6 flex flex-col gap-2 items-center border dark:border-[#454545] rounded-xl'>
            <div className='w-10 h-10'><GiProfit  size={32}/></div>
                <p className='text-md text-center font-medium'>It's <span className='text-[#EFB81C] text-[16px]'>powerfull</span>. $1 saved daily = $365 in 1 year.</p>
            </div>
            <div className='flex-1 hover:bg-[#f7f7f7] p-6 flex flex-col gap-2 items-center border dark:border-[#454545] rounded-xl'>
            <div className='w-10 h-10'><MdRocketLaunch size={32}/></div>
                <div className='flex justify-center items-center'><p className='text-md text-center font-medium'><span className='text-[#EFB81C] text-[16px]'>Start as low as $1.</span> Increase your amount anytime.</p></div>
            </div>
        </div>
        <div className='max-w-xs mx-auto text-center flex items-center flex-col'><h3 className='font-semibold'>Want to know how your gold savings will perform?</h3>
        <button className='py-2 w-2/3 text-center my-2 px-5 font-medium rounded-lg bg-[#EFB81c]'>Calculate now</button>
        </div>
        </div>
    </div>
  )
}

export default SavingGrow