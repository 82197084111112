import React from 'react';
import trustedBg from 'assets/img/trusted-crypto-bg.png';
import { IoShieldCheckmark } from 'react-icons/io5';
import { IoIosLink } from 'react-icons/io';
import { HiMiniSquare3Stack3D } from 'react-icons/hi2';
import { MdTripOrigin } from 'react-icons/md';

function TrustedExchange() {
  return (
    <div
      // style={{
      //   backgroundImage: `url(${trustedBg})`,
      //   backgroundRepeat: 'no-repeat',
      //   backgroundSize: 'cover',
      //   position: 'relative',
      // }}
      className="h-full w-full flex bg-white dark:bg-black"
    >
      {/* Black Overlay */}
      {/* <div className="absolute inset-0 bg-[#17181e] opacity-50"></div> */}

      {/* Content */}
      <div className='py-10 md:py-20 flex flex-col justify-between items-center z-30 mx-auto'>
      <h3 className="text-black dark:text-white font-extrabold capitalize text-center mx-auto max-w-lg z-10 text-2xl lg:text-5xl leading-snug">Your safe and Trusted crypto exchange</h3>
      <div className='flex flex-col lg:flex-row self-center lg:self-start items-center lg:items-start mt-5 md:mt-20 gap-5 md:gap-20 w-full max-w-6xl'>
      <div className='w-full sm:1/4 md:w-1/3 flex flex-col gap-4 items-center justify-center'>
      <div className='p-4 rounded-full bg-[#2E2F34] flex self-center'><IoShieldCheckmark size={32} fill='#EFB81C'/></div>
      <div className='flex flex-col w-[80%] sm:w-full items-center'>
      <h3 className='text-lg md:text-3xl font-extrabold text-black dark:text-white text-center capitalize'>Secure Asset Storage</h3>
      <p className='text-[#888888] pt-1 text-center capitalize'>Our industry-leading encryption and storage systems ensure that your assets are always safe and secure.</p>
      </div>
      </div>
      <div className='w-full md:w-1/3 flex flex-col gap-4 items-center justify-center'>
      <div className='p-4 rounded-full bg-[#2E2F34] flex self-center'><IoIosLink size={32} fill='#EFB81C'/></div>
      <div className='flex flex-col w-[80%] sm:w-full items-center'>
      <h3 className='text-lg md:text-3xl font-extrabold text-black dark:text-white text-center capitalize'>Strong Account Security</h3>
      <p className='text-[#888888] pt-1 text-center capitalize'>We adhere to the highest security standards and implement the strictest security practices to keep your account secure.</p>
      </div>
      </div>
      <div className='w-full md:w-1/3 flex flex-col gap-4 items-center justify-center'>
      <div className='p-4 rounded-full bg-[#2E2F34] flex self-center'><HiMiniSquare3Stack3D size={32} fill='#EFB81C'/></div>
      <div className='flex flex-col w-[80%] sm:w-full items-center'>
      <h3 className='text-lg md:text-3xl font-extrabold text-black dark:text-white text-center capitalize'>Trusted Platform</h3>
      <p className='text-[#888888] pt-1 text-center capitalize'>We have a secure-by-design foundation in place to ensure rapid detection and response to any cyber attacks.</p>
      </div>
      </div>
      <div className='w-full md:w-1/3 flex flex-col gap-4 items-center justify-center'>
      <div className='p-4 rounded-full bg-[#2E2F34] flex self-center'><MdTripOrigin size={32} fill='#EFB81C'/></div>
      <div className='flex flex-col w-[80%] sm:w-full items-center'>
      <h3 className='text-lg md:text-3xl font-extrabold text-black dark:text-white text-center capitalize'>PoR — Asset Transparency</h3>
      <p className='text-[#888888] pt-1 text-center capitalize'>PoR (Proof of Reserves) is a widely used method to prove custody of assets on the blockchain, confirming that KuCoin has the funds that cover all user assets on our books.</p>
      </div>
      </div>
      </div>
      <button className='rounded-[0.375rem] mt-8 bg-[#EFB81C] font-extrabold text-black dark:text-white px-8 py-2'>Learn More</button>
      </div>
    </div>
  );
}

export default TrustedExchange;
