import Repository from "./Repository";

const CurrentOrderEndPoint = "/issue/log";
const OrderHistoryEndPoint = "/issue/alllogs";
const LgProductEndPoint = "/issue/index";
const BuyOrderEndPoint = "/issue/upbuy";
const UnlockEndPoint = "/issue/unlock";
const CancelOrderEndPoint = "/issue/cancel";
const SubscribeLaunchguardEndPoint = "/issue/subscribeLaunchguard";
const CancelSubscriptionEndPoint = "/issue/cancelSub";
const MySubscriptionsEndPoint = "/issue/mySubs";

export default {
    currentOrder(userId, token) {
        return Repository.get(`${CurrentOrderEndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },

    orderHistory(userId, token) {
        return Repository.get(`${OrderHistoryEndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    
    lgProjects(userId, token) {
        return Repository.get(`${LgProductEndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    placeBuyOrder(userId, token, payload) {
        const formData = new FormData();
        formData.append("id", payload.id);
        formData.append("num", payload.num);
        formData.append("paypassword", payload.paypassword);
        formData.append("selected_coin", payload.selected_coin);
        return Repository.post(`${BuyOrderEndPoint}`, formData, {
            headers: {
                token: token,
                id: userId,
                "Content-Type": "multipart/form-data",  // Because the request is form-data
            },
        });
    },
    unlock(userId,id, token) {
        const formData = new FormData();
        formData.append("id", id);

        return Repository.post(`${UnlockEndPoint}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                token: token,
                id: userId,
            },
        });
    },

    cancelOrder(userId, token,id) {
        const formData = new FormData();
        formData.append("id", id);

        return Repository.post(`${CancelOrderEndPoint}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                token: token,
                id: userId,
            },
        });
    },

    subscribeLaunchguard(userId, token) {
        return Repository.get(`${SubscribeLaunchguardEndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },

    cancelSubscription(userId, token) {
       
        return Repository.get(`${CancelSubscriptionEndPoint}`, {
            headers: {
               
                token: token,
                id: userId,
            },
        });
    },
    mySubscriptions(userId, token) {
        return Repository.get(`${MySubscriptionsEndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
};
