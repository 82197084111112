import React, { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineFeaturedPlayList } from "react-icons/md";
import { Link } from "react-router-dom";

import {
    fetchCurrentOrders,
    fetchLgProjects,
} from "store/actions/LaunchGuardAction";

import HistorcialProject from "./HistorcialProject";
import UpcomingProject from "./UpcomingProject";
import LatestProject from "./LatestProject";
import Faqs from "./Faqs";

function Navbar() {
    const dispatch = useDispatch();
    const [activeComponent, setActiveComponent] = useState("latest");

    const userID = useSelector((state) => state?.authUser?.user?.ID);
    const userToken = useSelector((state) => state?.authUser?.user?.TOKEN);
    const projects = useSelector((state) => state?.lgData?.projects);

    useEffect(() => {
        dispatch(fetchLgProjects(userID, userToken));
    }, [dispatch, userID, userToken]);

    const tabs = [
        { id: "latest", label: "Latest Projects" },
        { id: "upcoming", label: "Upcoming Projects" },
        { id: "historical", label: "Historical Projects" },
    ];

    return (
        <>
         <div className="flex justify-between items-center w-full h-10 dark:bg-[#090a0c]">
    <div className="flex justify-end w-full">
        <Link to="/trade/launch-pad/my-order">
            <button className="flex gap-2 items-center text-sm font-medium bg-[#EFB81C] text-black dark:text-white px-5 py-2.5 rounded-lg shadow-md hover:bg-[#d4a118] transition duration-200">
                <MdOutlineFeaturedPlayList className="text-lg" />
                My Order
            </button>
        </Link>
    </div>
</div>


            <LatestProject latest_projects={projects?.data?.list_running} />
            <UpcomingProject upcoming_projects={projects?.data?.list_upcoming}/>
            <HistorcialProject ended_projects={projects?.data?.list_ended}/>
            <Faqs/>
        </>
    );
}

export default Navbar;
