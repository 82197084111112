import axios from "axios";
import qs from "qs";  // Ensure qs is installed via: npm install qs

const UP_TRADE_ENDPOINT = "https://api.buycex.com/Trade/upTrade";

async function upTradeData(payload, token, userId) {
    console.log("🚀 Trade Payload Sent:", payload);

    const formData = qs.stringify({
       
        market: payload.marketId,
        price: payload.price,
        num: payload.num,
        type: payload.type,
        tradeType: payload.tradeType,
    });

    try {
        const response = await axios.post(UP_TRADE_ENDPOINT, formData, {
            headers: {
                TOKEN: token,
                ID: userId,
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/json",
            },
            timeout: 10000, // 10 seconds timeout
        });

        return response; // Return only the response data
    } catch (error) {
        if (error.response) {
            console.error("❌ Server Error:", error.response.status, error.response.data);
        } else if (error.request) {
            console.error("⚠️ No Response from Server. Possible issues:");
            console.error("- API might be down");
            console.error("- Network connection issue");
            console.error("- CORS blocking the request");
        } else {
            console.error("❗ Trade Execution Error:", error.message);
        }
        throw error;
    }
}

export default upTradeData;
