import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

// Icons
import buycexOfficialLogo from "assets/img/logo/buycex-yellow.png";
import DefaultUserIcon from "assets/img/user/buycex-default-user-profile-img.png";
import VerifiedUserIcon from "assets/img/userProfileImage.png";

import "assets/css/global/Header.css";
import { useDispatch } from "react-redux";
import UserProfileMenu from "./user-menu/profile/UserProfileMenu";
import UserAssetMenu from "./user-menu/assets/UserAssetMenu";
import UserOrdersMenu from "./user-menu/orders/UserOrdersMenu";
import Identification from "./user-menu/Identification/Identification";
import Dropdownmore from "./user-menu/Dropdownmore/Dropdownmore";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { fetchMarketData } from "store/actions/marketActions"


import { GoChevronRight } from "react-icons/go";
import { CgOptions } from "react-icons/cg";
import { setSelectedMainFilter } from '../../store/actions/tradeAction';
import { IoWalletSharp } from "react-icons/io5";

import {
    BsBell,
    BsWallet2,
    BsArrowRightCircle,
    BsCaretDownFill,
    BsCloudDownloadFill,
    BsCoin,
    BsColumnsGap,
    BsCurrencyExchange,
    BsDiagram3Fill,
    BsDownload,
    BsGem,
    BsGlobe,
    BsMegaphoneFill,
    BsNutFill,
    BsPeopleFill,
    BsPercent,
    BsPersonBoundingBox,
    BsPersonFillAdd,
    BsCart2,
    BsList,
    BsPersonRaisedHand,
    BsPiggyBankFill,
    BsRocketTakeoffFill,
    BsLockFill, BsShieldFill, BsGiftFill, BsCpuFill,
    BsSearch,
    BsShop,
    BsSpeedometer,
    BsTrophy,
    BsSun,

    BsGear,
    BsTrophyFill,
    BsCashStack, BsGraphDown, BsFillPieChartFill,
    BsPeople,
    BsLaptop,
    BsGraphUp, BsGrid,
    BsCurrencyDollar, BsClipboardData, BsQuestionCircleFill
} from "react-icons/bs";
import { FaTh, FaCreditCard, FaUserCog, FaBitbucket, FaAlignRight, FaWallet, FaAngleDown } from 'react-icons/fa';
import { AiOutlineDownload, AiFillWallet, AiFillLayout, AiFillDownCircle } from 'react-icons/ai';
import { FaUserCircle } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useSelectedCoin } from "../spot-trade/context/SelectedCoinContext";
import { RiDownload2Line } from "react-icons/ri";
import { IoIosArrowDown } from "react-icons/io";
import { FiMenu, FiX } from "react-icons/fi";
import Sidebar from "./sidebar-menu/Sidebar";

const Header = () => {
    const user = useSelector((state) => state.authUser.user);
    const userLoggedIn = Boolean(user && user.ID && user.TOKEN);
    const dispatch = useDispatch();
    const history = useHistory();
    const { setSelectedCoin } = useSelectedCoin();



    // Fetch market data when the component mounts
    useEffect(() => {
        dispatch(fetchMarketData());
    }, [dispatch]);

    // Toggling Navigation Dropdown Elements




    const handleCoinSelect = (subItem) => {
        if (subItem.name) {
            // Update the selected coin
            setSelectedCoin({
                ticker: subItem.name.toLowerCase().replace("/", "_"),
                icon: subItem.icon,
                name: subItem.name,
                sell_price: subItem.sell_price || 0,
                buy_price: subItem.buy_price || 0,
                change: subItem.change || 0,
                max_price: subItem.max_price || 0,
                min_price: subItem.min_price || 0,
                volume: subItem.volume || 0,
            });

            // Only change the route if you're not on /trade/spot
            // if (history.location.pathname !== "/trade/spot") {
            //     history.push("/trade/spot");
            // }
        }
        history.push(`/trade/${selectedMainFilter.toLowerCase()}/${subItem.name}`)
    };


    // State to manage whether the search input is visible or not
    const [isSearchActive, setIsSearchActive] = useState(false);

    // Toggle search bar on click
    const handleSearchClick = () => {
        setIsSearchActive(!isSearchActive);
    };



    const [activeMenu, setActiveMenu] = useState(null);

    // Toggling User Asset Menu
    const toggleUserAssetMenu = () => {
        setActiveMenu(activeMenu === 'assets' ? null : 'assets');
    };

    // Toggling User Order Menu
    const toggleUserOrdersMenu = () => {
        setActiveMenu(activeMenu === 'orders' ? null : 'orders');
    };

    // Toggling User Profile Menu
    const toggleUserProfileMenu = () => {
        setActiveMenu(activeMenu === 'profile' ? null : 'profile');
    };

    // Toggling User Notification Menu
    const toggleUserNotification = () => {
        setActiveMenu(activeMenu === 'notification' ? null : 'notification');
    };

    const toggleDropdownMenu = (itemId) => {
        setShowDropdownMenu((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId] // Toggle on click
        }));
    };

    const openDropdownOnHover = (itemId) => {
        setShowDropdownMenu(() => ({
            [itemId]: true
        }));
    };


    const { userProfile } = useSelector((state) => state.userInfo);
    const notVerified =
        !userProfile?.idcardimg1 ||
        !userProfile?.idcardimg2 ||
        !userProfile?.idcardinfo;

    const [userLogin, setUserLogin] = useState(true);
    const [showDropdownMenu, setShowDropdownMenu] = useState({});
    const [showUserProfileMenu, setShowUserProfileMenu] = useState(false);
    const [showUserAssetMenu, setShowUserAssetMenu] = useState(false);
    const [showUserOrdersMenu, setShowUserOrdersMenu] = useState(false);
    const [showUserNotification, setShowUserNotification] = useState(false);
    const [showSpotSubMenu, setShowSpotSubMenu] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState("All");
    // const [selectedMainFilter, setSelectedMainFilter] = useState("Spot");
    const selectedMainFilter = useSelector(state => state.trade.selectedMainFilter)



    const dropdownRef = useRef([]);
    const userProfileMenuRef = useRef(null);
    const userAssetMenuRef = useRef(null);
    const userOrdersMenuRef = useRef(null);
    const userDropdownmoreRef = useRef(null);
    const userNotificationRef = useRef(null);
    const filters = ["All", "USDT", "BTC", "ETH", "CVM"];
    const mainfilters = ["Spot", "Perpetual", "Future", "Option", "Margin"]
    const [isOpenSidebar, setIsOpenSidebar] = useState(false);

    // Fetch Spot Pairs from API

    const redirectDeposit = () => {
        history.push("/crypto/deposit");
    };



    const toggleSpotSubMenu = () => {
        setShowSpotSubMenu(!showSpotSubMenu);
    };

    const marketData = useSelector((state) => state.market.data);


    // Check if marketData contains the market array and apply filtering

    const filteredSpotItems = marketData && Array.isArray(marketData.market) && marketData.market.length > 0
        ? marketData.market
            .filter((coin) => {
                if (selectedFilter === "All") return true;
                return coin.ticker?.toUpperCase().includes(selectedFilter.toUpperCase());
            })
            .map((coin) => ({
                id: coin.id,
                icon: coin.icon || '',
                name: coin.ticker,
                nameOnly: coin.name?.split("(")[0] || '',
                leverage: "10X",
                sell_price: coin.sell_price || 0, // Include sell_price
                buy_price: coin.buy_price || 0,   // Include buy_price
                change: coin.change || 0,         // Include change
                max_price: coin.max_price || 0,   // Include max_price
                min_price: coin.min_price || 0,   // Include min_price
                volume: coin.volume || 0,         // Include volume
                link: `/trade/spot`,
            }))
        : [];




    const handleClickOutside = (event) => {
        dropdownRef.current.forEach((ref, index) => {
            if (ref && !ref.contains(event.target)) {
                setShowDropdownMenu((prev) => ({
                    ...prev,
                    [index]: false,
                }));
            }
        });
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (userLoggedIn) {
            setUserLogin(true);
        } else {
            setUserLogin(false);
        }
    }, [userLoggedIn]);

    // Mega Menu Items
    const megaMenu = [
        {
            id: 1,
            name: <BsColumnsGap size={16} />,
            menuItems: [
                {
                    itemId: 1,
                    itemName: "VIP Program",
                    itemIcon: <BsGem size={16} />,
                    itemPath: "#!",
                    itemShortDesc:
                        "Exclusive benefits and rewards for our top users.",
                },
                {
                    itemId: 2,
                    itemName: "Referral Program",
                    itemIcon: <BsPersonFillAdd size={16} />,
                    itemPath: "#!",
                    itemShortDesc:
                        "Earn rewards by referring friends to our platform.",
                },
                {
                    itemId: 3,
                    itemName: "Affiliate Program",
                    itemIcon: <BsDiagram3Fill size={16} />,
                    itemPath: "#!",
                    itemShortDesc:
                        "Partner with us and earn commissions through our affiliate network.",
                },
                {
                    itemId: 4,
                    itemName: "Rewards Hub",
                    itemIcon: <BsTrophyFill size={16} />,
                    itemPath: "#!",
                    itemShortDesc:
                        "Access and manage your rewards and bonuses.",
                },
            ],
        },
    ];

    // Dropdown and Menu Items
    const dropdownMenu = [
        {
            id: 1,
            name: "BuyCrypto",
            hasDropdown: true,
            menuItem: [
                {
                    menuItemId: 1,
                    menuItemName: "Express",
                    menuItemIcon: <BsSpeedometer size={16} />,
                    menuItemPath: "/trade/express",
                    menuItemShortDesc: "Buy Crypto with one click",
                },
                {
                    menuItemId: 5,
                    menuItemName: "Transfer",
                    menuItemIcon: <BsArrowRightCircle size={16} />,
                    menuItemPath: "/crypto/transfer",
                    menuItemShortDesc: "transfer Crypto Easy & Faster",
                },
                {
                    menuItemId: 2,
                    menuItemName: "P2P Trading",
                    menuItemIcon: <BsPeopleFill size={16} />,
                    menuItemPath: "/trade/p2p",
                    menuItemShortDesc: "Buy and Sell via Peer-to-peer trading",
                },
                {
                    menuItemId: 3,
                    menuItemName: "Third Party Payment",
                    menuItemIcon: <BsPersonBoundingBox size={16} />,
                    menuItemPath: "/trade/third-party",
                    menuItemShortDesc: "Deposit via third party payments",
                },
                {
                    menuItemId: 4,
                    menuItemName: "TopUp Fiat",
                    menuItemIcon: <BsWallet2 size={16} />,
                    menuItemPath: "/fiat/deposit",
                    menuItemShortDesc: "Top up Fiat & keep Balance in Fiat Wallet",
                },

                {
                    menuItemId: 6,
                    menuItemName: "Crypto Deposit",
                    menuItemIcon: <BsCoin size={16} />,
                    menuItemPath: "/crypto/deposit",
                    menuItemShortDesc: "Deposit cryptocurrency to your account",
                },
                {
                    menuItemId: 7,
                    menuItemName: "Crypto Withdraw",
                    menuItemIcon: <BsCoin size={16} />,
                    menuItemPath: "/crypto/withdraw",
                    menuItemShortDesc: "Withdraw cryptocurrency from your account.",
                }

            ],
        },
        {
            id: 2,
            name: "Markets",
            hasDropdown: false,
            menuItemPath: "/market/overview",
        },
        {
            id: 3,
            name: "Trade",
            hasDropdown: true,
            menuItem: [
                {
                    menuItemId: 1,
                    menuItemName: "Spot",
                    menuItemIcon: <BsNutFill size={16} />,

                    menuItemPath: "/trade/spot",
                    menuItemShortDesc:
                        "Trade crypto instantly at market price.",
                },
                {
                    menuItemId: 2,
                    menuItemName: "Margin",
                    menuItemIcon: <BsPercent size={16} />,
                    menuItemPath: "/trade/margin",
                    menuItemShortDesc:
                        "Trade with leverage and increase your potential profits.",
                },
                {
                    menuItemId: 3,
                    menuItemName: "Convert",
                    menuItemIcon: <BsCurrencyExchange size={16} />,
                    menuItemPath: "/crypto/convert",
                    menuItemShortDesc:
                        "Convert between cryptocurrencies at market price.",
                },
                {
                    menuItemId: 4,
                    menuItemName: "OTC",
                    menuItemIcon: <BsPersonRaisedHand size={16} />,
                    menuItemPath: "/trade/otc",
                    menuItemShortDesc:
                        "Access Over-the-Counter trading for large volume transactions.",
                },
                {
                    menuItemId: 5,
                    menuItemName: "Launch Guard",
                    menuItemIcon: <BsCurrencyExchange size={16} />,
                    menuItemPath: "/trade/launch-guard",
                    menuItemShortDesc:
                        "Buy Pre Listed Token Without Risk. settled with usdt",
                },
                {
                    menuItemId: 5,
                    menuItemName: "Spot Trading AI",
                    menuItemIcon: <BsCurrencyExchange size={16} />,
                    menuItemPath: "/trade/spot-bots",
                    menuItemShortDesc:
                        "Trading made easy with artificial intelligence.",
                },
            ],
        },

        {
            id: 4,
            name: "Derivatives",
            hasDropdown: true,
            menuItem: [
                {
                    menuItemId: 1,
                    menuItemName: "Futures Home",
                    menuItemIcon: <BsNutFill size={16} />,
                    menuItemPath: "/futures/home",
                    menuItemShortDesc: "One-stop platform for all things Derivatives.",
                },
                {
                    menuItemId: 2,
                    menuItemName: "USDT Perpetuals",
                    menuItemIcon: <BsCurrencyExchange size={16} />,
                    menuItemPath: "/trade/perpetual/btc_usdt",
                    menuItemShortDesc: "Trade perpetual contracts, settled in USDT.",
                },

                {
                    menuItemId: 3,
                    menuItemName: "USDC Perpetuals",
                    menuItemIcon: <BsCashStack size={16} />,
                    menuItemPath: "/trade/perpetual/btc_usdc",
                    menuItemShortDesc: "Trade perpetual contracts, settled in USDC.",
                },
                {
                    menuItemId: 4,
                    menuItemName: "Coin M Perpetuals",
                    menuItemIcon: <BsLaptop size={16} />,
                    menuItemPath: "/trade/perpetual/bnb_btc",
                    menuItemShortDesc: "Trade futures contracts, settled in various types of coin.",
                },
                {
                    menuItemId: 5,
                    menuItemName: "USDT Delivery",
                    menuItemIcon: <BsLaptop size={16} />,
                    menuItemPath: "/trade/future/btc_usdt",
                    menuItemShortDesc: "Quarterly USDT-M Contracts With Expiration dates.",
                },
                {
                    menuItemId: 6,
                    menuItemName: "Coin-M Delivery",
                    menuItemIcon: <BsLaptop size={16} />,
                    menuItemPath: "/trade/future/bnb_btc",
                    menuItemShortDesc: "Quarterly Coin-M Contracts With Expiration dates.",
                },
                {
                    menuItemId: 7,
                    menuItemName: "Classic Options",
                    menuItemIcon: <CgOptions size={16} />,
                    menuItemPath: "/trade/option/bnb_eth",
                    menuItemShortDesc: "Trade options contracts, settled in USDT.",
                },
                {
                    menuItemId: 8,
                    menuItemName: "Copy Trading",
                    menuItemIcon: <BsPeople size={16} />,
                    menuItemPath: "/copy-trade",
                    menuItemShortDesc: "Automate your trading by Copying the top traders",
                },
                {
                    menuItemId: 9,
                    menuItemName: "Pre-Market Perpetual",
                    menuItemIcon: <BsLaptop size={16} />,
                    menuItemPath: "/futures/pre-market-perp",
                    menuItemShortDesc: "Buy tokens with contract before they hit the market.",
                },
                {
                    menuItemId: 10,
                    menuItemName: "Trading AI ",
                    menuItemIcon: <BsLaptop size={16} />,
                    menuItemPath: "/trade-ai",
                    menuItemShortDesc: "Trading made easy with artificial intelligence.",
                },

            ],
        },

        {
            id: 5,
            name: "Earn",
            hasDropdown: true,
            menuItem: [
                {
                    menuItemId: 1,
                    menuItemName: "Overview",
                    menuItemPath: "/earn/home",
                    menuItemShortDesc: "Get a summary of your BCX earnings and holdings.",
                    menuItemIcon: <BsGraphUp size={16} />,  // Graph icon for financial overview
                },
                {
                    menuItemId: 5,
                    menuItemName: "Bit Saver",
                    menuItemPath: "/earn/bit-saver",
                    menuItemShortDesc: "Earn rewards by staking and securing assets.",
                    menuItemIcon: <BsShieldFill size={16} />,  // Shield icon for staking security
                },
                {
                    menuItemId: 6,
                    menuItemName: "Infinity Club",
                    menuItemPath: "/earn/bcx-token",
                    menuItemShortDesc: "Claim your rewards and track your earnings in BCX.",
                    menuItemIcon: <BsGiftFill size={16} />,  // Gift icon for rewards portal
                },
                {
                    menuItemId: 8,
                    menuItemName: "Crypto Mining",
                    menuItemPath: "/crypto/mining",
                    menuItemShortDesc: "Participate in mining and earn passive income.",
                    menuItemIcon: <BsCpuFill size={16} />,  // CPU icon for mining rewards
                },
                {
                    menuItemId: 2,
                    menuItemName: "Flexible Savings",
                    menuItemPath: "/earn/home/flexible",
                    menuItemShortDesc: "Earn interest with flexible, accessible savings.",
                    menuItemIcon: <BsPiggyBankFill size={16} />,  // Piggybank icon for savings
                },
                {
                    menuItemId: 7,
                    menuItemName: "Rewards HUB",
                    menuItemPath: "/earn/reward",
                    menuItemShortDesc: "Claim your rewards and track your earnings in BCX.",
                    menuItemIcon: <BsGiftFill size={16} />,  // Gift icon for rewards portal
                },
                {
                    menuItemId: 3,
                    menuItemName: "Fixed-Term Savings",
                    menuItemPath: "/earn/home/fixed",
                    menuItemShortDesc: "Lock in your savings for higher, guaranteed interest.",
                    menuItemIcon: <BsLockFill size={16} />,  // Lock icon for fixed-term savings
                },

            ],
        },



        {
            id: 6,
            name: "Media",
            hasDropdown: false,
            menuItemPath: "/", menuItemPath: "/",

        },
        {
            id: 7,
            name: "More",
            hasDropdown: true,
            menuItem: [
                {
                    menuItemId: 1,
                    menuItemName: "Launchpool",
                    menuItemPath: "/Launchpool",
                    menuItemShortDesc: "Participate in our token sales and earn BCX tokens.",
                    menuItemIcon: <BsRocketTakeoffFill size={16} />,  // Launch icon for Launchpad
                },
                {
                    menuItemId: 2,
                    menuItemName: "Listing Voice",
                    menuItemPath: "/Vote/index-task",
                    menuItemIcon: <BsMegaphoneFill size={16} />,  // Megaphone icon for announcements or listings
                    menuItemShortDesc: "List your cryptocurrency for sale.",
                },
                {
                    menuItemId: 3,
                    menuItemName: "Marketplace",
                    menuItemPath: "/shop",
                    menuItemIcon: <BsShop size={16} />,  // Shop icon for the marketplace
                    menuItemShortDesc: "Buy and sell cryptocurrencies.",
                },
                {
                    menuItemId: 4,
                    menuItemName: "Downloads",

                    menuItemIcon: <BsCloudDownloadFill size={16} />,  // Download icon for app/browser downloads
                    menuItemPath: "/downloads",
                    menuItemShortDesc: "Download our app or browser extension for easy access.",
                },
                {
                    menuItemId: 5,
                    menuItemName: "Web3 Networks",
                    menuItemIcon: <BsGlobe size={16} />,  // Globe icon to represent networks
                    menuItemPath: "/listed-dapp",
                    menuItemShortDesc: "Explore and connect to various Web3 networks.",
                },
                {
                    menuItemId: 6,
                    menuItemName: "Listing Application",
                    menuItemIcon: <BsClipboardData size={16} />,  // Clipboard/data icon for applications
                    menuItemPath: "/apply",
                    menuItemShortDesc: "Apply to list your project on our platform.",
                },
                {
                    menuItemId: 7,
                    menuItemName: "Partners",
                    menuItemIcon: <BsPeopleFill size={16} />,  // People icon for partners/collaborations
                    menuItemPath: "/our-partners",
                    menuItemShortDesc: "Discover our partners and collaborative projects.",
                },

                {
                    menuItemId: 8,
                    menuItemName: "Help Center",
                    menuItemIcon: <BsQuestionCircleFill size={16} />,  // Help icon for assistance
                    menuItemPath: "/support",
                    menuItemShortDesc: "Find answers to common questions and get support.",
                },
            ],
        },

    ];
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

    const handleSubmenuOpen = () => setIsSubmenuOpen(true);
    const handleSubmenuClose = () => setIsSubmenuOpen(false);

    const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

    // Ensure dark mode is set on initial load
    useEffect(() => {
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
        localStorage.setItem("theme", theme);
    }, [theme]);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    };

    const toggleDropdownMenuMore = (isOpen) => {
        setActiveMenu(isOpen ? "Dropdownmore" : null);
    };

    // Dropdown and Menu Items
    return (
        <header className="buycex-master__header w-full z-[9997] text-[#151415] relative">
            <div className="flex items-center justify-between min-h-[48px] px-3 pt-1">
                {/* logo, menu, search */}
                <nav className="flex items-center gap-6 buycex-header__nav-wrapper w-max">
                    <div className="buycex-header__nav__logo-wrapper max-w-[120px]">
                        <Link to="/">
                            <img
                                src={buycexOfficialLogo}
                                alt="BUYCEX Official Logo"
                                title="BUYCEX"
                                className="w-full h-auto"
                                loading="eager"
                            />
                        </Link>
                    </div>
                    <div className="items-center hidden gap-6 buycex-header__nav__menu-wrapper md:hidden lg:flex ">
                        {/* Mega Menu Dropdown Item/Button */}
                        {megaMenu.map((item) => (
                            <div
                                key={item.id}
                                className="relative text-white buycex-header__nav-item"
                                onMouseEnter={() => openDropdownOnHover(item.id)} // Open on hover
                                onClick={() => toggleDropdownMenu(item.id)} // Toggle on click
                            >
                                <button
                                    type="button"
                                    className={`buycex-header__nav-item__dropdown-btn flex items-center gap-1 text-[14px] 
                ${showDropdownMenu[item.id] ? 'text-[#EFB81C]' : ''}`}
                                >
                                    {item.name}
                                    <IoIosArrowDown
                                        size={14}
                                        className={`text-[#6a6e73] transition-transform duration-300 
                    ${showDropdownMenu[item.id] ? 'rotate-180 text-[#EFB81C]' : 'rotate-0'}`}
                                    />
                                </button>

                                {showDropdownMenu[item.id] && (
                                    <ul
                                        ref={(el) => (dropdownRef.current[item.id] = el)}
                                        className="buycex-header__nav-item__dropdown-menu flex flex-wrap justify-between gap-5 p-3 absolute top-[30px] left-[-50%] w-[550px] bg-[#2b2d30] rounded-b-lg"
                                        onMouseLeave={() => setShowDropdownMenu({ [item.id]: false })} // Close on mouse leave
                                    >
                                        {item.menuItems.map((menuItem) => (
                                            <li key={menuItem.itemId} className="w-[48%]">
                                                <Link
                                                    to={menuItem.itemPath}
                                                    className="group text-[#151415] flex items-center px-2 py-2 hover:bg-[#383a3d] rounded-md"
                                                >
                                                    <div className="flex items-start gap-3 text-[14px]">
                                                        <span className="mt-1">{menuItem.itemIcon}</span>
                                                        <p className="flex flex-col gap-1">
                                                            <span className="font-semibold text-[14px] group-hover:text-[#EFB81C]">
                                                                {menuItem.itemName}
                                                            </span>
                                                            <span className="opacity-60 text-[12px]">
                                                                {menuItem.itemShortDesc}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}


                        {/* Dropdown Menu Items/Buttons */}
                        {dropdownMenu.map((item, index) => (
                            <div
                                key={index}
                                className="relative buycex-header__nav-item text-white"
                                onMouseEnter={() => item.hasDropdown && openDropdownOnHover(index)}
                                onClick={() => item.hasDropdown && toggleDropdownMenu(index)}

                            >
                                {item.hasDropdown ? (
                                    <>
                                        <button
                                            className={`buycex-header__nav-item__dropdown-btn flex items-center gap-1 text-[14px] 
                ${showDropdownMenu[index] ? 'text-[#EFB81C]' : ''}`}
                                        >
                                            {item.name}
                                            <IoIosArrowDown
                                                size={14}
                                                className={`text-[#6a6e73] transition-transform duration-300 
                    ${showDropdownMenu[index] ? 'rotate-180 text-[#EFB81C]' : 'rotate-0'}`}
                                            />
                                        </button>

                                        {showDropdownMenu[index] && (
                                            <ul
                                                ref={(el) => (dropdownRef.current[index] = el)}
                                                className={`buycex-header__nav-item__dropdown-menu   grid ${item.name === "Trade" || item.name === "Derivatives" ? "grid-cols-1 w-[300px]" : "grid-cols-2 w-[500px]"
                                                    } gap-x-8 gap-y-2 p-2 absolute top-[32px] left-0 bg-[#2b2d30] rounded-b-lg`}
                                                onMouseLeave={() => setShowDropdownMenu({ [item.id]: false })}
                                            >
                                                {item.menuItem.map((menuItem) => (
                                                    <li key={menuItem.menuItemId} className="relative w-full group">



                                                        {menuItem.menuItemName === "Spot" ? (
                                                            <>


                                                                <div className=" group text-[#151415] flex items-center justify-between px-2 py-2 hover:bg-[#383a3d] rounded-md cursor-pointer w-full">
                                                                    <div className="flex items-start gap-3">
                                                                        <span className="mt-1">{menuItem.menuItemIcon}</span>
                                                                        <p className="flex flex-col">
                                                                            <span className="font-semibold text-[14px] group-hover:text-[#EFB81C]">
                                                                                {menuItem.menuItemName}
                                                                            </span>
                                                                            <span className="opacity-60 text-[12px]">
                                                                                {menuItem.menuItemShortDesc}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                    <span>
                                                                        <GoChevronRight size={16} className="group-hover:text-[#EFB81C]" />
                                                                    </span>
                                                                </div>


                                                                <div
                                                                    className="absolute bg-transparent left-full top-[-10px] z-50 right-0 h-[475px] w-[14px] cursor-default"
                                                                    ref={(el) => (dropdownRef.current[index] = el)}
                                                                    onMouseEnter={() => item.hasDropdown && openDropdownOnHover(index)}
                                                                    onClick={() => item.hasDropdown && toggleDropdownMenu(index)}
                                                                ></div>

                                                                {/* Spot Submenu */}
                                                                <div className="absolute custom-scrollbar overflow-y-auto max-h-[475px] hidden group-hover:block left-[100%] top-[-8px] ml-2.5 w-[320px] bg-opacity-[0.99] bg-[#2b2d30] px- rounded-b-md z-10" ref={(el) => (dropdownRef.current[index] = el)}
                                                                    onMouseEnter={() => item.hasDropdown && openDropdownOnHover(index)}
                                                                    onClick={() => item.hasDropdown && toggleDropdownMenu(index)}
                                                                >
                                                                    <ul>

                                                                        {/* morefilter tops  */}
                                                                        <li className="flex justify-around border-b border-gray-600 gap-1 px-4 pt-1 text-sm">
                                                                            {mainfilters.map((filter) => (
                                                                                <button
                                                                                    key={filter}
                                                                                    className={`px-1 py-1 ${selectedMainFilter === filter
                                                                                        ? "font-bold text-sm text-[#EFB81C] border-b py-2 border-[#EFB81C]"
                                                                                        : "text-sm text-gray-400 font-bold"
                                                                                        } `}
                                                                                    onClick={() => dispatch(setSelectedMainFilter(filter))}
                                                                                >
                                                                                    {filter}
                                                                                </button>
                                                                            ))}
                                                                        </li>
                                                                        {/* Filter Tabs */}
                                                                        {/* <li className="flex justify-around gap-2 py-2 text-xs">
                                                                            {filters.map((filter) => (
                                                                                <button
                                                                                    key={filter}
                                                                                    className={`px-2 py-1 ${selectedFilter === filter
                                                                                        ? "font-bold text-[10px] text-[#EFB81C]"
                                                                                        : "text-xs"
                                                                                        } hover:bg-[#383a3d] transition-all duration-200 rounded`}
                                                                                    onClick={() => setSelectedFilter(filter)}
                                                                                >
                                                                                    {filter}
                                                                                </button>
                                                                            ))}
                                                                        </li> */}

                                                                        {/* Filtered Spot Items */}
                                                                        {filteredSpotItems.map((subItem) => (
                                                                            <li
                                                                                key={subItem.id || subItem.name}
                                                                                className="group flex justify-between items-center p-3 rounded-md hover:bg-[#383a3d] transition-all duration-200 cursor-pointer"
                                                                                onClick={() => handleCoinSelect(subItem)}
                                                                            >
                                                                                <div className="flex px-2 items-center gap-3">
                                                                                    <img
                                                                                        src={subItem.icon}
                                                                                        alt={subItem.name}
                                                                                        className="w-5 h-5 rounded-full"
                                                                                    />
                                                                                    <div className="flex flex-col">
                                                                                        <span className="text-white text-sm flex items-baseline gap-1">
                                                                                            <span className="text-sm font-medium">{subItem.name.split('_')[0].toUpperCase()}</span>
                                                                                            <span className="text-xs text-gray-400 font-normal opacity-40">/</span>
                                                                                            <span className="text-xs text-gray-400 font-normal opacity-40">{subItem.name.split('_')[1].toUpperCase()}</span>
                                                                                        </span>
                                                                                        <span className="text-xs text-gray-500">
                                                                                            {subItem.fullName || " "} {/* Subtitle text, e.g., "Litecoin" */}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex items-center pr-2 gap-2">
                                                                                    <span className="text-[#EFB81C] font-bold text-xs">
                                                                                        {subItem.leverage || "10X"}
                                                                                    </span>
                                                                                    <span className="text-[#EFB81C] opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                                                                        ➔
                                                                                    </span>
                                                                                </div>
                                                                            </li>
                                                                        ))}


                                                                    </ul>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <Link
                                                                to={menuItem.menuItemPath}
                                                                className="group text-[#151415] flex items-center px-2 py-2 hover:bg-[#383a3d] rounded-md"
                                                            >

                                                                <div className="flex items-start gap-3 text-[14px]">
                                                                    {/* Icon */}
                                                                    <span className="mt-1">{menuItem.menuItemIcon}</span>
                                                                    {/* Text */}
                                                                    <p className="flex flex-col">
                                                                        <span className="font-semibold text-[14px] group-hover:text-[#EFB81C]">
                                                                            {menuItem.menuItemName}
                                                                        </span>
                                                                        <span className="opacity-60 text-[12px]">
                                                                            {menuItem.menuItemShortDesc}
                                                                        </span>
                                                                    </p>
                                                                </div>

                                                            </Link>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>

                                        )}
                                    </>
                                ) : (
                                    <Link
                                        to={item.menuItemPath}
                                        className="hover:text-[#EFB81C] flex items-center gap-1 w-max"
                                    >
                                        {item.name}
                                    </Link>
                                )}
                            </div>
                        ))}

                    </div>

                    {/* search input field */}

                </nav>
                {/* login Register button and profile and notification */}
                <div className="flex items-center justify-end gap-1 md:gap-4  mb-1 ml-auto buycex-header__user-menu-wrapper w-max">




                    {userLogin ? (
                        <>
                            <div className="flex items-center gap-3">
                                {/* Search Icon */}
                                <div
                                    className="cursor-pointer buycex-header__nav__search-icon"
                                    onClick={handleSearchClick}
                                >
                                    <BsSearch size={20} className="text-white hidden md:block" />
                                </div>

                                {/* Search Bar */}
                                {isSearchActive && (
                                    <div className="buycex-header__nav__search-bar bg-[white] flex items-center overflow-hidden pl-2.5 transition-all ease-in-out duration-300">

                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="text-[#151415] py-2 px-2 pr-3 flex items-center outline-none placeholder:text-[12px] text-[13px]"
                                        />
                                    </div>
                                )}
                            </div>

                            <button onClick={redirectDeposit} className="bg-[#EFB81C] text-[14px] font-semibold px-3 py-1.5 rounded-md flex gap-1 items-center">
                                {/* Download Icon */}
                                <RiDownload2Line />

                                {/* Button Text */}
                                Deposit
                            </button>



                            {/* User Asset Menu */}
                            <div
                                className="relative user-menu__assets"


                            >




                                <button
                                    type="button"
                                    onMouseEnter={() => toggleUserAssetMenu(true)} // Show menu on button hover
                                    ref={userAssetMenuRef}
                                    className="text-[14px] flex items-center ml-3 md:ml-0 gap-1"
                                >
                                    {/* Wallet Icon on the left */}
                                    {/* Down Arrow Icon on the right */}
                                    <span>
                                        <FaUserCircle size={20} className="text-white" />
                                    </span>
                                </button>
                                {activeMenu === 'assets' && (
                                    <div
                                        // Keep menu open when hovering over the menu itself
                                        onMouseLeave={() => toggleUserAssetMenu(false)} // Hide menu when mouse leaves the menu
                                    >
                                        <UserAssetMenu />
                                    </div>
                                )}
                            </div>
                            {/* User Orders Menu */}
                            {/*  */}


                            {/* User Notifications */}
                            <div className="relative user-menu__notification-dropdown">
                                <button
                                    type="button"
                                    className="hidden md:block"
                                    onMouseEnter={() => toggleUserNotification(true)}
                                    ref={userNotificationRef}
                                >
                                    <span>
                                        <FaUserCog size={20} className="text-white mt-" />
                                    </span>
                                </button>


                                {activeMenu === 'notification' && (
                                    <div
                                        // Keep menu open when hovering over the menu itself
                                        onMouseLeave={() => toggleUserNotification(false)} // Hide menu when mouse leaves the menu
                                    >
                                        <Identification />
                                    </div>
                                )}

                            </div>
                        </>
                    ) : (

                        <div className="flex items-center gap-3">
                            <div className="buycex-header__nav__search-bar bg-[white] hidden md:hidden lg:flex items-center overflow-hidden pl-2.5">
                                <BsSearch size={15} fill="#151415" />
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="text-[#151415] py-2 px-2 pr-3 flex items-center outline-none placeholder:text-[12px] text-[13px]"
                                />
                            </div>
                            <Link
                                className="flex items-center py-1 px-2 rounded-md text-white text-[14px] font-semibold no-underline hover:no-underline"
                                to="/login"
                            >
                                Log in
                            </Link>
                            <Link
                                className="flex items-center py-1 px-2 rounded-md text-[14px] font-semibold no-underline hover:no-underline bg-[#EFB81C]"
                                to="/register"
                            >
                                Register
                            </Link>

                        </div>
                    )}


                    {/* Dropdown button */}

                    <div className="relative user-menu__DropdownMenu-dropdown">
                        <button
                            type="button"

                            onMouseEnter={() => toggleDropdownMenuMore(true)}
                            ref={userDropdownmoreRef}
                        >
                            <span>
                                <AiFillDownCircle className="mt-[0.5rem] text-white" size={22} />
                            </span>
                        </button>


                        {activeMenu === "Dropdownmore" && (
                <div onMouseLeave={() => toggleDropdownMenuMore(false)}>
                    <Dropdownmore theme={theme} toggleTheme={toggleTheme} />
                </div>
            )}

                    </div>
                    <div className="relative">
                        {/* Menu Icon */}
                        <button className="text-2xl p-2 lg:hidden" onClick={() => setIsOpenSidebar(true)}>
                            <FiMenu className="text-white"/>
                        </button>

                        {/* Sidebar Component */}
                        <Sidebar userLoggedIn={userLoggedIn} megaMenu={megaMenu} dropdownMenu={dropdownMenu} isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar} />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
