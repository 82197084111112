import React, { useState } from 'react';
import { SiBitcoinsv } from 'react-icons/si';

const historyData = [
    {
        id: 1,
        icon: <SiBitcoinsv fill='#f89b2b' size={36} />,
        title: 'Daily Savings',
        amount: '0.00001420 BTC',
        price: '$1',
        status: 'Success',
        statusColor: 'text-green-500',
        date: "15 Mar '25 | 03:30pm",
    },
    {
        id: 2,
        icon: <SiBitcoinsv fill='#f89b2b' size={36} />,
        title: 'Daily Savings',
        amount: '0.00001320 BTC',
        price: '$1',
        status: 'Success',
        statusColor: 'text-green-500',
        date: "14 Mar '25 | 03:30pm",
    },
    {
        id: 3,
        icon: <SiBitcoinsv fill='#f89b2b' size={36} />,
        title: 'Daily Savings',
        amount: '0.00001220 BTC',
        price: '$1',
        status: 'Success',
        statusColor: 'text-green-500',
        date: "13 Mar '25 | 03:30pm",
    },
];

function History() {
    const [selectedItem, setSelectedItem] = useState(null);

    return (
        <div className='border dark:border-[#454545] rounded-xl flex flex-col lg:flex-row'>
            {/* Sidebar */}
            <div className='border-0 lg:border-r dark:border-[#454545] w-full lg:w-1/4 py-4'>
                <h3 className='font-semibold text-lg mb-4 px-4'>History</h3>
                {historyData.map((item) => (
                    <div 
                        key={item.id} 
                        onClick={() => setSelectedItem(item)} 
                        className={`flex items-center gap-3 py-4 px-4 cursor-pointer 
                            ${selectedItem?.id === item.id ? 'bg-gray-200 dark:bg-[#0b0c0f]' : ''} 
                            ${item.id !== historyData.length ? 'border-b dark:border-[#454545]' : ''}`
                        }
                    >
                        {item.icon}
                        <div className='w-full'>
                            <div className='flex justify-between w-full'>
                                <div className='flex flex-col'>
                                    <h3 className='font-semibold'>{item.title}</h3>
                                    <span className='text-gray-600'>{item.amount}</span>
                                </div>
                                <span className='text-sm font-medium mt-0.5'>{item.price}</span>
                            </div>
                            <div className='flex items-center justify-between mt-2'>
                                <p className={`text-sm font-medium ${item.statusColor}`}>
                                    {item.status}
                                </p>
                                <p className='text-xs text-gray-600'>{item.date}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Right-side detail view */}
            <div className='w-full lg:w-3/4 p-6'>
                {selectedItem ? (
                    <div className="bg-gray-100 dark:bg-[#0b0c0f] p-6 rounded-lg">
                        <h3 className='font-semibold text-xl mb-4'>{selectedItem.title}</h3>
                        <div className='flex items-center gap-3'>
                            {selectedItem.icon}
                            <div className='flex flex-col'>
                                <span className='text-gray-600 text-lg'>{selectedItem.amount}</span>
                                <span className='text-sm font-medium'>{selectedItem.price}</span>
                            </div>
                        </div>
                        <p className={`mt-4 text-lg font-semibold ${selectedItem.statusColor}`}>
                            {selectedItem.status}
                        </p>
                        <p className='text-sm text-gray-600 mt-2'>{selectedItem.date}</p>
                    </div>
                ) : (
                    <p className='text-gray-500'>Click on a history item to see details</p>
                )}
            </div>
        </div>
    );
}

export default History;
