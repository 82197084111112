import React from "react";
import { Select, Input } from "antd";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    font-size: 18px;
    font-weight: 800;
    color: black; /* Default for light mode */
  }

  /* Dark mode */
  .dark & .ant-select-selection-item {
    color: white;
  }

  .ant-select-arrow {
    color: black; /* Light mode arrow color */
  }

  .dark & .ant-select-arrow {
    color: white; /* Dark mode arrow color */
  }
`;

const BuyComponent = ({
    p2pfiatCurrencies,
    amount,
    handleAmountChange,
    handleCoinChange,
    buyCoins,
    isBuyMode
}) => {
    return (
        <>
            <div className="border dark:border-[#454545] flex justify-between gap-2 w-full rounded-xl p-2">
                <div className="w-[65%] md:w-[65%] lg:w-[65%]">
                    <h3 className="pl-2 text-sm">{isBuyMode ? "You'll pay" : "You'll sell"}</h3>
                    <Input
                        className="focus:outline-none placeholder:text-[#b3b3b3] text-[20px] md:text-[24px] lg:text-[24px] focus:border-transparent focus:ring-0 focus:ring-transparent"
                        placeholder="0.00"
                        style={{
                            fontWeight: "600",
                            padding: "0px 11px",
                            backgroundColor: "transparent",
                        }}
                        variant="Enter Amount"
                        value={amount}
                        onChange={handleAmountChange}
                    />
                </div>
                <StyledSelect
                    className="bg-[#f7f7f7] dark:bg-[#151415] dark:text-white"
                    value={buyCoins}
                    style={{
                        borderRadius: "10px",
                        width: "35%",
                        minWidth:"120px",
                        height: "auto",
                    }}
                    variant="borderless"
                    onChange={handleCoinChange}
                    options={p2pfiatCurrencies?.map((currency) => {
                        return {
                            value: currency?.name,
                            label: (
                                <div className="flex items-center !text-black !dark:text-white">
                                    <img
                                        src={`https://api.buycex.com/Upload/coin/${currency?.img}`}
                                        alt="BTC"
                                        className="w-6 h-6 mr-2"
                                    />
                                    {currency?.title}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
        </>
    );
};
export default BuyComponent;
