import React, { useState, useEffect } from "react";
import NoData from "assets/img/user/No-data.svg";
import { useSelector, useDispatch } from "react-redux";
import { PollRewards } from "../../../../../store/actions/AssetsUserAction";

function ClaimHistoryTable() {
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.ID);
    const dispatch = useDispatch();
    const [pollReward, setPollReward] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(PollRewards(userID, userToken));
                setPollReward(response?.data?.data || []);
            } catch (error) {
                console.error("Error fetching LaunchPool:", error);
            }
        };

        fetchData();
    }, [dispatch, userID, userToken]);

    return (
        <div className="text-xs mt-8 text-[#707a8a] w-full overflow-x-auto">
            <div className="flex justify-between border-b pb-3 w-full">
                <p className="flex-1 text-start">Time</p>
                <p className="flex-1 text-start">CryptoCurrency</p>
                <p className="flex-1 text-start">Type</p>
                <p className="flex-1 text-center">Reward</p>
            </div>

            {pollReward.length > 0 ? (
                pollReward.map((item, index) => {
                    const userReward = item.user_reward_data
                        ? JSON.parse(item.user_reward_data)
                        : { type: "", value: "", description: "" };

                    return (
                        <div
                            key={index}
                            className="flex justify-between border-b py-2 w-full"
                        >
                            <p className="flex-1 text-start">
                                {item.updated_at}
                            </p>
                            <p className="flex-1 text-start">
                                {item.project_name}
                            </p>
                            <p className="flex-1 text-start">
                                {userReward.type}
                            </p>
                            <p className="flex-1 text-center">
                                {userReward.value}
                            </p>
                        </div>
                    );
                })
            ) : (
                <div className="w-full flex justify-center items-center h-96">
                    <div className="flex flex-col gap-1 h-44 justify-center items-center">
                        <img src={NoData} alt="No Data" />
                        <p className="text-[#707a8a] text-sm">No records</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ClaimHistoryTable;
