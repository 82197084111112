import React, { useEffect, useState } from "react";
import StakeEarn from "./StakeEarn";
import PastPerformance from "./PastPerformance";
const heroImages = [HeroImageOne, HeroImageTwo, HeroImageThird];
import HeroImageOne from "assets/img/Aboutsection4.png";
import HeroImageTwo from "assets/img/algo-orders.png";
import HeroImageThird from "assets/img/Aboutsection3.png";
import { FaArrowRightLong } from "react-icons/fa6";
import NewEvents from "./NewEvents";
import PreviousLaunches from "./PreviousLaunches";
import Faqs from "./Faqs";
import { useSelector, useDispatch } from "react-redux";
import { LaunchPool } from "../../../store/actions/AssetsUserAction";

function LaunchPools() {
    const flippingWords = [
        "Deposit USDT To Lock Token Before its Officially Listed in Exchange.",
        "Unlock USDT With Guarded Refund Any Time Without Assets Loss.",
    ];
    const [currentWord, setCurrentWord] = useState("Exchange Crypto");
    const [flipState, setFlipState] = useState("flip-enter");
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageTransitionState, setImageTransitionState] = useState("fade-in");
    const user = useSelector((state) => state.authUser.user);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const dispatch = useDispatch();
    const [launchPoolData, setLaunchPoolData] = useState(null);

    useEffect(() => {
        const wordInterval = setInterval(() => {
            setFlipState("flip-exit");

            setTimeout(() => {
                setCurrentWord((prev) => {
                    const nextIndex =
                        (flippingWords.indexOf(prev) + 1) %
                        flippingWords.length;
                    return flippingWords[nextIndex];
                });
                setFlipState("flip-enter");
            }, 600);
        }, 3000);

        return () => clearInterval(wordInterval);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(LaunchPool(userID, userToken));
                setLaunchPoolData(response.data.data); // Store response in state
            } catch (error) {
                console.error("Error fetching LaunchPool:", error);
            }
        };

        fetchData();
    }, [dispatch, userID, userToken]);

    useEffect(() => {
        console.log("Updated launchPoolData:", launchPoolData);
    }, [launchPoolData]);

    useEffect(() => {
        const imageInterval = setInterval(() => {
            setImageTransitionState("fade-out");

            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => {
                    return (prevIndex + 1) % heroImages.length;
                });
                setImageTransitionState("fade-in");
            }, 600);
        }, 3000);

        return () => clearInterval(imageInterval);
    }, []);

    return (
        <>
            <section className="hero-section-black flex relative">
                <div className="hero-section__wrapper-black container flex items-center justify-between gap-8 w-full m-auto max-md:flex-col">
                    <div className="hero-title-wrapper-black flex flex-col gap-2 w-full lg:w-[65%]">
                        <h1 className="hero-title-black flex gap-2">
                            <span className="text-xl sm:text-[32px] font-[900] text-[#EFB81C]">
                                Launchpool
                            </span>
                        </h1>
                        <p className="text-md font-medium text-[#adb1b8] capitalize">
                            Earn new tokens by staking crypto on BuyCex
                        </p>
                        <div className="flex flex-wrap gap-3 md:gap-0 w-full lg:w-[65%] py-4">
                            <div className="flex min-w-[180px] flex-col flex-1 gap-0.5 relative items-start pr-3">
                                <label className="text-[#adb1b8] text-sm text-ellipsis whitespace-nowrap">
                                    Total Prize Pool (in USD)
                                </label>
                                <p className="text-white font-bold text-[20px]">
                                    $
                                    {String(
                                        launchPoolData?.total_prize_pool_usd ||
                                            ""
                                    ).slice(0, 5)}
                                </p>
                                <div className="absolute hidden md:block right-0 top-1/2 h-1/2 w-[1px] bg-gray-500 -translate-y-1/2"></div>
                            </div>
                            <div className="flex">
                            <div className="flex min-w-[140px] sm:min-w-[180px] flex-col flex-1 gap-0.5 relative items-start ml:0 sm:ml-10">
                                <label className="text-[#adb1b8] text-sm text-ellipsis whitespace-nowrap">
                                    Total Participants
                                </label>
                                <p className="text-white font-bold text-[20px]">
                                    {launchPoolData?.users}{" "}
                                </p>
                                <div className="absolute hidden md:block right-0 top-1/2 h-1/2 w-[1px] bg-gray-500 -translate-y-1/2"></div>
                            </div>
                            <div className="flex min-w-[140px] sm:min-w-[180px] flex-col flex-1 gap-0.5 items-start ml-0 md:ml-10">
                                <label className="text-[#adb1b8] text-sm">
                                    Total Projects
                                </label>
                                <p className="text-white font-bold text-[20px]">
                                    {launchPoolData?.projects}{" "}
                                </p>
                            </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap gap-5">
                            <div className="flex items-center gap-1">
                                <span className="text-[#EFB81C]">
                                    Staking Records
                                </span>
                                <FaArrowRightLong size={12} fill="#EFB81C" />
                            </div>
                            <div className="flex items-center gap-1">
                                <span className="text-[#EFB81C]">
                                    Referral History & Rules
                                </span>
                                <FaArrowRightLong size={12} fill="#EFB81C" />
                            </div>
                        </div>
                    </div>
                    {/* Right Section: Hero Image */}
                    <div className="hidden lg:flex flex-col items-center w-[35%]">
                        <div className="hero-image-wrapper-black relative w-full h-[10rem] rounded-xl overflow-hidden">
                            {heroImages.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`hero-img-${index}`}
                                    className={`hero-image-black absolute top-0 left-0 w-full rounded-xl object-contain transition-opacity duration-700 ${imageTransitionState} ${
                                        index === currentImageIndex
                                            ? "active"
                                            : "inactive"
                                    }`}
                                />
                            ))}
                        </div>
                        <button className="font-semibold text-sm border-2 rounded-full border-[#EFB81C] text-[#EFB81C] px-4 py-1">
                            Become a Member Now!
                        </button>
                    </div>
                </div>
            </section>

            {/* <StakeEarn /> */}
            <NewEvents login={user} />
            <PreviousLaunches />
            {/* <PastPerformance /> */}
            <Faqs />
        </>
    );
}

export default LaunchPools;
