import React, { useEffect, useState } from 'react';
import EstimatedCopyTrading from './EstimatedCopyTrading';
import CopyTradingTable from './CopyTradingTable';

function CopyTrading() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(prev => !prev);
  };

  useEffect(() => {
    const storedVisibility = localStorage.getItem('balanceVisibility');
    if (storedVisibility) {
        setIsVisible(JSON.parse(storedVisibility));
    }
}, []);

  useEffect(() => {
      localStorage.setItem('balanceVisibility', JSON.stringify(isVisible));
  }, [isVisible]);

  return (
    <div className='px-0 lg:px-3'>
      <div className='max-w-[1400px] mx-auto !p-0'>
        <EstimatedCopyTrading isVisible={isVisible} toggleVisibility={toggleVisibility}/>
        <CopyTradingTable isVisible={isVisible}/>
      </div>
    </div>
  );
}

export default CopyTrading;
