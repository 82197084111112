import React, { useState, useEffect, useRef } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const SelectChain = ({
  options = [],
  selectedItem,
  setSelectedItem,
  placeholder = "Select a chain",
  loading = false,
  handleItemClick = () => {},
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option);
    setIsDropdownOpen(false);
    setSearchQuery(""); // Reset search
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex items-center justify-between border border-black hover:ring-1 ring-[#EFB81C] hover:border-[#EFB81C] rounded-lg px-4 py-4 cursor-pointer bg-white dark:bg-gray-800 dark:text-white"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div className="flex items-center">
          {selectedItem?.icon && (
            <img
              src={selectedItem.icon}
              alt={`${selectedItem.name}-icon`}
              className="w-6 h-6 mr-2"
            />
          )}
          <span className="text-sm">{selectedItem?.label || placeholder}</span>
        </div>
        <span className="text-sm">
          {isDropdownOpen ? <FaAngleUp /> : <FaAngleDown />}
        </span>
      </div>

      {isDropdownOpen && (
        <div className="absolute mt-2 w-full bg-white border rounded shadow-lg dark:bg-gray-800 dark:text-white z-10">
          <div className="p-2">
            <input
              type="text"
              placeholder="Search..."
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#EFB81C] dark:bg-gray-700 dark:text-white"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <ul className="max-h-64 overflow-auto">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li
                  key={option.value}
                  className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                  onClick={() => {
                    handleSelect(option);
                    handleItemClick(option);
                  }}
                >
                  {option.icon && (
                    <img
                      src={option.icon}
                      alt={`${option.label}-icon`}
                      className="w-6 h-6 mr-2"
                    />
                  )}
                  <span className="text-sm">{option.label}</span>
                </li>
              ))
            ) : (
              <li className="px-4 py-2 text-center text-gray-500 dark:text-gray-400">
                No results found
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectChain;
