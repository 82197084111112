import React, { useState, useRef, useEffect } from "react";
import { RiBitCoinFill, RiMoneyDollarCircleFill } from "react-icons/ri";
import { Line } from "react-chartjs-2";
import {
    Chart,
    LinearScale,
    CategoryScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from "chart.js";
import { FaAngleRight } from "react-icons/fa";
import TradeNowPopup from "./TradeNowPopup";
import { Flex, Progress } from 'antd';
import { TfiAnnouncement } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { subscribeLaunchguard,cancelSubscription ,fetchSubscriptionStatus  } from "store/actions/LaunchGuardAction";

const twoColors = {
    '0%': '#108ee9',
    '100%': '#87d068',
};
const conicColors = {
    '0%': '#f7f7f7',
    '50%': '#ffe58f',
    '100%': '#EFB81C',
};

Chart.register(
    LinearScale,
    CategoryScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);
import { useHistory } from "react-router-dom";



  
function LatestProject({ latest_projects }) {

    
   
    const history = useHistory();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false);
    const textRef = useRef(null);
    const [isPopupActive, setIsPopupActive] = useState(false);
    const [tradeValue, setTradeValue] = useState(null);
    const [countdowns, setCountdowns] = useState({});
      const userId = useSelector((state) => state?.authUser?.user?.ID);
        const token = useSelector((state) => state?.authUser?.user?.TOKEN);
        


        const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
   
    const isSubscribed = useSelector((state) => state?.lgData?.subscriptionStatus?.isSubscribed);
  
    
    useEffect(() => {
        if (userId && token) {
            dispatch(fetchSubscriptionStatus(userId, token));
        }
    }, [userId, token, dispatch]);
    useEffect(() => {
        console.log("Updated Subscription Status:", isSubscribed);
    }, [isSubscribed])
    const handleSubscribe = () => {
        if (!userId || !token ) {
            console.error("Missing required details");
            return;
        }

        setLoading(true);

        if (isSubscribed) {
            dispatch(cancelSubscription(userId, token))
                .then(() => dispatch(fetchSubscriptionStatus(userId, token))) // Refresh status
                .finally(() => setLoading(false));
        } else {
            dispatch(subscribeLaunchguard(userId, token))
                .then(() => dispatch(fetchSubscriptionStatus(userId, token))) // Refresh status
                .finally(() => setLoading(false));
        }
    };

    const showModal = (item) => {
        setIsPopupActive(true);
        setTradeValue({ ...item, icon: <RiBitCoinFill size={18} /> });
    };

    const handleCancel = () => {
        setIsPopupActive(false);
    };

    const handleOk = () => {
        setIsPopupActive(false);
    };

    useEffect(() => {
        if (textRef.current) {
            setIsTruncated(
                textRef.current.scrollHeight > textRef.current.clientHeight
            );
        }
    }, [isExpanded]);
    const sectionsData = Array.isArray(latest_projects)
    ? latest_projects?.map((item) => ({
        icon:   (
            <img
              src={`https://global.buycex.com/Upload/issue/${item.image}`}
              alt={item.name}
              className="w-10 h-10 rounded-full object-cover"
            />
          ),// Assuming a default icon for all items
          iconlink:`https://global.buycex.com/Upload/issue/${item.image}`,
        title: item.name,
        subtitle: item.coinname,
        description: item.content || "No description available.",
        id:item.id,
        stats: {
            twitter:item.twitter,
            telegram:item.telegram,
            facebook:item.facebook,
            volume: item.num,
            available:item.zhuangtai,
            turnover: item.deal,
            high: item.max,
            price:item.price,
            limit:item.limit,
            endTime:item.endTime,
            beginTime:item.beginTime,
            exchange_listing_date: item.exchange_listing_date ? item.exchange_listing_date : "Announced Soon",
            convertcurrency:item.convertcurrency,
            subscribed: item.deal,
            low: item.min,
            graphData: [parseFloat(item.price), parseFloat(item.min)], // Example graph data
            graphColor: {
                border: "#EF4444", // Default color
                background: "rgba(239, 68, 68, 0.2)", // Default color
            },
            change: item.commission,
            changeColor: "#EF4444", // Default color
        },
    }))
    : [];
    const handleMoreClick = () => {
        setIsExpanded(!isExpanded);
    };

    const calculateCountdown = (dateString) => {
        const targetDate = new Date(dateString);
        const now = new Date();
        const difference = targetDate - now;

        if (difference <= 0) {
            return "00d 00h 00m 00s"; // Expired
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    // Effect to update countdowns every second
    useEffect(() => {
        const interval = setInterval(() => {
            setCountdowns((prevCountdowns) => {
                const newCountdowns = { ...prevCountdowns };
                sectionsData.forEach((section, index) => {
                    newCountdowns[index] = calculateCountdown(section.stats.endTime);
                });
                return newCountdowns;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);


  
   
  

   

    const handleClick = (section) => {
        const sanitizedSection = {
            ...section,
            icon: null, // Remove icon if not needed
        };
        localStorage.setItem("selectedSection", JSON.stringify(sanitizedSection));
        console.log("sanitizedSection",sanitizedSection)
        history.push(`/trade/launch-guard/${encodeURIComponent(section.title)}`, { state: { section: sanitizedSection } });
    };
   
    

    return (
        <div className="bg-[#F5F7FA] dark:bg-black">
            <div className="container">
                <div className="max-w-[1400px] w-full mx-auto">
                    <p className="text-lg sm:text-[24px] dark:text-white font-semibold pt-8 pb-4 px-[16px] sm:px-0">Stake and Earn Tokens for Free</p>
                    <div className="bg-white dark:bg-[#090a0c] rounded-lg p-3 max-w-[1400px] px-0 sm:px-[32px] mx-auto flex flex-col lg:flex-row gap-4 justify-between w-full">
                        <div className="flex items-center gap-4 container">
                            <TfiAnnouncement className="text-black min-w-[24px] min-h-[24px] dark:text-white" />
                            <label className="font-medium lg:font-semibold text-xs sm:text-[16px]">You‘ve subscribed to receive notifications for all upcoming LaunchGuard events!</label>
                        </div>
                        <button
    className={`font-semibold text-sm px-4 py-2 rounded-lg transition duration-200 ${
        isSubscribed === null 
            ? "bg-gray-400 text-white cursor-not-allowed" // Disabled style
            : isSubscribed 
                ? "bg-red-500 text-white" 
                : "bg-[#EFB81C] text-black"
    } ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
    onClick={handleSubscribe}
    disabled={loading || isSubscribed === null} // Disable if still fetching
>
    {loading || isSubscribed === null 
        ? "Processing..." 
        : isSubscribed 
            ? "Unsubscribe" 
            : "Subscribe"}
</button>

                    </div>
                </div>
                <p className="text-lg sm:text-[24px] dark:text-white font-semibold pt-8 pb-4 px-[16px] sm:px-0">New Events</p>
                {sectionsData.map((section, index) => (
                  <div key={index} className="pb-6">
                      
                        <div key={index} className="bg-white dark:bg-[#090a0c] rounded-2xl px-6 py-4">
                            <div className="flex w-full justify-between">
                                <div className="text-[#22c55e] font-medium text-xs bg-[#22c55e] bg-opacity-10 px-2 py-1 flex rounded-md justify-self-start">
                                    In Progress
                                </div>
                                <button
                                    onClick={(event) => {
                                        event.preventDefault(); // Prevent Link navigation
                                        showModal(section); 
                                    }}
                                    className="bg-[#EFB81C] invisible px-3 py-0.5 rounded-md text-sm text-black font-medium"
                                >
                                    Buy now
                                </button>
                            </div>
                            <div className="flex flex-col lg:flex-row justify-between">
                            <div className="w-full lg:w-1/3">
                                    <div>
                                        <div className="flex items-center gap-3 mt-5">
                                            {section.icon}
                                            <div className="flex flex-col">
                                                <p className="font-bold text-2xl">
                                                    {section.title}
                                                </p>
                                                <span className="text-gray-400">
                                                    {section.subtitle}
                                                </span>
                                            </div>
                                        </div>
                                        <p>
                                            <p
                                                ref={textRef}
                                                className={`text-gray-600 text-sm font-medium mt-4 ${isExpanded ? "" : "line-clamp-2"
                                                    }`}
                                            >
                                                {section.description}
                                            </p>
                                            {isTruncated && (
                                                <p
                                                    onClick={handleMoreClick}
                                                    className="inline-flex text-xs font-medium items-center text-[#EFB81C] cursor-pointer"
                                                >
                                                    {isExpanded ? "Less" : "More"}{" "}
                                                    <FaAngleRight className="ml-0.5" />
                                                </p>
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between flex-col gap-10 h-full">
                                    <div className="flex gap-6 justify-between mt-10 w-full flex-wrap self-start">
                                        <div className="flex flex-col gap-4">
                                            <div className="flex flex-col">
                                                <p className="text-sm text-gray-400">
                                                    Total Supply
                                                </p>
                                                <p className="font-medium">
                                                    {section.stats.volume}
                                                </p>
                                            </div>
                                            <div className="flex flex-col">
                                                <p className="text-sm text-gray-400">
                                                    Price
                                                </p>
                                                <p className="font-medium">
                                                    ${section.stats.price}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-4">
                                            <div className="flex flex-col">
                                                <p className="text-sm text-gray-400">
                                                    Buy Limit
                                                </p>
                                                <p className="font-medium">
                                                    ${section.stats.limit}
                                                </p>
                                            </div>
                                            <div className="flex flex-col">
                                                <p className="text-sm text-gray-400">
                                                    Listing Date
                                                </p>
                                                <p className="font-medium">
                                                    {section.stats.exchange_listing_date}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-4">
                                            <div className="flex flex-col">
                                                <p className="text-sm text-gray-400">
                                                    Remaining time
                                                </p>
                                                <p className="font-medium">
                                                    {countdowns[index] || "Loading..."}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-normal lg:justify-end w-full">
                                    <button
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevent Link navigation
                                                handleClick(section);
                                            }}
                                            className="bg-[#EFB81C] px-10 py-2 rounded-md text-md text-black font-medium animate-pulse"
                                        >
                                            View Detail
                                        </button>
                                    </div>
                                </div>
                                <div className="w-1/6 self-start mt-10">
                                    <div className="flex flex-col gap-4">
                                        <div className="flex flex-col">
                                            <p className="text-sm text-gray-400">
                                                Subscibed
                                            </p>
                                            <p className="font-medium">
                                                {section.stats.subscribed}
                                            </p>
                                        </div>
                                        <div className="mt-4">
                                            <Progress type="circle" percent={93} strokeColor={conicColors} format={(percent) => (
                                                <span className="dark:text-white text-black text-2xl">{percent}%</span>
                                            )} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        
                        {tradeValue !== null && (
                                <TradeNowPopup
                                    isPopupActive={isPopupActive}
                                    onOk={handleCancel}
                                    onCancel={handleOk}
                                    tradeValue={tradeValue}
                                />
                            )}
                    </div>
                ))}
            </div>
            <style>
                {`
                @keyframes scaleUpDown {
                  0%, 100% { transform: scale(1); }
                  50% { transform: scale(1.05); }
                }
                `}
            </style>
        </div>
    );
}

export default LatestProject;
