import React from 'react'
import { FaApple } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import { HiOutlineQrCode } from 'react-icons/hi2'
import { IoIosPhoneLandscape } from 'react-icons/io'
import heroSectionImg from 'assets/img/hero-section.png'
import { useSpring, animated } from '@react-spring/web';

const AnimatedNumber = ({ value }) => {
    if (isNaN(value) || value === "--") {
        return <span style={{ fontSize: '32px' }}>--</span>;
    }

    const { number } = useSpring({
        from: { number: 0 },
        to: { number: value },
        config: { mass: 1, tension: 100, friction: 20 },
    });

    return <animated.span style={{fontSize:'32px'}}>{number.to(n => Math.floor(n))}</animated.span>;
};

function HeroSection() {
    return (
        <>
        <div className='h-screen bg-gradient-to-b dark:from-[#826000] from-[#fff8e3] dark:to-[#000000] to-[#FFFFFF]'>
            <div className='container flex justify-between items-center'>
            <div className='w-1/2'>
                <h1 className='text-[64px] font-[800] leading-[84.7px]'>Where The Future Of Crypto Begins!</h1>
                <div className="flex flex-col mt-5 gap-3">
                    <div className="flex w-80 relative">
                        <input
                            className="bg-white dark:bg-[#17181E] border dark:border-[#454545] placeholder:font-medium placeholder:text-[#888888] rounded-full text-sm my-3 px-4 w-96 h-12 pr-24 focus:outline-none"
                            placeholder="Email/Phone Number"
                        />
                        <button className="w-32 h-10 font-medium text-white absolute right-1 top-4 rounded-full bg-[#EFB81C]">
                            Sign Up
                        </button>
                    </div>
                    <div className='flex gap-8'>
                        <div className='flex flex-col gap-4'>
                            <h3 className='text-sm text-[#888888] font-normal'>Or Continue With</h3>
                            <div className='flex gap-3'>
                                <div className="p-2 bg-white dark:bg-[#17181E] shadow-md border dark:border-[#454545] rounded-full cursor-pointer">
                                    <FaApple size={28} />
                                </div>
                                <div className="p-2 bg-white dark:bg-[#17181E] shadow-md border dark:border-[#454545] rounded-full cursor-pointer">
                                    <FcGoogle size={28} />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <h3 className='text-sm text-[#888888] font-normal'>Download The App</h3>
                            <div className='flex items-center gap-3'>
                                <div>
                                    <IoIosPhoneLandscape className='rotate-90' size={30} />
                                </div>
                                <div className="p-2 bg-white dark:bg-[#17181E] shadow-md border dark:border-[#454545] rounded-xl cursor-pointer">
                                    <HiOutlineQrCode size={26} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <img src={heroSectionImg} className='max-w-[590px]' alt='hero-section'/>
            </div>
            </div>
            <div className='container'>
            <div className='bg-white dark:bg-[#17181E] shadow-md rounded-3xl flex border dark:border-[#454545] py-8 px-3 mb-32'>
                <div className='flex flex-col gap-2 dark:border-[#454545] border-r-[2px] w-1/3 px-8'>
                    <p className='font-bold text-[32px] leading-none'>
                        <AnimatedNumber value={"--"} />
                    </p>
                    <p className='text-[18px] text-[#78716C] font-normal leading-none'>Countries Covered</p>
                </div>
                <div className='flex flex-col gap-2 border-r-[2px] dark:border-[#454545] w-1/3 px-8'>
                    <p className='font-bold text-[32px] leading-none'>
                        <AnimatedNumber value={"--"} />
                    </p>
                    <p className='text-[18px] text-[#78716C] font-normal leading-none'>Global Investors</p>
                </div>
                <div className='flex flex-col gap-2 border-r-[2px] dark:border-[#454545] w-1/3 px-8'>
                    <p className='font-bold text-[32px] leading-none'>
                        <AnimatedNumber value={"--"} />
                    </p>
                    <p className='text-[18px] text-[#78716C] font-normal leading-none'>Coins</p>
                </div>
                <div className='flex flex-col gap-2 w-1/3 px-8'>
                    <p className='font-bold text-[32px] leading-none'>
                        <AnimatedNumber value={"--"} /> 
                    </p>
                    <p className='text-[18px] text-[#78716C] font-normal leading-none'>24h Trading Volume</p>
                </div>
            </div>
        </div>
        </div>
        </>
    )
}

export default HeroSection
