import React, { useState } from 'react';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { RiExchangeDollarLine } from "react-icons/ri";
import { FaArrowRight } from "react-icons/fa6";
import TransferModal from 'views/trade/PostAds/TransferModal';
import ConvertModal from 'views/trade/PostAds/ConvertModal';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function EstimatedFiatBalace({ isVisible, toggleVisibility,data }) {
  const [activeButton, setActiveButton] = useState('deposit');
  const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
  const estimateBalance = data?.estimate_balance || {};
  const usdData = data?.usd || {}; 
  const frozenBalance = isVisible ? parseFloat(usdData.frozen || 0).toFixed(2) : '****';
  const kyBalance = isVisible ? parseFloat(usdData.ky || 0).toFixed(2) : '****';
  const totalBalanceUsd = isVisible ? parseFloat(usdData.total_balance || 0).toFixed(2) : '****';
  const zjBalance = isVisible ? parseFloat(usdData.zj || 0).toFixed(2) : '****';
  const handleTransferClick = () => {
    setIsTransferModalVisible(true);
  };

  const handleTransferCancel = () => {
    setIsTransferModalVisible(false);
  };

  const [isConvertModalVisible, setIsConvertModalVisible] = useState(false);
  const handleConvertClick = () => {
    setIsConvertModalVisible(true); 
  };

  const handleConvertCancel = () => {
    setIsConvertModalVisible(false); 
  };
 
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    if (buttonType === 'Transfer') {
      handleTransferClick();
    }
    else if (buttonType === 'Convert') {
      handleConvertClick();
    }
  };

  const getButtonClass = (buttonType) => {
    return buttonType === activeButton
      ? 'bg-[#EFB81C] px-3 py-2 rounded-md text-sm font-semibold'
      : 'border border px-3 py-2 rounded-md text-sm font-semibold';
  };
  return (
    <div className='flex w-full flex-col sm:flex-row justify-between mt-0 md:mt-6 p-3 md:p-6 rounded-2xl border'>
      <div>
        <div className='flex items-center gap-1'>
          <p className='text-[20px] font-semibold'>Total Balance</p>
          <button onClick={toggleVisibility} className='mt-1'>
            {isVisible ? <FaRegEyeSlash className='text-xl' /> : <FaRegEye className='text-xl' />}
          </button>
        </div>
        <div className='flex items-baseline gap-2'>
          <p className='text-[32px] pb-2 font-semibold'>{isVisible ? parseFloat(totalBalanceUsd).toFixed(2) : '*****'}</p>
          <span className='text-sm flex items-center gap-1 font-semibold'>USD<FaAngleDown className='text-[12px] opacity-40 mt-1' /></span>
        </div>
        <p className='text-sm'>
    {isVisible ? `≈ $${parseFloat(totalBalanceUsd).toFixed(2)}` : '****'}
</p>

      </div>
      <div className='flex flex-col mt-4 sm:mt-0 items-start gap-4 sm:gap-0 sm:items-end justify-between'>
      <div className='flex justify-between my-4 md:my-0 w-full'>
          <button
            onClick={() => handleButtonClick('deposit')}
            className={`flex-1 ${getButtonClass('deposit')}`}
          >
            Topup
          </button>
          <button
            onClick={() => handleButtonClick('Transfer')}
            className={`flex-1 ml-4 ${getButtonClass('Transfer')}`}
          >
            Transfer
          </button>
          <TransferModal visible={isTransferModalVisible} onCancel={handleTransferCancel} />
          <button
            onClick={() => handleButtonClick('Convert')}
            className={`flex-1 ml-4 ${getButtonClass('Convert')}`}
          >
            Convert
          </button>
          <ConvertModal visible={isConvertModalVisible} onCancel={handleConvertCancel} />
        </div>
        <div className='flex gap-3 items-center'>
          <RiExchangeDollarLine className='text-2xl bg-black rounded-full text-white' />
          <div>
            <p className='text-xs'>Buy Bcx & Be a Proud</p>
            <div className='flex items-center gap-1'><p className='text-xs'>Owner of BuyCex</p> <Link to='/earn/bcx-token' className="flex items-center gap-1"><button className='text-[#EFB81C] text-xs font-semibold'>Details</button><FaArrowRight className='text-[#EFB81C] text-xs' /></Link></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EstimatedFiatBalace;
