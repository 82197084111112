import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { BsQuestionCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { fetchMarketData } from "store/actions/marketActions";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { FaAngleRight } from "react-icons/fa";

function NewListings() {
    const dispatch = useDispatch();
    const marketData = useSelector((state) => state.market.data?.market || []);
    const { loading, error } = useSelector((state) => state.market);
    const [validIcons, setValidIcons] = useState({});

    useEffect(() => {
        dispatch(fetchMarketData());
    }, [dispatch]);

    const checkIconValidity = (url) => {
        const invalidUrls = ["https://api.buycex.com/Upload/coin/"];
        if (invalidUrls.includes(url)) {
            return Promise.resolve(false);
        }

        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = url;
        });
    };

    useEffect(() => {
        const validateIcons = async () => {
            const results = {};
            for (const coin of marketData) {
                if (coin.icon) {
                    results[coin.id] = await checkIconValidity(coin.icon);
                }
            }
            setValidIcons(results);
        };

        if (marketData.length > 0) {
            validateIcons();
        }
    }, [marketData]);

    const extractCoinName = (name) => {
        const match = name.match(/\(([^/]+)\//);
        return match ? match[1] : name;
    };

    const formatPrice = (price) => {
        const numPrice = Number(price);
        if (isNaN(numPrice)) return "N/A";
        if (numPrice >= 1e9) return `$${(numPrice / 1e9).toFixed(2)}B`;
        if (numPrice >= 1e6) return `$${(numPrice / 1e6).toFixed(2)}M`;
        if (numPrice >= 1e3) return `$${(numPrice / 1e3).toFixed(2)}K`;
        return `$${numPrice.toFixed(2)}`;
    };

    // New Listed
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    const newListed = marketData
        .map((coin) => ({
            ...coin,
            id: Number(coin.id),
        }))
        .sort((a, b) => b.id - a.id)
        .slice(0, 5)
        .map((coin) => ({
            ...coin,
            id: coin.id.toString(),
        }));

    if (error) {
            return (
                <div className="min-h-[30vh] w-full flex m-auto">
                    <p className="m-auto text-white text-[14px] font-[500]">
                        Error fetching market data.
                    </p>
                </div>
            );
        }

    return <div className="py-1 px-0 sm:px-2">
        <table className="w-full text-white text-left">
            <thead>
            </thead>
            <tbody>
            {loading
                        ? [...Array(5)].map((_, index) => (
                        <tr key={index} className="py-2.5">
                            <td className="py-2 flex items-center gap-2">
                                <Skeleton
                                    circle
                                    width={25}
                                    height={25}
                                    baseColor="#1A1A1A"
                                    highlightColor="#333"
                                />
                                <div className="flex gap-1.5 items-center">
                                    <Skeleton
                                        width={50}
                                        height={16}
                                        baseColor="#1A1A1A"
                                        highlightColor="#333"
                                    />
                                    {/* <Skeleton
                                        width={40}
                                        height={12}
                                        baseColor="#1A1A1A"
                                        highlightColor="#333"
                                    /> */}
                                </div>
                            </td>
                            <td className="py-2 text-center">
                                <Skeleton
                                    width={60}
                                    height={16}
                                    baseColor="#1A1A1A"
                                    highlightColor="#333"
                                />
                            </td>
                            <td className="py-2 text-end">
                                <Skeleton
                                    width={50}
                                    height={16}
                                    baseColor="#1A1A1A"
                                    highlightColor="#333"
                                />
                            </td>
                        </tr>
                    ))
                    : newListed.map((coin) => (
                        <tr key={coin.id}>
                            <td className="py-2.5 flex items-center gap-2">
                                {validIcons[coin.id] ? (
                                    <img
                                        src={coin.icon}
                                        alt={coin.name}
                                        className="w-6 h-6 object-contain rounded"
                                        title={coin.name}
                                        loading="lazy"
                                        decoding="async"
                                    />
                                ) : (
                                    <BsQuestionCircleFill className="w-5 h-5" />
                                )}
                                <div className="flex gap-1.5 items-center"><label className="font-medium uppercase">{coin.coinData?.name}</label>{/* <label className="text-[#848E9C] text-[14px]">{coin.coinData?.title}</label> */}</div>
                            </td>
                            <td className="py-2.5 text-center font-medium">{formatPrice(
                                coin.new_price
                            )}</td>
                            <td
                                className={`py-2.5 text-end font-medium ${coin.change >= 0 ? "text-green-400" : "text-red-400"
                                    }`}
                            >
                                {coin.change > 0
                                    ? `+${coin.change.toFixed(
                                        2
                                    )}%`
                                    : `${coin.change.toFixed(
                                        2
                                    )}%`}
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    </div>;
}

function HeroTable() {
    const [activeTab, setActiveTab] = useState("new");

    return (
        <div className="bg-transparent sm:bg-[#090a0c] border-[#454545]/30 border-0 sm:border rounded-2xl p-0 sm:p-2">
            {/* Navbar */}
            <div className="flex justify-between w-full items-center">
                <div className="flex space-x-6">
                    <button
                        className="relative px-2 py-2.5 font-semibold text-white"
                        onClick={() => setActiveTab("new")}
                    >
                        <label className={activeTab === "new" ? "text-white" : "text-white/40"}>
                            New Listings
                        </label>
                        {activeTab === "new" && (
                            <span className="absolute left-1/2 bottom-0 w-[16px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2"></span>
                        )}
                    </button>
                </div>
                <Link to="/market/overview" className="text-[#848E9C] hidden sm:flex text-sm items-center gap-1">
                    View All Coins
                    <FaAngleRight className="mt-0.5" />
                </Link>
            </div>

            <div>
                {activeTab === "new" ? <NewListings /> : <NewListings />}
            </div>
        </div>
    );
}

export default HeroTable;
