import React, { useEffect, useState } from "react";
import "../../components/home-page-sections/hero-section/./HeroSection.scss";
import LaunchpadProjects from '../../components/Launchpad/LaunchpadProjects';
const heroImages = [HeroImageOne, HeroImageTwo, HeroImageThird];
import HeroImageOne from "assets/img/Aboutsection4.png";
import HeroImageTwo from "assets/img/algo-orders.png";
import HeroImageThird from "assets/img/Aboutsection3.png";
import ProjectGrid from 'components/Launchpad/ProjectCard';
import LaunchProjectsSection from 'components/Launchpad/LaunchProjectsSection';
import GetStartedSection from 'components/Launchpad/GetStartedSection';
import StakingPool from "components/Launchpad/Startupmining/StakingPool ";

const LaunchGuard = () => {

  const flippingWords = ["Deposit USDT To Lock Token Before its Officially Listed in Exchange.", "Unlock USDT With Guarded Refund Any Time Without Assets Loss."];
  const [currentWord, setCurrentWord] = useState("Exchange Crypto");
  const [flipState, setFlipState] = useState("flip-enter");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageTransitionState, setImageTransitionState] = useState("fade-in");

  useEffect(() => {
    const wordInterval = setInterval(() => {
      setFlipState("flip-exit");

      setTimeout(() => {
        setCurrentWord((prev) => {
          const nextIndex =
            (flippingWords.indexOf(prev) + 1) %
            flippingWords.length;
          return flippingWords[nextIndex];
        });
        setFlipState("flip-enter");
      }, 600);
    }, 3000);

    return () => clearInterval(wordInterval);
  }, []);

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setImageTransitionState("fade-out");

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => {
          return (prevIndex + 1) % heroImages.length;
        });
        setImageTransitionState("fade-in");
      }, 600);
    }, 3000);

    return () => clearInterval(imageInterval);
  }, []);
  return (
    <>

<section className="hero-section-black flex relative">
                <div className="hero-section__wrapper-black container flex items-center justify-between gap-8 w-full m-auto max-md:flex-col">
                    <div className="hero-title-wrapper-black flex flex-col gap-2 w-[60%]">
                        <h1 className='hero-title-black flex gap-2'>
                            <span className='text-[32px] font-[900] text-[#EFB81C]'>Launch</span><span className='text-white text-[32px]'>Guard</span>
                        </h1>
                        <p className='text-md font-medium text-[#adb1b8] capitalize'>Lock Now ! Reveal If Token Price Increase On Listing</p>
                        <div className='flex w-[70%] justify-between items-center'>
                            <h1 className='hero-title-black uppercase font-bold text-white'><p>Launch Your Projects on Startup!</p></h1>
                        </div>
                        <p className='text-md text-[#adb1b8] font-semibold'>Join our platform now and give your project more exposure and development opportunities</p>
                        <p className="hero-description-black mt- font-semibold">
                            <span className={`flip-text-black ${flipState}`}>{currentWord}</span>{"  "}
                            <span className='text-white'></span>
                        </p>
                    </div>
                    {/* Right Section: Hero Image */}
                    <div className="flex flex-col items-center w-[40%]">
                        <div className="hero-image-wrapper-black relative w-full h-[10rem] rounded-xl overflow-hidden">
                            {heroImages.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`hero-img-${index}`}
                                    className={`hero-image-black absolute top-0 left-0 w-full rounded-xl object-contain transition-opacity duration-700 ${imageTransitionState} ${index === currentImageIndex ? "active" : "inactive"
                                        }`}
                                />
                            ))}
                        </div>
                        <button className='font-semibold text-sm border-2 rounded-full border-[#EFB81C] text-[#EFB81C] px-4 py-1'>
                            Become a Member Now!
                        </button>
                    </div>
                </div>
            </section>



        {/* Other Components */}
        <StakingPool />


        {/* <LaunchpadProjects /> */}

        {/* <ProjectGrid /> */}

        <LaunchProjectsSection />
        <GetStartedSection />

    </>

  );
};

export default LaunchGuard;
