import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssetsContext } from "store/actions/AssetsUserAction";
import CoinSelect from 'components/cryptoDeposit/CoinSelect';
import NetworkSelectDropdown from "components/cryptoWithdraw/NetworkSelectWithdraw";
import { Input } from 'antd';
import { MdOutlineBrightnessHigh } from "react-icons/md";
import NetworkSelectWithdrawModal from './NetworkSelectWithdrawModal';
import { Radio } from "antd";
import styled from "styled-components";
import { FiRefreshCcw } from "react-icons/fi";

import InternalTransfer from "components/cryptoWithdraw/InternalTransfer";

function InternalTransfers({ onClose }) {
  const [selectedCoin, setSelectedCoin] = useState(null);
    const [activeButton, setActiveButton] = useState("buycex");
    const [selectedOption, setSelectedOption] = useState('');
    const dispatch = useDispatch();
        const { assetsContext, loading: assetsLoading } = useSelector((state) => state.assetsContext || {});
      const [coins, setCoins] = useState([]);
          const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
 const [showInternalTransfer, setShowInternalTransfer] = useState(true);
   const [withdrawAmount, setWithdrawAmount] = useState("");
    const [networks, setNetworks] = useState([]);
     const [balance, setBalance] = useState("");
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleInternalTransferClick = () => {
        setShowInternalTransfer(true);
    };
     const [feesFlat, setFeesFlat] = useState("");
  
    const handleImageClick = (coin) => {
        setSelectedNetwork(null);
        setSelectedCoin(coin);
    };
   
   // Check if both coin network and withdrawal address are selected before continuing
   const handleButtonClick = () => {
    if (!selectedCoin) {
        toast.error("Please select a Coin");
        return;
    }
    if (!selectedNetwork) {
        toast.error("Please select a network.");
        return;
    }

    if (!withdrawAddress) {
        toast.error("Please provide a withdrawal address.");
        return;
    }
    if (!withdrawAmount) {
        toast.error("Please provide a withdrawal amount.");
        return;
    }

    setIsVisible(!isVisible);
};
 useEffect(() => {
        if (!TOKEN || !ID) {
            history.push("/login");
            return;
        }

        if (selectedCoin && TOKEN && ID) {
            fetch(
                `https://api.buycex.com/Crypto/coinbalance/coin/${selectedCoin.value}`,
                {
                    headers: {
                        TOKEN: TOKEN,
                        ID: ID,
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.data) {
                        setNetworks([
                            { value: data.data.symbol, label: data.data.title },
                        ]);
                        setBalance(data.data.total);
                        setLimit(data.data.zhehe);
                        setFeesPercent(data.data.fees_percent);
                        setFeesFlat(data.data.fees_flat);
                    } else {
                        console.log("No data available");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [selectedCoin, TOKEN, ID, history]);

        useEffect(() => {
            if (!TOKEN || !ID) {
                history.push("/login");
                return;
            }
    
            if (selectedCoin && TOKEN && ID) {
                fetch(
                    `https://api.buycex.com/Crypto/coinbalance/coin/${selectedCoin.value}`,
                    {
                        headers: {
                            TOKEN: TOKEN,
                            ID: ID,
                        },
                    }
                )
                    .then((response) => response.json())
                    .then((data) => {
                        if (data && data.data) {
                            setNetworks([
                                { value: data.data.symbol, label: data.data.title },
                            ]);
                            setBalance(data.data.total);
                            setLimit(data.data.zhehe);
                            setFeesPercent(data.data.fees_percent);
                            setFeesFlat(data.data.fees_flat);
                        } else {
                            console.log("No data available");
                        }
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    });
            }
        }, [selectedCoin, TOKEN, ID, history]);
      useEffect(() => {
          if (!TOKEN || !ID) {
              console.log("Redirecting to sign-in");
              history.push("/login");
              return;
          }
          if (assetsContext?.cryptoList) {
              const coinsData = [];
  
              // Process cryptoList
              Object.keys(assetsContext.cryptoList).forEach((coinKey) => {
                  const coin = assetsContext.cryptoList[coinKey];
  
  
  
                  coinsData.push({
                      value: coinKey.toUpperCase(),
                      label: coinKey.toUpperCase(),
                      icon: `${coin.img}`,
  
                  });
              });
  
              // Set the coins data to state
              setCoins(coinsData);
          }
      }, [assetsContext]);
     useEffect(() => {
            if (!ID || !TOKEN) {
                history.push('/login');
                return;
            }
            dispatch(fetchAssetsContext(ID, TOKEN, "spot","withdraw"));
        }, [dispatch, ID, TOKEN, history]);

        const handleWithdraw = async () => {
            // Check if withdrawal address is empty or null
            if (!withdrawAddress) {
                toast.error("Please provide a withdrawal address.");
                return;
            }
    
            // Check if the withdrawal address format matches the selected coin
            if (selectedCoin.value === "LTC" && !withdrawAddress.startsWith("L")) {
                toast.error("Invalid withdrawal address format for LTC.");
                return;
            }
    
            if (selectedCoin.value === "TRX") {
                if (!withdrawAddress.startsWith("TP")) {
                    toast.error("Invalid withdrawal address format for TRX.");
                    return;
                }
    
                if (withdrawAddress.length !== 34) {
                    toast.error(
                        "Withdrawal address for TRX must be 34 characters long."
                    );
                    return;
                }
            }
    
            if (selectedCoin.value === "XRP") {
                if (!withdrawAddress.startsWith("r")) {
                    toast.error("Invalid withdrawal address format for XRP.");
                    return;
                }
    
                if (withdrawAddress.length !== 34) {
                    toast.error(
                        "Withdrawal address for XRP must be 34 characters long."
                    );
                    return;
                }
            }
    
            if (selectedCoin.value === "TUSDT") {
                if (!withdrawAddress.startsWith("TP")) {
                    toast.error("Invalid withdrawal address format for TUSDT.");
                    return;
                }
    
                if (withdrawAddress.length !== 34) {
                    toast.error(
                        "Withdrawal address for TUSDT must be 34 characters long."
                    );
                    return;
                }
            }
    
            // Check for other coins and their withdrawal address format
            if (!["LTC", "TRX", "XRP", "TUSDT"].includes(selectedCoin.value)) {
                // if (!withdrawAddress.startsWith("0x")) {
                //     toast.error(
                //         "Invalid withdrawal address format for the selected coin."
                //     );
                //     return;
                // }
                // if (withdrawAddress.length !== 42) {
                //     toast.error("Withdrawal address must be 42 characters long.");
                //     return;
                // }
            }
    
            // Proceed with the withdrawal
            const url = "https://api.buycex.com/Crypto/doWithdraw";
    
            const data = {
                coin: selectedCoin.value.toLowerCase(),
                num: withdrawAmount,
                addr: withdrawAddress,
                paypassword: fundPassword,
            };
    
            const options = {
                method: "POST",
                headers: {
                    TOKEN: TOKEN,
                    ID: ID,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            };
    
            try {
                const response = await fetch(url, options);
                console.log("Done the response", response);
                const responseData = await response.json();
                if (responseData.status === 1) {
                    toast.success("Successfully transferred the balance.");
                    setIsVisible(false);
                    setFundPassword("");
                    setWithdrawAddress("");
                    setWithdrawAmount("");
                } else {
                    toast.error(`Error: ${responseData.data}`);
                }
            } catch (error) {
                console.error("Error:", error);
                toast.error("An error occurred while processing the withdrawal.");
            }
        };

    return (
        <>
            <div className="flex flex-col lg:flex-row w-full gap-6 justify-between">
                <div className="w-full mt-5">
                <CoinSelect
                                                coins={coins}
                                                selectedCrypto={selectedCoin}
                                                setSelectedCrypto={setSelectedCoin}
                                                handleImageClick={handleImageClick}
                                            />
                </div>
            </div>
           

            {showInternalTransfer && (
                                        <InternalTransfer
                                        feesFlat={feesFlat}
                                        withdrawAmount={withdrawAmount}
                                        balance={balance}
                                            selectedCoin={selectedCoin}
                                            setSelectedCoin={setSelectedCoin}
                                        />
                                    )}
        
           
        </>
    );
}

const StyledRadio = styled(Radio)`
    .ant-radio-inner {
        border-color: #efb81c;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #efb81c;
        background-color: #efb81c;
    }

    .ant-radio-checked .ant-radio-inner::after {
        background-color: #fff;
    }

    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2);
    }

    .ant-radio-wrapper {
        color: #1f2937;
    }
`;

export default InternalTransfers;
