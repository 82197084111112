import Repository from "./Repository";
const AssetsEndPoint = "/Finance/assets";
const spotAssetsEndPoint = "/Login/spotAssets";
const AssetsContextEndPoint = "Finance/AssetsContext";
const FinanceOrders = "/Finance/orders";
const FinanceOpenOrders = "/Finance/openOrders";
const FinanceOrdersHistoryEndPoint = "/Finance/orders_history";
const FinanceOrdersTradeHistoryEndPoint = "/Finance/trade_history";
const FinanceUserSpotEndPoint = "/Finance/userSpot";
const FinanceUserFundingEndPoint = "/Finance/userFunding";
const FinanceUserEarnEndPoint = "/Finance/userEarn";
const FiatBalanceEndPoint = "User/FiatBalance";
const LaunchPool = "/LaunchPool/launchPoolSummary";
const NewEvent = "/LaunchPool/index";
const PollRewards = "/LaunchPool/launchPollRewards";
const yieldRewards = "/LaunchPool/yieldSummary";
const createstake = "/LaunchPool/createUserStake";
const getlaunchpool = "/LaunchPool/allSubs";
const subscribepool = "/LaunchPool/subscribeLaunchPool";
const getProjectDetails = "/LaunchPool/getProjectDetails";
const cancelSub = "/LaunchPool/cancelSub";
const claimreward = "/LaunchPool/claimReward";
const userbalance = "/LaunchPool/getUserBalance";

export default {
    getAssetsData(userId, token, type) {
        return Repository.get(`${AssetsEndPoint}`, {
            params: {
                user_id: userId,
                type: type,
            },
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    getspotAssetsData(userId, token) {
        return Repository.get(`${spotAssetsEndPoint}`, {
            params: {
                user_id: userId,
            },
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    getAssetsContext(userId, token, type, context) {
        return Repository.get(`${AssetsContextEndPoint}`, {
            params: {
                user_id: userId,
                type: type,
                context: context, // Add the context parameter
            },
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    FinanceUserSpot(userID, userToken) {
        return Repository.get(`${FinanceUserSpotEndPoint}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    FinanceUserFunding(userID, userToken) {
        return Repository.get(`${FinanceUserFundingEndPoint}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    FinanceUserEarn(userID, userToken) {
        // New method for userEarn
        return Repository.get(`${FinanceUserEarnEndPoint}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    getlaunchpool(userID, userToken) {
        // New method for userEarn
        return Repository.get(`${getlaunchpool}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    userbalance(userID, userToken) {
        // New method for userEarn
        return Repository.get(`${userbalance}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    FinanceOrders(userID, userToken, payload) {
        return Repository.post(`${FinanceOrders}`, payload, {
            headers: {
                token: userToken,
                id: userID,
                "Content-Type": "Multipart/form-data",
                Accept: "application/json",
            },
        });
    },
    createstake(userID, userToken, formData) {
        return Repository.post(`${createstake}`, formData, {
            headers: {
                token: userToken,
                id: userID,
                "Content-Type": "Multipart/form-data",
                Accept: "application/json",
            },
        });
    },
    subscribepool(userID, userToken, payload) {
        return Repository.post(`${subscribepool}`, payload, {
            headers: {
                token: userToken,
                id: userID,
                "Content-Type": "Multipart/form-data",
                Accept: "application/json",
            },
        });
    },
    cancelSub(userID, userToken, payload) {
        return Repository.post(`${cancelSub}`, payload, {
            headers: {
                token: userToken,
                id: userID,
                "Content-Type": "Multipart/form-data",
                Accept: "application/json",
            },
        });
    },

    FinanceOpenOrders(userID, userToken) {
        return Repository.get(`${FinanceOpenOrders}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },

    FinanceOrdersHistory(userID, userToken) {
        return Repository.get(`${FinanceOrdersHistoryEndPoint}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },

    FinanceOrdersTradeHistory(userID, userToken) {
        return Repository.get(`${FinanceOrdersTradeHistoryEndPoint}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    getFiatBalance(userID, userToken) {
        return Repository.get(`${FiatBalanceEndPoint}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    getLauchPool(userID, userToken) {
        return Repository.get(`${LaunchPool}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    getNewEvent(userID, userToken) {
        return Repository.get(`${NewEvent}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    getPollRewards(userID, userToken) {
        return Repository.get(`${PollRewards}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    getYieldRewards(userID, userToken) {
        return Repository.get(`${yieldRewards}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    getProjectDetails(userID, userToken, ids) {
        return Repository.get(`${getProjectDetails}?id=${ids}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    claimreward(userID, userToken, ids) {
        return Repository.get(`${claimreward}?id=${ids}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
};
